import React, { useEffect, useState } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DashboardActions from './DashboardActions';
import { getCurrentProfile, clearProfile, cleargid, corgetTFCcandlist, savecomments, corgetTFCincandlist } from '../../actions/profile';
import {getProfileVer, getProfilePdf, getProfileUpd, getProfileCor, changeAppStatus, clearApplication, checkfreezecand, checkunfreezecand, movetoIneligible} from '../../actions/vermaster';
import { getProfileAdmin } from '../../actions/master';
import { TFC_CENTER, INELIGIBLE_LIST } from '../../Constant';
import Spinner from '../layout/Spinner';
import Pagination from './Pagination';
import {listAll, openCert, gettfc} from '../../actions/dload';
import moment from 'moment';
const {TRANS_COLLEGES_LIST, NDEPT_LIST} = require("../../Constant2");


const CorSelect = ({
  getCurrentProfile, corgetTFCcandlist, getProfileVer, getProfilePdf, getProfileUpd, getProfileCor,
  clearProfile, changeAppStatus, checkfreezecand, checkunfreezecand, corgetTFCincandlist, movetoIneligible, listAll,
  auth: { user },
  profile: { profile3, gt, loading, getdb, profile4 },
  history
}) => {

  const [displaycmtscc, toggledisplaycmtscc] = useState(false);

  const [displaycmts, toggledisplaycmts] = useState(false);


const [currentPage, setCurrentPage] = useState(1);
const [postsPerPage] = useState(100);

var indexoflastpost = currentPage * postsPerPage;
var indexoffirstpost = indexoflastpost - postsPerPage;
var currentList;
if(profile3 !== null && profile3 !== undefined)
  if(profile3.length !== 0) 
      currentList = profile3.slice(indexoffirstpost, indexoflastpost);


      if(profile4 !== null && profile4 !== undefined)
      if(profile4.length !== 0) 
          currentList = profile4.slice(indexoffirstpost, indexoflastpost);
    

      const paginate = pageNumber => setCurrentPage(pageNumber);

     
    


  const [formData, setFormData] = useState({
    comment: '', appln: '', sgid: '', sgt: '', aname: '', cremarks: '', dispaid: '', elist: "", cremarksc: '',
  });

  const { stfc, ntfc, comment, appln, sgid, sgt, aname , cremarks, dispaid, elist, cremarksc} = formData;

  const navigate = useNavigate();


  
  const handleVerifyRow = async (aid) => {    
    console.log(aid);
    formData.appln = aid;
    await timeout(1000);    
    await getProfileVer(formData);
    await listAll(aid);  
    navigate("/verify2");
  }

  const handleVerifyRow2 = async (aid) => {    
    console.log(aid);
    formData.appln = aid;
    await timeout(1000);    
    await getProfileVer(formData);
    await listAll(aid);
    await timeout(1000);  
    navigate("/vdload");
  }

  function timeout(delay) {
    return new Promise( res => setTimeout(res, delay) );
  }

  const handleEditRow = async (aid) => {    
    console.log(aid);    
    await getProfileUpd(aid);       
    navigate("/adash");
  }

  const handlepend = async (aid) => {    
    console.log(aid);    
    await getProfileCor(aid);       
    navigate("/inlist");
  }

  const dispcmts = async (aid) => {
    formData.dispaid = aid;
    toggledisplaycmts(!displaycmts);
  }

  const dispcmtscc = async (aid) => {
    formData.dispaid = aid;
    toggledisplaycmtscc(!displaycmtscc);
  }


  const handleDownRow = async (aid) => {        
      await getProfilePdf(aid);
      navigate('/download2'); 
  }

  const corgetalltfcapps = async(aid) => {
    if(user !== null && user !== undefined && user.ltype.toString() === "C")
    //if(user !== null && user !== undefined && user.ltype.toString() === "V")
      {
          await clearProfile();
         corgetTFCcandlist(user.tfccode);
      }
    
  }

  const corgetintfcapps = async(aid) => {
   navigate('/coord2');
    
  }

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });


  const onCodeChange = e => {

    //e.preventDefault();

    let temp = e.target.value;

    if((e.target.name === "stfc")) {
      formData.ntfc = TFC_CENTER.filter(el => el.key.toString().includes(temp.toString())).map(el => el.label)[0];
    }

    if((e.target.name === "ntfc")) {
      formData.stfc = TFC_CENTER.filter(el => el.label.toString().includes(temp.toString())).map(el => el.key)[0];
    }
    
    

  setFormData({ ...formData, [e.target.name] : e.target.value });  

    //this.setState({name: e.target.value});

  }


  const candlist = profile3 !== null && profile3 !== undefined && currentList !== null && currentList !== undefined && currentList.length > 0 && currentList.map((edu, ind) => (
    <tr key={edu._aid}>
      <td> {ind+1} </td>
      <td> {edu.college} - {TRANS_COLLEGES_LIST.filter(e => e.key === edu.college).map(e => e.label)} </td>
      {/* <td>{edu.cv && edu.cv.sch}</td> */}
      <td style={{fontWeight: "bold"}}>{edu._aid}</td>   
      <td>{edu._n}</td>   
      <td>{edu._p && edu._p._bran && NDEPT_LIST.filter(e => e.key === edu._p._bran).map(e => e.label)} - {edu._p && edu._p._desig} </td>

      <td>{edu.m} </td>   

       <td> 
            {edu._st && edu._st.toString() === "preview" && edu._fzup === true &&
              <button className="btn btn-light" style={{fontSize: "9pt", fontWeight: "bold", color: "navy", border: "1px solid brown"}} onClick={i => handleVerifyRow(edu._aid)} > Details </button> 
            }
            
      </td>  

      {/* <td> 
            {edu._st && edu._st.toString() === "preview" && edu._fzup === true &&
              <button className="btn btn-light" style={{fontSize: "9pt", fontWeight: "bold", color: "navy", border: "1px solid brown"}} onClick={i => handleVerifyRow2(edu._aid)} > Uploaded Documents </button> 
            }
            
      </td>     */}

      {edu.status && edu.status.toString() === "complete" && 

          <td> <button className="btn btn-light" style={{fontSize: "9pt", fontWeight: "bold", color: "blue", border: "1px solid black"}} onClick={i => handleDownRow(edu._aid)} > Download PDF </button> </td>
          }  
                
        </tr>     
  ));







  const changeVerStatus = async(_aid, st) => {
    toggledisplaycmts(!displaycmts);
    const tobesent = {};
    tobesent.oldstatus = st;
    tobesent.status = "open";    

    tobesent.tfccode = user.tfccode;
    tobesent.username = user.username;
    tobesent.ltype = user.ltype;
    tobesent.gappid = _aid;
    tobesent.cremarks = cremarks;
    if(tobesent.cremarks === '')
      alert("pls enter comments");
    else {
      changeAppStatus(tobesent, navigate);
      clearProfile();
      navigate("/");
    //clearProf(e);
    }
  }

  const changeUpdStatus = async(_aid, st) => {
    toggledisplaycmts(!displaycmts);
    const tobesent = {};
    tobesent.oldstatus = st;
    tobesent.status = "toupdate";    
    tobesent.tfccode = user.tfccode;
    tobesent.username = user.username;
    tobesent.ltype = user.ltype;
    tobesent.gappid = _aid;
    tobesent.cremarks = cremarks;

    if(tobesent.cremarks === '')
      alert("pls enter comments");
    else {
      changeAppStatus(tobesent, navigate);
      clearProfile();
      navigate("/");
    //clearProf(e);
    }

  }

  const gofreezecand = async(aid) => {
      
    await checkfreezecand(aid, navigate);    
  
    clearProfile();

    navigate("/");
        
  };

  const gounfreezecand = async (aid) => {
    
    await checkunfreezecand(aid, navigate);     
    
    clearProfile();

    navigate("/");

  
  };

  const clearNext = async() => {
    //clearProfile(history, "3");   
    await clearProfile();
    await corgetTFCcandlist(formData.stfc);
  }




  const OnInelig = async (aid, st) => {    

    const tobesent = {};
    tobesent.appId = aid;
    if(formData.elist.toString() === "")
      tobesent.elist = INELIGIBLE_LIST[0].label;
    else
      tobesent.elist = formData.elist;
    tobesent.ineremarks = formData.cremarks;
    tobesent.oldstatus = st;
    tobesent.status = "ineligible";    

    tobesent.tfccode = user.tfccode;
    tobesent.username = user.username;
    tobesent.ltype = user.ltype;
    
    console.log(tobesent);

    if(tobesent.ineremarks === '')
        alert("pls enter comments");
    else {
        await movetoIneligible(tobesent, history);    
    }

    formData.cremarks = "";
    toggledisplaycmts(false);
    formData.elist = "";

  };


  const OnCComplete = async (aid, st) => {
    //e.preventDefault();
    const tobesent = {};
    tobesent.oldstatus = st;
    //formData.status = 'ccomplete';

    //console.log("Appln no status changed as complete " + formData.gappid);
    //console.log("tfcname " + user.tfcname + "username " + user.username + "ltype " + user.ltype);
    tobesent.status = "ccomplete";    
    tobesent.tfccode = user.tfccode;
    tobesent.username = user.username;
    tobesent.ltype = user.ltype;
    tobesent.gappid = aid;
    tobesent.cremarks = cremarksc;

    if(tobesent.cremarks === '')
      alert("pls enter comments");
    else {
      changeAppStatus(tobesent, navigate);
      clearProfile();
      navigate("/");
    //clearProf(e);
    }

  }


  const candlist2 = profile4 !== null && profile4 !== undefined && currentList !== null && currentList !== undefined && currentList.length > 0 && currentList.map((edu, ind) => (
    <tr key={edu._aid}>
      <td> {ind+1} </td>
      {/* <td>{edu.cv && edu.cv.sch}</td> */}
      <td style={{fontWeight: "bold"}}>{edu._aid} <br/> {edu._n}</td>   
      
      {/* <td>{edu.cv && edu.cv.d}</td>    */}
      <td>{edu.cv && edu.cv.vo} <br/> {edu.cv.upd !== undefined && edu.cv.upd[edu.cv.upd.length-1].un} </td>
          
    {edu.cv && edu.cv.st && edu.cv.st !== undefined && (edu.cv.st.toString() === "incomplete") ? <td style={{color: "darkred", fontSize: "10pt"}}> INCOMPLETE <br/> Mobile Number: {edu.m}   </td>  : null  }

    {edu.cv && edu.cv.st && edu.cv.st !== undefined && (edu.cv.st.toString() === "ineligible") ? <td style={{color: "crimson", fontSize: "10pt"}}> INELIGIBLE <br/> Mobile Number: {edu.m}   </td>  : null  }
          

          {(edu.cv && edu.cv.st && edu.cv.st.toString() === "incomplete" && (edu.cv.icl === undefined || edu.cv.icl.length === 0)) ?

          <td> 
            <div style={{color: "crimson"}}> Incomplete list of Certificates is pending </div>                         
          </td> : null
          
          }

{(edu.cv && edu.cv.st && edu.cv.st.toString() === "ineligible" && (edu.cv.inel !== undefined && edu.cv.inel.length > 0)) &&
<div style={{textAlign: "center", color: "crimson", paddingTop: "10px", fontWeight: "bold"}}>
<label> INELIGIBLE REASONS </label> <br/>
          {edu.cv.inel[edu.cv.inel.length-1].el}
</div>
}

          
{(edu.cv && edu.cv.st && edu.cv.st.toString() === "incomplete" && (edu.cv.icl !== undefined && edu.cv.icl.length > 0)) &&
<>
<div style={{textAlign: "center",  color: "darkred", fontWeight: "bold"}}> Pending Certificates List </div>          
           
    {edu.cv.icl[edu.cv.icl.length-1].bna !== false ? <div> *Bonafide Certificate (Nativity others) not available* </div> : null} 

  {edu.cv.icl[edu.cv.icl.length-1].tenna !== false ? <div>  *Xth marksheet not available*  </div> : null} 

  {edu.cv.icl[edu.cv.icl.length-1].elef !== false ? <div> *XIth: Fail in a Subject*  </div> : null}  

  {edu.cv.icl[edu.cv.icl.length-1].elena !== false ? <div>  *XIth marksheet not available* </div> : null} 

  {edu.cv.icl[edu.cv.icl.length-1].twena !== false ? <div>   *XIIth marksheet not available* </div> : null} 

  {edu.cv.icl[edu.cv.icl.length-1].twef !== false ? <div>  *XIIth: Fail in a Subject*  </div> : null} 

  {edu.cv.icl[edu.cv.icl.length-1].twiv ? <div>  *XIIth: Invalid*  </div> : null} 

  {edu.cv.icl[edu.cv.icl.length-1].pcm !== false ? <div>  *Physics, Chemistry, Mathematics not studied* </div> : null} 

  {edu.cv.icl[edu.cv.icl.length-1].indn !== false ? <div>  *Nationality not indian*  </div> : null} 

  {edu.cv.icl[edu.cv.icl.length-1].natina !== false ? <div>  *TN Nativity Certificate not available* </div> : null} 

  {edu.cv.icl[edu.cv.icl.length-1].natif !== false ? <div>  *TN Nativity Certificate not in format* </div> : null} 

  {edu.cv.icl[edu.cv.icl.length-1].pona !== false ? <div> *Parent Occupation Certificate not available*  </div> : null} 

  {edu.cv.icl[edu.cv.icl.length-1].ccna !== false ? <div> *TN Community Certificate not available*  </div> : null} 

  {edu.cv.icl[edu.cv.icl.length-1].ccf !== false ? <div>  *TN Community Certificate not in acceptable format* </div> : null} 
       
  {edu.cv.icl[edu.cv.icl.length-1].dpna !== false ? <div> *Disability Certificate not available*  </div> : null} 

  {edu.cv.icl[edu.cv.icl.length-1].dpf !== false ? <div>  *Disability Certificate not in acceptable format* </div> : null} 

  {edu.cv.icl[edu.cv.icl.length-1].exsna !== false ? <div> *Ex-Serviceman Certificate not available*  </div> : null} 

  {edu.cv.icl[edu.cv.icl.length-1].exsf !== false ? <div>  *Ex-Serviceman Certificate not in acceptable format* </div> : null} 

  {edu.cv.icl[edu.cv.icl.length-1].slna !== false ? <div> *Srilankan Refugee Certificate not available*  </div> : null} 

  {edu.cv.icl[edu.cv.icl.length-1].slf !== false ? <div>  *Srilankan Refugee Certificate not in acceptable format* </div> : null} 

  {edu.cv.icl[edu.cv.icl.length-1].fgna !== false ? <div>  *First Graduate Certificate not available* </div> : null} 

  {edu.cv.icl[edu.cv.icl.length-1].inna !== false ? <div>  *Income Certificate not available* </div> : null} 

  </>


}     

<td> No.of.docs uploaded: {edu._docs !== undefined && edu._docs.length} <br/><span style={{color: "blue"}}> {edu._alPeC ? "Pending Certificates - Upload is Open" : "Pending Certificates - Upload Freezed"} </span> </td>


<td> {edu._docs !== undefined && edu._docs.filter((e) => e.id.toString() === "pendingCertificate").length > 0 ? 
    <>
            <a  target="_blank" rel="noopener noreferrer"  style={{textDecoration: "underline", color: "crimson", fontWeight: "bold"}} href={edu._docs.filter((e) => e.id.toString() === "pendingCertificate").map((e) => e.durl)[0]}> Click here to Download Pending Certificates </a>  
            {edu.cv && (edu.cv.st === "incomplete") &&
          
                <button type="submit" style={{fontSize: "9pt", color: "white", fontWeight: "bold"}}  value="" className="my-2 btn btn-primary" onClick={(i) => dispcmtscc(edu._aid)}> <i class="fa fa-toggle-on" aria-hidden="true"></i> Move this application to Complete  </button>               
              
            }

          {edu.cv && ((edu.cv.st === "incomplete")) && displaycmtscc &&  (
                
                edu._aid === dispaid &&
                  <>
                <div className="my-2" style={{fontSize: "9pt", color: "black", fontWeight: "bold"}}> Enter Coordinator Comments </div>
                <textarea cols="30" style={{textAlign: "center"}} rows="2" className="textarea2" name="cremarksc" value={formData.cremarksc}  onChange = {e => onChange(e)}/>

                
                <button type="submit" style={{fontSize: "8pt"}}  value="" className="btn btn-dark" onClick= {i => OnCComplete(edu._aid, edu.cv.st)}> Confirm </button> 
              <br/>
                
                </>
              )}

    </>
: "Upload not completed"} </td>

<td style={{fontSize: "8pt"}}>

          {edu.cv && (edu.cv.st === "incomplete") &&
          
            <button type="submit" style={{fontSize: "9pt", color: ""}}  value="" className="my-2 btn btn-dark" onClick={(i) => dispcmts(edu._aid)}> <i class="fa fa-toggle-on" aria-hidden="true"></i> Move this application to Ineligible  </button>               
          
          }

      {edu.cv && ((edu.cv.st === "incomplete")) && displaycmts &&  (
            
            edu._aid === dispaid &&
              <>
            <div className="my-2" style={{fontSize: "9pt", color: "black", fontWeight: "bold"}}> Enter Coordinator Comments </div>
            <textarea cols="50" style={{textAlign: "center"}} rows="2" className="textarea2" name="cremarks" value={formData.cremarks}  onChange = {e => onChange(e)}/>

            <div className="form-group">
                <small className="form-text">         
                <label style={{color: "navy", fontSize: "10pt", fontWeight: "bold"}}> Please Select Ineligible Category </label> </small>
                <select name="elist" style={{color: "black", fontSize: "9pt"}} value={elist} onChange = {e => onChange(e)}>                           
                    {INELIGIBLE_LIST.map(e => <option key={e.key}> {e.label} </option>) }
                </select>
                </div>
            
            <button type="submit" style={{fontSize: "8pt"}}  value="" className="btn btn-dark" onClick= {i => OnInelig(edu._aid, edu.cv.st)}> Confirm </button> 
          <br/>
            
            </>
          )}

          </td>

          
        </tr>     
  ));


  return (
    <section className="container">
      {/* <h1 className="large text-primary">{user.tfcname}</h1> */}      

      <h5 style={{color: "crimson", textDecoration: "underline", textAlign: "center"}}> List of Transfer Applications </h5>

      <br />

      
       <button className='btn btn-dark' onClick={i => corgetintfcapps(1000)}> Back to Colleges List  </button>      

        {profile3 === null && loading === true && getdb === true ? (
        <Spinner /> ) : null}

        {profile3 !== null && profile3 !== undefined && 
      <>
          
          <div className='table-responsive'>
          <table className="table table-striped" style={{border: '2px ridge', fontSize: "10pt"}}>
            <thead className='bg-info' style={{fontSize: "9pt", color: "blue"}}>
              <tr>
                <th>Sl.No</th>
                {/* <th scope='col'>Schedule</th>                 */}
                <th> College </th>
                <th scope='col'>Application number</th>                
                <th scope='col'>Name</th>
                <th scope='col'>Department & Designation</th>
                
                <th scope='col'>Mobile Number</th>
                <th scope='col'>Application Details</th>                         
                <th scope='col'>Download Report</th>                         
                
              </tr>
                
            </thead>
            <tbody>{candlist}</tbody>
          </table>


          {profile3 && profile3.length > 0 &&
            <>
            <div className="p-2" />
            <div className="" style={{color: "teal", textAlign: "center", fontSize: "8pt"}}>Pages</div>
            <div className="p-1" />
            <Pagination postsPerpage={postsPerPage} totalPosts={Math.ceil(profile3.length / postsPerPage)}  paginate={paginate} />
            </>
          }

          </div>
      </>
      }      


{profile4 !== null && profile4 !== undefined && 

      <>
      <div className='profile-top' style={{margin: "10px", paddingLeft: "100px"}}>
  <h6>  </h6>
      <table className='table table-responsive' style={{fontSize: "10pt", padding: "10px"}}>
        <thead>
          <th scope="col">Sl.No</th>
          <th scope="col">Ineligible Category</th>
          <th scope="col">Result</th>
        </thead>
        {INELIGIBLE_LIST.map((edu, ind) => (
            <tr key={edu.ind}>
                <td> {ind+1} </td> 
                <td> {edu.label} </td> 
                <td> {edu.res} </td> 
            </tr>
        ))}
      </table>
</div>

          <h5 className="my-2">List of Incomplete Candidates </h5>
          <label style={{textAlign: "center", color: "darkred"}}> Kindly ask the candidate to upload all pending certificates as a single PDF </label>
          <div className='table-responsive'>
          <table className="table table-striped" style={{border: '2px ridge', fontSize: "10pt"}}>
            <thead className='bg-info' style={{fontSize: "9pt", color: "blue"}}>
              <tr>
                <th>Sl.No</th>
                {/* <th scope='col'>Schedule</th>                 */}
                <th scope='col'>Application number / Name</th>                
                
                
                {/* <th scope='col'>CV Date</th> */}
                <th scope='col'>Verification Login</th>
                <th scope='col'>CV Status</th>
                <th scope='col'>List of pending certificates</th>           
                <th scope='col'>Upload Status</th>                
                <th scope='col'>Pending Certificate Link</th>                
                <th scope='col'>Ineligible Status</th>                                  
              </tr>
                
            </thead>
            <tbody>{candlist2}</tbody>
          </table>


          {profile4 && profile4.length > 0 &&
            <>
            <div className="p-2" />
            <div className="" style={{color: "teal", textAlign: "center", fontSize: "8pt"}}>Pages</div>
            <div className="p-1" />
            <Pagination postsPerpage={postsPerPage} totalPosts={Math.ceil(profile4.length / postsPerPage)}  paginate={paginate} />
            </>
          }

          </div>
      </>
      }      


    </section>
  );
};

CorSelect.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  corgetTFCcandlist: PropTypes.func.isRequired,
  getProfileAdmin: PropTypes.func.isRequired,
  getProfileVer: PropTypes.func.isRequired,
  getProfilePdf: PropTypes.func.isRequired,
  getProfileUpd: PropTypes.func.isRequired,
  getProfileCor: PropTypes.func.isRequired,
  changeAppStatus: PropTypes.func.isRequired,
  checkfreezecand: PropTypes.func.isRequired,
  checkunfreezecand: PropTypes.func.isRequired,
  corgetTFCincandlist: PropTypes.func.isRequired,
  movetoIneligible: PropTypes.func.isRequired,

  clearProfile: PropTypes.func.isRequired,
  cleargid: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile
});

export default connect(mapStateToProps, { getCurrentProfile, clearProfile, cleargid, corgetTFCcandlist, getProfileVer, getProfilePdf, listAll,
getProfileAdmin, getProfileUpd, getProfileCor, changeAppStatus, checkfreezecand, checkunfreezecand, corgetTFCincandlist, movetoIneligible })(
  CorSelect
);
