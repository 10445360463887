import axios from 'axios';
import { setAlert } from './alert';
import setAuthToken from '../utils/setAuthToken';
import api from '../utils/api';

import {
    GET_PROFILE,
    PROFILE_ERROR,
    CLEAR_PROFILE, EDIT_PROFILE,
    GET_DDREPORT,
    LOGOUT,
    CLEAR_ALLCANDS, SET_DBCALL, RESET_DBCALL, SET_GT,
} from './types';


// Get profile by ID
export const getProfileById = (appId, history) => async dispatch => {
  try {    

    dispatch({ type: CLEAR_PROFILE });

    dispatch({
      type: SET_DBCALL
    });


      if (localStorage.token) {
        setAuthToken(localStorage.token);
      }
  
    console.log("Invoking get profile by ID " + appId);
    const res = await api.get(`/master/user/${appId}`);

    dispatch({
      type: GET_PROFILE,
      payload: res.data
    });      
    
    console.log("output for get profile by id");

    dispatch({
      type: RESET_DBCALL
    });

  } 
  
  catch (err) {

        console.log(err);
        dispatch({
          type: RESET_DBCALL
        });

        if(err.response.status === 400) {   // no profile found
          dispatch(setAlert("Incorrect Email address", 'danger'));
          dispatch({
            type: PROFILE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
            });  

          }

      if(err.response.status === 403 || err.response.status === 401) {   //401 invalid token/ no token
          console.log("Catch invalid token");

                dispatch({ type: LOGOUT });
                dispatch({ type: CLEAR_PROFILE });
                dispatch({ type: CLEAR_ALLCANDS });  
          
            history.push('/expire');
            if(err.response.status === 401)
            {
              dispatch(setAlert("SESSION EXPIRED. LOGIN AGAIN", 'danger'));
            }
          }

    }

};



export const getProfileAdmin = (appId, gt, history) => async dispatch => {
    try {
      
      console.log(gt);
      
      dispatch({
        type: SET_DBCALL
      });

      dispatch({
        type: SET_GT,
        payload: gt
      });
     
            
        console.log("Invoking get profile for Admin " + appId);
        const res = await api.get(`/master/admin/${appId}`);

        dispatch({
          type: GET_PROFILE,
          payload: res.data
        });      
        

      dispatch({
        type: RESET_DBCALL
      });
    } 
    
    catch (err) {

      console.log(err);
      dispatch({
        type: RESET_DBCALL
      });
    
      console.log(err.response);

      if(err.response.status === 400) {
        dispatch(setAlert("Incorrect Application number", 'danger'));
        dispatch({
          type: PROFILE_ERROR,
          payload: { msg: err.response.statusText, status: err.response.status }
      });  
    
    }          
    
    if(err.response.status === 403 || err.response.status === 401) {
        console.log("Catch invalid token");
    
              dispatch({ type: LOGOUT });
              dispatch({ type: CLEAR_PROFILE });
              dispatch({ type: CLEAR_ALLCANDS });  
         
          history.push('/expire');
          if(err.response.status === 401)
          {
            dispatch(setAlert("SESSION EXPIRED. LOGIN AGAIN", 'danger'));
            //const res = await axios.get(`/api/auth/logout`);
          }
        }
    
    }
};

export const unFreezeAdmin = (appId, history) => async dispatch => {
  try {    
    
    dispatch({
      type: SET_DBCALL
    });


      if (localStorage.token) {
        setAuthToken(localStorage.token);
      }

          
      console.log("Invoking get profile for Admin " + appId);
      const res = await axios.get(`/api/master/unfreeze/${appId}`);

      dispatch(setAlert(res.data.msg, 'primary'));

        dispatch({
             type: CLEAR_PROFILE,
             payload: res.data
        });

        
        history.push('/dashboard');      

        dispatch({
          type: RESET_DBCALL
          });
  } 
  
  catch (err) {

    console.log(err);
    dispatch({
      type: RESET_DBCALL
    });
  
    console.log(err.response);

    if(err.response.status === 400) {
      dispatch(setAlert("Unfreezed failed", 'danger'));
      dispatch({
        type: PROFILE_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status }
    });  
  
  }          
  
  if(err.response.status === 403 || err.response.status === 401) {
      console.log("Catch invalid token");
  
            dispatch({ type: LOGOUT });
            dispatch({ type: CLEAR_PROFILE });
            dispatch({ type: CLEAR_ALLCANDS });  
       
        history.push('/expire');
        if(err.response.status === 401)
        {
          dispatch(setAlert("SESSION EXPIRED. LOGIN AGAIN", 'danger'));
          //const res = await axios.get(`/api/auth/logout`);
        }
      }
  
  }
};

export const unFreezeUpload = (appId, navigate) => async dispatch => {
  try {    
    
    dispatch({
      type: SET_DBCALL
    });

          
      console.log("Invoking get profile for Admin for unfreeze " + appId);
      const res = await api.get(`/master/unfreezeupload/${appId}`);

      dispatch(setAlert(res.data.msg, 'primary'));

        dispatch({
             type: CLEAR_PROFILE,
             payload: res.data
        });

        
        navigate('/select-tfc');      

        dispatch({
          type: RESET_DBCALL
          });
  } 
  
  catch (err) {

    console.log(err);
    dispatch({
      type: RESET_DBCALL
    });
  
    console.log(err.response);

    if(err.response.status === 400) {
      dispatch(setAlert("Unfreezed failed", 'danger'));
      dispatch({
        type: PROFILE_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status }
    });  
  
  }          
  
  if(err.response.status === 403 || err.response.status === 401) {
      console.log("Catch invalid token");
  
            dispatch({ type: LOGOUT });
            dispatch({ type: CLEAR_PROFILE });
            dispatch({ type: CLEAR_ALLCANDS });  
       
        navigate('/');
        if(err.response.status === 401)
        {
          dispatch(setAlert("SESSION EXPIRED. LOGIN AGAIN", 'danger'));
          //const res = await axios.get(`/api/auth/logout`);
        }
      }
  
  }
};

export const adminchangegriev = (formData, navigate) => async dispatch => {
  try {

      const config = {
          headers: {
              'Content-type': 'application/json'
          }
      }

      dispatch({
        type: CLEAR_PROFILE,
        payload: null
   });

   dispatch({
    type: SET_DBCALL
  });


      const res = await api.post('/vermaster/achangegriev', formData, config);
      console.log("dispatched CV changes for " + formData._aid);
      
      dispatch(setAlert(res.data.msg, 'primary'));

      console.log("Got after db");
      //navigate('/select-tfc');
     // console.log(res.data);

      dispatch({
          type: EDIT_PROFILE,
          payload: res.data
      });
      
      //history.push('/dashboard');      

      dispatch({
        type: RESET_DBCALL
        });

    

  } catch (err) {

    //navigate('/select-tfc');

    console.log(err);
    dispatch({
      type: RESET_DBCALL
    });
  
    console.log(err.response);

    if(err.response.status === 400) {
      dispatch(setAlert("Application No Change", 'danger'));
    }

    
    if(err.response.status === 402) {
      dispatch(setAlert("Mobile number is duplicate", 'danger'));
      dispatch({
        type: PROFILE_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status }
      });  
  
    }          

  
    if(err.response.status === 403 || err.response.status === 401) {
      console.log("Catch invalid token");
  
            dispatch({ type: LOGOUT });
            // dispatch({ type: CLEAR_PROFILE });
            // dispatch({ type: CLEAR_ALLCANDS });  
       
        }
    console.log("error processing over");    
  }
};

export const clearApplication = (history) => async dispatch => {
  //console.log("clearing the profile");
  dispatch({
      type: CLEAR_PROFILE
  });

  //history.push('/');
};


export const getProfilePdf = (tfccode, history) => async dispatch => {
  try {

    dispatch({ type: CLEAR_PROFILE });

    dispatch({
      type: SET_DBCALL
    });

    //console.log("Invoking get profile Upd");    

    const res = await axios.get(`/api/master/pdf/${tfccode}`);

    console.log(res.data);

    dispatch({
      type: GET_DDREPORT,
      payload: res.data
    });      
    
    //console.log("after db retrieve");
    //console.log(res.data);

    dispatch({
      type: RESET_DBCALL
    });

} 
  
  catch (err) {

    console.log(err);
    dispatch({
      type: RESET_DBCALL
    });
  
    if(err.response.status === 400) {
      dispatch(setAlert("No records found", 'danger'));
      dispatch({
        type: PROFILE_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status }
    });  
  
  }
  
  if(err.response.status === 403 || err.response.status === 401) {
      console.log("Catch invalid token");
  
            dispatch({ type: LOGOUT });
            dispatch({ type: CLEAR_PROFILE });
            dispatch({ type: CLEAR_ALLCANDS });  
       
        history.push('/expire');
        if(err.response.status === 401)
        {
          dispatch(setAlert("SESSION EXPIRED. LOGIN AGAIN", 'danger'));
          //const res = await axios.get(`/api/auth/logout`);
        }
      }
    
  }
};

export const getProfilePdf2 = (tfccode, history) => async dispatch => {
  try {
    dispatch({ type: CLEAR_PROFILE });
    dispatch({
      type: SET_DBCALL
    });

    //console.log("Invoking get profile Upd");
    
    const res = await axios.get(`/api/master/pdf2/${tfccode}`);

    //console.log(res.data);

    dispatch({
      type: GET_DDREPORT,
      payload: res.data
    });      
    
    //console.log("after db retrieve");
    //console.log(res.data);

    dispatch({
      type: RESET_DBCALL
    });

} 
  
  catch (err) {

    console.log(err);
    dispatch({
      type: RESET_DBCALL
    });
  
    if(err.response.status === 400) {
      dispatch(setAlert("No records found", 'danger'));
      dispatch({
        type: PROFILE_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status }
      });  
  
    }
  
    if(err.response.status === 403 || err.response.status === 401) {
      console.log("Catch invalid token");
  
            dispatch({ type: LOGOUT });
            dispatch({ type: CLEAR_PROFILE });
            dispatch({ type: CLEAR_ALLCANDS });  
       
        history.push('/expire');
        if(err.response.status === 401)
        {
          dispatch(setAlert("SESSION EXPIRED. LOGIN AGAIN", 'danger'));
          //const res = await axios.get(`/api/auth/logout`);
        }
      }
  
}
};


export const getProfileStat = (tfccode, history) => async dispatch => {
  try {
    dispatch({ type: CLEAR_PROFILE });
    dispatch({
      type: SET_DBCALL
    });

    
    const res = await axios.get(`/api/master/stat/${tfccode}`);

    //console.log(res.data);

    dispatch({
      type: GET_DDREPORT,
      payload: res.data
    });      
    
    //console.log("after db retrieve");
    //console.log(res.data);

    dispatch({
      type: RESET_DBCALL
    });

} 
  
catch (err) {

  console.log(err);
  dispatch({
    type: RESET_DBCALL
  });

  if(err.response.status === 400) {
    dispatch(setAlert("No records found", 'danger'));
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });  

  }

  if(err.response.status === 403 || err.response.status === 401) {
    console.log("Catch invalid token");

          dispatch({ type: LOGOUT });
          dispatch({ type: CLEAR_PROFILE });
          dispatch({ type: CLEAR_ALLCANDS });  
     
      history.push('/expire');
      if(err.response.status === 401)
      {
        dispatch(setAlert("SESSION EXPIRED. LOGIN AGAIN", 'danger'));
        //const res = await axios.get(`/api/auth/logout`);
      }
    }

  
}
};

export const getProfileStG = (tfccode, history) => async dispatch => {
  try {
    dispatch({ type: CLEAR_PROFILE });
    dispatch({
      type: SET_DBCALL
    });

    
    const res = await axios.get(`/api/master/stgen/${tfccode}`);

    //console.log(res.data);

    dispatch({
      type: GET_DDREPORT,
      payload: res.data
    });      
    
    //console.log("after db retrieve");
    //console.log(res.data);

    dispatch({
      type: RESET_DBCALL
    });

} 
  
  catch (err) {

    console.log("Catch");

    dispatch({
      type: RESET_DBCALL
    });

    
    dispatch(setAlert(err.response.data.msg, 'danger'));
    
    /*console.log(err.response.data);
    const errors = err.response.data;

    if(errors) {
        errors.forEach(error => dispatch(setAlert(errors, 'danger')));
    }*/

    if(err.response.status === 403 || err.response.status === 401) {
      console.log("Catch invalid token");
  
            dispatch({ type: LOGOUT });
            dispatch({ type: CLEAR_PROFILE });
            dispatch({ type: CLEAR_ALLCANDS });  
       
        history.push('/expire');
        if(err.response.status === 401)
        {
          dispatch(setAlert("SESSION EXPIRED. LOGIN AGAIN", 'danger'));
          //const res = await axios.get(`/api/auth/logout`);
        }
      }
  

    dispatch({
        type: PROFILE_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status }
    });

}
};

export const getProfileStmode = (tfccode, history) => async dispatch => {
  try {
    dispatch({ type: CLEAR_PROFILE });
    dispatch({
      type: SET_DBCALL
    });

    
    const res = await axios.get(`/api/master/stmode/${tfccode}`);

    //console.log(res.data);

    dispatch({
      type: GET_DDREPORT,
      payload: res.data
    });      
    
    //console.log("after db retrieve");
    //console.log(res.data);

    dispatch({
      type: RESET_DBCALL
    });

} 
  
  catch (err) {

    console.log("Catch");

    dispatch({
      type: RESET_DBCALL
    });

    
    dispatch(setAlert(err.response.data.msg, 'danger'));
    
    /*console.log(err.response.data);
    const errors = err.response.data;

    if(errors) {
        errors.forEach(error => dispatch(setAlert(errors, 'danger')));
    }*/

    if(err.response.status === 403 || err.response.status === 401) {
      console.log("Catch invalid token");
  
            dispatch({ type: LOGOUT });
            dispatch({ type: CLEAR_PROFILE });
            dispatch({ type: CLEAR_ALLCANDS });  
       
        history.push('/expire');
        if(err.response.status === 401)
        {
          dispatch(setAlert("SESSION EXPIRED. LOGIN AGAIN", 'danger'));
          //const res = await axios.get(`/api/auth/logout`);
        }
      }
  

    dispatch({
        type: PROFILE_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status }
    });

}
};

export const getProfileStBd = (tfccode, history) => async dispatch => {
  try {
    dispatch({ type: CLEAR_PROFILE });
    dispatch({
      type: SET_DBCALL
    });

            
    const res = await axios.get(`/api/master/stboard/${tfccode}`);

    console.log(res.data);

    dispatch({
      type: GET_DDREPORT,
      payload: res.data
    });      
    
    //console.log("after db retrieve");
    //console.log(res.data);

    dispatch({
      type: RESET_DBCALL
    });

} 
  
  catch (err) {

    console.log("Catch");

    dispatch({
      type: RESET_DBCALL
    });

    
    dispatch(setAlert(err.response.data.msg, 'danger'));
    
    /*console.log(err.response.data);
    const errors = err.response.data;

    if(errors) {
        errors.forEach(error => dispatch(setAlert(errors, 'danger')));
    }*/

    if(err.response.status === 403 || err.response.status === 401) {
      console.log("Catch invalid token");
  
            dispatch({ type: LOGOUT });
            dispatch({ type: CLEAR_PROFILE });
            dispatch({ type: CLEAR_ALLCANDS });  
       
        history.push('/expire');
        if(err.response.status === 401)
        {
          dispatch(setAlert("SESSION EXPIRED. LOGIN AGAIN", 'danger'));
          //const res = await axios.get(`/api/auth/logout`);
        }
      }
  

    dispatch({
        type: PROFILE_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status }
    });

}
};

export const getProfileStDt = (tfccode, history) => async dispatch => {
  try {
    dispatch({ type: CLEAR_PROFILE });
    dispatch({
      type: SET_DBCALL
    });

    
    const res = await axios.get(`/api/master/stdt/${tfccode}`);

    //console.log(res.data);

    dispatch({
      type: GET_DDREPORT,
      payload: res.data
    });      
    
    //console.log("after db retrieve");
    //console.log(res.data);

    dispatch({
      type: RESET_DBCALL
    });

} 
  
  catch (err) {

    console.log("Catch");

    dispatch({
      type: RESET_DBCALL
    });

    
    dispatch(setAlert(err.response.data.msg, 'danger'));
    
    /*console.log(err.response.data);
    const errors = err.response.data;

    if(errors) {
        errors.forEach(error => dispatch(setAlert(errors, 'danger')));
    }*/

    if(err.response.status === 403 || err.response.status === 401) {
      console.log("Catch invalid token");
  
            dispatch({ type: LOGOUT });
            dispatch({ type: CLEAR_PROFILE });
            dispatch({ type: CLEAR_ALLCANDS });  
       
        history.push('/expire');
        if(err.response.status === 401)
        {
          dispatch(setAlert("SESSION EXPIRED. LOGIN AGAIN", 'danger'));
          //const res = await axios.get(`/api/auth/logout`);
        }
      }
  

    dispatch({
        type: PROFILE_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status }
    });

}
};

export const changeAppStatus = (formData, history) => async dispatch => {
    
  try {

      dispatch({
          type: SET_DBCALL
        });

      const config = {
          headers: {
              'Content-type': 'application/json'
          }
      }
      
      const res = await axios.post('api/master/changestatus', formData, config);
      console.log("dispatched change status");
      
      dispatch(setAlert(res.data.msg, 'primary'));

      dispatch({
          type: CLEAR_PROFILE,
          payload: res.data
      });

      history.push('/dashboard');

      dispatch({
          type: RESET_DBCALL
        });

  } catch (err) {

      console.log("Catch Status db error");
      dispatch({
          type: RESET_DBCALL
        });
      
      const errors = err.response.data.errors;

      if(errors) {
          errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
      }

      dispatch({
          type: PROFILE_ERROR,
          payload: { msg: err.response.statusText, status: err.response.status }
      });

  }
};

