import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import { Link, Navigate, useNavigate } from 'react-router-dom';

import {Card, ListGroup, CardSubtitle, CardTitle, CardText, ListGroupItem, CardBody,
            Row, Col} from 'reactstrap';

import { clearApplication, getProfileCor, changeAppStatus, getProfilePdf, movetoIneligible, checkfreezecand, checkunfreezecand, getProfileVer, getProfileUpd} from '../../actions/vermaster';

import {listAll, openCert, gettfc} from '../../actions/dload';

import {getIncomplete} from '../../actions/report';
import moment from 'moment';
import Dloadc from '../dashboard/dloadc';

const {GENDER, CASTE, NATIONALITY, NATIVITY, YES_NO_ARRAY, INELIGIBLE_LIST, DIST_LIST, STATES, DIFF_ABLED_TYPES} = require("../../Constant");

const VerSearch = ({
  getProfileCor, getProfileVer, getProfileUpd,
  getIncomplete, movetoIneligible, checkunfreezecand, checkfreezecand, listAll,
  auth: { user },
  profile: { profile, loading, logprofile, getdb, applnid },
  history,
  clearApplication,
  changeAppStatus
}) => {

  useEffect(() => {
    window.scrollTo(0,0);
  }, []); 

  const [formData, setFormData] = useState({
    gappid: '',
    vflag : "notyet",
    cremarks: '',
    status: '',
    tfcname: '',
    username: '',
    ltype: '', elist: '', ineremarks: '', pass: '', pass2: ''
  });

  const { gappid, cremarks, elist, ineremarks, pass, pass2 } = formData;

  const navigate = useNavigate();

  const onChange = e => {
    setFormData({ ...formData, [e.target.name] : e.target.value });  
    //name: e.target.value to change name alone
    //console.log(e.target.value);
  }

  const gofreezecand = async e => {
    e.preventDefault();     
    
    //console.log("Appln number : "+ formData.gappid);         
    await checkfreezecand(formData.gappid, navigate);    
  //console.log("tfc in form " + formData.tfc);
    clearApplication(navigate);
    
    //getIncomplete(formData.gappid);
  };

  const gounfreezecand = async e => {
    e.preventDefault();     
    
    //console.log("Appln number : "+ formData.gappid);         
    
    await checkunfreezecand(formData.gappid, navigate);     
  //console.log("tfc in form " + formData.tfc);
    clearApplication(navigate);   
    //getIncomplete(formData.gappid);
  };


 /* const onDownload = e => {
    e.preventDefault();
    clearProf(e);
    navigate('/download');
  }*/

  const onSubmit = async e => {
    e.preventDefault();    
    
    const tobesent = {};
    tobesent.pass = formData.pass;
    tobesent.tfccode = user.tfccode;
    tobesent.username = user.username;
    tobesent.tfcname = user.tfcname;
    
    //console.log("Appln number : "+ formData.gappid);  
    
    if(formData.pass.toString() != formData.pass2.toString())
      alert("Passwords dont match");
    else
      changeAppStatus(tobesent, navigate);
    //getIncomplete(formData.gappid);
  };

  const OnInelig = async e => {
    e.preventDefault();     

    const tobesent = {};
    tobesent.appId = formData.gappid;
    if(formData.elist.toString() === "")
      tobesent.elist = INELIGIBLE_LIST[0].label;
    else
      tobesent.elist = formData.elist;
    tobesent.ineremarks = formData.ineremarks;
    tobesent.oldstatus = profile.cv.st;
    tobesent.status = "ineligible";    

    tobesent.tfccode = user.tfccode;
    tobesent.username = user.username;
    tobesent.ltype = user.ltype;
    
    console.log(tobesent);

    if(tobesent.ineremarks === '')
        alert("pls enter comments");
    else {
        await movetoIneligible(tobesent, navigate);    
    }

  };


  
  const pendingupd = async e => {
    e.preventDefault();     
    
    //console.log("Appln number : "+ formData.gappid);         
    
    //getProfileCor(formData.gappid);    
    //getIncomplete(formData.gappid);
    navigate('/inlist');
  };


  const changeVerStatus = async e => {
    e.preventDefault();
    const tobesent = {};
    tobesent.oldstatus = profile.cv.st;
    //formData.status = 'present';
    //console.log("Appln no status changed as present " + formData.gappid);
    //console.log("tfcname " + user.tfcname + "username " + user.username + "ltype " + user.ltype);
    tobesent.status = "present";    

    tobesent.tfccode = user.tfccode;
    tobesent.username = user.username;
    tobesent.ltype = user.ltype;
    tobesent.gappid = gappid;
    tobesent.cremarks = cremarks;
    if(tobesent.cremarks === '')
      alert("pls enter comments");
    else {
      changeAppStatus(tobesent, navigate);
    //clearProf(e);
    }
  }

  const changeUpdStatus = async e => {
    e.preventDefault();
    const tobesent = {};
    tobesent.oldstatus = profile.cv.st;
    //formData.status = 'toupdate';
    //console.log("Appln no status changed as toupdate " + formData.gappid);
    //console.log("tfcname " + user.tfcname + "username " + user.username + "ltype " + user.ltype);
    tobesent.status = "toupdate";    
    tobesent.tfccode = user.tfccode;
    tobesent.username = user.username;
    tobesent.ltype = user.ltype;
    tobesent.gappid = gappid;
    tobesent.cremarks = cremarks;

    if(tobesent.cremarks === '')
      alert("pls enter comments");
    else {
      changeAppStatus(tobesent, navigate);
    //clearProf(e);
    }

  }

  const changeIncStatus = async e => {
    e.preventDefault();
    const tobesent = {};
    tobesent.oldstatus = profile.cv.st;
    //formData.status = 'ccomplete';

    //console.log("Appln no status changed as complete " + formData.gappid);
    //console.log("tfcname " + user.tfcname + "username " + user.username + "ltype " + user.ltype);
    tobesent.status = "ccomplete";    
    tobesent.tfccode = user.tfccode;
    tobesent.username = user.username;
    tobesent.ltype = user.ltype;
    tobesent.gappid = gappid;
    tobesent.cremarks = cremarks;

    if(tobesent.cremarks === '')
      alert("pls enter comments");
    else {
      changeAppStatus(tobesent, navigate);
    //clearProf(e);
    }

  }

  const seepdf = async e => {
    e.preventDefault();
    await getProfilePdf(applnid);
    navigate('/download'); 
  };

  const goback = async e => {
    e.preventDefault();    
    navigate('/searchmore'); 
  };

  const clearProf = async e => {    
    navigate('/tfcdash')    
  };

  
  const handleVerifyRow = async (aid) => {    
    console.log(aid);
    formData.appln = aid;    
    await getProfileVer(formData);
    await listAll(aid);
    navigate("/verify");
  }

  const handleEditRow = async (aid) => {    
    console.log(aid);    
    await getProfileUpd(aid);       
    await listAll(aid);
    navigate("/adash");
  }


  const [displayne, toggledisplayne] = useState(false);

function changeNE () {
    toggledisplayne(!displayne);
}

  return (    
    <section className='container'>
    <Fragment>
       {user === null ? (
      <Spinner /> ) : ( 
      <div className='' >
      <h5 style={{textAlign: 'center', color: "blue", fontSize: "11pt", fontWeight: "bolder"}}> {user && user.tfcname} </h5>
      <div className="container badge form" style={{textAlign: 'center', color: 'brown'}}>
        <h5 style={{fontWeight: "bold"}}> Change Password for username - {user && user.username} </h5>        
      </div>      
      
        <form className="my-2 row form" onSubmit = {e => onSubmit(e)} style={{alignItems: "center", textAlign: "center"}}>
                <h3 style={{fontWeight: "bold", fontSize: "12pt", textAlign: "center", alignContent: "center", alignItems: "center"}}>Enter new Password </h3> 
                <input type="password" name="pass" value={pass} onChange = {e => onChange(e)}/>   
                <h3 style={{fontWeight: "bold", fontSize: "12pt", textAlign: "center", alignContent: "center", alignItems: "center"}}>Confirm new Password </h3> 
                <input type="password" name="pass2" value={pass2} onChange = {e => onChange(e)}/>   
                <div className='my-2' style={{padding: "20px"}}></div>          

                <button className="btn btn-success" value="">  Save     </button>
                <button className="btn btn-primary" onClick= {e => clearProf(e)}> Cancel </button>                                                         
        </form>        
        
      
      </div> ) }
      
      
    </Fragment>
    </section>
  );
}

VerSearch.propTypes = {
  getProfileCor: PropTypes.func.isRequired,
  getIncomplete: PropTypes.func.isRequired,  
  clearApplication: PropTypes.func.isRequired,
  changeAppStatus: PropTypes.func.isRequired,
  getProfilePdf: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  movetoIneligible: PropTypes.func.isRequired,
  checkunfreezecand: PropTypes.func.isRequired,
  checkfreezecand: PropTypes.func.isRequired,

  getProfileVer: PropTypes.func.isRequired,
  getProfileUpd: PropTypes.func.isRequired,
  listAll: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  profile: state.profile
});

export default connect(
  mapStateToProps,
  { getProfileCor, clearApplication, changeAppStatus, getIncomplete, getProfilePdf, movetoIneligible, checkfreezecand, checkunfreezecand, getProfileVer, getProfileUpd, listAll}
)(VerSearch);