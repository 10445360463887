import React, { useEffect, useState } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DashboardActions from './DashboardActions';
import { getCurrentProfile, clearProfile, cleargid, corgetTFCcandlist, savecomments, corgetTFCincandlist } from '../../actions/profile';
import {getProfileVer, getProfilePdf, getProfileUpd, getProfileCor, changeAppStatus, clearApplication, checkfreezecand, checkunfreezecand, movetoIneligible} from '../../actions/vermaster';
import { getProfileAdmin } from '../../actions/master';
import { TFC_CENTER, INELIGIBLE_LIST } from '../../Constant';
import Spinner from '../layout/Spinner';
import Pagination from './Pagination';
import moment from 'moment';
const {TRANS_COLLEGES_LIST, NDEPT_LIST} = require("../../Constant2");

const CorSelect2 = ({
  getCurrentProfile, corgetTFCcandlist, getProfileVer, getProfilePdf, getProfileUpd, getProfileCor,
  clearProfile, changeAppStatus, checkfreezecand, checkunfreezecand, corgetTFCincandlist, movetoIneligible,
  auth: { user },
  profile: { profile3, gt, loading, getdb, profile4 },
  history
}) => {

  const [displaycmtscc, toggledisplaycmtscc] = useState(false);

  const [displaycmts, toggledisplaycmts] = useState(false);


const [currentPage, setCurrentPage] = useState(1);
const [postsPerPage] = useState(100);

var indexoflastpost = currentPage * postsPerPage;
var indexoffirstpost = indexoflastpost - postsPerPage;
var currentList;
if(profile3 !== null && profile3 !== undefined)
  if(profile3.length !== 0) 
      currentList = profile3.slice(indexoffirstpost, indexoflastpost);


      if(profile4 !== null && profile4 !== undefined)
      if(profile4.length !== 0) 
          currentList = profile4.slice(indexoffirstpost, indexoflastpost);
    

      const paginate = pageNumber => setCurrentPage(pageNumber);

     
    


  const [formData, setFormData] = useState({
    comment: '', appln: '', sgid: '', sgt: '', aname: '', cremarks: '', dispaid: '', elist: "", cremarksc: '',
  });

  const { stfc, ntfc, comment, appln, sgid, sgt, aname , cremarks, dispaid, elist, cremarksc} = formData;

  const navigate = useNavigate();


  
  const handleVerifyRow = async (aid) => {    

    if(user !== null && user !== undefined && user.ltype.toString() === "C")
        //if(user !== null && user !== undefined && user.ltype.toString() === "V")
          {
             await clearProfile();
             corgetTFCcandlist(aid);
          }

    navigate("/coord");
  }

  function timeout(delay) {
    return new Promise( res => setTimeout(res, delay) );
  }

  const handleEditRow = async (aid) => {    
    console.log(aid);    
    await getProfileUpd(aid);       
    navigate("/adash");
  }

  const handlepend = async (aid) => {    
    console.log(aid);    
    await getProfileCor(aid);       
    navigate("/inlist");
  }

  const dispcmts = async (aid) => {
    formData.dispaid = aid;
    toggledisplaycmts(!displaycmts);
  }

  const dispcmtscc = async (aid) => {
    formData.dispaid = aid;
    toggledisplaycmtscc(!displaycmtscc);
  }


  const handleDownRow = async (aid) => {        
      await getProfilePdf(aid);
      navigate('/download2'); 
  }

  const corgetalltfcapps = async(aid) => {
    if(user !== null && user !== undefined && user.ltype.toString() === "C")
    //if(user !== null && user !== undefined && user.ltype.toString() === "V")
      {
          await clearProfile();
         corgetTFCcandlist(user.tfccode);
      }
    
  }

  const corgetintfcapps = async(aid) => {
    if(user !== null && user !== undefined && user.ltype.toString() === "C")
    //if(user !== null && user !== undefined && user.ltype.toString() === "V")
      {
        await clearProfile();
        await corgetTFCincandlist(user.tfccode);
        //  function compare( a, b ) {
        //   if ( a._aid < b._aid ){
        //     return -1;
        //   }
        //   if ( a._aid > b._aid ){
        //     return 1;
        //   }
        //   return 0;
        // }
        // if(profile4 !== null && profile4 !== undefined && profile4.length > 0) {
        //   console.log("executing sort");
        //   profile4.sort( compare );
        // }
      }
    
  }

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });


  const onCodeChange = e => {

    //e.preventDefault();

    let temp = e.target.value;

    if((e.target.name === "stfc")) {
      formData.ntfc = TFC_CENTER.filter(el => el.key.toString().includes(temp.toString())).map(el => el.label)[0];
    }

    if((e.target.name === "ntfc")) {
      formData.stfc = TFC_CENTER.filter(el => el.label.toString().includes(temp.toString())).map(el => el.key)[0];
    }
    
    

  setFormData({ ...formData, [e.target.name] : e.target.value });  

    //this.setState({name: e.target.value});

  }


  const candlist = profile3 !== null && profile3 !== undefined && currentList !== null && currentList !== undefined && currentList.length > 0 && currentList.map((edu, ind) => (
    <tr key={edu._aid}>
      <td> {ind+1} </td>
      <td> {edu.college} - {TRANS_COLLEGES_LIST.filter(e => e.key === edu.college).map(e => e.label)} </td>
      {/* <td>{edu.cv && edu.cv.sch}</td> */}
      <td style={{fontWeight: "bold"}}>{edu._aid}</td>   
      <td>{edu._n}</td>   
      <td>{edu._p && edu._p._bran && NDEPT_LIST.filter(e => e.key === edu._p._bran).map(e => e.label)} - {edu._p && edu._p._desig} </td>

      <td>{edu.m} </td>   

       <td> 
            {edu._st && edu._st.toString() === "preview" && edu._fzup === true &&
              <button className="btn btn-light" style={{fontSize: "9pt", fontWeight: "bold", color: "navy", border: "1px solid brown"}} onClick={i => handleVerifyRow(edu._aid)} > Details </button> 
            }
            
      </td>    

      {edu.status && edu.status.toString() === "complete" && 

          <td> <button className="btn btn-light" style={{fontSize: "9pt", fontWeight: "bold", color: "blue", border: "1px solid black"}} onClick={i => handleDownRow(edu._aid)} > Download PDF </button> </td>
          }  
                
        </tr>     
  ));







  const changeVerStatus = async(_aid, st) => {
    toggledisplaycmts(!displaycmts);
    const tobesent = {};
    tobesent.oldstatus = st;
    tobesent.status = "open";    

    tobesent.tfccode = user.tfccode;
    tobesent.username = user.username;
    tobesent.ltype = user.ltype;
    tobesent.gappid = _aid;
    tobesent.cremarks = cremarks;
    if(tobesent.cremarks === '')
      alert("pls enter comments");
    else {
      changeAppStatus(tobesent, navigate);
      clearProfile();
      navigate("/");
    //clearProf(e);
    }
  }

  const changeUpdStatus = async(_aid, st) => {
    toggledisplaycmts(!displaycmts);
    const tobesent = {};
    tobesent.oldstatus = st;
    tobesent.status = "toupdate";    
    tobesent.tfccode = user.tfccode;
    tobesent.username = user.username;
    tobesent.ltype = user.ltype;
    tobesent.gappid = _aid;
    tobesent.cremarks = cremarks;

    if(tobesent.cremarks === '')
      alert("pls enter comments");
    else {
      changeAppStatus(tobesent, navigate);
      clearProfile();
      navigate("/");
    //clearProf(e);
    }

  }

  const gofreezecand = async(aid) => {
      
    await checkfreezecand(aid, navigate);    
  
    clearProfile();

    navigate("/");
        
  };

  const gounfreezecand = async (aid) => {
    
    await checkunfreezecand(aid, navigate);     
    
    clearProfile();

    navigate("/");

  
  };

  const clearNext = async() => {
    //clearProfile(history, "3");   
    await clearProfile();
    await corgetTFCcandlist(formData.stfc);
  }




  const OnInelig = async (aid, st) => {    

    const tobesent = {};
    tobesent.appId = aid;
    if(formData.elist.toString() === "")
      tobesent.elist = INELIGIBLE_LIST[0].label;
    else
      tobesent.elist = formData.elist;
    tobesent.ineremarks = formData.cremarks;
    tobesent.oldstatus = st;
    tobesent.status = "ineligible";    

    tobesent.tfccode = user.tfccode;
    tobesent.username = user.username;
    tobesent.ltype = user.ltype;
    
    console.log(tobesent);

    if(tobesent.ineremarks === '')
        alert("pls enter comments");
    else {
        await movetoIneligible(tobesent, history);    
    }

    formData.cremarks = "";
    toggledisplaycmts(false);
    formData.elist = "";

  };


  const OnCComplete = async (aid, st) => {
    //e.preventDefault();
    const tobesent = {};
    tobesent.oldstatus = st;
    //formData.status = 'ccomplete';

    //console.log("Appln no status changed as complete " + formData.gappid);
    //console.log("tfcname " + user.tfcname + "username " + user.username + "ltype " + user.ltype);
    tobesent.status = "ccomplete";    
    tobesent.tfccode = user.tfccode;
    tobesent.username = user.username;
    tobesent.ltype = user.ltype;
    tobesent.gappid = aid;
    tobesent.cremarks = cremarksc;

    if(tobesent.cremarks === '')
      alert("pls enter comments");
    else {
      changeAppStatus(tobesent, navigate);
      clearProfile();
      navigate("/");
    //clearProf(e);
    }

  }


  return (
    <section className="container">
      {/* <h1 className="large text-primary">{user.tfcname}</h1> */}

      <h4 style={{color: "navy", textAlign: "center"}}> Directorate of Technical Education, Chennai 600025 </h4>

      <h5 style={{color: "crimson", textAlign: "center", textDecoration: "underline"}}> Kindly Click College Code to view Transfer Applications </h5>
      
      <br />      

        {profile3 === null && loading === true && getdb === true ? (
        <Spinner /> ) : null}

          
          <div className='table-responsive'>
         

          <div className='profile-top' style={{fontSize: "10pt"}}>
  <h6>  </h6>
      <table className='table table-striped' style={{fontSize: "10pt", padding: "10px"}}>
        <thead>
          <th scope="col">Sl.No</th>
          <th scope="col">College Code</th>
          <th scope="col">College Name</th>
          <th scope="col">Category</th>
        </thead>
        {TRANS_COLLEGES_LIST.map((edu, ind) => (
            <tr key={edu.ind}>
                <td> {ind+1} </td> 
                <td>
                {
                    <button className="btn btn-light" style={{fontSize: "9pt", fontWeight: "bold", color: "navy", border: "1px solid brown"}} onClick={i => handleVerifyRow(edu.key)} > {edu.key} </button> 
                }
                </td>            
                <td> {edu.label} </td> 
                <td> {edu.category && edu.category.toString() === "poly" ? "POLYTECHNIC" : "ENGINEERING"} </td> 
            </tr>
        ))}
      </table>
    </div>        

          </div>
         


    </section>
  );
};

CorSelect2.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  corgetTFCcandlist: PropTypes.func.isRequired,
  getProfileAdmin: PropTypes.func.isRequired,
  getProfileVer: PropTypes.func.isRequired,
  getProfilePdf: PropTypes.func.isRequired,
  getProfileUpd: PropTypes.func.isRequired,
  getProfileCor: PropTypes.func.isRequired,
  changeAppStatus: PropTypes.func.isRequired,
  checkfreezecand: PropTypes.func.isRequired,
  checkunfreezecand: PropTypes.func.isRequired,
  corgetTFCincandlist: PropTypes.func.isRequired,
  movetoIneligible: PropTypes.func.isRequired,

  clearProfile: PropTypes.func.isRequired,
  cleargid: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile
});

export default connect(mapStateToProps, { getCurrentProfile, clearProfile, cleargid, corgetTFCcandlist, getProfileVer, getProfilePdf,
getProfileAdmin, getProfileUpd, getProfileCor, changeAppStatus, checkfreezecand, checkunfreezecand, corgetTFCincandlist, movetoIneligible })(
  CorSelect2
);
