import axios from 'axios';
import { setAlert } from './alert';

import {
        LOG_PROFILE, ALLCANDS_ERROR,  GET_ALLCANDS, SET_DBCALL, RESET_DBCALL
    } from './types';

    export const getdpiCompl = tfcName => async dispatch => {
      try {
    
          dispatch({
              type: SET_DBCALL
            });
    
    console.log("Get DPI Errors for tfc " + tfcName);
    const res = await axios.get(`/api/report/getapplnsdpi/${tfcName}`);      
    
    console.log("Completed receiving err dpi list");
    //console.log(res.data);
    
      dispatch({
      type: GET_ALLCANDS,
      payload: res.data
      });
    
      dispatch(setAlert("Retrieved Candidates List", 'success'));
    
      dispatch({
          type: RESET_DBCALL
        });
    
    }
    
    catch (err) {
    
      console.log("Catch");
      dispatch({
          type: RESET_DBCALL
        });
    
      dispatch(setAlert(err.response.data.msg, 'danger'));
      
      /*console.log(err.response.data);
      const errors = err.response.data;
    
      if(errors) {
          errors.forEach(error => dispatch(setAlert(errors, 'danger')));
      }*/
    
      dispatch({
        type: ALLCANDS_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status }
        });
    
    }
    };
    
    export const getfreezCompl = tfcName => async dispatch => {
      try {
    
          dispatch({
              type: SET_DBCALL
            });
    
    console.log("Get Upload Freezed for tfc " + tfcName);
    const res = await axios.get(`/api/report/getapplnsfreez/${tfcName}`);      
    
    console.log("Completed receiving freezed list");
    //console.log(res.data);
    
      dispatch({
      type: GET_ALLCANDS,
      payload: res.data
      });
    
      dispatch(setAlert("Retrieved Candidates List", 'success'));
    
      dispatch({
          type: RESET_DBCALL
        });
    
    }
    
    catch (err) {
    
      console.log("Catch");
      dispatch({
          type: RESET_DBCALL
        });
    
      dispatch(setAlert(err.response.data.msg, 'danger'));
      
      /*console.log(err.response.data);
      const errors = err.response.data;
    
      if(errors) {
          errors.forEach(error => dispatch(setAlert(errors, 'danger')));
      }*/
    
      dispatch({
        type: ALLCANDS_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status }
        });
    
    }
    };
    

export const getadminCompl = tfcName => async dispatch => {
      try {
    
          dispatch({
              type: SET_DBCALL
            });
    
    console.log("Get Admin Freezed for tfc " + tfcName);
    const res = await axios.get(`/api/report/getadminfreez/${tfcName}`);      
    
    console.log("Completed receiving freezed list");
    //console.log(res.data);
    
      dispatch({
      type: GET_ALLCANDS,
      payload: res.data
      });
    
      dispatch(setAlert("Retrieved Candidates List", 'success'));
    
      dispatch({
          type: RESET_DBCALL
        });
    
    }
    
    catch (err) {
    
      console.log("Catch");
      dispatch({
          type: RESET_DBCALL
        });
    
      dispatch(setAlert(err.response.data.msg, 'danger'));
      
      /*console.log(err.response.data);
      const errors = err.response.data;
    
      if(errors) {
          errors.forEach(error => dispatch(setAlert(errors, 'danger')));
      }*/
    
      dispatch({
        type: ALLCANDS_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status }
        });
    
    }
    };

export const getLogs = tfcName => async dispatch => {
        try {

            dispatch({
                type: SET_DBCALL
              });    

    console.log("Invoking logs for " + tfcName);
    const res = await axios.get(`/api/report/getlogs/${tfcName}`);      

    console.log("returned from db");
    //console.log(res.data);

    dispatch({
        type: GET_ALLCANDS,
        payload: res.data
        });

      
      console.log("Completed generating logs");
      dispatch({
        type: RESET_DBCALL
      });

    }
    
    catch (err) {

        console.log("Catch");
        
        dispatch({
            type: RESET_DBCALL
          });

          dispatch(setAlert(err.response.data.msg, 'danger'));
        
        /*console.log(err.response.data);
        const errors = err.response.data;

        if(errors) {
            errors.forEach(error => dispatch(setAlert(errors, 'danger')));
        }*/

        dispatch({
          type: ALLCANDS_ERROR,
          payload: { msg: err.response.statusText, status: err.response.status }
          });

    }
};


export const getIncomplete = appId => async dispatch => {
    try {

        dispatch({
            type: SET_DBCALL
          });

console.log("Invoking incomp list for " + appId);
const res = await axios.get(`/api/report/getincomp/${appId}`);      

dispatch({
    type: LOG_PROFILE,
    payload: res.data
    });

  
  console.log("Completed receiving incom list");
  dispatch({
    type: RESET_DBCALL
  });

}

catch (err) {

    console.log("Catch");
    dispatch({
        type: RESET_DBCALL
      });

    dispatch(setAlert(err.response.data.msg, 'danger'));
    
    /*console.log(err.response.data);
    const errors = err.response.data;

    if(errors) {
        errors.forEach(error => dispatch(setAlert(errors, 'danger')));
    }*/

    dispatch({
      type: ALLCANDS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
      });

}
};


export const getDDs = tfcName => async dispatch => {
    try {

        dispatch({
            type: SET_DBCALL
          });

console.log("Invoking logs for " + tfcName);
const res = await axios.get(`/api/report/getdds/${tfcName}`);      

dispatch({
    type: GET_ALLCANDS,
    payload: res.data
    });

  
  console.log("Completed generating dds");

  dispatch(setAlert("completed generating dd report", 'success'));

  dispatch({
    type: RESET_DBCALL
  });

}

catch (err) {

    console.log("Catch");
    
    dispatch({
        type: RESET_DBCALL
      });

      dispatch(setAlert(err.response.data.msg, 'danger'));
    
    /*console.log(err.response.data);
    const errors = err.response.data;

    if(errors) {
        errors.forEach(error => dispatch(setAlert(errors, 'danger')));
    }*/

    dispatch({
      type: ALLCANDS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
      });

}
};


  
export const getLogins = tfcName => async dispatch => {
    try {

        dispatch({
            type: SET_DBCALL
          });

console.log("Invoking logins for " + tfcName);
const res = await axios.get(`/api/report/getlogins/${tfcName}`);      

dispatch({
    type: GET_ALLCANDS,
    payload: res.data
    });

  dispatch(setAlert("completed generating", 'success'));

  //console.log(res.data);
  
  console.log("Completed generating logins");
  dispatch({
    type: RESET_DBCALL
  });

}

catch (err) {

    console.log("Catch");
    dispatch({
        type: RESET_DBCALL
      });

    dispatch(setAlert(err.response.data.msg, 'danger'));
    
    /*console.log(err.response.data);
    const errors = err.response.data;

    if(errors) {
        errors.forEach(error => dispatch(setAlert(errors, 'danger')));
    }*/

    dispatch({
      type: ALLCANDS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
      });

}
};

export const getApplnsPend = tfcName => async dispatch => {
    try {

        dispatch({
            type: SET_DBCALL
          });

console.log("Get all applns for tfc " + tfcName);
const res = await axios.get(`/api/report/getapplns/${tfcName}`);      

console.log("Completed receiving applns list");
//console.log(res.data.allapplns);


const res2 = await axios.get(`/api/report/getapplns2/${tfcName}`);      

console.log("Completed receiving cvall list");
//console.log(res2.data.cvall);

var arr1 = res.data.allapplns;
var arr2 = res2.data.cvall;
//console.log(arr1.length);
//console.log(arr2.length);
var result = arr1.filter( item => !arr2.some(dat => item._aid === dat._aid));

console.log("completed cv");
//console.log(result);

var tobesent = {};
tobesent.allapplns = result;


    dispatch({
    type: GET_ALLCANDS,
    payload: tobesent
    });

    dispatch(setAlert("completed retrieving", 'success'));
  
    dispatch({
        type: RESET_DBCALL
      });

}

catch (err) {

    console.log("Catch");
    dispatch({
        type: RESET_DBCALL
      });

    dispatch(setAlert(err.response.data.msg, 'danger'));
    
    /*console.log(err.response.data);
    const errors = err.response.data;

    if(errors) {
        errors.forEach(error => dispatch(setAlert(errors, 'danger')));
    }*/

    dispatch({
      type: ALLCANDS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
      });

}
};


export const getApplnsPro = tfcName => async dispatch => {
    try {

        dispatch({
            type: SET_DBCALL
          });

console.log("Get CV Progress for tfc " + tfcName);
const res = await axios.get(`/api/report/getapplnspro/${tfcName}`);      

console.log("Completed receiving applns list");
//console.log(res.data);

    dispatch({
    type: GET_ALLCANDS,
    payload: res.data
    });

    dispatch(setAlert("completed retrieving", 'success'));
  
    dispatch({
        type: RESET_DBCALL
      });

}

catch (err) {

    console.log("Catch");
    dispatch({
        type: RESET_DBCALL
      });

    dispatch(setAlert(err.response.data.msg, 'danger'));
    
    /*console.log(err.response.data);
    const errors = err.response.data;

    if(errors) {
        errors.forEach(error => dispatch(setAlert(errors, 'danger')));
    }*/

    dispatch({
      type: ALLCANDS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
      });

}
};

export const getApplnsCompl = tfcName => async dispatch => {
  try {

      dispatch({
          type: SET_DBCALL
        });

console.log("Get CV Progress for tfc " + tfcName);
const res = await axios.get(`/api/report/getapplnscompl/${tfcName}`);      

console.log("Completed receiving applns list");
//console.log(res.data);

  dispatch({
  type: GET_ALLCANDS,
  payload: res.data
  });

  dispatch(setAlert("completed retrieving", 'success'));

  dispatch({
      type: RESET_DBCALL
    });

}

catch (err) {

  console.log("Catch");
  dispatch({
      type: RESET_DBCALL
    });

  dispatch(setAlert(err.response.data.msg, 'danger'));
  
  /*console.log(err.response.data);
  const errors = err.response.data;

  if(errors) {
      errors.forEach(error => dispatch(setAlert(errors, 'danger')));
  }*/

  dispatch({
    type: ALLCANDS_ERROR,
    payload: { msg: err.response.statusText, status: err.response.status }
    });

}
};




export const getApplnsTot = tfcName => async dispatch => {
    try {

        dispatch({
            type: SET_DBCALL
          });

console.log("Get all applns for tfc " + tfcName);
const res = await axios.get(`/api/report/getapplnstot/${tfcName}`);      

console.log("Completed receiving applns list");
//console.log(res.data);

    dispatch({
    type: GET_ALLCANDS,
    payload: res.data
    });

    dispatch(setAlert("completed retrieving", 'success'));
    dispatch({
        type: RESET_DBCALL
      });

 
}

catch (err) {

    console.log("Catch");
    dispatch({
        type: RESET_DBCALL
      });

    dispatch(setAlert(err.response.data.msg, 'danger'));
    
    /*console.log(err.response.data);
    const errors = err.response.data;

    if(errors) {
        errors.forEach(error => dispatch(setAlert(errors, 'danger')));
    }*/

    dispatch({
      type: ALLCANDS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
      });

}
};