import React, { useEffect, useState } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DashboardActions from './DashboardActions';
import { getCurrentProfile, clearProfile, cleargid, getTFCcandlist, savecomments, getTFCcandinlist } from '../../actions/profile';
import {getProfileVer, getProfilePdf, getProfileUpd, getProfileCor, changeAppStatus} from '../../actions/vermaster';
import { getProfileAdmin } from '../../actions/master';
import { TFC_CENTER } from '../../Constant';
import Spinner from '../layout/Spinner';
import Pagination from './Pagination';
import {listAll, openCert, gettfc} from '../../actions/dload';

import moment from 'moment';
const {NDEPT_LIST} = require("../../Constant2");

const TFCSelect = ({
  getCurrentProfile, getTFCcandlist, getProfileVer, getProfilePdf, getProfileUpd, getProfileCor, listAll, getTFCcandinlist,
  clearProfile,
  changeAppStatus,
  auth: { user },
  profile: { profile3, gt, loading, getdb, profile4 },
  history
}) => {

const [currentPage, setCurrentPage] = useState(1);
const [postsPerPage] = useState(100);

const [displaycmts, toggledisplaycmts] = useState(false);

var indexoflastpost = currentPage * postsPerPage;
var indexoffirstpost = indexoflastpost - postsPerPage;
var currentList;
if(profile3 !== null && profile3 !== undefined)
  if(profile3.length !== 0) 
      currentList = profile3.slice(indexoffirstpost, indexoflastpost);


      if(profile4 !== null && profile4 !== undefined)
      if(profile4.length !== 0) 
          currentList = profile4.slice(indexoffirstpost, indexoflastpost);

      const paginate = pageNumber => setCurrentPage(pageNumber);

  const [formData, setFormData] = useState({
    stfc: '39', ntfc: 'Govt. Polytechnic College, Kilapalur Village, Ariyalur – 621 707.', comment: '', appln: '', sgid: '', sgt: '', aname: '', cremarks22: '', dispaid: '',
  });

  const { stfc, ntfc, comment, appln, sgid, sgt, aname, cremarks22, dispaid } = formData;

  const navigate = useNavigate();

  const dispcmts = async (aid) => {
    formData.dispaid = aid;
    toggledisplaycmts(!displaycmts);
  }


  const handleVerifyRow = async (aid) => {    
    console.log(aid);
    formData.appln = aid;
    await timeout(1000);    
    await getProfileVer(formData);
    await listAll(aid);
    navigate("/verify");
  }

  const handleEditRow = async (aid) => {    
    console.log(aid);   
    await timeout(1000); 
    await getProfileUpd(aid);       
    await listAll(aid);
    navigate("/adash");
  }

  const handlepend = async (aid) => {    
    console.log(aid);    
    await timeout(1000);
    await getProfileCor(aid + "$" + user.username);       
    navigate("/inlist");
  }


  const handleDownRow = async (aid) => {        
      await timeout(1000);
      await getProfilePdf(aid);
      navigate('/download'); 
  }

  const OnReleaseUpd = async(_aid, st) => {
    toggledisplaycmts(!displaycmts);
    const tobesent = {};
    tobesent.oldstatus = st;
    tobesent.status = "toupdate";    
    tobesent.tfccode = user.tfccode;
    tobesent.username = user.username;
    tobesent.ltype = user.ltype;
    tobesent.gappid = _aid;
    tobesent.cremarks = cremarks22;

    if(tobesent.cremarks === '')
      alert("pls enter comments");
    else {
      changeAppStatus(tobesent, navigate);
      clearProfile();
      navigate("/");
    //clearProf(e);
    }

  }

  const getalltfcapps = async(aid) => {
    if(user !== null && user !== undefined && ((user.ltype.toString() === "V") || user.ltype.toString() === "F"))
    //if(user !== null && user !== undefined && user.ltype.toString() === "V")
      {
        await timeout(1000);
        await clearProfile();
         getTFCcandlist(user.tfccode + "$" + user.username);
      }
    
  }



  const candlist2 = profile4 !== null && profile4 !== undefined && currentList !== null && currentList !== undefined && currentList.length > 0 && currentList.map((edu, ind) => (
    <tr key={edu._aid}>
      <td> {ind+1} </td>
      {/* <td>{edu.cv && edu.cv.sch}</td> */}
      <td style={{fontWeight: "bold"}}>{edu._aid} <br/> {edu._n}</td>   
          
    {edu.cv && edu.cv.st && edu.cv.st !== undefined && (edu.cv.st.toString() === "incomplete") ? <td style={{color: "darkred", fontSize: "10pt"}}> INCOMPLETE <br/> Mobile Number: {edu.m}   </td>  : null  }

    {edu.cv && edu.cv.st && edu.cv.st !== undefined && (edu.cv.st.toString() === "ineligible") ? <td style={{color: "crimson", fontSize: "10pt"}}> INELIGIBLE <br/> Mobile Number: {edu.m}   </td>  : null  }
          

          {(edu.cv && edu.cv.st && edu.cv.st.toString() === "incomplete" && (edu.cv.icl === undefined || edu.cv.icl.length === 0)) ?

          <td> 
            <div style={{color: "crimson"}}> Incomplete list of Certificates is pending </div>                         
          </td> : null
          
          }

{(edu.cv && edu.cv.st && edu.cv.st.toString() === "ineligible" && (edu.cv.inel !== undefined && edu.cv.inel.length > 0)) &&
<div style={{textAlign: "center", color: "crimson", paddingTop: "10px", fontWeight: "bold"}}>
<label> INELIGIBLE REASONS </label> <br/>
          {edu.cv.inel[edu.cv.inel.length-1].el}
</div>
}

          
{(edu.cv && edu.cv.st && edu.cv.st.toString() === "incomplete" && (edu.cv.icl !== undefined && edu.cv.icl.length > 0)) &&
<>
<div style={{textAlign: "center",  color: "darkred", fontWeight: "bold"}}> Pending Certificates List </div>          
           
    {edu.cv.icl[edu.cv.icl.length-1].bna !== false ? <div> *Bonafide Certificate (Nativity others) not available* </div> : null} 

  {edu.cv.icl[edu.cv.icl.length-1].tenna !== false ? <div>  *Xth marksheet not available*  </div> : null} 

  {edu.cv.icl[edu.cv.icl.length-1].elef !== false ? <div> *XIth: Fail in a Subject*  </div> : null}  

  {edu.cv.icl[edu.cv.icl.length-1].elena !== false ? <div>  *XIth marksheet not available* </div> : null} 

  {edu.cv.icl[edu.cv.icl.length-1].twena !== false ? <div>   *XIIth marksheet not available* </div> : null} 

  {edu.cv.icl[edu.cv.icl.length-1].twef !== false ? <div>  *XIIth: Fail in a Subject*  </div> : null} 

  {edu.cv.icl[edu.cv.icl.length-1].twiv ? <div>  *XIIth: Invalid*  </div> : null} 

  {edu.cv.icl[edu.cv.icl.length-1].pcm !== false ? <div>  *Physics, Chemistry, Mathematics not studied* </div> : null} 

  {edu.cv.icl[edu.cv.icl.length-1].indn !== false ? <div>  *Nationality not indian*  </div> : null} 

  {edu.cv.icl[edu.cv.icl.length-1].natina !== false ? <div>  *TN Nativity Certificate not available* </div> : null} 

  {edu.cv.icl[edu.cv.icl.length-1].natif !== false ? <div>  *TN Nativity Certificate not in format* </div> : null} 

  {edu.cv.icl[edu.cv.icl.length-1].pona !== false ? <div> *Parent Occupation Certificate not available*  </div> : null} 

  {edu.cv.icl[edu.cv.icl.length-1].ccna !== false ? <div> *TN Community Certificate not available*  </div> : null} 

  {edu.cv.icl[edu.cv.icl.length-1].ccf !== false ? <div>  *TN Community Certificate not in acceptable format* </div> : null} 
       
  {edu.cv.icl[edu.cv.icl.length-1].dpna !== false ? <div> *Disability Certificate not available*  </div> : null} 

  {edu.cv.icl[edu.cv.icl.length-1].dpf !== false ? <div>  *Disability Certificate not in acceptable format* </div> : null} 

  {edu.cv.icl[edu.cv.icl.length-1].exsna !== false ? <div> *Ex-Serviceman Certificate not available*  </div> : null} 

  {edu.cv.icl[edu.cv.icl.length-1].exsf !== false ? <div>  *Ex-Serviceman Certificate not in acceptable format* </div> : null} 

  {edu.cv.icl[edu.cv.icl.length-1].slna !== false ? <div> *Srilankan Refugee Certificate not available*  </div> : null} 

  {edu.cv.icl[edu.cv.icl.length-1].slf !== false ? <div>  *Srilankan Refugee Certificate not in acceptable format* </div> : null} 

  {edu.cv.icl[edu.cv.icl.length-1].fgna !== false ? <div>  *First Graduate Certificate not available* </div> : null} 

  {edu.cv.icl[edu.cv.icl.length-1].inna !== false ? <div>  *Income Certificate not available* </div> : null} 

  </>


}     

<td> No.of.docs uploaded: {edu._docs !== undefined && edu._docs.length} <br/> <span style={{color: "blue"}}> {edu._alPeC ? "Pending Certificates - Upload is Open" : "Upload Freezed"}</span> </td>

<td> {edu._docs !== undefined && edu._docs.filter((e) => e.id.toString() === "pendingCertificate").length > 0 ? <a  target="_blank" rel="noopener noreferrer"  style={{textDecoration: "underline", color: "crimson", fontWeight: "bold"}} href={edu._docs.filter((e) => e.id.toString() === "pendingCertificate").map((e) => e.durl)[0]}> Click here to Download Pending Certificates </a>    : "Upload not completed"} </td>

      <td style={{fontSize: "8pt"}}>

      {edu.cv && (edu.cv.st === "incomplete") &&

        <button type="submit" style={{fontSize: "9pt", color: ""}}  value="" className="my-2 btn btn-primary" onClick={(i) => dispcmts(edu._aid)}> <i class="fa fa-toggle-on" aria-hidden="true"></i> Release this application to Updation  </button>               

      }

      {edu.cv && ((edu.cv.st === "incomplete")) && displaycmts &&  (
        
        edu._aid === dispaid &&
          <>
        <div className="my-2" style={{fontSize: "9pt", color: "black", fontWeight: "bold"}}> Enter the reason </div>
        <textarea cols="50" style={{textAlign: "center"}} rows="2" className="textarea2" name="cremarks22" value={formData.cremarks22}  onChange = {e => onChange(e)}/>            
        
        <button type="submit" style={{fontSize: "8pt"}}  value="" className="btn btn-dark" onClick= {i => OnReleaseUpd(edu._aid, edu.cv.st)}> Confirm </button> 
      <br/>
        
        </>
      )}

      </td>


          
        </tr>     
  ));


  const getalltfcincompapps = async(aid) => {
    if(user !== null && user !== undefined && ((user.ltype.toString() === "V") || user.ltype.toString() === "F"))
    //if(user !== null && user !== undefined && user.ltype.toString() === "V")
      {
        await timeout(1000);
        console.log("calling candin list");
        await clearProfile();
         getTFCcandinlist(user.tfccode + "$" + user.username);
      }
    
  }


  function timeout(delay) {
    return new Promise( res => setTimeout(res, delay) );
  }

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });


  const onCodeChange = e => {

    //e.preventDefault();

    let temp = e.target.value;

    if((e.target.name === "stfc")) {
      formData.ntfc = TFC_CENTER.filter(el => el.key.toString().includes(temp.toString())).map(el => el.label)[0];
    }

    if((e.target.name === "ntfc")) {
      formData.stfc = TFC_CENTER.filter(el => el.label.toString().includes(temp.toString())).map(el => el.key)[0];
    }
    
    

  setFormData({ ...formData, [e.target.name] : e.target.value });  

    //this.setState({name: e.target.value});

  }


  const candlist = profile3 !== null && profile3 !== undefined && currentList.map((edu, ind) => (
    <tr key={edu._aid}>
      <td>{ind+1}</td>
      {/* <td>{edu.cv && edu.cv.sch}</td>    */}
      <td style={{fontWeight: "bold"}}>{edu._aid} <br/> {edu._n}</td>   
      <td>{edu._st && edu._st.toString() === "preview" ? "Application Completed" : "In Progress"} </td>   
      {/* <td>{edu._n}</td>    */}
      <td> {edu._fzup === true ? "Upload completed": "In Progress"} </td>
      {/* <td>{edu.cv && edu.cv.d}</td>    */}
      <td>{edu.m}</td>
      <td>{edu._p && edu._p._bran ? NDEPT_LIST.filter(e => e.key === edu._p._bran).map(e => e.label) : "NA"}</td>
      <td>{edu._p && edu._p._desig}</td>     
          
          {/* {edu._fzup === true  && */}

         <>

            <td> 
            {edu._st && edu._st.toString() === "preview" && edu._fzup === true &&
              <button className="btn btn-light" style={{fontSize: "9pt", fontWeight: "bold"}} onClick={i => handleVerifyRow(edu._aid)} > Verify </button> 
            }
            
            </td>

            {/* <td> <div> NA </div> </td>

            <td> <div> NA </div> </td>

            <td> <div> NA </div> </td> */}

            </>

          {/* } */}
          

          {edu.status && edu.status.toString() === "complete" && 

          <td> <button className="btn btn-light" style={{fontSize: "9pt", fontWeight: "bold"}} onClick={i => handleDownRow(edu._aid)} > Download</button> </td>
          }

        </tr>     
  ));

  const clearNext = async() => {
    //clearProfile(history, "3");   
    await clearProfile();
    await getTFCcandlist(formData.stfc);
  }

  return (
    <section className="container">
      {/* <h1 className="large text-primary">{user.tfcname}</h1> */}

      <h4> Principal Approval </h4>
      <p className="lead">
        <i className="fas fa-user" />  {user && user.tfccode} -- {user && user.tfcname}
      </p>
      <br />

      <button className='btn btn-primary' style={{backgroundColor: "lightblue", color: "black", fontWeight: "bold"}} onClick={i => getalltfcapps(1000)}> Get Transfer Applications List / ** Refresh List </button>      

      {/* <button className='btn btn-light' style={{backgroundColor: "lightgreen", color: "black"}} onClick={i => getalltfcincompapps(1000)}> Get Incomplete/Ineligible Applications List for - {user && user.username} </button>       */}

        {(profile3 === null || profile4 === null) && loading === true && getdb === true ? (
        <Spinner /> ) : null}

        {profile3 !== null && profile3 !== undefined && 
      <>
          <h5 className="my-2">List of Faculty Applied for Transfer </h5>
          <div className='table-responsive'>
          <table className="table" style={{border: '2px ridge', fontSize: "10pt"}}>
            <thead className='bg-info' style={{fontSize: "9pt", color: "crimson"}}>
              <tr>
                {/* <th scope='col'>Schedule</th>                 */}
                <th>Sl.No</th>                                
                <th scope='col'>Application number / Name</th>      
                <th scope='col'>Application Status</th>                                
                {/* <th scope='col'>Name</th> */}
                <th scope='col'>Upload Status</th>
                {/* <th scope='col'>CV Date</th> */}
                <th scope='col'>Mobile No.</th>
                <th scope='col'>Department</th>
                <th scope='col'>Designation</th>
                <th scope='col'>Check Details</th>
                
                <th scope='col'>Download Application Report</th>
                <th scope='col'></th>
              </tr>
                
            </thead>
            <tbody>{candlist}</tbody>
          </table>


          {profile3 && profile3.length > 0 &&
            <>
            <div className="p-2" />
            <div className="" style={{color: "teal", textAlign: "center", fontSize: "8pt"}}>Pages</div>
            <div className="p-1" />
            <Pagination postsPerpage={postsPerPage} totalPosts={Math.ceil(profile3.length / postsPerPage)}  paginate={paginate} />
            </>
          }

          </div>
      </>
      }      


{profile4 !== null && profile4 !== undefined && 

<>
    <h5 className="my-2">List of Incomplete Candidates </h5>
    <label style={{textAlign: "center", color: "green"}}> Kindly ask the candidate to upload all pending certificates as a single PDF </label>
    <div className='table-responsive'>
    <table className="table table-striped" style={{border: '2px ridge', fontSize: "10pt"}}>
      <thead className='bg-info' style={{fontSize: "9pt", color: "blue"}}>
        <tr>
          <th>Sl.No</th>
          {/* <th scope='col'>Schedule</th>                 */}
          <th scope='col'>Application number / Name</th>                
          
          
          {/* <th scope='col'>CV Date</th> */}
          
          <th scope='col'>CV Status</th>
          <th scope='col'>List of pending certificates</th>           
          <th scope='col'>Upload Status</th>                
          <th scope='col'>Pending Certificate Link</th>   
          <th scope='col'>Release for Updation</th>                                                 
                                       
        </tr>
          
      </thead>
      <tbody>{candlist2}</tbody>
    </table>


    {profile4 && profile4.length > 0 &&
      <>
      <div className="p-2" />
      <div className="" style={{color: "teal", textAlign: "center", fontSize: "8pt"}}>Pages</div>
      <div className="p-1" />
      <Pagination postsPerpage={postsPerPage} totalPosts={Math.ceil(profile4.length / postsPerPage)}  paginate={paginate} />
      </>
    }

    </div>
</>
}      



    </section>
  );
};

TFCSelect.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  getTFCcandlist: PropTypes.func.isRequired,
  getProfileAdmin: PropTypes.func.isRequired,
  getProfileVer: PropTypes.func.isRequired,
  getProfilePdf: PropTypes.func.isRequired,
  getProfileUpd: PropTypes.func.isRequired,
  getProfileCor: PropTypes.func.isRequired,
  changeAppStatus: PropTypes.func.isRequired,

  clearProfile: PropTypes.func.isRequired,
  cleargid: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  listAll: PropTypes.func.isRequired,

  getTFCcandinlist: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile
});

export default connect(mapStateToProps, { getCurrentProfile, clearProfile, cleargid, getTFCcandlist, getProfileVer, getProfilePdf,
getProfileAdmin, getProfileUpd, getProfileCor, listAll, getTFCcandinlist, changeAppStatus })(
  TFCSelect
);
