import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { deletegrievance } from '../../actions/profile';

const DashboardActions = ({
  auth: { user },
  profile: { profile, gid },
  deletegrievance
}
) => {



  const handleDeleteRow = async (i) => {
    const tobesent = {};
    tobesent._aid = profile._aid;
    tobesent.row = i;
    tobesent.gid = gid;
    console.log(tobesent);
    await deletegrievance(tobesent);        
    
  }


  const responses = profile !== null && profile !== undefined && profile.gr !== undefined && profile.gr.cts !== undefined && profile.gr.cts.length > 0 && profile.gr.cts.map((comment) => (
    <tr key={comment._id}>
        <td>{comment.rep}</td>  
        <td>{comment.rd}</td>          
    </tr>
  ));

  const grievances = profile !== null && profile !== undefined && profile.gr !== undefined && profile.gr.map((edu) => (
    <tr key={edu._id}>
      <td>{edu.gt}</td>      
      <td>{edu.st}</td>           
      <td> {moment(profile.updated).format('DD/MM/YYYY')} </td>
      <td> {edu.tfcc} </td>
      <td>
      {((profile.gups !== undefined && profile.gups !== null) && profile.gups.filter((item => (item._gid === edu._gid)))).map((chk) => (
        <a key={chk._gid} href={chk.l} target="_blank" rel="noopener noreferrer" > Download Request Letter </a>
      ))}
      
      </td>     
      <td>
        {responses}
      </td>     
      <td> <button className="btn btn-light" onClick={i => handleDeleteRow(edu._gid)} > delete </button> </td>
    </tr>
  ));


  return (
    <div className='dash-buttons'>     

      <Link to='/add-grievance' className='btn btn-dark'>
        <i className='fab fa-black-tie text-primary' />   Add New Grievance
      </Link>            

      {profile !== null && profile !== undefined &&
      <>
          <h3 className="my-2">Grievances List </h3>
          <table className="table">
            <thead>
              <tr>
                <th>Description</th>
                <th className="hide-sm">Status</th>
                <th className="hide-sm">Date</th>
                <th className="hide-sm">TFC</th>
                <th className="hide-sm">Request Letter</th>
                <th className="hide-sm">Admin response</th>
                <th/>
                <th />
              </tr>
            </thead>
            <tbody>{grievances}</tbody>
          </table>
      </>
      }      

    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
  deletegrievance: PropTypes.func.isRequired
});

//export default DashboardActions;

export default connect(mapStateToProps, { deletegrievance })(
  DashboardActions
);
