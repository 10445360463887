export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const GET_PROFILE = 'GET_PROFILE';
export const EDIT_PROFILE = 'EDIT_PROFILE';
export const GET_PROFILES = 'GET_PROFILES';
export const GET_REPOS = 'GET_REPOS';
export const NO_REPOS = 'NO_REPOS';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';

export const CLEAR_ALLCANDS = 'CLEAR_ALLCANDS';
export const SET_DBCALL = 'SET_DBCALL';
export const SET_LOADING = 'SET_LOADING';
export const RESET_DBCALL = 'RESET_DBCALL';
export const RESET_LOADING = 'RESET_LOADING';

export const GET_DDREPORT = 'GET_DDREPORT';

export const CLEAR_GID = 'CLEAR_GID';
export const SET_GID = 'SET_GID';

export const SET_IP = 'SET_IP';

export const UPDATE_PROFILE22 = 'UPDATE_PROFILE22';

export const CLEAR_PROFILE = 'CLEAR_PROFILE';
export const PROFILE_ERROR = 'PROFILE_ERROR';
export const ACCOUNT_DELETED = 'ACCOUNT_DELETED';
export const GET_POSTS = 'GET_POSTS';
export const GET_POST = 'GET_POST';
export const POST_ERROR = 'POST_ERROR';
export const UPDATE_LIKES = 'UPDATE_LIKES';
export const DELETE_POST = 'DELETE_POST';
export const ADD_POST = 'ADD_POST';
export const ADD_COMMENT = 'ADD_COMMENT';
export const REMOVE_COMMENT = 'REMOVE_COMMENT';

export const GET_APPLNS = 'GET_APPLNS';
export const GET_PROFILE2 = 'GET_PROFILE2';
export const EDIT_PROFILE2 = 'EDIT_PROFILE2';

export const SET_GT = 'SET_GT';
export const RESET_GT = 'RESET_GT';

export const GET_PROFILE22 = 'GET_PROFILE22';
export const EDIT_PROFILE22 = 'EDIT_PROFILE22';

export const GET_PROFILE3 = 'GET_PROFILE3';

export const GET_PROFILE4 = 'GET_PROFILE4';

export const GET_UPDLIST = 'GET_UPDLIST';
export const CLEAR_UPDLIST = 'CLEAR_UPDLIST';

export const CLEAR_PROFILEAPP = 'CLEAR_PROFILEAPP';

export const GET_ALLCANDS = 'GET_ALLCANDS';
export const ALLCANDS_ERROR = 'ALLCANDS_ERROR';
export const GET_CAND = 'GET_CAND';
export const ADD_CAND = 'ADD_CAND';

export const GET_DLINK = 'GET_DLINK';
export const LOG_PROFILE = 'LOG_PROFILE';
export const GET_REPORT = 'GET_REPORT';
export const CLEAR_REPORT = 'CLEAR_REPORT';

export const SET_COUNT = 'SET_COUNT';
export const SET_ALLOTED = 'SET_ALLOTED';

export const GET_FILELIST = 'GET_FILELIST';
export const CLEAR_FILELIST = 'CLEAR_FILELIST';