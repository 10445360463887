import React, { Fragment,  useState, useEffect } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import {Table} from 'reactstrap';

import {Card, ListGroup, CardSubtitle, CardTitle, CardText, ListGroupItem, CardBody, Row} from 'reactstrap';

import {getProfileAdmin,  unFreezeAdmin, changeAppStatus, unFreezeUpload, adminchangegriev} from '../../actions/grievmaster';

import {updateCV, clearApplication, getProfileUpd, getProfilePdf, getUpdpendingforme} from '../../actions/vermaster';

import moment from 'moment';
import Dload2 from './Dload2';

import {listAll, openCert, gettfc} from '../../actions/dload';

const {TFC_CENTER, GENDER, YES_NO_ARRAY, DIFF_ABLED_TYPES, NATIONALITY, NATIVITY, DIST_LIST, STATES, YEAR_OF_PASSING, ELIGIBILITY_TYPE, COMMUNITY, CASTE, QUALIFYING_EXAM, EXAM_BOARDS, HSC_GROUPS, HSC_ACADEMIC_GROUP_CODES, HSC_VOCATIONAL_GROUP_CODES, OTHER_GROUP_CODES,
GOVT_SCHOOL_TYPE, RELIGION, PARENT_OCCUPATION, MEDIUM_OF_INSTRUCTION, CATEGORY_OF_SCHOOL, 
} = require("../../Constant");

const {SCHOOL_BY_BLOCK, SCHOOL_BY_DISTRICT, ALL_SCHOOL, BLOCKS_BY_DISTRICT, BLOCKS} = require("../../Schools24");

const EditCand = ({
    getProfileAdmin, unFreezeAdmin, changeAppStatus, unFreezeUpload, adminchangegriev,
    clearApplication, updateCV, getProfileUpd, getProfilePdf, gettfc, listAll,
    auth: { user, ipaddr },
    
    profile: { profile, loading, logprofile, getdb, applnid, profile3 },
    history
}) => {

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0,0);
  }, []); 


  useEffect(() => {

    if(user && user.ltype === "F")
      navigate("/fgedit");
  }, []);

  // useEffect(() => {
  //   if(applnid !== null && applnid !== undefined && profile3 === undefined) {
  //   gettfc(applnid);       
  //    //console.log(logprofile);
  //    //console.log("invoking s3 list");
     
  //   listAll(applnid);
  //   }
  //    //console.log("completed retrieving the list");
  //  }, [applnid, listAll, gettfc]); 

  const onComplete= async e => {
    e.preventDefault();      

    const tobesent = {};    
    
    tobesent.uremarks = formData.uremarks;
    tobesent.uoname = formData.uoname;
    tobesent._aid = profile._aid;
    tobesent.tfccode = user.tfccode;
    tobesent.username = user.username;
    tobesent.ltype = user.ltype;    
    tobesent.vflag = "ucomplete";
    tobesent.ux = ux; tobesent.uxi = uxi; tobesent.uxii = uxii; 
    //tobesent.utc = utc; 
    tobesent.ucom = ucom;
    tobesent.unat = unat; tobesent.uelt = uelt; tobesent.uex = uex; tobesent.udap = udap;
    //tobesent.ugovs = ugovs;
    tobesent.uslrc = slrc; tobesent.ufgc = fgc; tobesent.uinc = inc; tobesent.noctup = noctup;
    
  
    if(tobesent.uremarks === '' || tobesent.uoname === '')
        alert("enter updation remarks and officer name");
    else {

      if(ux === false && uxi === false && uxii === false &&  ucom === false && unat === false && uelt === false && uex === false && udap === false && slrc === false && fgc === false && inc === false && noctup === false)
          alert("Please Select List of Certificates verified");
      else {
        await updateCV(tobesent, navigate);    
        //clearProf(e);
        console.log("redirecting to pdf");
        await getProfilePdf(applnid);
        console.log("navigating");
        navigate('/download');
      }
    }
  }; 

  const onIncomplete = async e => {
    e.preventDefault();      

    const tobesent = {};    
    
    tobesent.uremarks = formData.uremarks;
    tobesent.uoname = formData.uoname;
    tobesent._aid = profile._aid;
    tobesent.tfccode = user.tfccode;
    tobesent.username = user.username;
    tobesent.ltype = user.ltype;    
    tobesent.vflag = "incomplete";
    tobesent.ux = ux; tobesent.uxi = uxi; tobesent.uxii = uxii; 
    //tobesent.utc = utc; 
    tobesent.ucom = ucom;
    tobesent.unat = unat; tobesent.uelt = uelt; tobesent.uex = uex; tobesent.udap = udap;
    //tobesent.ugovs = ugovs;
    //console.log(tobesent);
    tobesent.uslrc = slrc; tobesent.ufgc = fgc; tobesent.uinc = inc; tobesent.noctup = noctup;

    if(tobesent.uremarks === '' || tobesent.uoname === '')
      alert("enter updation remarks and officer name");
    else {
      if(ux === false && uxi === false && uxii === false &&  ucom === false && unat === false && uelt === false && uex === false && udap === false && slrc === false && fgc === false && inc === false && noctup === false)
          alert("Please Select List of Certificates verified");
      else {
          await updateCV(tobesent, navigate);    
          //clearProf();        
          console.log("navigating");
        
          navigate('/tfcdash');
      }
    }
  };


    
const [formData, setFormData] = useState({
    gappid: '', religion: '', po: '', pn : '', ai: '', fgtfc: '', afgtf: '', aitfw: '', pms: '',
    tfc: '', mob: '', email: '', esp: '', ex: '', dap: '', adt: '', vsc: '',
    dob: '', ny: '', nt: '', name: '', gender: '', hrn: '', elt: '',
    ddno: '', bn: '', amt: '', br: '', ddd: '',
    sit: '', emis: '',
    sy8: '', ssn8: '', sd8: '',  sst8: '', 
    sy9: '', ssn9: '', sd9: '',  sst9: '',
    sy10: '', ssn10: '', sd10: '',  sst10: '',
    sy11: '', ssn11: '', sd11: '',  sst11: '',
    sy12: '', ssn12: '', sd12: '',  sst12: '', 
    sy7: '', ssn7: '', sd7: '',  sst7: '',
    sy6: '', ssn6: '', sd6: '',  sst6: '', 
    sst6st: '', sst7st: '', sst8st: '', sst9st: '', sst10st: '', sst11st: '', sst12st: '',
    sb6: '', sb7: '', sb8: '', sb9: '', sb10: '', sb11: '', sb12: '',
    sigsc: '', hron: '', catsc: '',
    cvdate: "", cvslot: '',
    qy: '', nbe: '', qe: '', gc: '', hg: '', mi: '',    
    hsctot: '', xtot: '', hscobt: '', xobt: '', commcnum: '',
    ux: false, uxi: false, uxii: false,  ucom: false, unat: false, uelt: false, uex: false, udap: false, slrc: false, fgc: false, inc: false,
    uoname: '', uremarks: '', noctup: false,
    mmo: '', mmt: '', pmo: '', pmt: '', cmo: '', cmt: '', omo: '', omt: '', tmo: '', tmt: '', mpcmo: '', mpcmt: '', p1mo: '', p2mt: '', p1mt: '', p2mo: '', 
    
  });

  const [displaydd, toggledisplaydd] = useState(false);
  const [displaySpecialInputs, toggleSpecialInputs] = useState(false);

const { gappid, name, gender, tfc, mob, email, esp, ex, dap, adt, nt, ny, dob, hrn, elt, vsc,
          ddno, bn, amt, br, ddd,
        sit, sy8, ssn8, sd8, sst8, 
        sy9, ssn9, sd9, sst9, 
        sy10, ssn10, sd10, sst10, 
        sy11, ssn11, sd11, sst11, 
        sy12, ssn12, sd12, sst12, cvdate, cvslot ,
        qy, nbe, qe, gc, hg, mi, sigsc, hron, catsc,
        sb8,sb9,sb10,sb11,sb12, sb6, sb7,
        sy6, ssn6, sd6, sst6, 
        sy7, ssn7, sd7, sst7,
        religion, co, cas, po, pn, ai, fgtfc, afgtf, aitfw, pms, emis,
        sst6st, sst7st, sst8st, sst9st, sst10st, sst11st, sst12st, hsctot, xtot, hscobt, xobt, commcnum,
        uoname, uremarks, ux, uxi, uxii, utc, ucom, unat, uelt, uex, udap, ugovs,
        mmo, mmt, pmo, pmt, cmo, cmt, omo, omt, tmo, tmt, mpcmo, mpcmt, p1mo, p1mt, p2mo, p2mt,
        slrc, fgc, inc, noctup

      } = formData;



      const onChange = e => {
    
        if((e.target.name === "vsc") && (e.target.value === "Yes")) {
             formData.tfc = TFC_CENTER.filter(el => el.key.toString() === "4").map(el => el.label)[0];
         }
       
         if((e.target.name === "catsc") && (e.target.value.toString() === "State Govt")) {
           formData.sigsc = "Yes";
           formData.sst6st = GOVT_SCHOOL_TYPE.filter(e => e.key === profile._sc.cVIst).map(e => e.label)[0];
           formData.sst7st = GOVT_SCHOOL_TYPE.filter(e => e.key === profile._sc.cVIIst).map(e => e.label)[0];
           formData.sst8st = GOVT_SCHOOL_TYPE.filter(e => e.key === profile._sc.cVIIIst).map(e => e.label)[0];
           formData.sst9st = GOVT_SCHOOL_TYPE.filter(e => e.key === profile._sc.cIXst).map(e => e.label)[0];
           formData.sst10st = GOVT_SCHOOL_TYPE.filter(e => e.key === profile._sc.cXst).map(e => e.label)[0];
           formData.sst11st = GOVT_SCHOOL_TYPE.filter(e => e.key === profile._sc.cXIst).map(e => e.label)[0];
           formData.sst12st = GOVT_SCHOOL_TYPE.filter(e => e.key === profile._sc.cXIIst).map(e => e.label)[0];
         }
         else if((e.target.name === "catsc") && (e.target.value.toString() !== "State Govt"))  {
           formData.sigsc = "No";
           formData.sst6st = null;
           formData.sst7st = null;
           formData.sst8st = null;
           formData.sst9st = null;
           formData.sst10st = null;
           formData.sst11st = null;
           formData.sst12st = null;
         }
       
         if(e.target.name === "co" ) {
           //console.log(CASTE[e.target.value].filter(e => e.label)[0]);
           const check = e.target.value;
           const temp = COMMUNITY.filter(en => en.label.toString() === check.toString()).map(en => en.key)[0];
           formData.cas = CASTE[temp.toString()].filter(em => em.label)[0].label;
           console.log(formData.cas);          
       
       }

       if(e.target.name === "qe" && (e.target.value === "CBSE"))
       {
        formData.nbe = EXAM_BOARDS.filter(e => e.key.toString() === "2").map(e => e.label);
       }

       if(e.target.name === "qe" && (e.target.value === "ICSE"))
       {
        formData.nbe = EXAM_BOARDS.filter(e => e.key.toString() === "3").map(e => e.label);
       }

       if(e.target.name === "qe" && (e.target.value === "Others"))
       {
        formData.nbe = EXAM_BOARDS.filter(e => e.key.toString() === "27").map(e => e.label);
       }

       if(e.target.name === "sd6") {

        const dist = e.target.value;

        console.log(dist, profile._sc.cVId, DIST_LIST.filter(e => e.label.toString() === dist.toString()).map(e => e.key)[0]);

        const distcode = DIST_LIST.filter(ek => ek.label.toString() === dist.toString()).map(ek => ek.key)[0];

        console.log(BLOCKS_BY_DISTRICT[distcode.toString()].filter(em => em.label)[0].label);

        if(BLOCKS_BY_DISTRICT[distcode.toString()] !== undefined)
          formData.sb6 = BLOCKS_BY_DISTRICT[distcode.toString()].filter(em => em.label)[0].label;
        
       }

       if(e.target.name === "sd7") {

        const dist = e.target.value;

        console.log(dist, profile._sc.cVIId, DIST_LIST.filter(e => e.label.toString() === dist.toString()).map(e => e.key)[0]);

        const distcode = DIST_LIST.filter(ek => ek.label.toString() === dist.toString()).map(ek => ek.key)[0];

        console.log(BLOCKS_BY_DISTRICT[distcode.toString()].filter(em => em.label)[0].label);

        if(BLOCKS_BY_DISTRICT[distcode.toString()] !== undefined)
          formData.sb7 = BLOCKS_BY_DISTRICT[distcode.toString()].filter(em => em.label)[0].label;
        
       }

       if(e.target.name === "sd8") {

        const dist = e.target.value;

        console.log(dist, profile._sc.cVIIId, DIST_LIST.filter(e => e.label.toString() === dist.toString()).map(e => e.key)[0]);

        const distcode = DIST_LIST.filter(ek => ek.label.toString() === dist.toString()).map(ek => ek.key)[0];

        console.log(BLOCKS_BY_DISTRICT[distcode.toString()].filter(em => em.label)[0].label);

        if(BLOCKS_BY_DISTRICT[distcode.toString()] !== undefined)
          formData.sb8 = BLOCKS_BY_DISTRICT[distcode.toString()].filter(em => em.label)[0].label;
        
       }


       if(e.target.name === "sd9") {

        const dist = e.target.value;

        console.log(dist, profile._sc.cIXd, DIST_LIST.filter(e => e.label.toString() === dist.toString()).map(e => e.key)[0]);

        const distcode = DIST_LIST.filter(ek => ek.label.toString() === dist.toString()).map(ek => ek.key)[0];

        console.log(BLOCKS_BY_DISTRICT[distcode.toString()].filter(em => em.label)[0].label);

        if(BLOCKS_BY_DISTRICT[distcode.toString()] !== undefined)
          formData.sb9 = BLOCKS_BY_DISTRICT[distcode.toString()].filter(em => em.label)[0].label;
        
       }

       if(e.target.name === "sd10") {

        const dist = e.target.value;

        console.log(dist, profile._sc.cXd, DIST_LIST.filter(e => e.label.toString() === dist.toString()).map(e => e.key)[0]);

        const distcode = DIST_LIST.filter(ek => ek.label.toString() === dist.toString()).map(ek => ek.key)[0];

        console.log(BLOCKS_BY_DISTRICT[distcode.toString()].filter(em => em.label)[0].label);

        if(BLOCKS_BY_DISTRICT[distcode.toString()] !== undefined)
          formData.sb10 = BLOCKS_BY_DISTRICT[distcode.toString()].filter(em => em.label)[0].label;
        
       }

       if(e.target.name === "sd11") {

        const dist = e.target.value;

        console.log(dist, profile._sc.cXId, DIST_LIST.filter(e => e.label.toString() === dist.toString()).map(e => e.key)[0]);

        const distcode = DIST_LIST.filter(ek => ek.label.toString() === dist.toString()).map(ek => ek.key)[0];

        console.log(BLOCKS_BY_DISTRICT[distcode.toString()].filter(em => em.label)[0].label);

        if(BLOCKS_BY_DISTRICT[distcode.toString()] !== undefined)
          formData.sb11 = BLOCKS_BY_DISTRICT[distcode.toString()].filter(em => em.label)[0].label;
        
       }

       if(e.target.name === "sd12") {

        const dist = e.target.value;

        console.log(dist, profile._sc.cXIId, DIST_LIST.filter(e => e.label.toString() === dist.toString()).map(e => e.key)[0]);

        const distcode = DIST_LIST.filter(ek => ek.label.toString() === dist.toString()).map(ek => ek.key)[0];

        console.log(BLOCKS_BY_DISTRICT[distcode.toString()].filter(em => em.label)[0].label);

        if(BLOCKS_BY_DISTRICT[distcode.toString()] !== undefined)
          formData.sb12 = BLOCKS_BY_DISTRICT[distcode.toString()].filter(em => em.label)[0].label;
        
       }
       
       if(e.target.name === "qe" && (e.target.value === "HSC"))
       {          
         if(hg === null)
              formData.hg = HSC_GROUPS.filter(em => em.key.toString() === "1").map(em => em.label)[0];      
        
         formData.nbe = EXAM_BOARDS.filter(e => e.key.toString() === "1").map(e => e.label);
       
         formData.gc = HSC_ACADEMIC_GROUP_CODES.filter(em => em.key.toString() === "1501").map(em => em.label)[0];      
       
         console.log("academic gc " + formData.gc);
       }
       
       
       if(e.target.name === "qe" && ((e.target.value === "CBSE") || (e.target.value === "ICSE") || (e.target.value === "Others")))
       {        
         formData.gc = OTHER_GROUP_CODES.filter(em => em.key.toString() === "0001").map(em => em.label)[0];      
         console.log("others gc " + formData.gc);
       }
       
       if(e.target.name === "hg" && (e.target.value === "HSC Vocational"))
       {
         formData.gc = HSC_VOCATIONAL_GROUP_CODES.filter(em => em.key.toString() === "2921").map(em => em.label)[0];  
       
         console.log("vocational gc " + formData.gc);
       }
       
       
         if((e.target.name === "ny") && ((e.target.value === "Srilankan Tamil Refugee") || (e.target.value === "Others"))) {
           formData.nt = NATIVITY.filter(em => em.key.toString() === "OTH").map(em => em.label)[0];
         }

         if((e.target.name === "sst6") && (e.target.value === "Tamil nadu")) {
        
          formData.sd6 = DIST_LIST.filter(em => em.key.toString() === "1").map(em => em.label)[0];
        
          if(BLOCKS_BY_DISTRICT["1"] !== undefined)
            formData.sb6 = BLOCKS_BY_DISTRICT["1"].filter(em => em.label)[0].label;
        }

        if((e.target.name === "sst6") && (e.target.value !== "Tamil nadu")) {
          formData.sd6= DIST_LIST.filter(em => em.key.toString() === "39").map(em => em.label)[0];
          formData.sb6 = "OTH";
          
        }        

         if((e.target.name === "sst7") && (e.target.value === "Tamil nadu")) {
          formData.sd7 = DIST_LIST.filter(em => em.key.toString() === "1").map(em => em.label)[0];

          if(BLOCKS_BY_DISTRICT["1"] !== undefined)
            formData.sb7 = BLOCKS_BY_DISTRICT["1"].filter(em => em.label)[0].label;
        }

        if((e.target.name === "sst7") && (e.target.value !== "Tamil nadu")) {
          formData.sd7 = DIST_LIST.filter(em => em.key.toString() === "39").map(em => em.label)[0];
          formData.sb7 = "OTH";
        }        

       
         if((e.target.name === "sst8") && (e.target.value === "Tamil nadu")) {
           formData.sd8 = DIST_LIST.filter(em => em.key.toString() === "1").map(em => em.label)[0];

           if(BLOCKS_BY_DISTRICT["1"] !== undefined)
            formData.sb8 = BLOCKS_BY_DISTRICT["1"].filter(em => em.label)[0].label;
         }

         if((e.target.name === "sst8") && (e.target.value !== "Tamil nadu")) {
          formData.sd8 = DIST_LIST.filter(em => em.key.toString() === "39").map(em => em.label)[0];
          formData.sb8 = "OTH";
        }        

       
         if((e.target.name === "sst9") && (e.target.value === "Tamil nadu")) {
           formData.sd9 = DIST_LIST.filter(em => em.key.toString() === "1").map(em => em.label)[0];

           if(BLOCKS_BY_DISTRICT["1"] !== undefined)
            formData.sb9 = BLOCKS_BY_DISTRICT["1"].filter(em => em.label)[0].label;

          //console.log(formData.sd9, formData.sb9, BLOCKS_BY_DISTRICT[formData.sd9.toString()]);
         }
         if((e.target.name === "sst9") && (e.target.value !== "Tamil nadu")) {
          formData.sd9 = DIST_LIST.filter(em => em.key.toString() === "39").map(em => em.label)[0];
          formData.sb9 = "OTH";
        }        

       
         if((e.target.name === "sst10") && (e.target.value === "Tamil nadu")) {
           formData.sd10 = DIST_LIST.filter(em => em.key.toString() === "1").map(em => em.label)[0];

           if(BLOCKS_BY_DISTRICT["1"] !== undefined)
             formData.sb10 = BLOCKS_BY_DISTRICT["1"].filter(em => em.label)[0].label;
        }
         if((e.target.name === "sst10") && (e.target.value !== "Tamil nadu")) {
          formData.sd10 = DIST_LIST.filter(em => em.key.toString() === "39").map(em => em.label)[0];
          formData.sb10 = "OTH";
        }        

       
         if((e.target.name === "sst11") && (e.target.value === "Tamil nadu")) {
           formData.sd11 = DIST_LIST.filter(em => em.key.toString() === "1").map(em => em.label)[0];

           if(BLOCKS_BY_DISTRICT["1"] !== undefined)
           formData.sb11 = BLOCKS_BY_DISTRICT["1"].filter(em => em.label)[0].label;

         }
         if((e.target.name === "sst11") && (e.target.value !== "Tamil nadu")) {
          formData.sd11 = DIST_LIST.filter(em => em.key.toString() === "39").map(em => em.label)[0];
          formData.sb11 = "OTH";
        }        

       
         if((e.target.name === "sst12") && (e.target.value === "Tamil nadu")) {
           formData.sd12 = DIST_LIST.filter(em => em.key.toString() === "1").map(em => em.label)[0];

           if(BLOCKS_BY_DISTRICT["1"] !== undefined)
            formData.sb12 = BLOCKS_BY_DISTRICT["1"].filter(em => em.label)[0].label;
         }
         if((e.target.name === "sst12") && (e.target.value !== "Tamil nadu")) {
          formData.sd12 = DIST_LIST.filter(em => em.key.toString() === "39").map(em => em.label)[0];
          formData.sb12 = "OTH";
        }        

       
         //name: e.target.value to change name alone
         //console.log("new " + formData.tfc);
       
         setFormData({ ...formData, [e.target.name] : e.target.value });  
       
           
   }
       
    

const clickunfr = async e => {
  e.preventDefault();       
  console.log(" Invoking unfreeze for " + profile._aid);
  //console.log("Appln number : "+ formData.gappid);       
  await unFreezeAdmin(profile._aid, navigate);   
  //console.log("tfc in form " + formData.tfc);
  clearApplication(navigate);
};

const clickunfrupd = async e => {
  e.preventDefault();       
  console.log(" Invoking unfreeze for " + profile._aid);
  //console.log("Appln number : "+ formData.gappid);       
  await unFreezeUpload(profile._aid, navigate);   
  //console.log("tfc in form " + formData.tfc);
  clearApplication(navigate);
};


const changeVerStatus = async e => {
  e.preventDefault();
  const tobesent = {};
  tobesent.oldstatus = profile.cv.st;
  //formData.status = 'present';
  console.log("Appln no status changed as present " + formData.gappid);
  //console.log("tfcname " + user.tfcname + "username " + user.username + "ltype " + user.ltype);
  tobesent.status = "present";    

  tobesent.tfccode = user.tfccode;
  tobesent.username = user.username;
  tobesent.ltype = user.ltype;
  tobesent.gappid = profile._aid;
  tobesent.cremarks = "admin";
    changeAppStatus(tobesent, navigate);
  //clearProf(e);
  }

  const changeUpdStatus = async e => {
    e.preventDefault();
    const tobesent = {};
    tobesent.oldstatus = profile.cv.st;
    //formData.status = 'toupdate';
    //console.log("Appln no status changed as toupdate " + formData.gappid);
    //console.log("tfcname " + user.tfcname + "username " + user.username + "ltype " + user.ltype);
    tobesent.status = "toupdate";    
    tobesent.tfccode = user.tfccode;
    tobesent.username = user.username;
    tobesent.ltype = user.ltype;
    tobesent.gappid = profile._aid;
    tobesent.cremarks = "admin";

      changeAppStatus(tobesent, navigate);
    //clearProf(e);

  }

  const changeCorStatus = async e => {
    e.preventDefault();
    const tobesent = {};
    tobesent.oldstatus = profile.cv.st;
    //formData.status = 'toupdate';
    //console.log("Appln no status changed as toupdate " + formData.gappid);
    //console.log("tfcname " + user.tfcname + "username " + user.username + "ltype " + user.ltype);
    tobesent.status = "incomplete";    
    tobesent.tfccode = user.tfccode;
    tobesent.username = user.username;
    tobesent.ltype = user.ltype;
    tobesent.gappid = profile._aid;
    tobesent.cremarks = "admin";

      changeAppStatus(tobesent, navigate);
    //clearProf(e);

  }


  const changeEliStatus = async e => {
    e.preventDefault();
    const tobesent = {};
    tobesent.oldstatus = profile.cv.st;
    //formData.status = 'toupdate';
    //console.log("Appln no status changed as toupdate " + formData.gappid);
    //console.log("tfcname " + user.tfcname + "username " + user.username + "ltype " + user.ltype);
    tobesent.status = "incomplete";    
    tobesent.tfccode = user.tfccode;
    tobesent.username = user.username;
    tobesent.ltype = user.ltype;
    tobesent.gappid = profile._aid;
    tobesent.cremarks = "admin";
    console.log(tobesent);

      changeAppStatus(tobesent, navigate);
    //clearProf(e);

  }

function changeTFC () {
    toggleChangeTFC(!displayChangeTFC);
    
    
    setFormData({ ...formData, 
        
      name: loading || !profile._n ? '' : profile._n,

        gender: loading || !profile._p.g ? '' : GENDER.filter(e => e.key === profile._p.g).map(e => e.label)[0] ,

        dob: loading || !profile.dob ? '' : moment(profile.dob, 'DD-MM-YYYY').format('YYYY-MM-DD'),        

        cvdate: (!profile.cv || !profile.cv.d) ? '' : moment(profile.cv.d, 'DD-MM-YYYY').format('YYYY-MM-DD'),        

        cvslot: (!profile.cv || !profile.cv.s) ? '' : profile.cv.s,        

        hrn: loading || !profile._ac.hrn ? '': profile._ac.hrn,

        emis: loading || !profile._ac.emisno ? '': profile._ac.emisno,

        commcnum: loading || !profile._ac.commcnum ? '': profile._ac.commcnum,

        hron: loading || !profile._ac.hron ? '': profile._ac.hron,

        co: loading || !profile._p.co ? '' : COMMUNITY.filter(e => e.key === profile._p.co).map(e => e.label)[0],
    
        cas: loading || !profile._p.cas ? '' : CASTE[profile._p.co].filter(e => e.key.toString() === profile._p.cas.toString()).map(e => e.label),

        // vsc: YES_NO_ARRAY.filter(e => e.key.toString() === profile._sr.vsc.toString()).map(e => e.label),

        religion: loading || !profile._p.r ? '' : RELIGION.filter(e => e.key.toString() === profile._p.r.toString()).map(e => e.label)[0],

        pn: loading || !profile._p.pn ? '' : profile._p.pn,

        po: loading || !profile._sp.po ? '' : PARENT_OCCUPATION.filter(e => e.key.toString() === profile._sp.po.toString()).map(e => e.label),  

        ai: loading || !profile._sp.ai ? '' : profile._sp.ai,

        pms: loading || !profile._sp.pms ? '' : YES_NO_ARRAY.filter(e => e.key.toString() === profile._sp.pms.toString()).map(e => e.label),

        fgtfc: loading || !profile._sp.fgtfc ? '' : YES_NO_ARRAY.filter(e => e.key.toString() === profile._sp.fgtfc.toString()).map(e => e.label),


        qe: loading || !profile._ac.qe ? '' : QUALIFYING_EXAM.filter(e => e.key.toString() === profile._ac.qe.toString()).map(e => e.label),

        nbe: loading || !profile._ac.nbe ? '': EXAM_BOARDS.filter(e => e.key.toString() === profile._ac.nbe.toString()).map(e => e.label),
  
        hg: loading || !profile._ac.hg ? null: HSC_GROUPS.filter(e => e.key.toString() === profile._ac.hg.toString()).map(e => e.label).toString(),
    
      gc: ((loading || !profile._ac.gc) ? null :  (profile._ac.qe.toString() === "1" && profile._ac.hg.toString() === "1")) ?
      
      HSC_ACADEMIC_GROUP_CODES.filter(e => e.key.toString() === profile._ac.gc.toString()).map(e => e.label) : 
      
      (loading || (profile._ac.qe.toString() === "1" && profile._ac.hg.toString() === "2")) ?
  
      HSC_VOCATIONAL_GROUP_CODES.filter(e => e.key.toString() === profile._ac.gc.toString()).map(e => e.label) : (loading || (profile._ac.qe.toString() !== "1")) ?
  
      OTHER_GROUP_CODES.filter(e => e.key.toString() === profile._ac.gc.toString()).map(e => e.label) : null
      ,
      
        hsctot: loading || (profile._ac.hsctm === null || profile._ac.hsctm === undefined) ? '0' : profile._ac.hsctm,
        hscobt: loading || (profile._ac.hscobm === null || profile._ac.hscobm === undefined) ? '0' : profile._ac.hscobm,

        xtot: loading || !profile._ac.xtm ? '0' : profile._ac.xtm,
        xobt: loading || !profile._ac.xobm ? '0' : profile._ac.xobm,
        
        qy: loading || !profile._ac.qy ? '': YEAR_OF_PASSING.filter(e => e.key.toString() === profile._ac.qy.toString()).map(e => e.label),

        mi: loading || !profile._ac.mi ? '': MEDIUM_OF_INSTRUCTION.filter(e => e.key.toString() === profile._ac.mi.toString()).map(e => e.label),

        catsc: loading || !profile._sc.caofsc ? '': CATEGORY_OF_SCHOOL.filter(e => e.key.toString() === profile._sc.caofsc.toString()).map(e => e.label),


        tfc : loading || !profile._sr.tfc ? '': TFC_CENTER.filter(e => e.key.toString() === profile._sr.tfc.toString()).map(e => e.label)[0],
        mob: profile.m,
        email: profile.e,
        // esp: YES_NO_ARRAY.filter(e => e.key.toString() === profile._sr.vsc.toString()).map(e => e.label),
        // //vsc: YES_NO_ARRAY.filter(e => e.key.toString() === profile._sr.vsc.toString()).map(e => e.label),
        // ex: YES_NO_ARRAY.filter(e => e.key.toString() === profile._sr.ex.toString()).map(e => e.label),
        // dap: YES_NO_ARRAY.filter(e => e.key.toString() === profile._sr.dap.toString()).map(e => e.label),
        // adt: !profile._sr.adt ? null : DIFF_ABLED_TYPES.filter(e => e.key.toString() === profile._sr.adt.toString()).map(e => e.label)[0],
//        dob: moment(profile.dob, 'DD-MM-YYYY').format('YYYY-MM-DD'),
        ny: !profile._p.ny ? '' : NATIONALITY.filter(e => e.key === profile._p.ny).map(e => e.label)[0],
        nt: !profile._p.nt ? '' : NATIVITY.filter(e => e.key.toString() === profile._p.nt.toString()).map(e => e.label)[0],

        elt: !profile._p.elt ? null: ELIGIBILITY_TYPE.filter(e => e.key.toString() === profile._p.elt.toString()).map(e => e.label)[0],

        sigsc: !profile._sc.sigsc ? '' : YES_NO_ARRAY.filter(e => e.key.toString() === profile._sc.sigsc.toString()).map(e => e.label),

        sit: YES_NO_ARRAY.filter(e => e.key.toString() === profile._sc.sit.toString()).map(e => e.label),



      sy7: !profile._sc.cVIIy ? '' : YEAR_OF_PASSING.filter(e => e.key === profile._sc.cVIIy).map(e => e.label)[0], 
      
      ssn7: !profile._sc.cVIIsn ? '': SCHOOL_BY_BLOCK[profile._sc.cVIIb] === undefined ? profile._sc.cVIIsn : SCHOOL_BY_BLOCK[profile._sc.cVIIb].filter(e => e.key === profile._sc.cVIIsn).map(e => e.label)[0],         

      sd7: !profile._sc.cVIId ? '' : DIST_LIST.filter(e => e.key.toString() === profile._sc.cVIId.toString()) === undefined ? profile._sc.cVIIId : DIST_LIST.filter(e => e.key.toString() === profile._sc.cVIId.toString()).map(e => e.label)[0],

      sst7: !profile._sc.cVIIs ? '' : STATES.filter(e => e.key === profile._sc.cVIIs) === undefined ? profile._sc.cVIIs : STATES.filter(e => e.key === profile._sc.cVIIs).map(e => e.label)[0],

      sst7st: !profile._sc.cVIIst ? null : GOVT_SCHOOL_TYPE.filter(e => e.key === profile._sc.cVIIst) === undefined ? profile._sc.cVIIst : GOVT_SCHOOL_TYPE.filter(e => e.key === profile._sc.cVIIst).map(e => e.label)[0],

      sb7: !profile._sc.cVIIb ? null : BLOCKS_BY_DISTRICT[profile._sc.cVIId] === undefined ? profile._sc.cVIIb : BLOCKS_BY_DISTRICT[profile._sc.cVIId].filter(e => e.key === profile._sc.cVIIb).map(e => e.label)[0],         


      

      sy6: !profile._sc.cVIy ? '' : YEAR_OF_PASSING.filter(e => e.key === profile._sc.cVIy).map(e => e.label)[0],

      ssn6: !profile._sc.cVIsn ? '': SCHOOL_BY_BLOCK[profile._sc.cVIb] === undefined ? profile._sc.cVIsn : SCHOOL_BY_BLOCK[profile._sc.cVIb].filter(e => e.key === profile._sc.cVIsn).map(e => e.label)[0], 
      
      
      sd6: !profile._sc.cVId ? '' : DIST_LIST.filter(e => e.key.toString() === profile._sc.cVId.toString()) === undefined ? profile._sc.cVId : DIST_LIST.filter(e => e.key.toString() === profile._sc.cVId.toString()).map(e => e.label)[0],        


      sst6: !profile._sc.cVIs ? '' : STATES.filter(e => e.key === profile._sc.cVIs) === undefined ? profile._sc.cVId : STATES.filter(e => e.key === profile._sc.cVIs).map(e => e.label)[0],


      sst6st: !profile._sc.cVIst ? null : GOVT_SCHOOL_TYPE.filter(e => e.key === profile._sc.cVIst) === undefined ? profile._sc.cVIst : GOVT_SCHOOL_TYPE.filter(e => e.key === profile._sc.cVIst).map(e => e.label)[0],


      sb6: !profile._sc.cVIb ? null : BLOCKS_BY_DISTRICT[profile._sc.cVId] === undefined ? profile._sc.cVIb : BLOCKS_BY_DISTRICT[profile._sc.cVId].filter(e => e.key === profile._sc.cVIb).map(e => e.label)[0],         



      sy8: !profile._sc.cVIIIy ? '' : YEAR_OF_PASSING.filter(e => e.key === profile._sc.cVIIIy).map(e => e.label)[0], 

      ssn8: !profile._sc.cVIIIsn ? '': SCHOOL_BY_BLOCK[profile._sc.cVIIIb] === undefined ? profile._sc.cVIIIsn : SCHOOL_BY_BLOCK[profile._sc.cVIIIb].filter(e => e.key === profile._sc.cVIIIsn).map(e => e.label)[0],         

      sd8: !profile._sc.cVIIId ? '' : DIST_LIST.filter(e => e.key.toString() === profile._sc.cVIIId.toString()) === undefined ? profile._sc.cVIIId : DIST_LIST.filter(e => e.key.toString() === profile._sc.cVIIId.toString()).map(e => e.label)[0], 

      sst8: !profile._sc.cVIIIs ? '' : STATES.filter(e => e.key === profile._sc.cVIIIs) === undefined ? profile._sc.cVIIIs : STATES.filter(e => e.key === profile._sc.cVIIIs).map(e => e.label)[0],

      sst8st: !profile._sc.cVIIIst ? null : GOVT_SCHOOL_TYPE.filter(e => e.key === profile._sc.cVIIIst) === undefined ? profile._sc.cVIIIst : GOVT_SCHOOL_TYPE.filter(e => e.key === profile._sc.cVIIIst).map(e => e.label)[0],

      sb8: !profile._sc.cVIIIb ? null : BLOCKS_BY_DISTRICT[profile._sc.cVIIId] === undefined ? profile._sc.cVIIIb : BLOCKS_BY_DISTRICT[profile._sc.cVIIId].filter(e => e.key === profile._sc.cVIIIb).map(e => e.label)[0],         


 
      sy9: !profile._sc.cIXy ? '' : YEAR_OF_PASSING.filter(e => e.key === profile._sc.cIXy).map(e => e.label)[0], 

      ssn9: !profile._sc.cIXsn ? '': SCHOOL_BY_BLOCK[profile._sc.cIXb] === undefined ? profile._sc.cIXsn : SCHOOL_BY_BLOCK[profile._sc.cIXb].filter(e => e.key === profile._sc.cIXsn).map(e => e.label)[0],

      sd9: !profile._sc.cIXd ? '' : DIST_LIST.filter(e => e.key.toString() === profile._sc.cIXd.toString()) === undefined ? profile._sc.cIXd : DIST_LIST.filter(e => e.key.toString() === profile._sc.cIXd.toString()).map(e => e.label)[0],

      sst9: !profile._sc.cIXs ? '' : STATES.filter(e => e.key === profile._sc.cIXs) === undefined ? profile._sc.cIXs : STATES.filter(e => e.key === profile._sc.cIXs).map(e => e.label)[0],

      sst9st: !profile._sc.cIXst ? null : GOVT_SCHOOL_TYPE.filter(e => e.key === profile._sc.cIXst) === undefined ? profile._sc.cIXst : GOVT_SCHOOL_TYPE.filter(e => e.key === profile._sc.cIXst).map(e => e.label)[0],

      sb9: !profile._sc.cIXb ? null : BLOCKS_BY_DISTRICT[profile._sc.cIXd] === undefined ? profile._sc.cIXb : BLOCKS_BY_DISTRICT[profile._sc.cIXd].filter(e => e.key === profile._sc.cIXb).map(e => e.label)[0],         



      sy10: !profile._sc.cXy ? '' : YEAR_OF_PASSING.filter(e => e.key === profile._sc.cXy).map(e => e.label)[0], 

      ssn10: !profile._sc.cXsn ? '': SCHOOL_BY_BLOCK[profile._sc.cXb] === undefined ? profile._sc.cXsn : SCHOOL_BY_BLOCK[profile._sc.cXb].filter(e => e.key === profile._sc.cXsn).map(e => e.label)[0],       

      sd10: !profile._sc.cXd ? '' : DIST_LIST.filter(e => e.key.toString() === profile._sc.cXd.toString()) === undefined ? profile._sc.cXd : DIST_LIST.filter(e => e.key.toString() === profile._sc.cXd.toString()).map(e => e.label)[0],

      sst10: !profile._sc.cXs ? '' : STATES.filter(e => e.key === profile._sc.cXs) === undefined ? profile._sc.cXs : STATES.filter(e => e.key === profile._sc.cXs).map(e => e.label)[0],

      sst10st: !profile._sc.cXst ? null : GOVT_SCHOOL_TYPE.filter(e => e.key === profile._sc.cXst) === undefined ? profile._sc.cXst : GOVT_SCHOOL_TYPE.filter(e => e.key === profile._sc.cXst).map(e => e.label)[0],

      sb10: !profile._sc.cXb ? null : BLOCKS_BY_DISTRICT[profile._sc.cXd] === undefined ? profile._sc.cXb :  BLOCKS_BY_DISTRICT[profile._sc.cXd].filter(e => e.key === profile._sc.cXb).map(e => e.label)[0],         



      sy11: !profile._sc.cXIy ? '' : YEAR_OF_PASSING.filter(e => e.key === profile._sc.cXIy).map(e => e.label)[0], 

      ssn11: !profile._sc.cXIsn ? '':  SCHOOL_BY_BLOCK[profile._sc.cXIb] === undefined ? profile._sc.cXIsn : SCHOOL_BY_BLOCK[profile._sc.cXIb].filter(e => e.key === profile._sc.cXIsn).map(e => e.label)[0],  

      sd11: !profile._sc.cXId ? '' : DIST_LIST.filter(e => e.key.toString() === profile._sc.cXId.toString()) === undefined ? profile._sc.cXId : DIST_LIST.filter(e => e.key.toString() === profile._sc.cXId.toString()).map(e => e.label)[0],

      sst11: !profile._sc.cXIs ? '' : STATES.filter(e => e.key === profile._sc.cXIs).map(e => e.label) === undefined ? profile._sc.cXIs : STATES.filter(e => e.key === profile._sc.cXIs).map(e => e.label)[0],

      sst11st: !profile._sc.cXIst ? null : GOVT_SCHOOL_TYPE.filter(e => e.key === profile._sc.cXIst) === undefined ? profile._sc.cXIst : GOVT_SCHOOL_TYPE.filter(e => e.key === profile._sc.cXIst).map(e => e.label)[0],

      sb11: !profile._sc.cXIb ? null : BLOCKS_BY_DISTRICT[profile._sc.cXId] === undefined ? profile._sc.cXIb : BLOCKS_BY_DISTRICT[profile._sc.cXId].filter(e => e.key === profile._sc.cXIb).map(e => e.label)[0],         


      sy12: !profile._sc.cXIIy ? '' : YEAR_OF_PASSING.filter(e => e.key === profile._sc.cXIIy).map(e => e.label)[0], 

      ssn12: !profile._sc.cXIIsn ? '': SCHOOL_BY_BLOCK[profile._sc.cXIIb] === undefined ? profile._sc.cXIIsn : SCHOOL_BY_BLOCK[profile._sc.cXIIb].filter(e => e.key === profile._sc.cXIIsn).map(e => e.label)[0],         

      sd12: !profile._sc.cXIId ? '' : DIST_LIST.filter(e => e.key.toString() === profile._sc.cXIId.toString()) === undefined ? profile._sc.cXIId : DIST_LIST.filter(e => e.key.toString() === profile._sc.cXIId.toString()).map(e => e.label)[0],

      sst12: !profile._sc.cXIIs ? '' : STATES.filter(e => e.key === profile._sc.cXIIs) === undefined ? profile._sc.cXIIs : STATES.filter(e => e.key === profile._sc.cXIIs).map(e => e.label)[0],

      sst12st: !profile._sc.cXIIst ? null : GOVT_SCHOOL_TYPE.filter(e => e.key === profile._sc.cXIIst) === undefined ? profile._sc.cXIIst : GOVT_SCHOOL_TYPE.filter(e => e.key === profile._sc.cXIIst).map(e => e.label)[0],

      sb12: !profile._sc.cXIIb ? null : BLOCKS_BY_DISTRICT[profile._sc.cXIId] === undefined ? profile._sc.cXIIb : BLOCKS_BY_DISTRICT[profile._sc.cXIId].filter(e => e.key === profile._sc.cXIIb).map(e => e.label)[0],         

      dd: '', br: '', bn: '', ddd: '', amt: '',

      mmo: loading || !profile._ac.mmo ? '' : profile._ac.mmo,
      mmt: loading || !profile._ac.mmt ? '' : profile._ac.mmt,
      pmo: loading || !profile._ac.pmo ? '' : profile._ac.pmo,
      pmt: loading || !profile._ac.pmt ? '' : profile._ac.pmt,
      cmo: loading || !profile._ac.cmo ? '' : profile._ac.cmo,
      cmt: loading || !profile._ac.cmt ? '' : profile._ac.cmt,
      omo: loading || !profile._ac.omo ? '' : profile._ac.omo,
      omt: loading || !profile._ac.omt ? '' : profile._ac.omt,

      tmo: loading || !profile._ac.tmo ? '' : profile._ac.tmo,
      tmt: loading || !profile._ac.tmt ? '' : profile._ac.tmt,
      mpcmo: loading || !profile._ac.mpcmo ? '' : profile._ac.mpcmo,
      mpcmt: loading || !profile._ac.mpcmt ? '' : profile._ac.mpcmt,
      p1mo: loading || !profile._ac.p1mo ? '' : profile._ac.p1mo,
      p1mt: loading || !profile._ac.p1mt ? '' : profile._ac.p1mt,
      p2mo: loading || !profile._ac.p2mo ? '' : profile._ac.p2mo,
      p2mt: loading || !profile._ac.p2mt ? '' : profile._ac.p2mt,


    });  

    window.scrollTo({
      top: 900,
      left: 100,
      behavior: 'smooth'
    });


};

const handledateChange = async (e) => {
  e.preventDefault();
  //console.log(e.target.value);
  //console.log("Converted " + moment(e.target.value).format('DD-MM-YYYY'));
  //let temp = moment(dob).format('DD-MM-YYYY');
  setFormData({ ...formData, dob: e.target.value });    
};

const handledateChangecv = async (e) => {
  e.preventDefault();
  //console.log(e.target.value);
  //console.log("Converted " + moment(e.target.value).format('DD-MM-YYYY'));
  //let temp = moment(dob).format('DD-MM-YYYY');
  setFormData({ ...formData, cvdate: e.target.value });    
};

const handledddateChange = async (e) => {
  e.preventDefault();
  //console.log(e.target.value);
  //console.log("Converted " + moment(e.target.value).format('DD-MM-YYYY'));
  //let temp = moment(dob).format('DD-MM-YYYY');
  setFormData({ ...formData, ddd: e.target.value });    
};

const [displayChangeTFC, toggleChangeTFC] = useState(false);

const [displayChTFC, toggledisplayChTFC] = useState(false);

function changename() {

  var temp = [];    

  if(profile._n.toString() !== name.toString()) {
    //vflag = vflag.concat(" nc ", profile._n.toString());

    const lfields = {};
    lfields.fn = "_n";
    lfields.o = profile._n.toString(); lfields.n = name.toString();
    temp.push(lfields);
    
    const lfields2 = {};
    lfields2.fn = "_p._n";
    lfields2.o = profile._n.toString(); lfields2.n = name.toString();
    temp.push(lfields2);

    profile._n = name;

  }

  return temp;

};


function changegender() {

  var temp = [];    

  if(profile._p.g.toString() !== GENDER.filter(e => e.label === gender).map(e => e.key.toString())[0])
  {
    //vflag = vflag.concat(" gc ", profile._p.g.toString());

    const lfields = {};
    lfields.fn = "_p.g";
    lfields.o = profile._p.g.toString(); 
    lfields.n = GENDER.filter(e => e.label === gender).map(e => e.key.toString())[0];
    temp.push(lfields);

    profile._p.g = GENDER.filter(e => e.label === gender.toString()).map(e => e.key.toString())[0];    
  }

  return temp;
};


function changedob() {

  var temp = [];    

  if(profile.dob !== moment(dob, "YYYY-MM-DD").format('DD-MM-YYYY')) {
    //vflag = vflag.concat(" dobc ", profile.dob);

    const lfields = {};
    lfields.fn = "dob";
    lfields.o = profile.dob; 
    lfields.n = moment(dob, "YYYY-MM-DD").format('DD-MM-YYYY');
    temp.push(lfields);

    const lfields2 = {};
    lfields2.fn = "_p.dob";
    lfields2.o = profile.dob.toString(); 
    lfields2.n = moment(dob, "YYYY-MM-DD").format('DD-MM-YYYY');
    temp.push(lfields2);

    profile.dob = moment(dob, "YYYY-MM-DD").format('DD-MM-YYYY');
    
}

  return temp;
};

function changemobile() {

  var temp = [];    

  if(profile.m.toString() !== mob) {

    const lfields = {};
    lfields.fn = "m";
    lfields.o = profile.m.toString(); 
    lfields.n = mob;
    temp.push(lfields);    
    
    profile.m = mob;

  }
  
  return temp;
}

function changenationality() {

  var temp = [];

  if(profile._p.ny !== NATIONALITY.filter(e => e.label === ny).map(e => e.key.toString())[0])
    {
        //vflag = vflag.concat(" nyc ", profile._p.ny);
        
        const lfields = {};
        lfields.fn = "_p.ny";
        lfields.o = profile._p.ny.toString(); 
        lfields.n = NATIONALITY.filter(e => e.label === ny).map(e => e.key.toString())[0];
        temp.push(lfields);    

    }

    profile._p.ny = NATIONALITY.filter(e => e.label === ny).map(e => e.key.toString())[0];

    return temp;
}

function changenativity() {
  var temp = [];

  if(profile._p.nt !== NATIVITY.filter(e => e.label === nt).map(e => e.key.toString())[0])
    {
     //   vflag = vflag.concat(" ntc ", profile._p.nt);

        const lfields = {};
        lfields.fn = "_p.nt";
        lfields.o = profile._p.nt.toString(); 
        lfields.n = NATIVITY.filter(e => e.label === nt).map(e => e.key.toString())[0];
        temp.push(lfields);

        if(lfields.n === "TN") {

          //console.log ("Nat from others to TN " + profile._p.elt);
          if(profile._p.elt === undefined) 
                profile._p.elt = null;
          
          const lfields5 = {};
          lfields5.fn = "_p.elt";
          lfields5.o = profile._p.elt; 
          lfields5.n = null;
          temp.push(lfields5);  

          //tobesent.elt = null;
        }

        if (lfields.n === "OTH") {
          //alert ("Got you");

          if(profile._p.co.toString() !== "OC") {
              const lfields3 = {};
              lfields3.fn = "_p.co";
              lfields3.o = profile._p.co.toString(); 
              lfields3.n = "OC";

              temp.push(lfields3);

              //tobesent.co = "OC";
      
              const lfields2 = {};
              lfields2.fn = "_p.cas";
              lfields2.o = profile._p.cas.toString(); 
              lfields2.n = "500";
              temp.push(lfields2);
      
              //tobesent.cas = "500";
          }
        }
    }

    profile._p.nt = NATIVITY.filter(e => e.label === nt).map(e => e.key.toString())[0];

    return temp;
}


function handleeltcases() {

  console.log("Nativ ", profile._p.nt, "Nation", profile._p.ny );
    var nelt = null;
    var eltflag = false;

    var temp = [];

    if(profile._p.nt.toString() === "OTH" && profile._p.ny === "IN") {

      //console.log("Elt : " + profile._p.elt + " " + elt);

          if(((profile._p.elt === undefined) || (profile._p.elt === null)) && ((elt === "") || (elt === null)))
          {            
                console.log ("case elt null");
                nelt = "studiedInTN";
                profile._p.elt = null;
                eltflag = true;
          }          
          else if(((profile._p.elt === undefined) || (profile._p.elt === null)) && elt !== null)      
          {

                console.log ("old null elt not null case");
                nelt = ELIGIBILITY_TYPE.filter(e => e.label === elt.toString()).map(e => e.key.toString())[0];
                profile._p.elt = null;
                eltflag = true;
          }            
          else if(profile._p.elt.toString() !== ELIGIBILITY_TYPE.filter(e => e.label === elt.toString()).map(e => e.key.toString())[0]) {
              //alert("change in elt");
              
              nelt = ELIGIBILITY_TYPE.filter(e => e.label === elt.toString()).map(e => e.key.toString())[0];

              eltflag = true;

            }
          else
          {
            console.log("no elt change"); eltflag = false;
          }

          if(eltflag === true) {
                          
            //vflag = vflag.concat(" eltc ", profile._p.elt);

            const lfields = {};
            lfields.fn = "_p.elt";
            lfields.o = profile._p.elt; 
            lfields.n = nelt;
            temp.push(lfields);

            profile._p.elt = nelt;
          }

          }

          return temp;
}

function changereligion() {
  
  var temp = [];
  
  if(profile._p.r.toString() !== RELIGION.filter(e => e.label === religion.toString()).map(e => e.key.toString())[0])
  {
    //vflag = vflag.concat(" rc ", profile._p.r.toString());

    const lfields = {};
    lfields.fn = "_p.r";
    lfields.o = profile._p.r.toString(); 
    lfields.n = RELIGION.filter(e => e.label === religion).map(e => e.key.toString())[0];
    temp.push(lfields);
    profile._p.r = RELIGION.filter(e => e.label === religion.toString()).map(e => e.key.toString())[0];
  }
  
  return temp;
}

function changesports() {
  
  var temp = [];

    if((profile._sr.vsc.toString() === "N") && (vsc.toString() === "Yes"))
    {
      //vflag = vflag.concat("vsc_ac");
      const lfields = {};
      lfields.fn = "_sr.vsc";
      lfields.o = "N"; lfields.n = "Y";
      temp.push(lfields);

    }
    if((profile._sr.vsc.toString() === "Y") && (vsc.toString() === "No")) {
      //vflag = vflag.concat("vsc_cc");

      const lfields = {};
      lfields.fn = "_sr.vsc";
      lfields.o = "Y"; lfields.n = "N";
      temp.push(lfields);

    }

    profile._sr.vsc = YES_NO_ARRAY.filter(e => e.label.toString() === vsc.toString()).map(e => e.key.toString())[0];

    return temp;
  
}

function tfcchange() {

  var temp = [];

    if((profile._sr.tfc === null) || (profile._sr.tfc === undefined))
      profile._sr.tfc = "1";

      console.log(profile._sr.tfc, tfc, TFC_CENTER.filter(e => e.label.toString().includes(tfc.toString())).map(e => e.key.toString())[0]);

      if(profile._sr.tfc.toString() !== TFC_CENTER.filter(e => e.label.toString().includes(tfc.toString())).map(e => e.key.toString())[0]) {
        //vflag = vflag.concat(" tfcc ", profile._sr.tfc.toString());    
        const lfields = {};
        lfields.fn = "_sr.tfc";
        
        lfields.o = profile._sr.tfc.toString(); 
        
        lfields.n = TFC_CENTER.filter(e => e.label.toString().includes(tfc.toString())).map(e => e.key.toString())[0];
        temp.push(lfields);

        profile._sr.tfc = TFC_CENTER.filter(e => e.label.toString().includes(tfc.toString())).map(e => e.key.toString())[0];
      } 

  return temp;
}

function exserchange() {

  var temp = [];

  if((profile._sr.ex.toString() === "N") && (ex.toString() === "Yes")) {
    //vflag = vflag.concat(" ex_ac ");
    const lfields = {};
    lfields.fn = "_sr.ex";
    lfields.o = "N"; lfields.n = "Y";
    temp.push(lfields);
    
  }
  if((profile._sr.ex.toString() === "Y") && (ex.toString() === "No")) {
    //vflag = vflag.concat(" ex_cc ");
    const lfields = {};
    lfields.fn = "_sr.ex";
    lfields.o = "Y"; lfields.n = "N";
    temp.push(lfields);
  }
  profile._sr.ex = YES_NO_ARRAY.filter(e => e.label.toString() === ex.toString()).map(e => e.key.toString())[0];
  return temp;
}

function changediffdisabled() {

  var temp = [];
  var nadt = null;

  if((profile._sr.dap.toString() === "N") && (dap.toString() === "Yes")) {      
    if(adt === null) {
        nadt = "1";
    }
    else 
        nadt = DIFF_ABLED_TYPES.filter(e => e.label.toString() === adt.toString()).map(e => e.key)[0].toString();

    //vflag = vflag.concat(" dap_ac ", nadt);
    const lfields = {};
    lfields.fn = "_sr.dap";
    lfields.o = "N"; lfields.n = "Y";
    temp.push(lfields);

    const lfields2 = {};
    lfields2.fn = "_sr.adt";
    lfields2.o = null; lfields2.n = nadt.toString();
    temp.push(lfields2);    
  }

  //console.log("adt " + adt + " profile " + profile._sr.dap + " here " + dap);

  if((profile._sr.dap.toString() === "Y") && (dap.toString() === "Yes")) {    
      //console.log("new adt " + nadt);
        if(adt === null) {
            nadt = "1";

            const lfields = {};
            lfields.fn = "_sr.dap";
            lfields.o = "Y"; lfields.n = "Y";
            temp.push(lfields);
      
            const lfields2 = {};
            lfields2.fn = "_sr.adt";
            lfields2.o = profile._sr.adt.toString(); lfields2.n = nadt.toString();
            temp.push(lfields2);

        }
        else {        
            nadt = DIFF_ABLED_TYPES.filter(e => e.label.toString() === adt.toString()).map(e => e.key)[0].toString();

                if(profile._sr.adt.toString() !== nadt.toString())
                  {
                    //vflag = vflag.concat(" adt_ac ", profile._sr.adt);

                    const lfields = {};
                    lfields.fn = "_sr.dap";
                    lfields.o = "Y"; lfields.n = "Y";
                    temp.push(lfields);
              
                    const lfields2 = {};
                    lfields2.fn = "_sr.adt";
                    lfields2.o = profile._sr.adt.toString(); lfields2.n = nadt;
                    temp.push(lfields2);
                  }

            }
    }
        

  if((profile._sr.dap.toString() === "Y") && (dap.toString() === "No")) {
    //vflag = vflag.concat(" dap_cc ", profile._sr.adt );

    const lfields = {};
    lfields.fn = "_sr.dap";
    lfields.o = "Y"; lfields.n = "N";
    temp.push(lfields);

    const lfields2 = {};
    lfields2.fn = "_sr.adt";
    lfields2.o = profile._sr.adt.toString(); lfields2.n = null;
    temp.push(lfields2);

  }

  profile._sr.dap = YES_NO_ARRAY.filter(e => e.label.toString() === dap.toString()).map(e => e.key.toString())[0];
  profile._sr.adt = nadt;

  return temp;
}

function changepo () {

  var temp = [];

  if(profile._sp.po.toString() !== PARENT_OCCUPATION.filter(e => e.label === po.toString()).map(e => e.key.toString())[0])
    {
      //vflag = vflag.concat(" poc ", profile._sp.po.toString());

      const lfields = {};
      lfields.fn = "_sp.po";
      lfields.o = profile._sp.po.toString(); 
      lfields.n = PARENT_OCCUPATION.filter(e => e.label === po).map(e => e.key.toString())[0];
      temp.push(lfields);

      profile._sp.po = PARENT_OCCUPATION.filter(e => e.label === po.toString()).map(e => e.key.toString())[0];

    }


    return temp;
}

function changepn() {
  var temp = [];

  if(profile._p.pn.toString() !== pn.toString()) {
  //  vflag = vflag.concat(" pnc ", profile._p.pn.toString());

    const lfields = {};
    lfields.fn = "_p.pn";
    lfields.o = profile._p.pn.toString(); 
    lfields.n = pn.toString();
    temp.push(lfields);
    profile._p.pn = pn;
  }

  return temp;
}


function changeai() {

  var temp = [];

  if(profile._sp.ai.toString() !== ai.toString())
    {
      //vflag = vflag.concat(" aic ", profile._sp.ai.toString());

      const lfields = {};
      lfields.fn = "_sp.ai";
      lfields.o = profile._sp.ai.toString(); 
      lfields.n = ai.toString();
      temp.push(lfields);
      profile._sp.ai = ai.toString();
    }

    return temp;
}

function changefirstgrad() {
  var temp = [];

  if(profile._sp.fgtfc.toString() !== YES_NO_ARRAY.filter(e => e.label === fgtfc.toString()).map(e => e.key.toString())[0])
    {
      //vflag = vflag.concat(" fgtfc ", profile._sp.fgtfc.toString());

      const lfields = {};
      lfields.fn = "_sp.fgtfc";
      lfields.o = profile._sp.fgtfc.toString(); 
      lfields.n = YES_NO_ARRAY.filter(e => e.label === fgtfc.toString()).map(e => e.key.toString())[0];
      temp.push(lfields);
      profile._sp.fgtfc = YES_NO_ARRAY.filter(e => e.label === fgtfc.toString()).map(e => e.key.toString())[0];

    }
    return temp;
}

function changepms() {

  var temp = [];

  if(profile._sp.pms.toString() !== YES_NO_ARRAY.filter(e => e.label === pms.toString()).map(e => e.key.toString())[0])
    {
      //vflag = vflag.concat(" pms ", profile._sp.pms.toString());

      const lfields = {};
      lfields.fn = "_sp.pms";
      lfields.o = profile._sp.pms.toString(); 
      lfields.n = YES_NO_ARRAY.filter(e => e.label === pms.toString()).map(e => e.key.toString())[0];
      temp.push(lfields);
      profile._sp.pms = YES_NO_ARRAY.filter(e => e.label === pms.toString()).map(e => e.key.toString())[0];
    }

    return temp;
}

var splcomchange = false;
var qechange = false;
var nbechange = false;
var bderr = false;    
var hgchange = false;
var oldhg = null;
var tobesenthg = null;
var bdchange = null;
var nbdchange = null;

 
const onVerify = async e => {
  e.preventDefault();          

  const tobesent = {};
  tobesent.farr = [];
  var tt = null;
  

  if((tt = changename()).length > 0) {
    tobesent._n = profile._n;
    tobesent.farr = [...tobesent.farr, ...tt];
  }

  if((tt = changegender()).length > 0) {    
    tobesent.g = profile._p.g;
    tobesent.farr = [...tobesent.farr, ...tt];
  }

  if((tt = changedob()).length > 0) {    
    tobesent.dob = profile.dob;
    tobesent.farr = [...tobesent.farr, ...tt];
  }
  
  tobesent.mobflag = false;
  
  if((tt = changemobile()).length > 0) {    
    tobesent.om = tt[0].o;
    tobesent.mobile = tt[0].n;
    tobesent.email = profile.e;
    tobesent.farr = [...tobesent.farr, ...tt];
    tobesent.mobflag = true;
     
  }

  if((tt = changenationality()).length > 0) {
    tobesent.ny = profile._p.ny;  
    tobesent.farr = [...tobesent.farr, ...tt];
    if(tt[0].n === "IN") { 
      tobesent.co = profile._p.co;
      tobesent.cas = profile._p.cas;
    }

  }

  if((tt = changenativity()).length > 0) {    
    tobesent.farr = [...tobesent.farr, ...tt];
    if(tt[0].n === "TN") {
      tobesent.elt = null;
    }

    if (tt[0].n === "OTH") {
      
      if(profile._p.co.toString() !== "OC") {
          tobesent.co = "OC"; 
          tobesent.cas = "500";
        }
    }
    tobesent.nt = profile._p.nt;
  }

  if((tt = handleeltcases()).length > 0) {
    tobesent.farr = [...tobesent.farr, ...tt];
  }

  if(profile._p.nt.toString() === "OTH" && profile._p.ny.toString() === "IN")
    tobesent.elt = profile._p.elt;

    
  if((tt = changereligion()).length > 0) {
      tobesent.r = profile._p.r;
      tobesent.farr = [...tobesent.farr, ...tt];
  }

  // if((tt = changesports()).length > 0) {
  //   tobesent.vsc = profile._sr.vsc;
  //   tobesent.farr = [...tobesent.farr, ...tt];
  // }

  if((tt = tfcchange()).length > 0) {
    tobesent.ntfccode = profile._sr.tfc;
    tobesent.farr = [...tobesent.farr, ...tt];
  }

  // if((tt = exserchange()).length > 0) {
  //   tobesent.ex = profile._sr.ex;
  //   tobesent.farr = [...tobesent.farr, ...tt];
  // }

  // if((tt = changediffdisabled()).length > 0) {
  //   tobesent.dap = profile._sr.dap;
  //   tobesent.nadt = profile._sr.adt;
  //   tobesent.farr = [...tobesent.farr, ...tt];
  // }

  if((tt = changepo()).length > 0) {
    tobesent.po = profile._sp.po;
    tobesent.farr = [...tobesent.farr, ...tt];
  }

  if((tt = changepn()).length > 0) {
    tobesent.pn = profile._p.pn;
    tobesent.farr = [...tobesent.farr, ...tt];
  }

  if((tt = changeai()).length > 0) {
    tobesent.ai = profile._sp.ai;
    tobesent.farr = [...tobesent.farr, ...tt];
  }

  if((tt = changefirstgrad()).length > 0) {
    tobesent.fgtfc = profile._sp.fgtfc;
    tobesent.farr = [...tobesent.farr, ...tt];
  }

  if((tt = changepms()).length > 0) {
    tobesent.pms = profile._sp.pms;
    tobesent.farr = [...tobesent.farr, ...tt];
  }

  if((tt = changeemis()).length > 0) {

    tobesent.emis = profile._ac.emisno;
    tobesent.farr = [...tobesent.farr, ...tt];
  }

  if((tt = changehron()).length > 0) {
    tobesent.hron = profile._ac.hron;
    tobesent.farr = [...tobesent.farr, ...tt];
  }

  if((tt = changesit()).length > 0) {
    tobesent.sit = profile._sc.sit;
    tobesent.farr = [...tobesent.farr, ...tt];
  }

  if((tt = changesigsc()).length > 0) {
    tobesent.sigsc = profile._sc.sigsc;
    tobesent.farr = [...tobesent.farr, ...tt];
  }

  if((tt = change_qe_nbe_hg_gc()).length > 0)
  {
    tobesent.qe = profile._ac.qe;
    tobesent.nbe = profile._ac.nbe;
    tobesent.hg = profile._ac.hg;
    tobesenthg = tobesent.hg;
    tobesent.gc = profile._ac.gc;
    tobesent.farr = [...tobesent.farr, ...tt];

  }

  if((tt = changeqy()).length > 0) {
    tobesent.qy = profile._ac.qy;
    tobesent.farr = [...tobesent.farr, ...tt];
  }

  if((tt = changemi()).length > 0) {
    tobesent.mi = profile._ac.mi;
    tobesent.farr = [...tobesent.farr, ...tt];
  }

  if((tt = changecaofsc()).length > 0) {
    tobesent.caofsc = profile._sc.caofsc;
    tobesent.farr = [...tobesent.farr, ...tt];
  }

  //  if((tt = changeschoolfields6()).length > 0) {
  //    tobesent.farr = [...tobesent.farr, ...tt];
  //    tobesent.cVIy = profile._sc.cVIy;
  //    tobesent.cVIsn = profile._sc.cVIsn;
  //    tobesent.cVId = profile._sc.cVId;
  //    tobesent.cVIs = profile._sc.cVIs;
  //    if(sigsc.toString() === "Yes" && catsc.toString() === "State Govt" && profile._sc.cVIst === null)
  //      tobesent.cVIst = "1";
  //    else
  //      tobesent.cVIst = profile._sc.cVIst;
     
  //    tobesent.cVIb = profile._sc.cVIb;     
  //  }

  //  if((tt = changeschoolfields7()).length > 0) {
  //   tobesent.farr = [...tobesent.farr, ...tt];
  //   tobesent.cVIIy = profile._sc.cVIIy;
  //   tobesent.cVIIsn = profile._sc.cVIIsn;
  //   tobesent.cVIId = profile._sc.cVIId;
  //   tobesent.cVIIs = profile._sc.cVIIs;
  //   if(sigsc.toString() === "Yes" && catsc.toString() === "State Govt" && profile._sc.cVIIst === null)
  //     tobesent.cVIIst = "1";
  //   else
  //     tobesent.cVIIst = profile._sc.cVIIst;
    
  //   tobesent.cVIIb = profile._sc.cVIIb;
    
  // }

  // if((tt = changeschoolfields8()).length > 0) {
  //   tobesent.farr = [...tobesent.farr, ...tt];
  //   tobesent.cVIIIy = profile._sc.cVIIIy;
  //   tobesent.cVIIIsn = profile._sc.cVIIIsn;
  //   tobesent.cVIIId = profile._sc.cVIIId;
  //   tobesent.cVIIIs = profile._sc.cVIIIs;
  //   if(sigsc.toString() === "Yes" && catsc.toString() === "State Govt" && profile._sc.cVIIIst === null)
  //     tobesent.cVIIIst = "1";
  //   else
  //     tobesent.cVIIIst = profile._sc.cVIIIst;
    
  //   tobesent.cVIIIb = profile._sc.cVIIIb;    
  // }

  // if((tt = changeschoolfields9()).length > 0) {
  //   tobesent.farr = [...tobesent.farr, ...tt];
  //   tobesent.cIXy = profile._sc.cIXy;
  //   tobesent.cIXsn = profile._sc.cIXsn;
  //   tobesent.cIXd = profile._sc.cIXd;
  //   tobesent.cIXs = profile._sc.cIXs;
  //   if(sigsc.toString() === "Yes" && catsc.toString() === "State Govt" && profile._sc.cIXst === null)
  //     tobesent.cIXst = "1";
  //   else
  //     tobesent.cIXst = profile._sc.cIXst;
    
  //   tobesent.cIXb = profile._sc.cIXb;
    
  // }

  // if((tt = changeschoolfields10()).length > 0) {
  //   tobesent.farr = [...tobesent.farr, ...tt];
  //   tobesent.cXy = profile._sc.cXy;
  //   tobesent.cXsn = profile._sc.cXsn;
  //   tobesent.cXd = profile._sc.cXd;
  //   tobesent.cXs = profile._sc.cXs;
  //   if(sigsc.toString() === "Yes" && catsc.toString() === "State Govt" && profile._sc.cXst === null)
  //     tobesent.cXst = "1";
  //   else
  //     tobesent.cXst = profile._sc.cXst;
    
  //   tobesent.cXb = profile._sc.cXb;
    
  // }

  // if((tt = changeschoolfields11()).length > 0) {
  //   tobesent.farr = [...tobesent.farr, ...tt];
  //   tobesent.cXIy = profile._sc.cXIy;
  //   tobesent.cXIsn = profile._sc.cXIsn;
  //   tobesent.cXId = profile._sc.cXId;
  //   tobesent.cXIs = profile._sc.cXIs;
  //   if(sigsc.toString() === "Yes" && catsc.toString() === "State Govt" && profile._sc.cXIst === null)
  //     tobesent.cXIst = "1";
  //   else
  //     tobesent.cXIst = profile._sc.cXIst;
    
  //   tobesent.cXIb = profile._sc.cXIb;
    
  // }

  // if((tt = changeschoolfields12()).length > 0) {
  //   tobesent.farr = [...tobesent.farr, ...tt];
  //   tobesent.cXIIy = profile._sc.cXIIy;
  //   tobesent.cXIIsn = profile._sc.cXIIsn;
  //   tobesent.cXIId = profile._sc.cXIId;
  //   tobesent.cXIIs = profile._sc.cXIIs;
  //   if(sigsc.toString() === "Yes" && catsc.toString() === "State Govt" && profile._sc.cXIIst === null)
  //     tobesent.cXIIst = "1";
  //   else
  //     tobesent.cXIIst = profile._sc.cXIIst;
    
  //   tobesent.cXIIb = profile._sc.cXIIb;
    
  // }

  if((tt = changehsctotobt()).length > 0) {

    tobesent.hscobm = profile._ac.hscobm;
    tobesent.hsctm = profile._ac.hsctm;
    tobesent.farr = [...tobesent.farr, ...tt];

  }

  if((tt = changextotobt()).length > 0){
    tobesent.farr = [...tobesent.farr, ...tt];
    tobesent.xobm = profile._ac.xobm;
    tobesent.xtm = profile._ac.xtm;
  }

  if((tt = changehrnp()).length > 0){
    tobesent.farr = [...tobesent.farr, ...tt];
    tobesent.hrn = profile._ac.hrn;    
  }

  if((tt = changemarks()).length > 0){
    tobesent.farr = [...tobesent.farr, ...tt];
    tobesent.mmo = profile._ac.mmo;
    tobesent.mmt = profile._ac.mmt;

    tobesent.pmo = profile._ac.pmo;
    tobesent.pmt = profile._ac.pmt;

    tobesent.cmo = profile._ac.cmo;
    tobesent.cmt = profile._ac.cmt;

    tobesent.omo = profile._ac.omo;
    tobesent.omt = profile._ac.omt;


    tobesent.mpcmo = profile._ac.mpcmo;
    tobesent.mpcmt = profile._ac.mpcmt;

    tobesent.p1mo = profile._ac.p1mo;
    tobesent.p1mt = profile._ac.p1mt;

    tobesent.p2mo = profile._ac.p2mo;
    tobesent.p2mt = profile._ac.p2mt;

    tobesent.tmo = profile._ac.tmo;
    tobesent.tmt = profile._ac.tmt;

  }


  if((tt = changecommcnum()).length > 0){
    tobesent.farr = [...tobesent.farr, ...tt];
    tobesent.commcnum = profile._ac.commcnum;    
  }

  tobesent.splcomchange = false;
  tobesent.ddflag = false;
  tobesent.bdchange = bdchange;
  tobesent.nbdchange = nbdchange;

  if((tt = castecommchange()).length > 0) {
    tobesent.farr = [...tobesent.farr, ...tt];
    tobesent.co = profile._p.co;

    //profile._p.cas = CASTE[tobesent.co].filter(e => e.label.toString() === cas.toString()).map(e => e.key.toString())[0];
    //alert("final value caste " + profile._p.cas);
    tobesent.cas = profile._p.cas;

    var dontsendflag = false;
    tobesent.ddetails = {};
    
    

    let totamt = 0;

          if(splcomchange === true)
          {
                  totamt += 250;
                  console.log(totamt);
    
                  if((formData.ddd.toString() === "") || (formData.bn.toString() === "") || (formData.br.toString() === "") || (formData.ddno.toString() === "") || (formData.amt < 250)) 
                  {
                      alert("Invalid DD Details");
                      dontsendflag = true;                   
                  }
                  else {
                      tobesent.splcomchange = true;
                      tobesent.ddflag = true;
                      const temp = moment(ddd, "YYYY-MM-DD").format('DD-MM-YYYY');
                      
                      tobesent.ddetails.amt = formData.amt;
                      tobesent.ddetails.bn = formData.bn;
                      tobesent.ddetails.br = formData.br;
        
                      tobesent.ddetails.ddd = temp;
                      tobesent.ddetails.ddno = formData.ddno;
                  }
          }
  }

  tobesent.email = profile.e;
  tobesent.mobile = profile.m;
  tobesent._aid = profile._aid;
  tobesent.username = user.username;
  tobesent.ltype = user.ltype;
  //tobesent.cip = ipaddr; //has to be fixed
  
  console.log(tobesent);

  await adminchangegriev(tobesent, navigate);

  console.log("updated details");

  await getProfileUpd(tobesent._aid);      
  
  console.log("retrieved new");

}


function changeqy() {
  var temp = [];
  if(profile._ac.qy.toString() !== YEAR_OF_PASSING.filter(e => e.label === qy.toString()).map(e => e.key.toString())[0])
  {
    //vflag = vflag.concat(" qyc ", profile._ac.qy.toString());

    const lfields = {};
    lfields.fn = "_ac.qy";
    lfields.o = profile._ac.qy.toString(); 
    lfields.n = YEAR_OF_PASSING.filter(e => e.label === qy.toString()).map(e => e.key.toString())[0];
    temp.push(lfields);

    profile._ac.qy = YEAR_OF_PASSING.filter(e => e.label === qy.toString()).map(e => e.key.toString())[0];
  }
  return temp;
}

function change_qe_nbe_hg_gc() {

  var temp = [];


  if(profile._ac.qe.toString() !== QUALIFYING_EXAM.filter(e => e.label === qe.toString()).map(e => e.key.toString())[0])
    {
     
      //vflag = vflag.concat(" qec ", profile._ac.qe.toString()); //some change in qe
  
      const lfields = {};
      lfields.fn = "_ac.qe";
      lfields.o = profile._ac.qe.toString(); 
      lfields.n = QUALIFYING_EXAM.filter(e => e.label === qe.toString()).map(e => e.key.toString())[0];
      temp.push(lfields);      
      qechange = true;

      profile._ac.qe = QUALIFYING_EXAM.filter(e => e.label === qe.toString()).map(e => e.key.toString())[0];
      
    }


    if(profile._ac.nbe.toString() !== EXAM_BOARDS.filter(e => e.label === nbe.toString()).map(e => e.key.toString())[0])
    {
      //vflag = vflag.concat(" nbec ", profile._ac.nbe.toString()); //some board change
  
      const lfields = {};
      lfields.fn = "_ac.nbe";
      lfields.o = profile._ac.nbe.toString(); 
      lfields.n = EXAM_BOARDS.filter(e => e.label === nbe.toString()).map(e => e.key.toString())[0];
      temp.push(lfields);
      nbechange = true;

      profile._ac.nbe = EXAM_BOARDS.filter(e => e.label === nbe.toString()).map(e => e.key.toString())[0];

    }



    if(qechange === true)
    {
        if(nbechange === false) {
          alert("Please check Board of Examination");
          bderr = true;
        }
    }


    if(profile._ac.qe.toString() === "1" && profile._ac.nbe.toString() === "1") //new qualifying exam 1
    {        
      
        if((profile._ac.hg !== null) && (profile._ac.hg !== undefined)) //cbse to hsc
        {

          if(profile._ac.hg.toString() !== HSC_GROUPS.filter(e => e.label === hg.toString()).map(e => e.key.toString())[0]) {
              //alert("new board hsc. only group is changed");

              //academic to vocational and vice versa

              //alert(profile._ac.hg);

              //vflag = vflag.concat(" hgc ", profile._ac.hg.toString());
  
              const lfields = {};
              lfields.fn = "_ac.hg";
              lfields.o = profile._ac.hg.toString(); 
              lfields.n = HSC_GROUPS.filter(e => e.label === hg.toString()).map((e,index) => index.toString())[0];
              temp.push(lfields);

              profile._ac.hg = HSC_GROUPS.filter(e => e.label === hg.toString()).map((e,index) => index.toString())[0];

              //tobesent.hg = profile._ac.hg;

              hgchange = true; oldhg = lfields.o;
          }
          else {
           //   alert(" No change in hg ");
          }
        }
        else
        {
          //cbse to hsc
          
              //alert("new board hsc. old group is empty. ie old board is cbse");         

              //vflag = vflag.concat(" hgc null " );
  
              const lfields = {};
              lfields.fn = "_ac.hg";
              lfields.o = null; 
              lfields.n = HSC_GROUPS.filter(e => e.label === hg.toString()).map(e => e.key.toString())[0];
              temp.push(lfields);

              profile._ac.hg = HSC_GROUPS.filter(e => e.label === hg.toString()).map(e => e.key.toString())[0];

              //tobesent.hg = profile._ac.hg;

              hgchange = true; oldhg = lfields.o;

        }
    }
    else if(profile._ac.qe.toString() !== "1" && profile._ac.nbe.toString() !== "1")
    {
        if((profile._ac.hg !== null ) && (profile._ac.hg !== undefined))
        {
          // hsc to cbse

            //alert("new board others. old board is hsc. so make hg as null");

            //vflag = vflag.concat(" hgc ", profile._ac.hg.toString());
  
            const lfields = {};
            lfields.fn = "_ac.hg";
            lfields.o = profile._ac.hg.toString(); 
            lfields.n = null;
            temp.push(lfields);

            profile._ac.hg = null;

            //tobesent.hg = profile._ac.hg;

            hgchange = true; oldhg = lfields.o;
        }
        else
        {
            //alert("new board others. old board is not hsc. as hg is null");

            //cbse to icse

            //alert("no change in hg");
        }
    }
    
    //alert("GC Check " + profile._ac.qe + " " + profile._ac.nbe);


    if(profile._ac.qe.toString() === "1" && profile._ac.nbe.toString() === "1") 
    {                     

       if( (hgchange === true)) {

          if(profile._ac.hg.toString() === "1")
          {
                //alert("hsc. group code academic " + gc);

                //vflag = vflag.concat(" gcc ", profile._ac.gc.toString());
  
                const lfields = {};
                lfields.fn = "_ac.gc";
                lfields.o = profile._ac.gc.toString();                 
                
                //alert("old gc " + profile._ac.gc + " ngc " + gc);                
                 
                lfields.n = HSC_ACADEMIC_GROUP_CODES.filter(e => e.label.toString() === gc.toString()).map(e => e.key.toString())[0];
                
                //alert(lfields.n);


                temp.push(lfields);
    
                profile._ac.gc = lfields.n;
    
                //tobesent.gc = profile._ac.gc;

                
    
          }
          else if(profile._ac.hg.toString() === "2")
          {
                //alert("hsc. group code vocational");

                //vflag = vflag.concat(" gcc ", profile._ac.gc.toString());
  
                const lfields = {};
                lfields.fn = "_ac.gc";
                lfields.o = profile._ac.gc.toString();                 
                
                //alert("old gc " + profile._ac.gc + " ngc " + gc);                
                 
                lfields.n = HSC_VOCATIONAL_GROUP_CODES.filter(e => e.label.toString() === gc.toString()).map(e => e.key.toString())[0];
                
                //alert(lfields.n);


                temp.push(lfields);
    
                profile._ac.gc = lfields.n;
    
                //tobesent.gc = profile._ac.gc;

          }
      }
      else 
      {
        //alert(profile._ac.gc + " ==== " + gc);
        if(profile._ac.hg.toString() === "1")
        {
            if(profile._ac.gc.toString() !== HSC_ACADEMIC_GROUP_CODES.filter(e => e.label.toString() === gc.toString()).map(e => e.key.toString())[0])
            {
                //alert("gc alone changed in academic");

                //vflag = vflag.concat(" gcc ", profile._ac.gc.toString());
  
                const lfields = {};
                lfields.fn = "_ac.gc";
                lfields.o = profile._ac.gc.toString();                 
                
                //alert("old gc " + profile._ac.gc + " ngc " + gc);                
                 
                lfields.n = HSC_ACADEMIC_GROUP_CODES.filter(e => e.label.toString() === gc.toString()).map(e => e.key.toString())[0];
                
                //alert(lfields.n);


                temp.push(lfields);
    
                profile._ac.gc = lfields.n;
    
                //tobesent.gc = profile._ac.gc;
            }            
        }
        else if(profile._ac.hg.toString() === "2")
        {
            if(profile._ac.gc.toString() !== HSC_VOCATIONAL_GROUP_CODES.filter(e => e.label.toString() === gc.toString()).map(e => e.key.toString())[0])
            {
                //alert("gc alone changed in vocational");

                //vflag = vflag.concat(" gcc ", profile._ac.gc.toString());
  
                const lfields = {};
                lfields.fn = "_ac.gc";
                lfields.o = profile._ac.gc.toString();                 
                
                //alert("old gc " + profile._ac.gc + " ngc " + gc);                
                 
                lfields.n = HSC_VOCATIONAL_GROUP_CODES.filter(e => e.label.toString() === gc.toString()).map(e => e.key.toString())[0];
                
                //alert(lfields.n);


                temp.push(lfields);
    
                profile._ac.gc = lfields.n;
    
                //tobesent.gc = profile._ac.gc;
            }            
        }
      }

    }
    else if(profile._ac.qe.toString() !== "1" && profile._ac.nbe.toString() !== "1")
    {
      //alert("QE " + qechange + " NBE " + nbechange + " HG " + hgchange);

      if( (hgchange === true)) {

          //alert("others. group code general");

          //vflag = vflag.concat(" gcc ", profile._ac.gc.toString());
  
          const lfields = {};
          lfields.fn = "_ac.gc";
          lfields.o = profile._ac.gc.toString();                 
          
          //alert("old gc " + profile._ac.gc + " ngc " + gc);                
           
          lfields.n = OTHER_GROUP_CODES.filter(e => e.label.toString() === gc.toString()).map(e => e.key.toString())[0];
          
          //alert(lfields.n);


          temp.push(lfields);

          profile._ac.gc = lfields.n;          

      }

    }    
    

  return temp;
}



function changemarks() {

var temp = [];


var nmmo = mmo , nmmt = mmt , npmo = pmo , npmt = pmt, ncmo = cmo , ncmt = cmt, nomo = omo , nomt = omt;

var nmpcmo = mpcmo , nmpcmt = mpcmt , np1mo = p1mo , np1mt = p1mt, np2mo = p2mo , np2mt = p2mt, ntmo = tmo , ntmt = tmt;


if((hgchange === true && oldhg === null && tobesenthg === "2") || (hgchange === true && oldhg === "1" && tobesenthg === "2"))
{
  //alert("Resetting marks. qe changed from cbse/hsc aca to hsc voca");
  nmmo = nmmt = npmo = npmt = ncmo = ncmt = nomo = nomt = null;

  bdchange = "1";

            // vflag = vflag.concat(" _ac.mmo ", profile._ac.mmo);
            const lfields = {};
            lfields.fn = "_ac.mmo";            
                lfields.o = profile._ac.mmo;                             
            lfields.n = null;
            temp.push(lfields);

            // vflag = vflag.concat(" _ac.mmt", profile._ac.mmt);
            const lfields2 = {};
            lfields2.fn = "_ac.mmt";            
                lfields2.o = profile._ac.mmt;                             
            lfields2.n = null;
            temp.push(lfields2);

            // vflag = vflag.concat(" _ac.pmo ", profile._ac.pmo);
            const lfields3 = {};
            lfields3.fn = "_ac.pmo";            
                lfields3.o = profile._ac.pmo;                             
            lfields3.n = null;
            temp.push(lfields3);

            // vflag = vflag.concat(" _ac.pmt", profile._ac.pmt);
            const lfields4 = {};
            lfields4.fn = "_ac.pmt";            
                lfields4.o = profile._ac.pmt;                             
            lfields4.n = null;
            temp.push(lfields4);

            // vflag = vflag.concat(" _ac.cmo ", profile._ac.cmo);
            const lfields5 = {};
            lfields5.fn = "_ac.cmo";            
                lfields5.o = profile._ac.cmo;                             
            lfields5.n = null;
            temp.push(lfields5);

            // vflag = vflag.concat(" _ac.cmt", profile._ac.cmt);
            const lfields6 = {};
            lfields6.fn = "_ac.cmt";            
                lfields6.o = profile._ac.cmt;                             
            lfields6.n = null;
            temp.push(lfields6);

            // vflag = vflag.concat(" _ac.omo ", profile._ac.omo);
            const lfields7 = {};
            lfields7.fn = "_ac.omo";            
                lfields7.o = profile._ac.omo;                             
            lfields7.n = null;
            temp.push(lfields7);

            // vflag = vflag.concat(" _ac.omt", profile._ac.omt);
            const lfields8 = {};
            lfields8.fn = "_ac.omt";            
                lfields8.o = profile._ac.omt;                             
            lfields8.n = null;
            temp.push(lfields8);
}

if((hgchange === true && oldhg === "2" && tobesenthg === "1") || (hgchange === true && oldhg === "2" && tobesenthg === null))
{
  //alert("Resetting marks. qe changed from hsc voca to hsc aca / cbse");

  bdchange = "2";

  nmpcmo = nmpcmt = np1mo = np1mt = np2mo = np2mt = ntmo = ntmt = null;

            // vflag = vflag.concat(" _ac.mpcmo ", profile._ac.mpcmo);
            const lfields = {};
            lfields.fn = "_ac.mpcmo";            
                lfields.o = profile._ac.mpcmo;                             
            lfields.n = null;
            temp.push(lfields);

            // vflag = vflag.concat(" _ac.mpcmt", profile._ac.mpcmt);
            const lfields2 = {};
            lfields2.fn = "_ac.mpcmt";            
                lfields2.o = profile._ac.mpcmt;                             
            lfields2.n = null;
            temp.push(lfields2);

            // vflag = vflag.concat(" _ac.p1mo ", profile._ac.p1mo);
            const lfields3 = {};
            lfields3.fn = "_ac.p1mo";            
                lfields3.o = profile._ac.p1mo;                             
            lfields3.n = null;
            temp.push(lfields3);

            // vflag = vflag.concat(" _ac.p1mt", profile._ac.p1mt);
            const lfields4 = {};
            lfields4.fn = "_ac.p1mt";            
                lfields4.o = profile._ac.p1mt;                             
            lfields4.n = null;
            temp.push(lfields4);

            // vflag = vflag.concat(" _ac.p2mo ", profile._ac.p2mo);
            const lfields5 = {};
            lfields5.fn = "_ac.p2mo";            
                lfields5.o = profile._ac.p2mo;                             
            lfields5.n = null;
            temp.push(lfields5);

            // vflag = vflag.concat(" _ac.p2mt", profile._ac.p2mt);
            const lfields6 = {};
            lfields6.fn = "_ac.p2mt";            
                lfields6.o = profile._ac.p2mt;                             
            lfields6.n = null;
            temp.push(lfields6);

            // vflag = vflag.concat(" _ac.tmo ", profile._ac.tmo);
            const lfields7 = {};
            lfields7.fn = "_ac.tmo";            
                lfields7.o = profile._ac.tmo;                             
            lfields7.n = null;
            temp.push(lfields7);

            // vflag = vflag.concat(" _ac.tmt", profile._ac.tmt);
            const lfields8 = {};
            lfields8.fn = "_ac.tmt";            
                lfields8.o = profile._ac.tmt;                             
            lfields8.n = null;
            temp.push(lfields8);

}

//alert(profile._ac.qe + " " + profile._ac.hg);


if((profile._ac.qe.toString() !== "1") || ((profile._ac.qe.toString() === "1") && (profile._ac.hg.toString() === "1")))
{

  nbdchange = "1";

  //alert("Marks changed");

          if(profile._ac.mmo !== mmo)
          {
            // vflag = vflag.concat(" _ac.mmo ", profile._ac.mmo);
            const lfields = {};
            lfields.fn = "_ac.mmo";
            if((profile._ac.mmo !== undefined) && (profile._ac.mmo !== null))
                lfields.o = profile._ac.mmo; 
            else
                lfields.o = null;
            lfields.n = mmo.toString();
            temp.push(lfields);
          }

          if(profile._ac.mmt !== mmt)
          {
            // vflag = vflag.concat(" _ac.mmt ", profile._ac.mmt);
            const lfields = {};
            lfields.fn = "_ac.mmt";
            if((profile._ac.mmt !== undefined) && (profile._ac.mmt !== null))
                lfields.o = profile._ac.mmt;
            else
                lfields.o = null;
            lfields.n = mmt.toString();
            temp.push(lfields);
          }

          if(profile._ac.pmo !== pmo)
          {
            // vflag = vflag.concat(" _ac.pmo ", profile._ac.pmo);
            const lfields = {};
            lfields.fn = "_ac.pmo";
            if((profile._ac.pmo !== undefined) && (profile._ac.pmo !== null))
                lfields.o = profile._ac.pmo;
            else
                lfields.o = null;
            lfields.n = pmo.toString();
            temp.push(lfields);
          }

          if(profile._ac.pmt !== pmt)
          {
            // vflag = vflag.concat(" _ac.pmt ", profile._ac.pmt);
            const lfields = {};
            lfields.fn = "_ac.pmt";
            if((profile._ac.pmt !== undefined) && (profile._ac.pmt !== null))
                lfields.o = profile._ac.pmt;
            else
                lfields.o = null; 
            lfields.n = pmt.toString();
            temp.push(lfields);
          }

          if(profile._ac.cmo !== cmo)
          {
            // vflag = vflag.concat(" _ac.cmo ", profile._ac.cmo);
            const lfields = {};
            lfields.fn = "_ac.cmo";
            if((profile._ac.cmo !== undefined) && (profile._ac.cmo !== null))
                  lfields.o = profile._ac.cmo; 
            else
                lfields.o = null;
            lfields.n = cmo.toString();
            temp.push(lfields);
          }

          if(profile._ac.cmt !== cmt)
          {
            // vflag = vflag.concat(" _ac.cmt ", profile._ac.cmt);
            const lfields = {};
            lfields.fn = "_ac.cmt";
            if((profile._ac.cmt !== undefined) && (profile._ac.cmt !== null))
                lfields.o = profile._ac.cmt; 
            else
                lfields.o = null;
            lfields.n = cmt.toString();
            temp.push(lfields);
          }

          if(profile._ac.omo !== omo)
          {
            // vflag = vflag.concat(" _ac.omo ", profile._ac.omo);
            const lfields = {};
            lfields.fn = "_ac.omo";
            if((profile._ac.omo !== undefined) && (profile._ac.omo !== null))
                lfields.o = profile._ac.omo; 
            else
                lfields.o = null;
            lfields.n = omo.toString();
            temp.push(lfields);
          }

          if(profile._ac.omt !== omt)
          {
            // vflag = vflag.concat(" _ac.omt ", profile._ac.omt);
            const lfields = {};
            lfields.fn = "_ac.omt";
            if((profile._ac.omt !== undefined) && (profile._ac.omt !== null))
                lfields.o = profile._ac.omt; 
            else
                lfields.o = null;
            lfields.n = omt.toString();
            temp.push(lfields);
          }

          profile._ac.mmo = mmo.toString();
          profile._ac.mmt = mmt.toString();
          profile._ac.pmo = pmo.toString();
          profile._ac.pmt = pmt.toString();
          profile._ac.cmo = cmo.toString();
          profile._ac.cmt = cmt.toString();
          profile._ac.omo = omo.toString();
          profile._ac.omt = omt.toString();

          profile._ac.mpcmo = profile._ac.mpcmt = profile._ac.p1mo = profile._ac.p1mt = profile._ac.p2mo = profile._ac.p2mt = profile._ac.tmo = profile._ac.tmt = null;
}

else if(profile._ac.hg.toString() === "2")
{
  nbdchange = "2";

  //alert("vocational marks changed");

          if(profile._ac.mpcmo !== mpcmo)
          {
            // vflag = vflag.concat(" _ac.mpcmo ", profile._ac.mpcmo);
            const lfields = {};
            lfields.fn = "_ac.mpcmo";
            if((profile._ac.mpcmo !== undefined) && (profile._ac.mpcmo !== null))
                lfields.o = profile._ac.mpcmo; 
            else
                lfields.o = null;
            lfields.n = mpcmo.toString();
            temp.push(lfields);
          }

          if(profile._ac.mpcmt !== mpcmt)
          {
            // vflag = vflag.concat(" _ac.mpcmt ", profile._ac.mpcmt);
            const lfields = {};
            lfields.fn = "_ac.mpcmt";
            if((profile._ac.mpcmt !== undefined) && (profile._ac.mpcmt !== null))
                lfields.o = profile._ac.mpcmt;
            else
                lfields.o = null;
            lfields.n = mpcmt.toString();
            temp.push(lfields);
          }

          if(profile._ac.p1mo !== p1mo)
          {
            // vflag = vflag.concat(" _ac.p1mo ", profile._ac.p1mo);
            const lfields = {};
            lfields.fn = "_ac.p1mo";
            if((profile._ac.p1mo !== undefined) && (profile._ac.p1mo !== null))
                lfields.o = profile._ac.p1mo;
            else
                lfields.o = null;
            lfields.n = p1mo.toString();
            temp.push(lfields);
          }

          if(profile._ac.p1mt !== p1mt)
          {
            // vflag = vflag.concat(" _ac.p1mt ", profile._ac.p1mt);
            const lfields = {};
            lfields.fn = "_ac.p1mt";
            if((profile._ac.p1mt !== undefined) && (profile._ac.p1mt !== null))
                lfields.o = profile._ac.p1mt;
            else
                lfields.o = null; 
            lfields.n = p1mt.toString();
            temp.push(lfields);
          }

          if(profile._ac.p2mo !== p2mo)
          {
            // vflag = vflag.concat(" _ac.p2mo ", profile._ac.p2mo);
            const lfields = {};
            lfields.fn = "_ac.p2mo";
            if((profile._ac.p2mo !== undefined) && (profile._ac.p2mo !== null))
                  lfields.o = profile._ac.p2mo; 
            else
                lfields.o = null;
            lfields.n = p2mo.toString();
            temp.push(lfields);
          }

          if(profile._ac.p2mt !== p2mt)
          {
            // vflag = vflag.concat(" _ac.p2mt ", profile._ac.p2mt);
            const lfields = {};
            lfields.fn = "_ac.p2mt";
            if((profile._ac.p2mt !== undefined) && (profile._ac.p2mt !== null))
                lfields.o = profile._ac.p2mt; 
            else
                lfields.o = null;
            lfields.n = p2mt.toString();
            temp.push(lfields);
          }

          if(profile._ac.tmo !== tmo)
          {
            // vflag = vflag.concat(" _ac.tmo ", profile._ac.tmo);
            const lfields = {};
            lfields.fn = "_ac.tmo";
            if((profile._ac.tmo !== undefined) && (profile._ac.tmo !== null))
                lfields.o = profile._ac.tmo; 
            else
                lfields.o = null;
            lfields.n = tmo.toString();
            temp.push(lfields);
          }

          if(profile._ac.tmt !== tmt)
          {
            // vflag = vflag.concat(" _ac.tmt ", profile._ac.tmt);
            const lfields = {};
            lfields.fn = "_ac.tmt";
            if((profile._ac.tmt !== undefined) && (profile._ac.tmt !== null))
                lfields.o = profile._ac.tmt; 
            else
                lfields.o = null;
            lfields.n = tmt.toString();
            temp.push(lfields);
          }

          profile._ac.mpcmo = mpcmo.toString();
          profile._ac.mpcmt = mpcmt.toString();
          profile._ac.p1mo = p1mo.toString();
          profile._ac.p1mt = p1mt.toString();
          profile._ac.p2mo = p2mo.toString();
          profile._ac.p2mt = p2mt.toString();
          profile._ac.tmo = tmo.toString();
          profile._ac.tmt = tmt.toString();

          profile._ac.mmo = profile._ac.mmt = profile._ac.pmo = profile._ac.pmt = profile._ac.cmo = profile._ac.cmt = profile._ac.omo = profile._ac.omt = null;

}



return temp;


}



function changehrnp() {
  var temp = [];

  if(((profile._ac.hrn === null) || (profile._ac.hrn === undefined)) && ((hrn) && (hrn.toString() !== '')))
  {
    //alert("Case hrn: 1: null to new value");
    // vflag = vflag.concat(" hrnc null");

    const lfields = {};
    lfields.fn = "_ac.hrn";
    lfields.o = null; lfields.n = hrn.toString();

    profile._ac.hrn = lfields.n;

    temp.push(lfields);
   
  }

  else if((profile._ac.hrn !== null) && (profile._ac.hrn !== undefined) && (hrn) && (hrn.toString() !== ''))
  {
     //alert(profile._ac.hrn + " " + hrn);
     if(profile._ac.hrn.toString() !== hrn.toString()) {

      //alert("Case hrn: 2: old to new value");

      // vflag = vflag.concat(" hrnc ", profile._ac.hrn.toString());    

  
      const lfields = {};
      lfields.fn = "_ac.hrn";
      lfields.o = profile._ac.hrn.toString(); lfields.n = hrn.toString();

      profile._ac.hrn = lfields.n;
      //alert("final val hrn " + profile._ac.hrn);
      temp.push(lfields);

    }
}


  return temp;
}

function changecommcnum() {
  var temp = [];

  if(profile._ac.commcnum === undefined || profile._ac.commcnum === null) profile._ac.commcnum = "";

  if(profile._ac.commcnum.toString() !== commcnum.toString()) {

    //vflag = vflag.concat(" xtmc ", profile._ac.xtm.toString());
    
    const lfields = {};
    lfields.fn = "_ac.commcnum";
    lfields.o = profile._ac.commcnum.toString(); lfields.n = commcnum.toString();
    temp.push(lfields);
    profile._ac.commcnum = commcnum;
  }


  return temp;
}

function changextotobt() {

  var temp = [];

  if(profile._ac.xtm === undefined || profile._ac.xobm === undefined || profile._ac.xobm === null || profile._ac.xtot === null) {
    profile._ac.xtm = "0";
    profile._ac.xobm = "0";
  }

      if(profile._ac.xtm.toString() !== xtot.toString()) {

        //vflag = vflag.concat(" xtmc ", profile._ac.xtm.toString());
        
        const lfields = {};
        lfields.fn = "_ac.xtm";
        lfields.o = profile._ac.xtm.toString(); lfields.n = xtot.toString();
        temp.push(lfields);
        profile._ac.xtm = xtot;
      }
      
      if(profile._ac.xobm.toString() !== xobt.toString()) {
      
        //vflag = vflag.concat(" xobmc ", profile._ac.xobm.toString());
        
        const lfields = {};
        lfields.fn = "_ac.xobm";
        lfields.o = profile._ac.xobm.toString(); lfields.n = xobt.toString();
        temp.push(lfields);
        profile._ac.xobm = xobt;
      }

  return temp;
}

function changehsctotobt() {

  var temp = [];

  if(profile._ac.hsctm === undefined || profile._ac.hsctm === null) profile._ac.hsctm = "0";
  if(profile._ac.hscobm === undefined || profile._ac.hscobm === null) profile._ac.hscobm = "0";
  
        if(profile._ac.hsctm.toString() !== hsctot.toString()) {

          //vflag = vflag.concat(" hsctmc ", profile._ac.hsctm.toString());
          
          const lfields = {};
          lfields.fn = "_ac.hsctm";
          lfields.o = profile._ac.hsctm.toString(); lfields.n = hsctot.toString();
          temp.push(lfields);
          profile._ac.hsctm = hsctot;
        }

        if(profile._ac.hscobm.toString() !== hscobt.toString()) {

          //vflag = vflag.concat(" hscobmc ", profile._ac.hscobm.toString());
          
          const lfields = {};
          lfields.fn = "_ac.hscobm";
          lfields.o = profile._ac.hscobm.toString(); lfields.n = hscobt.toString();
          temp.push(lfields);          
          profile._ac.hscobm = hscobt;
        }

        return temp;
}

function castecommchange() {

  var temp = [];  

    if(profile._p.co.toString() !== COMMUNITY.filter(e => e.label === co.toString()).map(e => e.key.toString())[0]) {

      //vflag = vflag.concat(" coc ", profile._p.co.toString());

      const lfields = {};
      lfields.fn = "_p.co";
      lfields.o = profile._p.co.toString(); 
      lfields.n = COMMUNITY.filter(e => e.label === co.toString()).map(e => e.key.toString())[0];
      temp.push(lfields);

      if((profile._p.co.toString() === "SC") || (profile._p.co.toString() === "ST") || (profile._p.co.toString() === "SCA"))
        {
          if((co.toString() === "BC") || (co.toString() === "MBC & DNC") || (co.toString() === "MBC") || (co.toString() === "MBCV") || (co.toString() === "OC") || (formData.co.toString() === "BCM")) {
            console.log("Community spl change");   
            splcomchange =  true;
          }
        }

    }

    const tempco = COMMUNITY.filter(en => en.label.toString() === co.toString()).map(en => en.key)[0]; 

    if(profile._p.cas === null) {

      //vflag = vflag.concat(" casc ", "null");

      if(cas.toString() === "")
      {
        //alert("caste case null");
      }

      const lfields = {};
      lfields.fn = "_p.cas";
      lfields.o = null; 
      lfields.n = CASTE[tempco.toString()].filter(e => e.label.toString() === cas.toString()).map(e => e.key.toString())[0];
            
      temp.push(lfields);

      if(cas.toString() !== null && cas.toString() !== "")
        profile._p.cas = CASTE[tempco.toString()].filter(e => e.label.toString() === cas.toString()).map(e => e.key.toString())[0];

      if(cas.toString() === "")
      {
        //alert("first value case");
          if(profile._p.co.toString() === "SC")
            profile._p.cas = "37";
          if(profile._p.co.toString() === "ST")
            profile._p.cas = "1";
          if(profile._p.co.toString() === "SCA")
            profile._p.cas = "101";
          if(profile._p.co.toString() === "BC")
            profile._p.cas = "224";
          if(profile._p.co.toString() === "BCM")
            profile._p.cas = "363";
          if(profile._p.co.toString() === "MBC")
            profile._p.cas = "111";
          if(profile._p.co.toString() === "MBCDNC")
            profile._p.cas = "108";
          if(profile._p.co.toString() === "MBCV")
            profile._p.cas = "215";
          if(profile._p.co.toString() === "OC")
            profile._p.cas = "500";
      }
    }
    else if(profile._p.cas && profile._p.cas.toString() !== CASTE[tempco.toString()].filter(e => e.label.toString() === cas.toString()).map(e => e.key.toString())[0]) {

      //vflag = vflag.concat(" casc ", profile._p.cas.toString());

      const lfields = {};
      lfields.fn = "_p.cas";
      lfields.o = profile._p.cas.toString(); 
      lfields.n = CASTE[tempco.toString()].filter(e => e.label.toString() === cas.toString()).map(e => e.key.toString())[0];
      temp.push(lfields);
      profile._p.cas = CASTE[tempco.toString()].filter(e => e.label.toString() === cas.toString()).map(e => e.key.toString())[0];
    }

    profile._p.co = COMMUNITY.filter(e => e.label === co.toString()).map(e => e.key.toString())[0];
    
    return temp;
}

function changeschoolfields12 ()
{
      var temp = [];

      if(profile._sc.cXIIy !== sy12.toString())
      {
        //vflag = vflag.concat(" cXIIy ", profile._sc.cXIIy);
        const lfields = {};
        lfields.fn = "_sc.cXIIy";
        lfields.o = profile._sc.cXIIy.toString(); 
        lfields.n = sy12.toString();
        temp.push(lfields);
      }


      if(SCHOOL_BY_BLOCK[BLOCKS.filter(op => op.label.toString() === sb12.toString()).map(er => er.key)[0]] !== undefined) {

          if(profile._sc.cXIIsn !== SCHOOL_BY_BLOCK[BLOCKS.filter(op => op.label.toString() === sb12.toString()).map(er => er.key)[0]].filter(ep => ep.label.toString() === ssn12.toString()).map(tt => tt.key)[0].toString())
          {
            //vflag = vflag.concat(" cXIIsn ", profile._sc.cXIIsn);
            console.log("in school name change");
            console.log(sb12);
            const lfields = {};
            lfields.fn = "_sc.cXIIsn";
            lfields.o = profile._sc.cXIIsn.toString(); 
            //SCHOOL_BY_BLOCK[BLOCKS.filter(op => op.label.toString() === sb12.toString()).map(tt => tt.key)[0]].map(e => <option key={index}> {e.label} </option>)
            lfields.n = SCHOOL_BY_BLOCK[BLOCKS.filter(op => op.label.toString() === sb12.toString()).map(er => er.key)[0]].filter(ep => ep.label.toString() === ssn12.toString()).map(tt => tt.key)[0].toString();
            console.log(lfields);
            temp.push(lfields);
            profile._sc.cXIIsn = SCHOOL_BY_BLOCK[BLOCKS.filter(op => op.label.toString() === sb12.toString()).map(er => er.key)[0]].filter(ep => ep.label.toString() === ssn12.toString()).map(tt => tt.key)[0].toString();
          }
    }

    else {

      if(profile._sc.cXIIsn !== ssn12.toString())
      {
        //vflag = vflag.concat(" cXIIsn ", profile._sc.cXIIsn);
        console.log("in school name change");
        console.log(sb12);
        const lfields = {};
        lfields.fn = "_sc.cXIIsn";
        lfields.o = profile._sc.cXIIsn.toString(); 
        //SCHOOL_BY_BLOCK[BLOCKS.filter(op => op.label.toString() === sb12.toString()).map(tt => tt.key)[0]].map(e => <option key={index}> {e.label} </option>)
        lfields.n = ssn12.toString();
        console.log(lfields);
        temp.push(lfields);
        profile._sc.cXIIsn = ssn12.toString();
      }
    }
      
      
      if(profile._sc.cXIId.toString() !== DIST_LIST.filter(e => e.label.toString() === sd12.toString()).map(e => e.key)[0].toString())
      {
        //vflag = vflag.concat(" cXIId ", profile._sc.cXIId);
        const lfields = {};
        lfields.fn = "_sc.cXIId";
        lfields.o = profile._sc.cXIId.toString(); 
        lfields.n = DIST_LIST.filter(e => e.label.toString() === sd12.toString()).map(e => e.key)[0].toString();
        temp.push(lfields);
        profile._sc.cXIId = DIST_LIST.filter(e => e.label.toString() === sd12.toString()).map(e => e.key)[0].toString();      
        
      }

      console.log("printing state",profile._sc.cXIIs,STATES.filter(e => e.label.toString() === sst12.toString()).map(e => e.key)[0]);
      
      if(profile._sc.cXIIs.toString() !== STATES.filter(e => e.label.toString() === sst12.toString()).map(e => e.key)[0].toString())
      {
        //vflag = vflag.concat(" cXIIs ", profile._sc.cXIIs);
        const lfields = {};
        lfields.fn = "_sc.cXIIs";
        lfields.o = profile._sc.cXIIs.toString(); 
        lfields.n = STATES.filter(e => e.label.toString() === sst12.toString()).map(e => e.key)[0].toString();
        temp.push(lfields);
        profile._sc.cXIIs = STATES.filter(e => e.label.toString() === sst12.toString()).map(e => e.key)[0].toString();
      }
      
      if(sst12st === null) {
        //vflag = vflag.concat(" cXIIst ", profile._sc.cXIIst);
        const lfields = {};
        lfields.fn = "_sc.cXIIst";
        if((profile._sc.cXIIst !== null) && (profile._sc.cXIIst !== undefined)) {
          lfields.o = profile._sc.cXIIst.toString(); 
        }
        else  {
          lfields.o = null;
        }
        lfields.n = null;
        
        if(lfields.o === null && lfields.n === null)
        {
      
        }
        else
          temp.push(lfields);
      }
      
            
      //alert("sigsc " + sigsc + " caofsc " + catsc + " sst12st " + sst12st);
      
      if((sst12st !== '') && (sst12st !== null) && (sst12st !== undefined) && (profile._sc.cXIIst !== GOVT_SCHOOL_TYPE.filter(e => e.label.toString() === sst12st.toString()).map(e => e.key)[0].toString()))
      {
        //vflag = vflag.concat(" cXIIst ", profile._sc.cXIIst);
        const lfields = {};
        lfields.fn = "_sc.cXIIst";
        if((profile._sc.cXIIst !== null) && (profile._sc.cXIIst !== undefined))
          lfields.o = profile._sc.cXIIst.toString(); 
        else
          lfields.o = null;
        lfields.n = GOVT_SCHOOL_TYPE.filter(e => e.label.toString() === sst12st.toString()).map(e => e.key)[0].toString();
        temp.push(lfields);
      }

      //sb12: !profile._sc.cXIIIb ? null : BLOCKS_BY_DISTRICT[profile._sc.cXIIId].filter(e => e.key === profile._sc.cXIIIb).map(e => e.label)[0]

      if((sb12.toString() !== undefined) && BLOCKS_BY_DISTRICT[DIST_LIST.filter(e => e.label.toString() === sd12.toString()).map(e => e.key)[0].toString()] !== undefined) {
          if(profile._sc.cXIIb !== BLOCKS_BY_DISTRICT[DIST_LIST.filter(e => e.label.toString() === sd12.toString()).map(e => e.key)[0].toString()].filter(e => e.label.toString() === sb12.toString()).map(e => e.key)[0].toString())
          {
            //vflag = vflag.concat(" cXIIs ", profile._sc.cXIIs);
            const lfields = {};
            lfields.fn = "_sc.cXIIb";
            lfields.o = profile._sc.cXIIb.toString(); 
            lfields.n = BLOCKS_BY_DISTRICT[DIST_LIST.filter(e => e.label.toString() === sd12.toString()).map(e => e.key)[0].toString()].filter(e => e.label.toString() === sb12.toString()).map(e => e.key)[0].toString();
            temp.push(lfields);
            profile._sc.cXIIb = BLOCKS_BY_DISTRICT[DIST_LIST.filter(e => e.label.toString() === sd12.toString()).map(e => e.key)[0].toString()].filter(e => e.label.toString() === sb12.toString()).map(e => e.key)[0].toString();
          }
      }
      else {

        if(profile._sc.cXIIb.toString() !== sb12.toString())
        {
          //vflag = vflag.concat(" cXIIs ", profile._sc.cXIIs);
          const lfields = {};
          lfields.fn = "_sc.cXIIb";
          lfields.o = profile._sc.cXIIb.toString(); 
          lfields.n = sb12.toString();
          temp.push(lfields);
          profile._sc.cXIIb = sb12.toString();
        }

      }
      
      profile._sc.cXIIy = sy12.toString();
     
      if((sst12st !== null) && (sst12st !== '')&& (sst12st !== undefined)) {
        profile._sc.cXIIst = GOVT_SCHOOL_TYPE.filter(e => e.label.toString() === sst12st.toString()).map(e => e.key)[0].toString();
      }
      else {
        profile._sc.cXIIst = null;
      }
      
            
      
      return temp;

}


function changeschoolfields11 ()
{
      var temp = [];

      if(profile._sc.cXIy !== sy11.toString())
      {
        //vflag = vflag.concat(" cXIy ", profile._sc.cXIy);
        const lfields = {};
        lfields.fn = "_sc.cXIy";
        lfields.o = profile._sc.cXIy.toString(); 
        lfields.n = sy11.toString();
        temp.push(lfields);
      }     

      if(SCHOOL_BY_BLOCK[BLOCKS.filter(op => op.label.toString() === sb11.toString()).map(er => er.key)[0]] !== undefined) {

          if(profile._sc.cXIsn !== SCHOOL_BY_BLOCK[BLOCKS.filter(op => op.label.toString() === sb11.toString()).map(er => er.key)[0]].filter(ep => ep.label.toString() === ssn11.toString()).map(tt => tt.key)[0].toString())
          {
            //vflag = vflag.concat(" cXIsn ", profile._sc.cXIsn);
            console.log("in school name change");
            console.log(sb11);
            const lfields = {};
            lfields.fn = "_sc.cXIsn";
            lfields.o = profile._sc.cXIsn.toString(); 
            //SCHOOL_BY_BLOCK[BLOCKS.filter(op => op.label.toString() === sb11.toString()).map(tt => tt.key)[0]].map(e => <option key={index}> {e.label} </option>)
            lfields.n = SCHOOL_BY_BLOCK[BLOCKS.filter(op => op.label.toString() === sb11.toString()).map(er => er.key)[0]].filter(ep => ep.label.toString() === ssn11.toString()).map(tt => tt.key)[0].toString();
            console.log(lfields);
            temp.push(lfields);
            profile._sc.cXIsn = SCHOOL_BY_BLOCK[BLOCKS.filter(op => op.label.toString() === sb11.toString()).map(er => er.key)[0]].filter(ep => ep.label.toString() === ssn11.toString()).map(tt => tt.key)[0].toString();
          }
    }

    else {

      if(profile._sc.cXIsn !== ssn11.toString())
      {
        //vflag = vflag.concat(" cXIsn ", profile._sc.cXIsn);
        console.log("in school name change");
        console.log(sb11);
        const lfields = {};
        lfields.fn = "_sc.cXIsn";
        lfields.o = profile._sc.cXIsn.toString(); 
        //SCHOOL_BY_BLOCK[BLOCKS.filter(op => op.label.toString() === sb11.toString()).map(tt => tt.key)[0]].map(e => <option key={index}> {e.label} </option>)
        lfields.n = ssn11.toString();
        console.log(lfields);
        temp.push(lfields);
        profile._sc.cXIsn = ssn11.toString();
      }
    }
      
      
      if(profile._sc.cXId !== DIST_LIST.filter(e => e.label.toString() === sd11.toString()).map(e => e.key)[0].toString())
      {
        //vflag = vflag.concat(" cXId ", profile._sc.cXId);
        const lfields = {};
        lfields.fn = "_sc.cXId";
        lfields.o = profile._sc.cXId.toString(); 
        lfields.n = DIST_LIST.filter(e => e.label.toString() === sd11.toString()).map(e => e.key)[0].toString();
        temp.push(lfields);
      }
      
      if(profile._sc.cXIs !== STATES.filter(e => e.label.toString() === sst11.toString()).map(e => e.key)[0].toString())
      {
        //vflag = vflag.concat(" cXIs ", profile._sc.cXIs);
        const lfields = {};
        lfields.fn = "_sc.cXIs";
        lfields.o = profile._sc.cXIs.toString(); 
        lfields.n = STATES.filter(e => e.label.toString() === sst11.toString()).map(e => e.key)[0].toString();
        temp.push(lfields);
      }
      
      if(sst11st === null) {
        //vflag = vflag.concat(" cXIst ", profile._sc.cXIst);
        const lfields = {};
        lfields.fn = "_sc.cXIst";
        if((profile._sc.cXIst !== null) && (profile._sc.cXIst !== undefined)) {
          lfields.o = profile._sc.cXIst.toString(); 
        }
        else  {
          lfields.o = null;
        }
        lfields.n = null;
        
        if(lfields.o === null && lfields.n === null)
        {
      
        }
        else
          temp.push(lfields);
      }
      
            
      //alert("sigsc " + sigsc + " caofsc " + catsc + " sst11st " + sst11st);
      
      if((sst11st !== '') && (sst11st !== null) && (sst11st !== undefined) && (profile._sc.cXIst !== GOVT_SCHOOL_TYPE.filter(e => e.label.toString() === sst11st.toString()).map(e => e.key)[0].toString()))
      {
        //vflag = vflag.concat(" cXIst ", profile._sc.cXIst);
        const lfields = {};
        lfields.fn = "_sc.cXIst";
        if((profile._sc.cXIst !== null) && (profile._sc.cXIst !== undefined))
          lfields.o = profile._sc.cXIst.toString(); 
        else
          lfields.o = null;
        lfields.n = GOVT_SCHOOL_TYPE.filter(e => e.label.toString() === sst11st.toString()).map(e => e.key)[0].toString();
        temp.push(lfields);
      }

      //sb11: !profile._sc.cXIIb ? null : BLOCKS_BY_DISTRICT[profile._sc.cXIId].filter(e => e.key === profile._sc.cXIIb).map(e => e.label)[0]

      if((sb11.toString() !== undefined) && BLOCKS_BY_DISTRICT[DIST_LIST.filter(e => e.label.toString() === sd11.toString()).map(e => e.key)[0].toString()] !== undefined) {
          if(profile._sc.cXIb !== BLOCKS_BY_DISTRICT[DIST_LIST.filter(e => e.label.toString() === sd11.toString()).map(e => e.key)[0].toString()].filter(e => e.label.toString() === sb11.toString()).map(e => e.key)[0].toString())
          {
            //vflag = vflag.concat(" cXIs ", profile._sc.cXIs);
            const lfields = {};
            lfields.fn = "_sc.cXIb";
            lfields.o = profile._sc.cXIb.toString(); 
            lfields.n = BLOCKS_BY_DISTRICT[DIST_LIST.filter(e => e.label.toString() === sd11.toString()).map(e => e.key)[0].toString()].filter(e => e.label.toString() === sb11.toString()).map(e => e.key)[0].toString();
            temp.push(lfields);
            profile._sc.cXIb = BLOCKS_BY_DISTRICT[DIST_LIST.filter(e => e.label.toString() === sd11.toString()).map(e => e.key)[0].toString()].filter(e => e.label.toString() === sb11.toString()).map(e => e.key)[0].toString();
          }
      }
      else {

        if(profile._sc.cXIb.toString() !== sb11.toString())
        {
          //vflag = vflag.concat(" cXIs ", profile._sc.cXIs);
          const lfields = {};
          lfields.fn = "_sc.cXIb";
          lfields.o = profile._sc.cXIb.toString(); 
          lfields.n = sb11.toString();
          temp.push(lfields);
          profile._sc.cXIb = sb11.toString();
        }

      }
      
      profile._sc.cXIy = sy11.toString();
      profile._sc.cXId = DIST_LIST.filter(e => e.label.toString() === sd11.toString()).map(e => e.key)[0].toString();      
      profile._sc.cXIs = STATES.filter(e => e.label.toString() === sst11.toString()).map(e => e.key)[0].toString();
      if((sst11st !== null) && (sst11st !== '')&& (sst11st !== undefined)) {
        profile._sc.cXIst = GOVT_SCHOOL_TYPE.filter(e => e.label.toString() === sst11st.toString()).map(e => e.key)[0].toString();
        console.log(profile._sc.cXIst);
      }
      else {
        profile._sc.cXIst = null;
      }
      
            
      
      return temp;

}


function changeschoolfields10 ()
{
      var temp = [];

      if(profile._sc.cXy !== sy10.toString())
      {
        //vflag = vflag.concat(" cXy ", profile._sc.cXy);
        const lfields = {};
        lfields.fn = "_sc.cXy";
        lfields.o = profile._sc.cXy.toString(); 
        lfields.n = sy10.toString();
        temp.push(lfields);
      }


      if(SCHOOL_BY_BLOCK[BLOCKS.filter(op => op.label.toString() === sb10.toString()).map(er => er.key)[0]] !== undefined) {

          if(profile._sc.cXsn !== SCHOOL_BY_BLOCK[BLOCKS.filter(op => op.label.toString() === sb10.toString()).map(er => er.key)[0]].filter(ep => ep.label.toString() === ssn10.toString()).map(tt => tt.key)[0].toString())
          {
            //vflag = vflag.concat(" cXsn ", profile._sc.cXsn);
            console.log("in school name change");
            console.log(sb10);
            const lfields = {};
            lfields.fn = "_sc.cXsn";
            lfields.o = profile._sc.cXsn.toString(); 
            //SCHOOL_BY_BLOCK[BLOCKS.filter(op => op.label.toString() === sb10.toString()).map(tt => tt.key)[0]].map(e => <option key={index}> {e.label} </option>)
            lfields.n = SCHOOL_BY_BLOCK[BLOCKS.filter(op => op.label.toString() === sb10.toString()).map(er => er.key)[0]].filter(ep => ep.label.toString() === ssn10.toString()).map(tt => tt.key)[0].toString();
            console.log(lfields);
            temp.push(lfields);
            profile._sc.cXsn = SCHOOL_BY_BLOCK[BLOCKS.filter(op => op.label.toString() === sb10.toString()).map(er => er.key)[0]].filter(ep => ep.label.toString() === ssn10.toString()).map(tt => tt.key)[0].toString();
          }
    }

    else {

      if(profile._sc.cXsn !== ssn10.toString())
      {
        //vflag = vflag.concat(" cXsn ", profile._sc.cXsn);
        console.log("in school name change");
        console.log(sb10);
        const lfields = {};
        lfields.fn = "_sc.cXsn";
        lfields.o = profile._sc.cXsn.toString(); 
        //SCHOOL_BY_BLOCK[BLOCKS.filter(op => op.label.toString() === sb10.toString()).map(tt => tt.key)[0]].map(e => <option key={index}> {e.label} </option>)
        lfields.n = ssn10.toString();
        console.log(lfields);
        temp.push(lfields);
        profile._sc.cXsn = ssn10.toString();
      }
    }
      
      
      if(profile._sc.cXd !== DIST_LIST.filter(e => e.label.toString() === sd10.toString()).map(e => e.key)[0].toString())
      {
        //vflag = vflag.concat(" cXd ", profile._sc.cXd);
        const lfields = {};
        lfields.fn = "_sc.cXd";
        lfields.o = profile._sc.cXd.toString(); 
        lfields.n = DIST_LIST.filter(e => e.label.toString() === sd10.toString()).map(e => e.key)[0].toString();
        temp.push(lfields);
      }
      
      if(profile._sc.cXs !== STATES.filter(e => e.label.toString() === sst10.toString()).map(e => e.key)[0].toString())
      {
        //vflag = vflag.concat(" cXs ", profile._sc.cXs);
        const lfields = {};
        lfields.fn = "_sc.cXs";
        lfields.o = profile._sc.cXs.toString(); 
        lfields.n = STATES.filter(e => e.label.toString() === sst10.toString()).map(e => e.key)[0].toString();
        temp.push(lfields);
      }
      
      if(sst10st === null) {
        //vflag = vflag.concat(" cXst ", profile._sc.cXst);
        const lfields = {};
        lfields.fn = "_sc.cXst";
        if((profile._sc.cXst !== null) && (profile._sc.cXst !== undefined)) {
          lfields.o = profile._sc.cXst.toString(); 
        }
        else  {
          lfields.o = null;
        }
        lfields.n = null;
        
        if(lfields.o === null && lfields.n === null)
        {
      
        }
        else
          temp.push(lfields);
      }
      
            
      //alert("sigsc " + sigsc + " caofsc " + catsc + " sst10st " + sst10st);
      
      if((sst10st !== '') && (sst10st !== null) && (sst10st !== undefined) && (profile._sc.cXst !== GOVT_SCHOOL_TYPE.filter(e => e.label.toString() === sst10st.toString()).map(e => e.key)[0].toString()))
      {
        //vflag = vflag.concat(" cXst ", profile._sc.cXst);
        const lfields = {};
        lfields.fn = "_sc.cXst";
        if((profile._sc.cXst !== null) && (profile._sc.cXst !== undefined))
          lfields.o = profile._sc.cXst.toString(); 
        else
          lfields.o = null;
        lfields.n = GOVT_SCHOOL_TYPE.filter(e => e.label.toString() === sst10st.toString()).map(e => e.key)[0].toString();
        temp.push(lfields);
      }

      //sb10: !profile._sc.cXIb ? null : BLOCKS_BY_DISTRICT[profile._sc.cXId].filter(e => e.key === profile._sc.cXIb).map(e => e.label)[0]

      if((sb10.toString() !== undefined) && BLOCKS_BY_DISTRICT[DIST_LIST.filter(e => e.label.toString() === sd10.toString()).map(e => e.key)[0].toString()] !== undefined) {
          if(profile._sc.cXb !== BLOCKS_BY_DISTRICT[DIST_LIST.filter(e => e.label.toString() === sd10.toString()).map(e => e.key)[0].toString()].filter(e => e.label.toString() === sb10.toString()).map(e => e.key)[0].toString())
          {
            //vflag = vflag.concat(" cXs ", profile._sc.cXs);
            const lfields = {};
            lfields.fn = "_sc.cXb";
            lfields.o = profile._sc.cXb.toString(); 
            lfields.n = BLOCKS_BY_DISTRICT[DIST_LIST.filter(e => e.label.toString() === sd10.toString()).map(e => e.key)[0].toString()].filter(e => e.label.toString() === sb10.toString()).map(e => e.key)[0].toString();
            temp.push(lfields);
            profile._sc.cXb = BLOCKS_BY_DISTRICT[DIST_LIST.filter(e => e.label.toString() === sd10.toString()).map(e => e.key)[0].toString()].filter(e => e.label.toString() === sb10.toString()).map(e => e.key)[0].toString();
          }
      }
      else {

        if(profile._sc.cXb.toString() !== sb10.toString())
        {
          //vflag = vflag.concat(" cXs ", profile._sc.cXs);
          const lfields = {};
          lfields.fn = "_sc.cXb";
          lfields.o = profile._sc.cXb.toString(); 
          lfields.n = sb10.toString();
          temp.push(lfields);
          profile._sc.cXb = sb10.toString();
        }

      }
      
      profile._sc.cXy = sy10.toString();
      profile._sc.cXd = DIST_LIST.filter(e => e.label.toString() === sd10.toString()).map(e => e.key)[0].toString();      
      profile._sc.cXs = STATES.filter(e => e.label.toString() === sst10.toString()).map(e => e.key)[0].toString();
      if((sst10st !== null) && (sst10st !== '')&& (sst10st !== undefined)) {
        profile._sc.cXst = GOVT_SCHOOL_TYPE.filter(e => e.label.toString() === sst10st.toString()).map(e => e.key)[0].toString();
      }
      else {
        profile._sc.cXst = null;
      }
      
            
      
      return temp;

}


function changeschoolfields9 ()
{
      var temp = [];

      if(profile._sc.cIXy !== sy9.toString())
      {
        //vflag = vflag.concat(" cIXy ", profile._sc.cIXy);
        const lfields = {};
        lfields.fn = "_sc.cIXy";
        lfields.o = profile._sc.cIXy.toString(); 
        lfields.n = sy9.toString();
        temp.push(lfields);
      }


      if(SCHOOL_BY_BLOCK[BLOCKS.filter(op => op.label.toString() === sb9.toString()).map(er => er.key)[0]] !== undefined) {

          if(profile._sc.cIXsn !== SCHOOL_BY_BLOCK[BLOCKS.filter(op => op.label.toString() === sb9.toString()).map(er => er.key)[0]].filter(ep => ep.label.toString() === ssn9.toString()).map(tt => tt.key)[0].toString())
          {
            //vflag = vflag.concat(" cIXsn ", profile._sc.cIXsn);
            console.log("in school name change");
            console.log(sb9);
            const lfields = {};
            lfields.fn = "_sc.cIXsn";
            lfields.o = profile._sc.cIXsn.toString(); 
            //SCHOOL_BY_BLOCK[BLOCKS.filter(op => op.label.toString() === sb9.toString()).map(tt => tt.key)[0]].map(e => <option key={index}> {e.label} </option>)
            lfields.n = SCHOOL_BY_BLOCK[BLOCKS.filter(op => op.label.toString() === sb9.toString()).map(er => er.key)[0]].filter(ep => ep.label.toString() === ssn9.toString()).map(tt => tt.key)[0].toString();
            console.log(lfields);
            temp.push(lfields);
            profile._sc.cIXsn = SCHOOL_BY_BLOCK[BLOCKS.filter(op => op.label.toString() === sb9.toString()).map(er => er.key)[0]].filter(ep => ep.label.toString() === ssn9.toString()).map(tt => tt.key)[0].toString();
          }
    }

    else {

      if(profile._sc.cIXsn !== ssn9.toString())
      {
        //vflag = vflag.concat(" cIXsn ", profile._sc.cIXsn);
        console.log("in school name change");
        console.log(sb9);
        const lfields = {};
        lfields.fn = "_sc.cIXsn";
        lfields.o = profile._sc.cIXsn.toString(); 
        //SCHOOL_BY_BLOCK[BLOCKS.filter(op => op.label.toString() === sb9.toString()).map(tt => tt.key)[0]].map(e => <option key={index}> {e.label} </option>)
        lfields.n = ssn9.toString();
        console.log(lfields);
        temp.push(lfields);
        profile._sc.cIXsn = ssn9.toString();
      }
    }
      
      
      if(profile._sc.cIXd !== DIST_LIST.filter(e => e.label.toString() === sd9.toString()).map(e => e.key)[0].toString())
      {
        //vflag = vflag.concat(" cIXd ", profile._sc.cIXd);
        const lfields = {};
        lfields.fn = "_sc.cIXd";
        lfields.o = profile._sc.cIXd.toString(); 
        lfields.n = DIST_LIST.filter(e => e.label.toString() === sd9.toString()).map(e => e.key)[0].toString();
        temp.push(lfields);
      }
      
      if(profile._sc.cIXs !== STATES.filter(e => e.label.toString() === sst9.toString()).map(e => e.key)[0].toString())
      {
        //vflag = vflag.concat(" cIXs ", profile._sc.cIXs);
        const lfields = {};
        lfields.fn = "_sc.cIXs";
        lfields.o = profile._sc.cIXs.toString(); 
        lfields.n = STATES.filter(e => e.label.toString() === sst9.toString()).map(e => e.key)[0].toString();
        temp.push(lfields);
      }
      
      if(sst9st === null) {
        //vflag = vflag.concat(" cIXst ", profile._sc.cIXst);
        const lfields = {};
        lfields.fn = "_sc.cIXst";
        if((profile._sc.cIXst !== null) && (profile._sc.cIXst !== undefined)) {
          lfields.o = profile._sc.cIXst.toString(); 
        }
        else  {
          lfields.o = null;
        }
        lfields.n = null;
        
        if(lfields.o === null && lfields.n === null)
        {
      
        }
        else
          temp.push(lfields);
      }
      
            
      //alert("sigsc " + sigsc + " caofsc " + catsc + " sst9st " + sst9st);
      
      if((sst9st !== '') && (sst9st !== null) && (sst9st !== undefined) && (profile._sc.cIXst !== GOVT_SCHOOL_TYPE.filter(e => e.label.toString() === sst9st.toString()).map(e => e.key)[0].toString()))
      {
        //vflag = vflag.concat(" cIXst ", profile._sc.cIXst);
        const lfields = {};
        lfields.fn = "_sc.cIXst";
        if((profile._sc.cIXst !== null) && (profile._sc.cIXst !== undefined))
          lfields.o = profile._sc.cIXst.toString(); 
        else
          lfields.o = null;
        lfields.n = GOVT_SCHOOL_TYPE.filter(e => e.label.toString() === sst9st.toString()).map(e => e.key)[0].toString();
        temp.push(lfields);
      }

      //sb9: !profile._sc.cIXIb ? null : BLOCKS_BY_DISTRICT[profile._sc.cIXId].filter(e => e.key === profile._sc.cIXIb).map(e => e.label)[0]

      console.log(sb9, sd9);

      if((sb9.toString() !== undefined) && BLOCKS_BY_DISTRICT[DIST_LIST.filter(e => e.label.toString() === sd9.toString()).map(e => e.key)[0].toString()] !== undefined) {
          if(profile._sc.cIXb !== BLOCKS_BY_DISTRICT[DIST_LIST.filter(e => e.label.toString() === sd9.toString()).map(e => e.key)[0].toString()].filter(e => e.label.toString() === sb9.toString()).map(e => e.key)[0].toString())
          {
            //vflag = vflag.concat(" cIXs ", profile._sc.cIXs);
            const lfields = {};
            lfields.fn = "_sc.cIXb";
            lfields.o = profile._sc.cIXb.toString(); 
            lfields.n = BLOCKS_BY_DISTRICT[DIST_LIST.filter(e => e.label.toString() === sd9.toString()).map(e => e.key)[0].toString()].filter(e => e.label.toString() === sb9.toString()).map(e => e.key)[0].toString();
            temp.push(lfields);
            profile._sc.cIXb = BLOCKS_BY_DISTRICT[DIST_LIST.filter(e => e.label.toString() === sd9.toString()).map(e => e.key)[0].toString()].filter(e => e.label.toString() === sb9.toString()).map(e => e.key)[0].toString();
          }
      }
      else {

        if(profile._sc.cIXb.toString() !== sb9.toString())
        {
          //vflag = vflag.concat(" cIXs ", profile._sc.cIXs);
          const lfields = {};
          lfields.fn = "_sc.cIXb";
          lfields.o = profile._sc.cIXb.toString(); 
          lfields.n = sb9.toString();
          temp.push(lfields);
          profile._sc.cIXb = sb9.toString();
        }

      }
      
      profile._sc.cIXy = sy9.toString();
      profile._sc.cIXd = DIST_LIST.filter(e => e.label.toString() === sd9.toString()).map(e => e.key)[0].toString();      
      profile._sc.cIXs = STATES.filter(e => e.label.toString() === sst9.toString()).map(e => e.key)[0].toString();
      if((sst9st !== null) && (sst9st !== '')&& (sst9st !== undefined)) {
        profile._sc.cIXst = GOVT_SCHOOL_TYPE.filter(e => e.label.toString() === sst9st.toString()).map(e => e.key)[0].toString();
      }
      else {
        profile._sc.cIXst = null;
      }
      
            
      
      return temp;

}


function changeschoolfields8 ()
{
      var temp = [];

      if(profile._sc.cVIIIy !== sy8.toString())
      {
        //vflag = vflag.concat(" cVIIIy ", profile._sc.cVIIIy);
        const lfields = {};
        lfields.fn = "_sc.cVIIIy";
        lfields.o = profile._sc.cVIIIy.toString(); 
        lfields.n = sy8.toString();
        temp.push(lfields);
      }


      if(SCHOOL_BY_BLOCK[BLOCKS.filter(op => op.label.toString() === sb8.toString()).map(er => er.key)[0]] !== undefined) {

          if(profile._sc.cVIIIsn !== SCHOOL_BY_BLOCK[BLOCKS.filter(op => op.label.toString() === sb8.toString()).map(er => er.key)[0]].filter(ep => ep.label.toString() === ssn8.toString()).map(tt => tt.key)[0].toString())
          {
            //vflag = vflag.concat(" cVIIIsn ", profile._sc.cVIIIsn);
            console.log("in school name change");
            console.log(sb8);
            const lfields = {};
            lfields.fn = "_sc.cVIIIsn";
            lfields.o = profile._sc.cVIIIsn.toString(); 
            //SCHOOL_BY_BLOCK[BLOCKS.filter(op => op.label.toString() === sb8.toString()).map(tt => tt.key)[0]].map(e => <option key={index}> {e.label} </option>)
            lfields.n = SCHOOL_BY_BLOCK[BLOCKS.filter(op => op.label.toString() === sb8.toString()).map(er => er.key)[0]].filter(ep => ep.label.toString() === ssn8.toString()).map(tt => tt.key)[0].toString();
            console.log(lfields);
            temp.push(lfields);
            profile._sc.cVIIIsn = SCHOOL_BY_BLOCK[BLOCKS.filter(op => op.label.toString() === sb8.toString()).map(er => er.key)[0]].filter(ep => ep.label.toString() === ssn8.toString()).map(tt => tt.key)[0].toString();
          }
    }

    else {

      if(profile._sc.cVIIIsn !== ssn8.toString())
      {
        //vflag = vflag.concat(" cVIIIsn ", profile._sc.cVIIIsn);
        console.log("in school name change");
        console.log(sb8);
        const lfields = {};
        lfields.fn = "_sc.cVIIIsn";
        lfields.o = profile._sc.cVIIIsn.toString(); 
        //SCHOOL_BY_BLOCK[BLOCKS.filter(op => op.label.toString() === sb8.toString()).map(tt => tt.key)[0]].map(e => <option key={index}> {e.label} </option>)
        lfields.n = ssn8.toString();
        console.log(lfields);
        temp.push(lfields);
        profile._sc.cVIIIsn = ssn8.toString();
      }
    }
      
      
      if(profile._sc.cVIIId !== DIST_LIST.filter(e => e.label.toString() === sd8.toString()).map(e => e.key)[0].toString())
      {
        //vflag = vflag.concat(" cVIIId ", profile._sc.cVIIId);
        const lfields = {};
        lfields.fn = "_sc.cVIIId";
        lfields.o = profile._sc.cVIIId.toString(); 
        lfields.n = DIST_LIST.filter(e => e.label.toString() === sd8.toString()).map(e => e.key)[0].toString();
        temp.push(lfields);
      }

      console.log(profile._sc.cVIIIs, STATES.filter(e => e.label.toString() === sst8.toString()).map(e => e.key)[0].toString());


      
      if(profile._sc.cVIIIs !== STATES.filter(e => e.label.toString() === sst8.toString()).map(e => e.key)[0].toString())
      {
        //vflag = vflag.concat(" cVIIIs ", profile._sc.cVIIIs);
        const lfields = {};
        lfields.fn = "_sc.cVIIIs";
        lfields.o = profile._sc.cVIIIs.toString(); 
        lfields.n = STATES.filter(e => e.label.toString() === sst8.toString()).map(e => e.key)[0].toString();
        temp.push(lfields);
      }
      
      if(sst8st === null) {
        //vflag = vflag.concat(" cVIIIst ", profile._sc.cVIIIst);
        const lfields = {};
        lfields.fn = "_sc.cVIIIst";
        if((profile._sc.cVIIIst !== null) && (profile._sc.cVIIIst !== undefined)) {
          lfields.o = profile._sc.cVIIIst.toString(); 
        }
        else  {
          lfields.o = null;
        }
        lfields.n = null;
        
        if(lfields.o === null && lfields.n === null)
        {
      
        }
        else
          temp.push(lfields);
      }
      
            
      //alert("sigsc " + sigsc + " caofsc " + catsc + " sst8st " + sst8st);
      
      if((sst8st !== '') && (sst8st !== null) && (sst8st !== undefined) && (profile._sc.cVIIIst !== GOVT_SCHOOL_TYPE.filter(e => e.label.toString() === sst8st.toString()).map(e => e.key)[0].toString()))
      {
        //vflag = vflag.concat(" cVIIIst ", profile._sc.cVIIIst);
        const lfields = {};
        lfields.fn = "_sc.cVIIIst";
        if((profile._sc.cVIIIst !== null) && (profile._sc.cVIIIst !== undefined))
          lfields.o = profile._sc.cVIIIst.toString(); 
        else
          lfields.o = null;
        lfields.n = GOVT_SCHOOL_TYPE.filter(e => e.label.toString() === sst8st.toString()).map(e => e.key)[0].toString();
        temp.push(lfields);
      }

      //sb8: !profile._sc.cVIIIIb ? null : BLOCKS_BY_DISTRICT[profile._sc.cVIIIId].filter(e => e.key === profile._sc.cVIIIIb).map(e => e.label)[0]

      if((sb8.toString() !== undefined) && BLOCKS_BY_DISTRICT[DIST_LIST.filter(e => e.label.toString() === sd8.toString()).map(e => e.key)[0].toString()] !== undefined) {
          if(profile._sc.cVIIIb !== BLOCKS_BY_DISTRICT[DIST_LIST.filter(e => e.label.toString() === sd8.toString()).map(e => e.key)[0].toString()].filter(e => e.label.toString() === sb8.toString()).map(e => e.key)[0].toString())
          {
            //vflag = vflag.concat(" cVIIIs ", profile._sc.cVIIIs);
            const lfields = {};
            lfields.fn = "_sc.cVIIIb";
            lfields.o = profile._sc.cVIIIb.toString(); 
            lfields.n = BLOCKS_BY_DISTRICT[DIST_LIST.filter(e => e.label.toString() === sd8.toString()).map(e => e.key)[0].toString()].filter(e => e.label.toString() === sb8.toString()).map(e => e.key)[0].toString();
            temp.push(lfields);
            profile._sc.cVIIIb =  BLOCKS_BY_DISTRICT[DIST_LIST.filter(e => e.label.toString() === sd8.toString()).map(e => e.key)[0].toString()].filter(e => e.label.toString() === sb8.toString()).map(e => e.key)[0].toString();
          }
      }
      else {

        if(profile._sc.cVIIIb.toString() !== sb8.toString())
        {
          //vflag = vflag.concat(" cVIIIs ", profile._sc.cVIIIs);
          const lfields = {};
          lfields.fn = "_sc.cVIIIb";
          lfields.o = profile._sc.cVIIIb.toString(); 
          lfields.n = sb8.toString();
          temp.push(lfields);
          profile._sc.cVIIIb = sb8.toString();
        }

      }
      
      profile._sc.cVIIIy = sy8.toString();
      profile._sc.cVIIId = DIST_LIST.filter(e => e.label.toString() === sd8.toString()).map(e => e.key)[0].toString();      
      profile._sc.cVIIIs = STATES.filter(e => e.label.toString() === sst8.toString()).map(e => e.key)[0].toString();
      if((sst8st !== null) && (sst8st !== '')&& (sst8st !== undefined)) {
        profile._sc.cVIIIst = GOVT_SCHOOL_TYPE.filter(e => e.label.toString() === sst8st.toString()).map(e => e.key)[0].toString();
      }
      else {
        profile._sc.cVIIIst = null;
      }
      
            
      
      return temp;

}


function changeschoolfields7 ()
{
      var temp = [];

      if(profile._sc.cVIIy !== sy7.toString())
      {
        //vflag = vflag.concat(" cVIIy ", profile._sc.cVIIy);
        const lfields = {};
        lfields.fn = "_sc.cVIIy";
        lfields.o = profile._sc.cVIIy.toString(); 
        lfields.n = sy7.toString();
        temp.push(lfields);
      }


      if(SCHOOL_BY_BLOCK[BLOCKS.filter(op => op.label.toString() === sb7.toString()).map(er => er.key)[0]] !== undefined) {

          if(profile._sc.cVIIsn !== SCHOOL_BY_BLOCK[BLOCKS.filter(op => op.label.toString() === sb7.toString()).map(er => er.key)[0]].filter(ep => ep.label.toString() === ssn7.toString()).map(tt => tt.key)[0].toString())
          {
            //vflag = vflag.concat(" cVIIsn ", profile._sc.cVIIsn);
            console.log("in school name change");
            console.log(sb7);
            const lfields = {};
            lfields.fn = "_sc.cVIIsn";
            lfields.o = profile._sc.cVIIsn.toString(); 
            //SCHOOL_BY_BLOCK[BLOCKS.filter(op => op.label.toString() === sb7.toString()).map(tt => tt.key)[0]].map(e => <option key={index}> {e.label} </option>)
            lfields.n = SCHOOL_BY_BLOCK[BLOCKS.filter(op => op.label.toString() === sb7.toString()).map(er => er.key)[0]].filter(ep => ep.label.toString() === ssn7.toString()).map(tt => tt.key)[0].toString();
            console.log(lfields);
            temp.push(lfields);
            profile._sc.cVIIsn = SCHOOL_BY_BLOCK[BLOCKS.filter(op => op.label.toString() === sb7.toString()).map(er => er.key)[0]].filter(ep => ep.label.toString() === ssn7.toString()).map(tt => tt.key)[0].toString();
          }
    }

    else {

      if(profile._sc.cVIIsn !== ssn7.toString())
      {
        //vflag = vflag.concat(" cVIIsn ", profile._sc.cVIIsn);
        console.log("in school name change");
        console.log(sb7);
        const lfields = {};
        lfields.fn = "_sc.cVIIsn";
        lfields.o = profile._sc.cVIIsn.toString(); 
        //SCHOOL_BY_BLOCK[BLOCKS.filter(op => op.label.toString() === sb7.toString()).map(tt => tt.key)[0]].map(e => <option key={index}> {e.label} </option>)
        lfields.n = ssn7.toString();
        console.log(lfields);
        temp.push(lfields);
        profile._sc.cVIIsn = ssn7.toString();
      }
    }
      
      
      if(profile._sc.cVIId !== DIST_LIST.filter(e => e.label.toString() === sd7.toString()).map(e => e.key)[0].toString())
      {
        //vflag = vflag.concat(" cVIId ", profile._sc.cVIId);
        const lfields = {};
        lfields.fn = "_sc.cVIId";
        lfields.o = profile._sc.cVIId.toString(); 
        lfields.n = DIST_LIST.filter(e => e.label.toString() === sd7.toString()).map(e => e.key)[0].toString();
        temp.push(lfields);
      }
      
      if(profile._sc.cVIIs !== STATES.filter(e => e.label.toString() === sst7.toString()).map(e => e.key)[0].toString())
      {
        //vflag = vflag.concat(" cVIIs ", profile._sc.cVIIs);
        const lfields = {};
        lfields.fn = "_sc.cVIIs";
        lfields.o = profile._sc.cVIIs.toString(); 
        lfields.n = STATES.filter(e => e.label.toString() === sst7.toString()).map(e => e.key)[0].toString();
        temp.push(lfields);
      }
      
      if(sst7st === null) {
        //vflag = vflag.concat(" cVIIst ", profile._sc.cVIIst);
        const lfields = {};
        lfields.fn = "_sc.cVIIst";
        if((profile._sc.cVIIst !== null) && (profile._sc.cVIIst !== undefined)) {
          lfields.o = profile._sc.cVIIst.toString(); 
        }
        else  {
          lfields.o = null;
        }
        lfields.n = null;
        
        if(lfields.o === null && lfields.n === null)
        {
      
        }
        else
          temp.push(lfields);
      }
      
            
      //alert("sigsc " + sigsc + " caofsc " + catsc + " sst7st " + sst7st);
      
      if((sst7st !== '') && (sst7st !== null) && (sst7st !== undefined) && (profile._sc.cVIIst !== GOVT_SCHOOL_TYPE.filter(e => e.label.toString() === sst7st.toString()).map(e => e.key)[0].toString()))
      {
        //vflag = vflag.concat(" cVIIst ", profile._sc.cVIIst);
        const lfields = {};
        lfields.fn = "_sc.cVIIst";
        if((profile._sc.cVIIst !== null) && (profile._sc.cVIIst !== undefined))
          lfields.o = profile._sc.cVIIst.toString(); 
        else
          lfields.o = null;
        lfields.n = GOVT_SCHOOL_TYPE.filter(e => e.label.toString() === sst7st.toString()).map(e => e.key)[0].toString();
        temp.push(lfields);
      }

      //sb7: !profile._sc.cVIIIb ? null : BLOCKS_BY_DISTRICT[profile._sc.cVIIId].filter(e => e.key === profile._sc.cVIIIb).map(e => e.label)[0]

      if((sb7.toString() !== undefined) && BLOCKS_BY_DISTRICT[DIST_LIST.filter(e => e.label.toString() === sd7.toString()).map(e => e.key)[0].toString()] !== undefined) {
          if(profile._sc.cVIIb !== BLOCKS_BY_DISTRICT[DIST_LIST.filter(e => e.label.toString() === sd7.toString()).map(e => e.key)[0].toString()].filter(e => e.label.toString() === sb7.toString()).map(e => e.key)[0].toString())
          {
            //vflag = vflag.concat(" cVIIs ", profile._sc.cVIIs);
            const lfields = {};
            lfields.fn = "_sc.cVIIb";
            lfields.o = profile._sc.cVIIb.toString(); 
            lfields.n = BLOCKS_BY_DISTRICT[DIST_LIST.filter(e => e.label.toString() === sd7.toString()).map(e => e.key)[0].toString()].filter(e => e.label.toString() === sb7.toString()).map(e => e.key)[0].toString();
            temp.push(lfields);
            profile._sc.cVIIb = BLOCKS_BY_DISTRICT[DIST_LIST.filter(e => e.label.toString() === sd7.toString()).map(e => e.key)[0].toString()].filter(e => e.label.toString() === sb7.toString()).map(e => e.key)[0].toString();
          }
      }
      else {

        if(profile._sc.cVIIb.toString() !== sb7.toString())
        {
          //vflag = vflag.concat(" cVIIs ", profile._sc.cVIIs);
          const lfields = {};
          lfields.fn = "_sc.cVIIb";
          lfields.o = profile._sc.cVIIb.toString(); 
          lfields.n = sb7.toString();
          temp.push(lfields);
          profile._sc.cVIIb = sb7.toString();
        }

      }
      
      profile._sc.cVIIy = sy7.toString();
      profile._sc.cVIId = DIST_LIST.filter(e => e.label.toString() === sd7.toString()).map(e => e.key)[0].toString();      
      profile._sc.cVIIs = STATES.filter(e => e.label.toString() === sst7.toString()).map(e => e.key)[0].toString();
      if((sst7st !== null) && (sst7st !== '')&& (sst7st !== undefined)) {
        profile._sc.cVIIst = GOVT_SCHOOL_TYPE.filter(e => e.label.toString() === sst7st.toString()).map(e => e.key)[0].toString();
      }
      else {
        profile._sc.cVIIst = null;
      }
      
            
      
      return temp;

}



function changeschoolfields6 ()
{
      var temp = [];

      if(profile._sc.cVIy !== sy6.toString())
      {
        //vflag = vflag.concat(" cVIy ", profile._sc.cVIy);
        const lfields = {};
        lfields.fn = "_sc.cVIy";
        lfields.o = profile._sc.cVIy.toString(); 
        lfields.n = sy6.toString();
        temp.push(lfields);
      }


      if(SCHOOL_BY_BLOCK[BLOCKS.filter(op => op.label.toString() === sb6.toString()).map(er => er.key)[0]] !== undefined) {

          if(profile._sc.cVIsn !== SCHOOL_BY_BLOCK[BLOCKS.filter(op => op.label.toString() === sb6.toString()).map(er => er.key)[0]].filter(ep => ep.label.toString() === ssn6.toString()).map(tt => tt.key)[0].toString())
          {
            //vflag = vflag.concat(" cVIsn ", profile._sc.cVIsn);
            console.log("in school name change");
            console.log(sb6);
            const lfields = {};
            lfields.fn = "_sc.cVIsn";
            lfields.o = profile._sc.cVIsn.toString(); 
            //SCHOOL_BY_BLOCK[BLOCKS.filter(op => op.label.toString() === sb6.toString()).map(tt => tt.key)[0]].map(e => <option key={index}> {e.label} </option>)
            lfields.n = SCHOOL_BY_BLOCK[BLOCKS.filter(op => op.label.toString() === sb6.toString()).map(er => er.key)[0]].filter(ep => ep.label.toString() === ssn6.toString()).map(tt => tt.key)[0].toString();
            console.log(lfields);
            temp.push(lfields);
            profile._sc.cVIsn = SCHOOL_BY_BLOCK[BLOCKS.filter(op => op.label.toString() === sb6.toString()).map(er => er.key)[0]].filter(ep => ep.label.toString() === ssn6.toString()).map(tt => tt.key)[0].toString();
          }
    }

    else {

      if(profile._sc.cVIsn !== ssn6.toString())
      {
        //vflag = vflag.concat(" cVIsn ", profile._sc.cVIsn);
        console.log("in school name change");
        console.log(sb6);
        const lfields = {};
        lfields.fn = "_sc.cVIsn";
        lfields.o = profile._sc.cVIsn.toString(); 
        //SCHOOL_BY_BLOCK[BLOCKS.filter(op => op.label.toString() === sb6.toString()).map(tt => tt.key)[0]].map(e => <option key={index}> {e.label} </option>)
        lfields.n = ssn6.toString();
        console.log(lfields);
        temp.push(lfields);
        profile._sc.cVIsn = ssn6.toString();
      }
    }
      
      
      if(profile._sc.cVId !== DIST_LIST.filter(e => e.label.toString() === sd6.toString()).map(e => e.key)[0].toString())
      {
        //vflag = vflag.concat(" cVId ", profile._sc.cVId);
        const lfields = {};
        lfields.fn = "_sc.cVId";
        lfields.o = profile._sc.cVId.toString(); 
        lfields.n = DIST_LIST.filter(e => e.label.toString() === sd6.toString()).map(e => e.key)[0].toString();
        temp.push(lfields);
      }
      
      if(profile._sc.cVIs !== STATES.filter(e => e.label.toString() === sst6.toString()).map(e => e.key)[0].toString())
      {
        //vflag = vflag.concat(" cVIs ", profile._sc.cVIs);
        const lfields = {};
        lfields.fn = "_sc.cVIs";
        lfields.o = profile._sc.cVIs.toString(); 
        lfields.n = STATES.filter(e => e.label.toString() === sst6.toString()).map(e => e.key)[0].toString();
        temp.push(lfields);
      }
      
      if(sst6st === null) {
        //vflag = vflag.concat(" cVIst ", profile._sc.cVIst);
        const lfields = {};
        lfields.fn = "_sc.cVIst";
        if((profile._sc.cVIst !== null) && (profile._sc.cVIst !== undefined)) {
          lfields.o = profile._sc.cVIst.toString(); 
        }
        else  {
          lfields.o = null;
        }
        lfields.n = null;
        
        if(lfields.o === null && lfields.n === null)
        {
      
        }
        else
          temp.push(lfields);
      }
      
            
      //alert("sigsc " + sigsc + " caofsc " + catsc + " sst6st " + sst6st);
      
      if((sst6st !== '') && (sst6st !== null) && (sst6st !== undefined) && (profile._sc.cVIst !== GOVT_SCHOOL_TYPE.filter(e => e.label.toString() === sst6st.toString()).map(e => e.key)[0].toString()))
      {
        //vflag = vflag.concat(" cVIst ", profile._sc.cVIst);
        const lfields = {};
        lfields.fn = "_sc.cVIst";
        if((profile._sc.cVIst !== null) && (profile._sc.cVIst !== undefined))
          lfields.o = profile._sc.cVIst.toString(); 
        else
          lfields.o = null;
        lfields.n = GOVT_SCHOOL_TYPE.filter(e => e.label.toString() === sst6st.toString()).map(e => e.key)[0].toString();
        temp.push(lfields);
      }

      //sb7: !profile._sc.cVIIb ? null : BLOCKS_BY_DISTRICT[profile._sc.cVIId].filter(e => e.key === profile._sc.cVIIb).map(e => e.label)[0]

      console.log(sb6, sd6);

      if((sb6.toString() !== undefined) && BLOCKS_BY_DISTRICT[DIST_LIST.filter(e => e.label.toString() === sd6.toString()).map(e => e.key)[0].toString()] !== undefined) {
          if(profile._sc.cVIb !== BLOCKS_BY_DISTRICT[DIST_LIST.filter(e => e.label.toString() === sd6.toString()).map(e => e.key)[0].toString()].filter(e => e.label.toString() === sb6.toString()).map(e => e.key)[0].toString())
          {
            //vflag = vflag.concat(" cVIs ", profile._sc.cVIs);
            const lfields = {};
            lfields.fn = "_sc.cVIb";
            lfields.o = profile._sc.cVIb.toString(); 
            lfields.n = BLOCKS_BY_DISTRICT[DIST_LIST.filter(e => e.label.toString() === sd6.toString()).map(e => e.key)[0].toString()].filter(e => e.label.toString() === sb6.toString()).map(e => e.key)[0].toString();
            temp.push(lfields);
            profile._sc.cVIb = BLOCKS_BY_DISTRICT[DIST_LIST.filter(e => e.label.toString() === sd6.toString()).map(e => e.key)[0].toString()].filter(e => e.label.toString() === sb6.toString()).map(e => e.key)[0].toString();
          }
      }
      else {

        if(profile._sc.cVIb.toString() !== sb6.toString())
        {
          //vflag = vflag.concat(" cVIs ", profile._sc.cVIs);
          const lfields = {};
          lfields.fn = "_sc.cVIb";
          lfields.o = profile._sc.cVIb.toString(); 
          lfields.n = sb6.toString();
          temp.push(lfields);
          profile._sc.cVIb = sb6.toString();
        }

      }
      
      profile._sc.cVIy = sy6.toString();
      profile._sc.cVId = DIST_LIST.filter(e => e.label.toString() === sd6.toString()).map(e => e.key)[0].toString();      
      profile._sc.cVIs = STATES.filter(e => e.label.toString() === sst6.toString()).map(e => e.key)[0].toString();
      if((sst6st !== null) && (sst6st !== '')&& (sst6st !== undefined)) {
        profile._sc.cVIst = GOVT_SCHOOL_TYPE.filter(e => e.label.toString() === sst6st.toString()).map(e => e.key)[0].toString();
      }
      else {
        profile._sc.cVIst = null;
      }
      
            
      
      return temp;

}

function changecaofsc() {

  var temp = [];

  if(profile._sc.caofsc.toString() !== CATEGORY_OF_SCHOOL.filter(e => e.label === catsc.toString()).map(e => e.key.toString())[0])
  {
    //vflag = vflag.concat(" caofscc ", profile._sc.caofsc.toString());

    const lfields = {};
    lfields.fn = "_sc.caofsc";
    lfields.o = profile._sc.caofsc.toString(); 
    lfields.n = CATEGORY_OF_SCHOOL.filter(e => e.label === catsc.toString()).map(e => e.key.toString())[0];
    temp.push(lfields);
    profile._sc.caofsc = CATEGORY_OF_SCHOOL.filter(e => e.label === catsc.toString()).map(e => e.key.toString())[0];
  }

  return temp;
}

function changemi() {
  var temp = [];
  if(profile._ac.mi.toString() !== MEDIUM_OF_INSTRUCTION.filter(e => e.label === mi.toString()).map(e => e.key.toString())[0])
  {
    //vflag = vflag.concat(" mic ", profile._ac.mi.toString());

    const lfields = {};
    lfields.fn = "_ac.mi";
    lfields.o = profile._ac.mi.toString(); 
    lfields.n = MEDIUM_OF_INSTRUCTION.filter(e => e.label === mi.toString()).map(e => e.key.toString())[0];
    temp.push(lfields);
    profile._ac.mi = MEDIUM_OF_INSTRUCTION.filter(e => e.label === mi.toString()).map(e => e.key.toString())[0];
  }
  return temp;
}


function changesigsc() {

  var temp = [];

  if(profile._sc.sigsc.toString() !== YES_NO_ARRAY.filter(e => e.label === sigsc.toString()).map(e => e.key.toString())[0])
    {
      //vflag = vflag.concat(" sigsc ", profile._sc.sigsc.toString());

      const lfields = {};
      lfields.fn = "_sc.sigsc";
      lfields.o = profile._sc.sigsc.toString(); 
      lfields.n = YES_NO_ARRAY.filter(e => e.label === sigsc.toString()).map(e => e.key.toString())[0];
      temp.push(lfields);
      profile._sc.sigsc = YES_NO_ARRAY.filter(e => e.label === sigsc.toString()).map(e => e.key.toString())[0];
    }

  return temp;
}

function changesit() {

  var temp = [];

  if(profile._sc.sit.toString() !== YES_NO_ARRAY.filter(e => e.label === sit.toString()).map(e => e.key.toString())[0])
    {
      //vflag = vflag.concat(" sitc ", profile._sc.sit.toString());

      const lfields = {};
      lfields.fn = "_sc.sit";
      lfields.o = profile._sc.sit.toString(); 
      lfields.n = YES_NO_ARRAY.filter(e => e.label === sit.toString()).map(e => e.key.toString())[0];
      temp.push(lfields);

      profile._sc.sit = YES_NO_ARRAY.filter(e => e.label === sit.toString()).map(e => e.key.toString())[0];

    }


  return temp;
}

function changehron() {
  var temp = [];

  if(profile._ac.hron.toString() !== hron.toString()) {

    //vflag = vflag.concat(" hronc ", profile._ac.hron.toString());
    
    const lfields = {};
    lfields.fn = "_ac.hron";
    lfields.o = profile._ac.hron.toString(); lfields.n = hron.toString();
    temp.push(lfields);

    profile._ac.hron = hron.toString();

  }

  return temp;
}

function changeemis() {

  var temp = [];

  if (profile._ac.emisno === undefined || profile._ac.emisno === null) profile._ac.emisno = "";

  if(profile._ac.emisno.toString() !== emis.toString()) {

    //vflag = vflag.concat(" hronc ", profile._ac.hron.toString());
    
    const lfields = {};
    lfields.fn = "_ac.emis";
    lfields.o = profile._ac.emisno.toString(); lfields.n = emis.toString();
    temp.push(lfields);

    profile._ac.emisno = emis.toString();

  }

  return temp;
}



  const clearProf = async e => {    
    clearApplication(navigate);    
    navigate("/tfcdash");
    formData.gappid = '';
  };


  return (    
    <Fragment>   

      {user === null ? (
        <Spinner /> ) : (   
          
    <div className= "profile-top" style={{paddingTop: "100px"}}>
      <h5 style={{textAlign: 'center', color: "teal", fontSize: "11pt", fontWeight: "bolder"}}> TFC Centre: {user && user.tfcname} </h5>
      <h5 style={{fontWeight: "bold"}}>  Updation System </h5>                                                             
                        
      </div>
      
        )}

      {loading === true && getdb === true ? (
        <Spinner /> ) : (
            profile !== null && user &&
        <Fragment>                 
        
        <div className='profile-top' style={{alignItems: "center", textAlign: "center"}}>
        <h5 style={{fontWeight: "bold", color: "blue"}}>                  
            Application Number: {profile._aid}      
        </h5>
              
          </div>

        <div className="" style={{textAlign: "left", alignItems: "center", textOverflow: "ellipsis", marginLeft: "150px"}}>
        <div className="grid-4" style={{border: "1px solid grey"}}>

        <div>            
            <Card> 
              <CardBody>
                <CardTitle> BASIC DETAILS </CardTitle>
                <CardSubtitle> </CardSubtitle>  
                <CardText>
                  <ListGroup>
                    <ListGroupItem> Name: <span style={{fontWeight: 'bold'}}>{profile._n} </span> </ListGroupItem>                   
                    <ListGroupItem> Date of Birth: <span style={{fontWeight: 'bold'}}> {profile.dob} </span> </ListGroupItem>                    
                    <ListGroupItem>Gender: <span style={{fontWeight: 'bold'}}>
                      {GENDER.filter(e => e.key === profile._p.g).map(e => e.label)} </span>
                    </ListGroupItem>                    
                    <ListGroupItem> Nationality: <span style={{fontWeight: 'bold'}}> {NATIONALITY.filter(e => e.key.toString() === profile._p.ny.toString()).map(e => e.label)} </span> </ListGroupItem>                    
                    <ListGroupItem> Nativity: <span style={{fontWeight: 'bold'}}> {NATIVITY.filter(e => e.key.toString() === profile._p.nt.toString()).map(e => e.label)} </span> </ListGroupItem>                    
                    <ListGroupItem> Studied in TamilNadu? <span style={{fontWeight: 'bold'}}>{YES_NO_ARRAY.filter(e => e.key.toString() === profile._sc.sit.toString()).map(e => e.label)} </span> </ListGroupItem>
                    <ListGroupItem> Community:  <span style={{fontWeight: 'bold'}}>{COMMUNITY.filter(e => e.key.toString() === profile._p.co.toString()).map(e => e.label)} </span> </ListGroupItem>

                    <ListGroupItem> Caste:  <span style={{fontWeight: 'bold'}}>{CASTE[profile._p.co].filter(e => e.key.toString() === profile._p.cas.toString()).map(e => e.label) } </span> </ListGroupItem>
 
                    <ListGroupItem> Community Certificate Number <span style={{fontWeight: 'bold'}}>{profile._ac.commcnum} </span> </ListGroupItem>

                    {(profile._p.ny === "IN" && profile._p.nt === "OTH") ?
                    <ListGroupItem> Eligibility Type:  

                      <span style={{fontWeight: 'bold'}}>                          
                      {(profile._p.elt !== null && profile._p.elt !== undefined )?                      
                      ELIGIBILITY_TYPE.filter(e => e.key.toString() === profile._p.elt.toString()).map(e => e.label) 
                      :   " NULL" }
                      
                      </span> 
    
                      </ListGroupItem> : null }        

                  </ListGroup>
                  </CardText>
                  </CardBody>   
                  </Card>             
        </div>

        <div>            
            <Card> 
              <CardBody >
                <CardTitle>  SPECIAL RESERVATION DETAILS </CardTitle>
                <CardSubtitle> </CardSubtitle>  
                <CardText >
                  <ListGroup>
                    {/* <ListGroupItem> Sports Certificates: <span style={{fontWeight: 'bold'}}>{YES_NO_ARRAY.filter(e => e.key.toString() === profile._sr.vsc.toString()).map(e => e.label)} </span></ListGroupItem>
                    
                    <ListGroupItem> Ex-Serviceman: <span style={{fontWeight: 'bold'}}> {YES_NO_ARRAY.filter(e => e.key.toString() === profile._sr.ex.toString()).map(e => e.label)} </span> </ListGroupItem>        
                    <ListGroupItem> Physically Challenged: 
                    <span style={{fontWeight: 'bold'}}>{YES_NO_ARRAY.filter(e => e.key.toString() === profile._sr.dap.toString()).map(e => e.label)} </span>
                    </ListGroupItem>    
                    {profile && profile._sr && profile._sr.dap === "Y" && profile._sr.adt &&
                    <ListGroupItem> Disability Type: 
                    <span style={{fontWeight: 'bold'}}>{DIFF_ABLED_TYPES.filter(e => e.key.toString() === profile._sr.adt.toString()).map(e => e.label)} </span>
                    </ListGroupItem>                        
                    }       */}
                    <ListGroupItem> Studied in Govt School(6 to 12)? <span style={{fontWeight: 'bold'}}>{YES_NO_ARRAY.filter(e => e.key.toString() === profile._sc.sigsc.toString()).map(e => e.label)} </span> </ListGroupItem>

                    <ListGroupItem> EMIS Number <span style={{fontWeight: 'bold'}}>{profile._ac.emisno} </span> </ListGroupItem>

                    <ListGroupItem> Annual Income: 
                    <span style={{fontWeight: 'bold'}}>{profile._sp.ai}</span>
                    </ListGroupItem>    
                    <ListGroupItem> First Graduate: <span style={{fontWeight: 'bold'}}> {YES_NO_ARRAY.filter(e => e.key.toString() === profile._sp.fgtfc.toString()).map(e => e.label)} </span>
                          </ListGroupItem> 
                          <ListGroupItem> PMSS: <span style={{fontWeight: 'bold'}}> {YES_NO_ARRAY.filter(e => e.key.toString() === profile._sp.pms.toString()).map(e => e.label)} </span>
                          </ListGroupItem>                           


                  </ListGroup>
                  </CardText>
                  </CardBody>   
                  </Card>                     
        </div>
        <div>            
            <Card> 
              <CardBody >
                <CardTitle>  
                 ACADEMIC DETAILS </CardTitle>
                <CardSubtitle> </CardSubtitle>  
                <CardText >
                  <ListGroup>                    
                    <ListGroupItem> HSC Register Number: <span style={{fontWeight: 'bold'}}>{profile._ac && profile._ac.hrn} </span> </ListGroupItem> 
                    <ListGroupItem> HSC Roll Number: <span style={{fontWeight: 'bold'}}>{profile._ac && profile._ac.hron} </span> </ListGroupItem> 
                    <ListGroupItem> Qualifying Year: <span style={{fontWeight: 'bold'}}>{profile._ac && profile._ac.qy} </span> </ListGroupItem> 
                    <ListGroupItem> Qualifying Exam: <span style={{fontWeight: 'bold'}}> {QUALIFYING_EXAM.filter(e => e.key.toString() === profile._ac.qe.toString()).map(e => e.label)} </span> </ListGroupItem>
                    <ListGroupItem> Name of the board: <span style={{fontWeight: 'bold'}}> {EXAM_BOARDS.filter(e => e.key.toString() === profile._ac.nbe.toString()).map(e => e.label)} </span> </ListGroupItem> 

              {(profile._ac.hg !== undefined && profile._ac.hg !== null) &&
              <ListGroupItem> HSC Group: <span style={{fontWeight: 'bold'}}> {profile._ac && profile._ac.hg && HSC_GROUPS.filter(e => e.key.toString() === profile._ac.hg.toString()).map(e => e.label)} </span> </ListGroupItem> 
              }

                {profile._ac.qe === "1" && profile._ac.hg.toString() === "1" && profile._ac.gc !== null &&
                  <ListGroupItem> Group code: <span style={{fontWeight: 'bold'}}> {profile._ac && HSC_ACADEMIC_GROUP_CODES.filter(e => e.key.toString() === profile._ac.gc.toString()).map(e => e.label)} </span> </ListGroupItem>            
                }       

                {profile._ac.qe === "1" && profile._ac.hg.toString() === "2" && profile._ac.gc !== null &&
                  <ListGroupItem> Group code: <span style={{fontWeight: 'bold'}}> {profile._ac && HSC_VOCATIONAL_GROUP_CODES.filter(e => e.key.toString() === profile._ac.gc.toString()).map(e => e.label)} </span> </ListGroupItem>            
                }       

                {profile._ac.qe !== "1" && profile._ac.gc !== null && 
                  <ListGroupItem> Group code: <span style={{fontWeight: 'bold'}}> {profile._ac &&   OTHER_GROUP_CODES.filter(e => e.key.toString() === profile._ac.gc.toString()).map(e => e.label)} </span> </ListGroupItem>            
                }       

                 {(profile._ac.gc === null || profile._ac.gc === undefined ) && 
                    <ListGroupItem>
                      <span style={{color: "red", fontWeight: "bold"}}>** Wrongly Updated Group Code. Pls Contact TNEA Team. **</span>
                    </ListGroupItem>
                  } 

                  </ListGroup>
                  </CardText>
                  </CardBody>   
                  </Card>              
        </div>    

        <div>            
            <Card > 
              <CardBody >
                <CardTitle>  
                 OTHER DETAILS </CardTitle>
                <CardSubtitle> </CardSubtitle>  
                <CardText >
                  <ListGroup>                    
                    <ListGroupItem> Email address: <span style={{fontWeight: 'bold'}}>
                      {profile.e} </span> </ListGroupItem>   
                      <ListGroupItem> Mobile No: <span style={{fontWeight: 'bold'}}>
                      {profile.m} </span> </ListGroupItem>   
                      <ListGroupItem> Parent Name: <span style={{fontWeight: 'bold'}}>
                      {profile._p && profile._p.pn} </span> </ListGroupItem>   
                    <ListGroupItem> Payment Status: <span style={{fontWeight: 'bold'}}>
                      {profile.regPaymentStatus} </span> </ListGroupItem>                   
                          <ListGroupItem> No of Docs Uploaded: <span style={{fontWeight: 'bold'}}> {profile._docs && profile._docs.length > 0 ? profile._docs.length : "0"} </span>
                          </ListGroupItem> 
                          <ListGroupItem> Freezed: <span style={{fontWeight: 'bold'}}> {profile._fzup ? "TRUE" : "FALSE"} </span>
                          </ListGroupItem> 
                          <ListGroupItem> Upload Allowed: <span style={{fontWeight: 'bold'}}> {profile._alup ? "TRUE" : "FALSE"} </span>
                          </ListGroupItem> 
                          <ListGroupItem> DPI Status: <span style={{fontWeight: 'bold'}}>  {profile.dpi ? profile.dpi.flag : 'Failed'} </span>  </ListGroupItem>
                  </ListGroup>
                  </CardText>
                  </CardBody>   
                  </Card>              
        </div>    


        </div>
        </div>

        <div className='profile-top'>
        <Dload2/>
        </div>


        {profile && profile.cv && profile.cv.st !== undefined ? (
        <div>
            <div className='my-2 profile-top'>
            <Card style={{ fontFamily: "Verdana", fontSize: "11pt", textAlign: "center"}}> 
              <CardBody className="text-left">
                <CardTitle style={{ textAlign: "center", color: "navy", fontWeight: "bolder"}}>  CERTIFICATE VERIFICATION SUMMARY </CardTitle>
                <CardSubtitle> </CardSubtitle>  
                <CardText style={{color: 'darkred'}}>
                  <ListGroup>                  
                  <ListGroupItem> Application Status :  <span style={{fontWeight: 'bold'}}> 
                      {profile.cv.st === "open" ? 'To be Verified' : null} 
                      {profile.cv.st === "toupdate" ? 'Sent for Updation' : null} 
                      {profile.cv.st === "vcomplete" ? 'Verified Successfully' : null}
                      {profile.cv.st === "ucomplete" ? "Updated Successfully" : null}
                      {profile.cv.st === "incomplete" ? "INCOMPLETE" : null}   
                      {profile.cv.st === "complete" ? "COMPLETE" : null}  
                      {profile.cv.st === "ccomplete" ? "COMPLETE" : null}                      
                      </span></ListGroupItem>
                      
                      {profile.cv.ver && profile.cv.ver[profile.cv.ver.length-1] !== undefined &&
                      <ListGroupItem> Verification Remarks:  <span style={{fontWeight: 'bold'}}>
                      {profile.cv.ver[profile.cv.ver.length-1].vr}
                      </span></ListGroupItem> }

                      {profile.cv.ver  && profile.cv.ver[profile.cv.ver.length-1] !== undefined && 
                      <ListGroupItem> Verification Officer:  <span style={{fontWeight: 'bold'}}>
                      {profile.cv.ver[profile.cv.ver.length-1].vn}
                      </span></ListGroupItem> }
                    
                      {profile.cv.upd  && profile.cv.upd[profile.cv.upd.length-1] !== undefined &&
                      <ListGroupItem> Updation Remarks:  <span style={{fontWeight: 'bold'}}>
                      {profile.cv.upd[profile.cv.upd.length-1].ur}
                      </span> </ListGroupItem> }

                      {profile.cv.upd  && profile.cv.upd[profile.cv.upd.length-1] !== undefined &&
                      <ListGroupItem> Updation officer:  <span style={{fontWeight: 'bold'}}>
                      {profile.cv.upd[profile.cv.upd.length-1].un}
                      </span></ListGroupItem> }

                  </ListGroup>
                  </CardText>
                  </CardBody>   
                  </Card>    
          </div>       
          
        </div>
        ) : null }


         <form className="profile-top">            .
            <div className='form-group' >
                <button
                onClick={() => changeTFC()}
                type='button'
                className='btn btn-danger' 
                >
                Click here to Change Application Details
                </button>  

                <input type="submit"  value="Cancel" className="btn btn-light" onClick= {e => clearProf(e)} />  
                
                {/* <input type="submit"  value="UNFREEZE EDIT" className="btn btn-light" onClick= {e => clickunfr(e)} /> */}

                {profile._fzup === true &&

                <input type="submit"  value="Unfreeze Upload" className="btn btn-primary" onClick= {e => clickunfrupd(e)} />

                }      
               
            </div>
        </form>
        <form className='form'>
                  
            {displayChangeTFC && (            
            
            <div className='container'>              
              
              <div className="profile-top my-2">
                <h5 style={{color: "navy", fontWeight: "bold", textAlign: "center"}}> Personal Information </h5>
              </div>

              <div className='grid-4' style={{border: "1px solid grey"}}>

                  <div className="form-group ">   
                  <small >         
                    <label> Name </label>             
                  </small>
                  <input type="text" name="name" value={name} onChange = {e => onChange(e)}  />                                       
                  </div>
          
                  <div className="form-group ">
                    <small >         
                    <label> Gender </label> 
                    </small>
                    <select name="gender" value={gender} onChange = {e => onChange(e)}   >                           
                        {GENDER.map((e,index) => <option key={index}> {e.label} </option>) }
                    </select>
                  </div>

                  <div className="form-group ">   
                  <small >         
                  <label> Date of Birth </label>  </small>           
                    <input type="date" name="dob"  value={dob} onChange = {e => handledateChange(e)} />                                       
                  </div>

                  <div className="form-group ">   
                  <small >         
                    <label> Mobile number </label>             
                  </small>
                      <input type="number" name="mob" value={mob} style={{fontSize: "15pt"}} onChange = {e => onChange(e)}  />                                       
                  </div>


                  <div className="form-group ">
                  <small >         
                  <label> Nativity </label> </small>
                  <select name="nt" value={nt} onChange = {e => onChange(e)}  >                           
                      {NATIVITY.map((e,index) => <option key={index}> {e.label} </option>) }
                  </select>
                  </div>

                  <div className="form-group">
                  <small >         
                  <label> Nationality </label> </small>
                  <select name="ny" value={ny} onChange = {e => onChange(e)}  > 
                      {NATIONALITY.map((e,index) => <option key={index}> {e.label} </option>) }
                  </select>
                  </div>

                  {nt.toString() === "Others" && ny.toString() === "Indian" ?
                      <div className="form-group ">   
                        <small >         
                        <label> Eligibility Type </label>             
                        </small>
                        <select name="elt" value={elt} onChange = {e => onChange(e)}  >                           
                          {ELIGIBILITY_TYPE.map((e,index) => <option key={index}> {e.label} </option>) }
                        </select>

                      </div> : null
                  }

                  <div className="form-group ">
                  <small className="form-text">         
                  <label> Religion </label> </small>
                  <select name="religion" value={religion}  onChange = {e => onChange(e)}>                           
                      {RELIGION.map((e,index) => <option key={index}> {e.label} </option>) }
                  </select>
                  </div>          

                </div>

                {/* <div className="profile-top my-2">
                  <h5 style={{color: "navy", fontWeight: "bold", textAlign: "center"}}> Special Reservation Information </h5>
                </div> */}
                               
              <div className='grid-4' style={{border: "1px solid grey"}}>  
              
              {/* <div className="form-group ">
              <small className="form-text">         
              <label> Sports Person </label> </small>
              <select name="vsc" value={vsc} onChange = {e => onChange(e)} disabled = {true} >                           
                {YES_NO_ARRAY.map((e,index) => <option key={index}> {e.label} </option>) }
              </select>
              </div> */}

                

{/* 
            <div className="form-group ">
                <small >         
                <label> Ex-Servicemen </label> </small>
                <select name="ex" value={ex} onChange = {e => onChange(e)}  disabled = {true} >                           
                    {YES_NO_ARRAY.map((e,index) => <option key={index}> {e.label} </option>) }
                </select>
            </div>

            <div className="form-group ">
                <small >         
                <label> Differently Abled </label> </small>
                <select name="dap" value={dap} onChange = {e => onChange(e)}  disabled = {true} >                           
                    {YES_NO_ARRAY.map((e,index) => <option key={index}> {e.label} </option>) }
                </select>
            </div>             */}


            {/* {dap.toString() === "Yes" ?
                <div className="form-group ">
                <small >         
                <label> Diff Abled Category </label> </small>
                <select name="adt" value={adt} onChange = {e => onChange(e)}  disabled = {true} >                           
                    {DIFF_ABLED_TYPES.map((e,index) => <option key={index}> {e.label} </option>) } 
                </select>
                </div>
          : null} */}

              <div className="form-group">   
              <small className="form-text">         
                <label> Studied VI to XII in Government School </label>             
              </small>
              <select name="sigsc" value={sigsc} onChange = {e => onChange(e)} disabled = {true}>                           
                {YES_NO_ARRAY.map((e,index) => <option key={index}> {e.label} </option>) }
              </select>
              </div>           


                  </div>
              
            
              <div className="profile-top my-2">
                  <h5 style={{color: "navy", fontWeight: "bold", textAlign: "center"}}> Academic Information </h5>
                </div>
            
            <div className='grid-4' style={{border: "1px solid grey"}}>
            <div className="form-group ">   
                  <small >         
                  <label> HSC Roll Number </label>             
                  </small>
                  <input type="text" name="hron" value={hron}   onChange = {e => onChange(e)} />                                       
                </div>              

                <div className="form-group container3">   
            <small className="form-text">         
            <label> HSC Register Number </label>             
            </small>
            <input type="text" name="hrn" value={hrn} onChange = {e => onChange(e)}/>                                       
          </div>


            <div className="form-group ">   
                  <small >         
                  <label> Studied VIII to XII in tamilnadu? </label>             
                  </small>
                  <select name="sit" value={sit} onChange = {e => onChange(e)}  >                           
                    {YES_NO_ARRAY.map((e,index) => <option key={index}> {e.label} </option>) }
                  </select>

             </div>                

                  <br/>

          <div className="form-group ">
            <small >         
            <label> Qualifying Examination </label> 
            </small>
            <select name="qe" value={qe} onChange = {e => onChange(e)}  >
                {QUALIFYING_EXAM.map((e,index) => <option key={index}> {e.label} </option>) }
            </select>
          </div>

          <div className="form-group">
            <small >         
            <label> Name of the Board </label> 
            </small>
            <select name="nbe" value={nbe} onChange = {e => onChange(e)}  >
                {EXAM_BOARDS.map((e,index) => <option key={index}> {e.label} </option>) }
            </select>
          </div>          

          {qe.toString() === "HSC" &&
          <div className="form-group ">
            <small className="form-text">         
            <label> HSC Stream </label> 
            </small>
            <select name="hg" value={hg} onChange = {e => onChange(e)}>                           
                {HSC_GROUPS.map((e,index) => <option key={index}> {e.label} </option>) }
            </select>
          </div>
          }

          
          {qe.toString() === "HSC" && hg === "HSC Academic" &&
          <div className="form-group ">   
            <small className="form-text">         
              <label> Group Code </label>             
            </small>
                                                 
            <select name="gc" value={gc} onChange = {e => onChange(e)}>                           
                {HSC_ACADEMIC_GROUP_CODES.map((e,index) => <option key={index}> {e.label} </option>) }
            </select>
          </div>
          }

        {qe.toString() === "HSC" && hg === "HSC Vocational" &&
          <div className="form-group ">   
            <small className="form-text">         
              <label> Group Code </label>             
            </small>
                                                  
            <select name="gc" value={gc} onChange = {e => onChange(e)}>                           
                {HSC_VOCATIONAL_GROUP_CODES.map((e,index) => <option key={index}> {e.label} </option>) }
            </select>
          </div>
        }
        

        {qe.toString() !== "HSC" &&
          <div className="form-group ">   
            <small className="form-text">         
              <label> Group Code </label>             
            </small>
                                                  
            <select name="gc" value={gc} onChange = {e => onChange(e)}>                           
                {OTHER_GROUP_CODES.map((e,index) => <option key={index}> {e.label} </option>) }
            </select>
          </div>
          }

      
          <div className="form-group">   
            <small className="form-text">         
            <label> HSC Obtained Marks </label>             
            </small>
            <input type="text" name="hscobt" value={hscobt} onChange = {e => onChange(e)}/>                                       
          </div>
          

          <div className="form-group">   
            <small className="form-text">         
            <label> HSC Total Marks </label>             
            </small>
            <input type="text" name="hsctot" value={hsctot} onChange = {e => onChange(e)}/>                                       
          </div>

          <div className="form-group">   
            <small className="form-text">         
            <label> X Obtained Marks </label>             
            </small>
            <input type="text" name="xobt" value={xobt} onChange = {e => onChange(e)}/>                                       
          </div>

          <div className="form-group">   
            <small className="form-text">         
            <label> X Total Marks </label>             
            </small>
            <input type="text" name="xtot" value={xtot} onChange = {e => onChange(e)}/>                                       
          </div>
   
          <br/>

          <div className="form-group">   
            <small className="form-text">         
            <label> Community certificate number </label>             
            </small>
            <input type="text" name="commcnum" value={commcnum} onChange = {e => onChange(e)}/>                                       
          </div>

          <div className="form-group ">
          <small >         
          <label> Year of Passing </label> </small>
          <select name="qy" value={qy} onChange = {e => onChange(e)}  > 
              {YEAR_OF_PASSING.map((e,index) => <option key={index}> {e.label} </option>) }
          </select>
          </div>

          <div className="form-group ">
          <small className="form-text">         
          <label> Medium of Instruction </label> </small>
          <select name="mi" value={mi} onChange = {e => onChange(e)}>                           
              {MEDIUM_OF_INSTRUCTION.map((e,index) => <option key={index}> {e.label} </option>) }
          </select>
          </div>


            </div>

          <div className="profile-top my-2">
          <h5 style={{color: "navy", fontWeight: "bold"}}> Marks Information </h5>
          </div>



        {hg === "HSC Academic" || qe.toString() === "CBSE" || qe.toString() === "ICSE" || qe.toString() === "Others" ?
          <div className="grid-4" style={{border: "1px solid grey"}}>
          
          <div className="form-group">   
          <small className="form-text">         
            <label> Maths marks: obtained </label>             </small>
            <input type="text" name="mmo" value={mmo} onChange = {e => onChange(e)}/>                                       
          </div>            

          <div className="form-group">   
          <small className="form-text">         
            <label> Maths: Maximum marks </label>             </small>
            <input type="text" name="mmt" value={mmt} onChange = {e => onChange(e)}/>                                       
          </div> 

          <div className="form-group">   
          <small className="form-text">         
            <label> Physics marks: obtained </label>             </small>
            <input type="text" name="pmo" value={pmo} onChange = {e => onChange(e)}/>                                       
          </div>            

          <div className="form-group">   
          <small className="form-text">         
            <label> Physics: Maximum marks </label>             </small>
            <input type="text" name="pmt" value={pmt} onChange = {e => onChange(e)}/>                                       
          </div>            

          <div className="form-group">   
          <small className="form-text">         
            <label> Chemistry marks: obtained </label>             </small>
            <input type="text" name="cmo" value={cmo} onChange = {e => onChange(e)}/>                                       
          </div>            

          <div className="form-group">   
          <small className="form-text">         
            <label> Chemistry: Maximum marks </label>             </small>
            <input type="text" name="cmt" value={cmt} onChange = {e => onChange(e)}/>                                       
          </div> 

          <div className="form-group">   
          <small className="form-text">         
            <label> Optional marks: obtained </label>             </small>
            <input type="text" name="omo" value={omo} onChange = {e => onChange(e)}/>                                       
          </div>            

          <div className="form-group">   
          <small className="form-text">         
            <label> Optional: Maximum marks </label>             </small>
            <input type="text" name="omt" value={omt} onChange = {e => onChange(e)}/>                                       
          </div> 

          </div>
        
        : null }

      {hg === "HSC Vocational" && qe.toString() === "HSC" ?
          
          
          <div className="grid-4" style={{border: "1px solid grey"}}>
          
          <div className="form-group">   
          <small className="form-text">         
            <label> M/P/C: Marks obtained </label>             </small>
            <input type="text" name="mpcmo" value={mpcmo} onChange = {e => onChange(e)}/>                                       
          </div>            

          <div className="form-group">   
          <small className="form-text">         
            <label> M/P/C: Maximum marks </label>             </small>
            <input type="text" name="mpcmt" value={mpcmt} onChange = {e => onChange(e)}/>                                       
          </div> 

          <div className="form-group">   
          <small className="form-text">         
            <label> Theory: Marks obtained </label>             </small>
            <input type="text" name="tmo" value={tmo} onChange = {e => onChange(e)}/>                                       
          </div>            

          <div className="form-group">   
          <small className="form-text">         
            <label> Theory: Maximum marks </label>             </small>
            <input type="text" name="tmt" value={tmt} onChange = {e => onChange(e)}/>                                       
          </div>            

          <div className="form-group">   
          <small className="form-text">         
            <label> Practical I: Marks obtained </label>             </small>
            <input type="text" name="p1mo" value={p1mo} onChange = {e => onChange(e)}/>                                       
          </div>            

          <div className="form-group">   
          <small className="form-text">         
            <label> Practical I: Maximum marks </label>             </small>
            <input type="text" name="p1mt" value={p1mt} onChange = {e => onChange(e)}/>                                       
          </div> 

          <div className="form-group">   
          <small className="form-text">         
            <label> Practical II: Marks obtained </label>             </small>
            <input type="text" name="p2mo" value={p2mo} onChange = {e => onChange(e)}/>                                       
          </div>            

          <div className="form-group">   
          <small className="form-text">         
            <label> Practical II: Maximum marks </label>             </small>
            <input type="text" name="p2mt" value={p2mt} onChange = {e => onChange(e)}/>                                       
          </div> 

          </div>
         
    
    : null }           




        <div className="profile-top my-2">
          <h5 style={{color: "navy", fontWeight: "bold", textAlign: "center"}}> Scholarship Information </h5>
        </div>

        <div className="grid-4" style={{border: "1px solid grey"}}>

          <div className="form-group">
            <small className="form-text">         
            <label> Parent Occupation </label> 
            </small>
            <select name="po" value={po} onChange = {e => onChange(e)}>                           
              {PARENT_OCCUPATION.map((e, ind) => <option key={ind}> {e.label} </option>) }
            </select>
          </div>

          <div className="form-group ">   
          <small className="form-text">         
              <label> Parent Name </label>             
          </small>
              <input type="text" name="pn" value={pn}  onChange = {e => onChange(e)}/>                                       
          </div>

          <div className="form-group ">
            <small className="form-text">         
            <label> Annual Income </label> </small>
            <input type="text" name="ai" value={ai} onChange = {e => onChange(e)}/>
          </div>

          <div className="form-group ">
            <small className="form-text">         
            <label> FG Scholarship </label> </small>
            <select name="fgtfc" value={fgtfc} onChange = {e => onChange(e)}>                           
                {YES_NO_ARRAY.map((e,index) => <option key={index}> {e.label} </option>) }
            </select>
          </div>


          <div className="form-group">
              <small className="form-text">         
              <label> Post Matric SS? </label> 
              </small>
              <select name="pms" value={pms} onChange = {e => onChange(e)}>                           
              {YES_NO_ARRAY.map((e,index) => <option key={index}> {e.label} </option>) }
              </select>
            </div>

          </div>

          <div className="profile-top my-2">
          <h5 style={{color: "navy", fontWeight: "bold", textAlign: "center"}}> School Details </h5>          
          </div>

          
          <div className='grid-2' style={{border: "1px solid grey"}}>
          {nbe.toString() === EXAM_BOARDS.filter(e => e.key.toString() === "1").map(e => e.label).toString() &&
          <div className="form-group ">   
                  <small >         
                  <label> EMIS No. </label>             
                  </small>
                  <input type="text" name="emis" value={emis}   onChange = {e => onChange(e)}  />                                       
                </div>              
          }
          <div className="form-group ">
          <small className="form-text">         
          <label> Category of School </label> </small>
          <select name="catsc" value={catsc} onChange = {e => onChange(e)}>                           
              {CATEGORY_OF_SCHOOL.map((e,index) => <option key={index}> {e.label} </option>) }
          </select>
          </div>
          </div>
          <div className="profile-grid" style={{paddingLeft: "40px", fontWeight: "bold"}}>        
            
            <Table bordered variant="dark" style={{color: "green", fontSize: "11pt"}} >
            <thead>
              
              <tr style={{color: "black"}}>   
                <th scope="col"> Class </th>             
                <th scope="col"> Year of Passing </th> 
                <th scope="col"> Name of School </th>                
                             
                <th scope = "col" > District </th> 
                <th scope="col"> State </th>  

                <th scope="col"> School Block </th>  

                {sigsc.toString() === "Yes" && catsc.toString() === "State Govt" &&
                  <th scope="col"> School Type </th>  
                }
                
              </tr>
            </thead>
            <tbody>

            <tr key={"20"}>                       
              <td> <div className='form-control'> VI </div></td>
            <td>            
                <div >
                <select className='form-control' name="sy6" value={sy6} onChange = {e => onChange(e)} disabled = {true}>        
            {YEAR_OF_PASSING.map((e, ind) => <option key = {ind}> {e.label} </option>)}                               
                </select>
                </div>                      
            </td>

              <td>
                  <div >
                    {/* {alert(sb6.toString())}
                    {alert(SCHOOL_BY_BLOCK[BLOCKS.filter(op => op.label.toString() === sb6.toString()).map(tt => tt.key)[0]])} */}
                    {SCHOOL_BY_BLOCK[BLOCKS.filter(op => op.label.toString() === sb6.toString()).map((tt) => tt.key)[0]] !== undefined ? 
                    <select className='form-control' name="ssn6" value={ssn6}  onChange = {e => onChange(e)} disabled = {true}>       
                    {SCHOOL_BY_BLOCK[BLOCKS.filter(op => op.label.toString() === sb6.toString()).map(tt => tt.key)[0]].map((e, ind) => <option key={ind}>  {e.label} </option> )} </select>
                    : <input type="text" name="ssn6" value={ssn6}   onChange = {e => onChange(e)} />                                       
                    }
                    </div>            
              </td>

              <td>

              <div >
                <select className='form-control' name="sd6" value={sd6}  onChange = {e => onChange(e)} disabled = {true}>        
            {DIST_LIST.map((e, ind) => <option key={ind}> {e.label} </option>)}                               
                </select>
                </div>            
              </td>

              <td>
              <div >
                <select className='form-control' name="sst6" value={sst6}  onChange = {e => onChange(e)} disabled = {true}>       
            {STATES.map((e, ind) => <option key={ind}> {e.label} </option> )}                                
                </select>
                </div>            

              </td>

              <td>
                  <div >
                    {/* <input className='form-control' type="text" name="sb6" value={sb6} onChange = {e => onChange(e)}/>  */}
                
                        {/* {SCHOOL_BY_BLOCK[profile._sc.cVIb].map(e => <option key={index}> {e.label} </option> )}                                 */}
                        {BLOCKS_BY_DISTRICT[DIST_LIST.filter(ek => ek.label.toString() === sd6.toString()).map(ek => ek.key)[0]] !== undefined ? <select className='form-control' name="sb6" value={sb6}  onChange = {e => onChange(e)} disabled = {true}>       
                        {BLOCKS_BY_DISTRICT[DIST_LIST.filter(ek => ek.label.toString() === sd6.toString()).map(ek => ek.key)[0]].map((e, ind) => <option key={ind}> {e.label} </option> )} </select> :  <input type="text" name="sb6" value={sb6}   onChange = {e => onChange(e)} disabled = {true}/> }
                
                    </div>            
              </td>

            {sigsc.toString() === "Yes" && catsc.toString() === "State Govt" &&

              <td>              
                <select className='form-control' name="sst6st" value={sst6st} onChange = {e => onChange(e)} disabled = {true}>       
                    {GOVT_SCHOOL_TYPE.map((e, ind) => <option key={ind}> {e.label} </option> )}                                
                </select>               

              </td>
            }

            </tr>

            <tr key="2">                       
              <td> <div className='form-control'> VII </div></td>
            <td>            
                <div >
                <select className='form-control' name="sy7" value={sy7} onChange = {e => onChange(e)} disabled = {true}>        
            {YEAR_OF_PASSING.map((e, ind) => <option key = {ind}> {e.label} </option>)}                               
                </select>
                </div>                      
            </td>

            <td>
                  <div >
                    {/* <input className='form-control' type="text" name="ssn6" value={ssn6} onChange = {e => onChange(e)}/>  */}
                    {SCHOOL_BY_BLOCK[BLOCKS.filter(op => op.label.toString() === sb7.toString()).map(tt => tt.key)[0]] !== undefined ? 
                    <select className='form-control' name="ssn7" value={ssn7}  onChange = {e => onChange(e)} disabled = {true}>       
                    {SCHOOL_BY_BLOCK[BLOCKS.filter(op => op.label.toString() === sb7.toString()).map(tt => tt.key)[0]].map((e, ind) => <option key={ind}>  {e.label} </option> )} </select>
                    : <input type="text" name="ssn7" value={ssn7}   onChange = {e => onChange(e)} disabled = {true} />                                       
                    }
                    </div>            
              </td>

              <td>

              <div >
                <select className='form-control' name="sd7" value={sd7} onChange = {e => onChange(e)} disabled = {true}>        
            {DIST_LIST.map((e, ind) => <option key={ind}> {e.label} </option>)}                               
                </select>
                </div>            
              </td>

              <td>
              <div >
                <select className='form-control' name="sst7" value={sst7} onChange = {e => onChange(e)} disabled = {true}>       
            {STATES.map((e, ind) => <option key={ind}> {e.label} </option> )}                                
                </select>
                </div>            

              </td>

              <td>
                  <div >
                    {/* <input className='form-control' type="text" name="sb6" value={sb6} onChange = {e => onChange(e)}/>  */}
                   {/* {SCHOOL_BY_BLOCK[profile._sc.cVIb].map(e => <option key={index}> {e.label} </option> )}                                 */}
                   {BLOCKS_BY_DISTRICT[DIST_LIST.filter(ek => ek.label.toString() === sd7.toString()).map(ek => ek.key)[0]] !== undefined ? <select className='form-control' name="sb7" value={sb7}  onChange = {e => onChange(e)} disabled = {true}>       
                        {BLOCKS_BY_DISTRICT[DIST_LIST.filter(ek => ek.label.toString() === sd7.toString()).map(ek => ek.key)[0]].map((e, ind) => <option key={ind}> {e.label} </option> )} </select> :  <input type="text" name="sb7" value={sb7}   onChange = {e => onChange(e)} disabled = {true} /> }
                    </div>            
              </td>

              {sigsc.toString() === "Yes" && catsc.toString() === "State Govt" &&

              <td>              
                <select className='form-control' name="sst7st" value={sst7st} onChange = {e => onChange(e)} disabled = {true}>       
                    {GOVT_SCHOOL_TYPE.map((e, ind) => <option key={ind}> {e.label} </option> )}                                
                </select>               

              </td>
            }

            </tr>

          
            <tr key="3">                       
              <td> <div className='form-control'> VIII </div></td>
            <td>            
                <div >
                <select className='form-control' name="sy8" value={sy8}  onChange = {e => onChange(e)} disabled = {true}>        
            {YEAR_OF_PASSING.map((e, ind) => <option key = {ind}> {e.label} </option>)}                               
                </select>
                </div>                      
            </td>

            <td>
                  <div >
                    {/* <input className='form-control' type="text" name="ssn6" value={ssn6} onChange = {e => onChange(e)}/>  */}
                    {SCHOOL_BY_BLOCK[BLOCKS.filter(op => op.label.toString() === sb8.toString()).map(tt => tt.key)[0]] !== undefined ? 
                    <select className='form-control' name="ssn8" value={ssn8}  onChange = {e => onChange(e)} disabled = {true}>       
                    {SCHOOL_BY_BLOCK[BLOCKS.filter(op => op.label.toString() === sb8.toString()).map(tt => tt.key)[0]].map((e, ind) => <option key={ind}>  {e.label} </option> )} </select>
                    : <input type="text" name="ssn8" value={ssn8}   onChange = {e => onChange(e)} disabled = {true} />                                       
                    }

                    </div>            
              </td>

              <td>

              <div >
                <select className='form-control' name="sd8" value={sd8}   onChange = {e => onChange(e)} disabled = {true}>        
            {DIST_LIST.map((e, ind) => <option key={ind}> {e.label} </option>)}                               
                </select>
                </div>            
              </td>

              <td>
              <div >
                <select className='form-control' name="sst8" value={sst8}   onChange = {e => onChange(e)} disabled = {true}>       
            {STATES.map((e, ind) => <option key={ind}> {e.label} </option> )}                                
                </select>
                </div>            

              </td>

              <td>
                  <div >
                    {/* <input className='form-control' type="text" name="sb6" value={sb6} onChange = {e => onChange(e)}/>  */}
{/* {SCHOOL_BY_BLOCK[profile._sc.cVIb].map(e => <option key={index}> {e.label} </option> )}                                 */}
                    {BLOCKS_BY_DISTRICT[DIST_LIST.filter(ek => ek.label.toString() === sd8.toString()).map(ek => ek.key)[0]] !== undefined ? <select className='form-control' name="sb8" value={sb8}  onChange = {e => onChange(e)} disabled = {true}>       
                        {BLOCKS_BY_DISTRICT[DIST_LIST.filter(ek => ek.label.toString() === sd8.toString()).map(ek => ek.key)[0]].map((e, ind) => <option key={ind}> {e.label} </option> )} </select> :  <input type="text" name="sb8" value={sb8}   onChange = {e => onChange(e)} disabled = {true}/> }
                    </div>            
              </td>

              {sigsc.toString() === "Yes" && catsc.toString() === "State Govt" &&

              <td>
              <div >
                <select className='form-control' name="sst8st" value={sst8st}   onChange = {e => onChange(e)} disabled = {true}>       
              {GOVT_SCHOOL_TYPE.map((e, ind) => <option key={ind}> {e.label} </option> )}                                
                </select>
                </div>            

              </td>
              }
 
            </tr>

            <tr key="4">                       
              <td> <div className='form-control'> IX </div></td>
            <td>            
                <div >
                <select className='form-control' name="sy9" value={sy9}    onChange = {e => onChange(e)} disabled = {true}>        
            {YEAR_OF_PASSING.map((e, ind) => <option key = {ind}> {e.label} </option>)}                               
                </select>
                </div>                      
            </td>

            <td>
                  <div >
                    {/* <input className='form-control' type="text" name="ssn6" value={ssn6} onChange = {e => onChange(e)}/>  */}
                    {SCHOOL_BY_BLOCK[BLOCKS.filter(op => op.label.toString() === sb9.toString()).map(tt => tt.key)[0]] !== undefined ? 
                    <select className='form-control' name="ssn9" value={ssn9}  onChange = {e => onChange(e)} disabled = {true}>       
                    {SCHOOL_BY_BLOCK[BLOCKS.filter(op => op.label.toString() === sb9.toString()).map(tt => tt.key)[0]].map((e, ind) => <option key={ind}>  {e.label} </option> )} </select>
                    : <input type="text" name="ssn9" value={ssn9}   onChange = {e => onChange(e)} disabled = {true}/>                                       
                    }
                    </div>            
              </td>

              <td>

              <div >
                <select className='form-control' name="sd9" value={sd9}   onChange = {e => onChange(e)} disabled = {true}>        
            {DIST_LIST.map((e, ind) => <option key={ind}> {e.label} </option>)}                               
                </select>
                </div>            
              </td>

              <td>
              <div >
                <select className='form-control' name="sst9" value={sst9}   onChange = {e => onChange(e)} disabled = {true}>       
                  {STATES.map((e, ind) => <option key={ind}> {e.label} </option> )}                                
                </select>
                </div>            

              </td>

              <td>
                  <div >
                {/* {SCHOOL_BY_BLOCK[profile._sc.cVIb].map(e => <option key={index}> {e.label} </option> )}                                 */}
                    {BLOCKS_BY_DISTRICT[DIST_LIST.filter(ek => ek.label.toString() === sd9.toString()).map(ek => ek.key)[0]] !== undefined ? <select className='form-control' name="sb9" value={sb9}  onChange = {e => onChange(e)} disabled = {true}>       
                        {BLOCKS_BY_DISTRICT[DIST_LIST.filter(ek => ek.label.toString() === sd9.toString()).map(ek => ek.key)[0]].map((e, ind) => <option key={ind}> {e.label} </option> )} </select> :  <input type="text" name="sb9" value={sb9}   onChange = {e => onChange(e)} disabled = {true}/> }
                    </div>            
              </td>
              {sigsc.toString() === "Yes" && catsc.toString() === "State Govt" &&

              <td>
              <div >
                <select className='form-control' name="sst9st" value={sst9st}   onChange = {e => onChange(e)} disabled = {true}>       
              {GOVT_SCHOOL_TYPE.map((e, ind) => <option key={ind}> {e.label} </option> )}                                
                </select>
                </div>            

              </td>
              }
            </tr>

            <tr key="5">                       
              <td> <div className='form-control'> X </div></td>
            <td>            
                <div >
                <select className='form-control' name="sy10" value={sy10}   onChange = {e => onChange(e)} disabled = {true}>        
            {YEAR_OF_PASSING.map((e, ind) => <option key = {ind}> {e.label} </option>)}                               
                </select>
                </div>                      
            </td>

            <td>
                  <div >
                  {SCHOOL_BY_BLOCK[BLOCKS.filter(op => op.label.toString() === sb10.toString()).map(tt => tt.key)[0]] !== undefined ? 
                    <select className='form-control' name="ssn10" value={ssn10}  onChange = {e => onChange(e)} disabled = {true}>       
                    {SCHOOL_BY_BLOCK[BLOCKS.filter(op => op.label.toString() === sb10.toString()).map(tt => tt.key)[0]].map((e, ind) => <option key={ind}>  {e.label} </option> )} </select>
                    : <input type="text" name="ssn10" value={ssn10}   onChange = {e => onChange(e)} disabled = {true} />                                       
                    }

                    </div>            
              </td>
              <td>

              <div >
                <select className='form-control' name="sd10" value={sd10}   onChange = {e => onChange(e)} disabled = {true}>        
            {DIST_LIST.map((e, ind) => <option key={ind}> {e.label} </option>)}                               
                </select>
                </div>            
              </td>

              <td>
              <div >
                <select className='form-control' name="sst10" value={sst10}   onChange = {e => onChange(e)} disabled = {true}>       
            {STATES.map((e, ind) => <option key={ind}> {e.label} </option> )}                                
                </select>
                </div>            

              </td>


              <td>
                  <div >
                   
                  {BLOCKS_BY_DISTRICT[DIST_LIST.filter(ek => ek.label.toString() === sd10.toString()).map(ek => ek.key)[0]] !== undefined ? <select className='form-control' name="sb10" value={sb10}  onChange = {e => onChange(e)} disabled = {true}>       
                        {BLOCKS_BY_DISTRICT[DIST_LIST.filter(ek => ek.label.toString() === sd10.toString()).map(ek => ek.key)[0]].map((e, ind) => <option key={ind}> {e.label} </option> )} </select> :  <input type="text" name="sb10" value={sb10}   onChange = {e => onChange(e)} disabled = {true} /> }
                    </div>            
              </td>
              {sigsc.toString() === "Yes" && catsc.toString() === "State Govt" &&

              <td>
              <div >
                <select className='form-control' name="sst10st" value={sst10st}   onChange = {e => onChange(e)} disabled = {true}>       
              {GOVT_SCHOOL_TYPE.map((e, ind) => <option key={ind}> {e.label} </option> )}                                
                </select>
                </div>            

              </td>
              }

            </tr>

            <tr key="80">                       
              <td> <div className='form-control'> XI </div></td>
            <td>            
                <div >
                <select className='form-control' name="sy11" value={sy11}   onChange = {e => onChange(e)} disabled = {true}>        
            {YEAR_OF_PASSING.map((e, ind) => <option key = {ind}> {e.label} </option>)}                               
                </select>
                </div>                      
            </td>

            <td>
                  <div >
                  {SCHOOL_BY_BLOCK[BLOCKS.filter(op => op.label.toString() === sb11.toString()).map(tt => tt.key)[0]] !== undefined ? 
                    <select className='form-control' name="ssn11" value={ssn11}  onChange = {e => onChange(e)} disabled = {true}>       
                    {SCHOOL_BY_BLOCK[BLOCKS.filter(op => op.label.toString() === sb11.toString()).map(tt => tt.key)[0]].map((e, ind) => <option key={ind}>  {e.label} </option> )} </select>
                    : <input type="text" name="ssn11" value={ssn11}   onChange = {e => onChange(e)} disabled = {true}/>                                       
                    }
                    </div>            
              </td>
              <td>

              <div >
                <select className='form-control' name="sd11" value={sd11}   onChange = {e => onChange(e)} disabled = {true}>        
            {DIST_LIST.map((e, ind) => <option key={ind}> {e.label} </option>)}                               
                </select>
                </div>            
              </td>

              <td>
              <div >
                <select className='form-control' name="sst11" value={sst11}   onChange = {e => onChange(e)} disabled = {true}>       
            {STATES.map((e, ind) => <option key={ind}> {e.label} </option> )}                                
                </select>
                </div>            

              </td>

              <td>
                  <div >
                  {BLOCKS_BY_DISTRICT[DIST_LIST.filter(ek => ek.label.toString() === sd11.toString()).map(ek => ek.key)[0]] !== undefined ? <select className='form-control' name="sb11" value={sb11}  onChange = {e => onChange(e)} disabled = {true}>       
                        {BLOCKS_BY_DISTRICT[DIST_LIST.filter(ek => ek.label.toString() === sd11.toString()).map(ek => ek.key)[0]].map((e, ind) => <option key={ind}> {e.label} </option> )} </select> :  <input type="text" name="sb11" value={sb11}   onChange = {e => onChange(e)} disabled = {true}/> }
                    </div>            
              </td>
              {sigsc.toString() === "Yes" && catsc.toString() === "State Govt" &&

              <td>
              <div >
                <select className='form-control' name="sst11st" value={sst11st}   onChange = {e => onChange(e)} disabled = {true}>       
              {GOVT_SCHOOL_TYPE.map((e, ind) => <option key={ind}> {e.label} </option> )}                                
                </select>
                </div>            

              </td>
              }

            </tr>

            <tr key="7">                       
              <td> <div className='form-control'> XII </div></td>
            <td>            
                <div >
                <select className='form-control' name="sy12" value={sy12}   onChange = {e => onChange(e)} disabled = {true}>        
            {YEAR_OF_PASSING.map((e, ind) => <option key = {ind}> {e.label} </option>)}                               
                </select>
                </div>                      
            </td>

            <td>
                  <div >
                  {SCHOOL_BY_BLOCK[BLOCKS.filter(op => op.label.toString() === sb12.toString()).map(tt => tt.key)[0]] !== undefined ? 
                    <select className='form-control' name="ssn12" value={ssn12}  onChange = {e => onChange(e)} disabled = {true}>       
                    {SCHOOL_BY_BLOCK[BLOCKS.filter(op => op.label.toString() === sb12.toString()).map(tt => tt.key)[0]].map((e, ind) => <option key={ind}>  {e.label} </option> )} </select>
                    : <input type="text" name="ssn12" value={ssn12}   onChange = {e => onChange(e)} disabled = {true}/>                                       
                    }
                    </div>            
              </td>

              <td>

              <div >
              <select className='form-control' name="sd12" value={sd12}   onChange = {e => onChange(e)} disabled = {true}>        
            {DIST_LIST.map((e, ind) => <option key={ind}> {e.label} </option>)}                               
                </select>
                </div>            
              </td>

              <td>
              <div >
                <select className='form-control' name="sst12" value={sst12}   onChange = {e => onChange(e)} disabled = {true}>       
            {STATES.map((e, ind) => <option key={ind}> {e.label} </option> )}                                
                </select>
                </div>            

              </td>

              <td>
                  <div >
                  {BLOCKS_BY_DISTRICT[DIST_LIST.filter(ek => ek.label.toString() === sd12.toString()).map(ek => ek.key)[0]] !== undefined ? <select className='form-control' name="sb12" value={sb12}  onChange = {e => onChange(e)} disabled = {true}>       
                        {BLOCKS_BY_DISTRICT[DIST_LIST.filter(ek => ek.label.toString() === sd12.toString()).map(ek => ek.key)[0]].map((e, ind) => <option key={ind}> {e.label} </option> )} </select> :  <input type="text" name="sb12" value={sb12}   onChange = {e => onChange(e)} disabled = {true}/> }
                
                    </div>            
              </td>
              {sigsc.toString() === "Yes" && catsc.toString() === "State Govt" &&

              <td>
              <div >
                <select className='form-control' name="sst12st" value={sst12st}   onChange = {e => onChange(e)} disabled = {true}>       
              {GOVT_SCHOOL_TYPE.map((e, ind) => <option key={ind}> {e.label} </option> )}                                
                </select>
                </div>            

              </td>
              }
            </tr>


            </tbody> </Table> </div> 

          


        <div className='profile-top'>
          <button
            onClick={() => toggleSpecialInputs(!displaySpecialInputs)}
            type='button'
            className='btn btn-dark'
          >
            Click here to Change Community 
          </button>          
        </div>

      {displaySpecialInputs && 

          <div className="grid-2 my-4" style={{border: "1px solid"}}>                                     

          <div className="form-group ">
          <small className="form-text">         
          <label> Community </label> </small>
          <select name="co" value={co} onChange = {e => onChange(e)}>                           
              {COMMUNITY.map((e,index) => <option key={index}> {e.label} </option>) }
          </select>
          </div>

          <div className="form-group " >
          <small className="form-text">         
          <label> Caste </label> </small>
          <select name="cas" value={cas} onChange = {e => onChange(e)} class='myselect'> 
              { CASTE[COMMUNITY.filter(e => e.label === co).map(e => e.key)[0]].map((t,index) => <option key={index}> {t.label} </option>) }            
          </select>
          </div>            

          
      
      </div>
      
      }
            

            {(
           
           (((profile._p.co.toString() === "SC") || (profile._p.co.toString() === "ST") || (profile._p.co.toString() === "SCA"))
           &&
           ((co.toString() === "BC") || (co.toString() === "MBC & DNC") || (co.toString() === "OC") || (co.toString() === "BCM")))            
           ) ?
          <div>
          <div className="badge2 but-center">
          <h4 style={{color: "black", fontWeight: "bold", textAlign: "center"}}> **Please Enter Demand Draft Details** </h4>
          </div>
          
          <div className="my-2 grid-4" style={{border: "1px solid grey"}}>
          
                    <div className="form-group">
                    <small className="form-text">         
                      <label> DD Number </label> </small>
                      <input type="number" name="ddno" value={ddno} style={{fontSize: "15pt"}} onChange = {e => onChange(e)}/>                                       
                    </div>


                    <div className="form-group">
                    <small className="form-text">         
                      <label> Amount </label> </small>
                          <input type="number" name="amt" value={amt} style={{fontSize: "15pt"}} onChange = {e => onChange(e)}/>                                       
                    </div>

                    <div className="form-group">
                    <small className="form-text">         
                      <label> Bank Name </label> </small>
                          <input type="text" name="bn" value={bn} onChange = {e => onChange(e)}/>                                       
                    </div>

                    <div className="form-group">
                    <small className="form-text">         
                      <label> Branch </label> </small>
                          <input type="text" name="br" value={br} onChange = {e => onChange(e)}/>                                       
                    </div>

                  <div className="form-group">
                  <small className="form-text">         
                  <label> Drawn Date </label>  </small>  
                      <div className="col-10">
                      <input type="date"  style={{color: "darkblue", fontWeight: "bold"}}                 
                                    onChange={ e => handledddateChange(e) }        
                                    name="ddd"                                                    
                                    value={ddd}     
                                    id = "ddd"                     
                        />          
                      </div> 
                  </div>    

          </div>
          
          </div>
          : null }


            <div className= "my-2 container" >
            <input type="submit" className="btn btn-danger" value="Save Changes" onClick= {e => onVerify(e)} />  
            <input type="submit"  value="Cancel" className="btn btn-success" onClick= {e => clearProf(e)} />  
            </div>

            </div>

            

            )}
        
          
            </form>  

        </Fragment> ) 
    }

<form>
        <div className="container profile-top" style={{fontWeight: 'bold', background: 'lightgrey', textAlign: "center"}}>
        <h5 style={{fontWeight: "bold", color: "black"}}>                  
              Updation Status
        </h5>
        </div>

        <div className="profile-top" style={{fontWeight: 'bold', background: 'white', fontSize: "8pt"}}>
        <h5 style={{fontWeight: "bold", color: "black"}}>                  
              List of Certificates Verified
        </h5>
        </div>

        <div className="grid-6" style={{textAlign: "center", color: "crimsongold", border: "1px solid", margin: "40px"}}>
        <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" name="ux" checked={ux} value="ux" onChange={() => {setFormData({ ...formData, ux: !ux }); }}   />
            <label class="form-check-label" htmlFor="inlineCheckbox1">X</label>
        </div>
        <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" name="uxi" checked={uxi} value="uxi" onChange={() => {setFormData({ ...formData, uxi: !uxi }); }}/>
            <label class="form-check-label" htmlFor="inlineCheckbox1">XI</label>
        </div>
        <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" name="uxii" checked={uxii} value="uxii" onChange={() => {setFormData({ ...formData, uxii: !uxii }); }}/>
            <label class="form-check-label" htmlFor="inlineCheckbox1">XII</label>
        </div>
       
        <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" name="ucom" checked={ucom} value="ucom" onChange={() => {setFormData({ ...formData, ucom: !ucom }); }}/>
            <label class="form-check-label" htmlFor="inlineCheckbox1">Community</label>
        </div>
        <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" name="unat" checked={unat} value="unat" onChange={() => {setFormData({ ...formData, unat: !unat }); }}/>
            <label class="form-check-label" htmlFor="inlineCheckbox1">Nativity</label>
        </div>
        
        <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" name="uex" checked={uex} value="uex" onChange={() => {setFormData({ ...formData, uex: !uex }); }}/>
            <label class="form-check-label" htmlFor="inlineCheckbox1">Ex-Serviceman</label>
        </div>
        <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" name="udap" checked={udap} value="udap" onChange={() => {setFormData({ ...formData, udap: !udap }); }}/>
            <label class="form-check-label" htmlFor="inlineCheckbox1">Differently-abled</label>
        </div>
        <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" name="uelt" checked={uelt} value="uelt" onChange={() => {setFormData({ ...formData, uelt: !uelt }); }}/>
            <label class="form-check-label" htmlFor="inlineCheckbox1">Eligibility Type</label>
        </div>

        <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" name="fgc" checked={fgc} value="fgc" onChange={() => {setFormData({ ...formData, fgc: !fgc }); }}/>
            <label class="p-1 form-check-label" htmlFor="inlineCheckbox1">First Graduate</label>
        </div>

        <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" name="inc" checked={inc} value="inc" onChange={() => {setFormData({ ...formData, inc: !inc }); }}/>
            <label class="p-1 form-check-label" htmlFor="inlineCheckbox1">Income</label>
        </div>
        <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" name="slrc" checked={slrc} value="slrc" onChange={() => {setFormData({ ...formData, slrc: !slrc }); }}/>
            <label class="p-1 form-check-label" htmlFor="inlineCheckbox1">SL Refugee</label>
        </div>
        <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" name="noctup" checked={noctup} value="noctup" onChange={() => {setFormData({ ...formData, noctup: !noctup }); }}/>
            <label class="p-1 form-check-label" htmlFor="inlineCheckbox1">No Certificates Uploaded</label>
        </div>

        </div>
        </form>

        <form className='form' style={{margin: "40px"}}>
        
        <div className="form-text text-center" style={{padding: "10px", fontWeight: 'bold', color: "blue", textAlign: ""}}> Enter Updation Comments </div>           
          <textarea placeholder="Enter Updation comments here" cols="80" rows="5" className="textarea2" name="uremarks" value={uremarks} onChange = {e => onChange(e)}/>
        
        <div className="form-text text-center" style={{padding: "10px", fontWeight: 'bold', color: "blue", textAlign: ""}}> Name of Updation officer </div>     
          <textarea placeholder="Enter Name of Updation officer" cols="80" rows="2" className="textarea2" name="uoname" value={uoname} onChange = {e => onChange(e)}/>
          
        </form>


        <div className="my-2" style={{alignItems: "center", textAlign: "center", justifyContent: "center"}}>                 
        <input type='submit' className='btn btn-success' onClick={ e => onComplete(e) } value="All Details found Correct"/>
        <input type="submit"  value="Cancel" className="btn btn-dark" onClick= {e => clearProf(e)} />  
        <input type='submit' className='btn btn-danger' onClick={ e => onIncomplete(e) } value="InComplete (Enable Upload for Candidate)"/>                         
        </div>

        <h5 className='my-4'></h5>
        <h5 className='my-4'></h5>

    </Fragment>       
    
  );
}

EditCand.propTypes = {
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  
  getProfileAdmin: PropTypes.func.isRequired,  
  clearApplication: PropTypes.func.isRequired,

  unFreezeAdmin: PropTypes.func.isRequired,
  changeAppStatus: PropTypes.func.isRequired,
  unFreezeUpload: PropTypes.func.isRequired,
  adminchangegriev: PropTypes.func.isRequired,
  updateCV: PropTypes.func.isRequired,
  getProfileUpd: PropTypes.func.isRequired,
  getProfilePdf: PropTypes.func.isRequired,

  gettfc: PropTypes.func.isRequired,
  listAll: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  profile: state.profile,
});

export default connect(
  mapStateToProps,
  { getProfileAdmin, clearApplication,  unFreezeAdmin, changeAppStatus, unFreezeUpload, adminchangegriev, updateCV, getProfileUpd,
  getProfilePdf, gettfc, listAll}
)(EditCand);
