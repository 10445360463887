import React from 'react';
import { Link, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import tnimage from '../../utils/tnimage.png';

const Landing = ({ isAuthenticated }) => {
  if (isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <section className="landing" >
      <div style={{color: "crimson"}}>
        <div className="landing-inner" style={{paddingTop: "100px"}}>
        <img src={tnimage} style={{height: "100px", width: "auto"}} alt="tn"/>
        <br/>
            <h2 className="" style={{color: "blue", fontWeight: "bold" }}>DIRECTORATE OF TECHNICAL EDUCATION</h2>
            <h3 className="" style={{color: "brown", fontWeight: "bold"}}>TRANSFER COUNSELLING 2024</h3>
          
          <p className="lead" style={{color: "navy", fontWeight: "bold"}}>
           Principal Approval System
          </p>
          <div className="buttons">
            {/* <Link to="/register" className="btn btn-primary">
              Sign Up
            </Link> */}
            <Link to="/login" className="btn btn-white">
              Login
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

Landing.propTypes = {
  isAuthenticated: PropTypes.bool
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps)(Landing);
