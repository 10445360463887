import {
    GET_ALLCANDS, ALLCANDS_ERROR, GET_CAND, ADD_CAND, CLEAR_ALLCANDS, 
    GET_REPORT, CLEAR_REPORT, SET_COUNT, SET_ALLOTED, SET_DBCALL, RESET_LOADING, RESET_DBCALL, SET_LOADING, GET_FILELIST, CLEAR_FILELIST, GET_DLINK,
  } from '../actions/types';

const initialState = {
    candprofiles: null,    
    loading: true,
    error: {},
    reports: null,
    count: null,
    alloted: null,
    getdb: false,
    flist: null,
    dlink: null
};

export default function(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case SET_COUNT:
            return {
                ...state, count: payload
            };
        case SET_ALLOTED:
            return {
                ...state, alloted: payload
            };
        
            case SET_DBCALL:
                return {
                    ...state, getdb: true
                };

                case RESET_DBCALL:
                    return {
                        ...state, getdb: false, loading: true
                    };

                    case SET_LOADING:
                        return {
                            ...state, loading: true
                        };

                case RESET_LOADING:
                    return {
                        ...state, loading: false
                    };
    
                    case GET_FILELIST:
                return {
                    ...state,
                    flist: payload, loading: false
                };

                case GET_DLINK:
                    return {
                        ...state,
                        dlink: payload, loading: false
                    };
    
    
            case CLEAR_FILELIST:
                return {
                    ...state,
                    flist: [],
                    dlink: null
                };
        case GET_ALLCANDS:
            return {
                ...state,
                candprofiles: payload,
                loading: false
            };
        case CLEAR_ALLCANDS:
            return {
                ...state,
                candprofiles: null,
                loading: false,
                error: null,
                flist: [],
                dlink: null
            };
        case GET_CAND:
            return {
                ...state,
                candprofile: payload,
                loading: false
            };
        case GET_REPORT:
            return {
                    ...state,
                    reports: [payload, ...state.reports],
                    loading: false
                };
        
        case CLEAR_REPORT:
            return {
                    ...state,
                    reports: null,
                    loading: false
            };
    
        case ADD_CAND:
            return {
                ...state,
                candprofiles: [payload, ...state.candprofiles]
            };
        case ALLCANDS_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            };
        
        default: 
            return state;
    };
};