import React, { useState, useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { login, getClientIp } from '../../actions/auth';

const Login = ({ login, isAuthenticated, getClientIp, ipaddr }) => {
  const [formData, setFormData] = useState({
    username: '',
    password: ''
  });

  useEffect(() => {
    getClientIp();
  }, [getClientIp]);

  const { username, password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    console.log(username, password);
    login(username, password);
  };

  if (isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <section className="container">
      
      <p className="lead">
        <i className="fas fa-user" /> Principal Sign In
      </p>
      
      <form className="form" onSubmit={onSubmit}>
        <div className="form-group">
          <input
            type="text"
            placeholder="Enter Username"
            name="username"
            value={username}
            onChange={onChange}
          />
        </div>
        <div className="form-group">
          <input
            type="password"
            placeholder="Password"
            name="password"
            value={password}
            onChange={onChange}
            minLength="6"
          />
        </div>
        <input type="submit" className="btn btn-dark" value="Login" />
      </form>
       <p className="my-1">
       Your IP Address : {ipaddr}
       </p> 
    </section>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  getClientIp: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  

};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  ipaddr: state.auth.ipaddr,
});

export default connect(mapStateToProps, { login, getClientIp })(Login);
