import React, { Fragment, useState } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import moment from 'moment';
import Pagination from './Pagination';

import {Table} from 'reactstrap';
import {CV_SLOTS, DIST_LIST, STATES} from '../../Constant';


import { getLogs, getDDs, getLogins, getApplnsPend, getApplnsTot, getApplnsPro, getApplnsCompl, getfreezCompl, getdpiCompl, getadminCompl } from '../../actions/report';

import {getsharedfiles, getdownfile} from '../../actions/dload';

import { YES_NO_ARRAY } from '../../Constant';
import { TRANS_COLLEGES_LIST } from '../../Constant2';

//import {TFC_CENTER} from './../../Constant';


const ReportDash = ({
  getLogs, getDDs, getLogins, getApplnsTot, getApplnsPro, getApplnsPend, getApplnsCompl, getsharedfiles, getdownfile, getfreezCompl, getdpiCompl, getadminCompl,
  auth: { user },
  history,
  rank: {candprofiles, loading, getdb, dlink},
  allapplns
}) => {


  let tty = 0;

   //const [posts, setPosts] = useState(filtered);
const [currentPage, setCurrentPage] = useState(1);
const [postsPerPage] = useState(100);

var indexoflastpost = currentPage * postsPerPage;
var indexoffirstpost = indexoflastpost - postsPerPage;
var currentList;
if(candprofiles !== null && candprofiles.allapplns !== undefined && candprofiles.allapplns !== null )
  if(candprofiles.allapplns.length !== 0) 
      currentList = candprofiles.allapplns.slice(indexoffirstpost, indexoflastpost);

if(candprofiles !== null && candprofiles.allapps !== undefined && candprofiles.allapps !== null)
  if(candprofiles.allapps.length !== 0) 
      currentList = candprofiles.allapps.slice(indexoffirstpost, indexoflastpost);


  if(candprofiles !== null && candprofiles.cvcompl !== undefined && candprofiles.cvcompl !== null)
      if(candprofiles.cvcompl.length !== 0) 
          currentList = candprofiles.cvcompl.slice(indexoffirstpost, indexoflastpost);

  if(candprofiles !== null && candprofiles.cvall !== undefined && candprofiles.cvall !== null)
          if(candprofiles.cvall.length !== 0) 
              currentList = candprofiles.cvall.slice(indexoffirstpost, indexoflastpost);


    
  if(candprofiles !== null && candprofiles.alis !== undefined && candprofiles.alis !== null)
              if(candprofiles.alis.length !== 0) 
                  currentList = candprofiles.alis.slice(indexoffirstpost, indexoflastpost);

  if(candprofiles !== null && candprofiles.freez !== undefined && candprofiles.freez !== null)
              if(candprofiles.freez.length !== 0) 
                  currentList = candprofiles.freez.slice(indexoffirstpost, indexoflastpost);
  
  if(candprofiles !== null && candprofiles.admins !== undefined && candprofiles.admins !== null)
              if(candprofiles.admins.length !== 0) 
                  currentList = candprofiles.admins.slice(indexoffirstpost, indexoflastpost);

  if(candprofiles !== null && candprofiles.dpierr !== undefined && candprofiles.dpierr !== null)
              if(candprofiles.dpierr.length !== 0) 
                  currentList = candprofiles.dpierr.slice(indexoffirstpost, indexoflastpost);

        
const paginate = pageNumber => setCurrentPage(pageNumber);

  const getallLogs = async e => {
    e.preventDefault();     
    
    await getLogs(user.tfccode);        
    
    setCurrentPage(1);
  };

  const sharedfiles = async e => {
    e.preventDefault();

    await getsharedfiles(user.tfccode);
  }

  const getallLogins = async e => {
    e.preventDefault();     
    
    await getLogins(user.tfccode);    

    setCurrentPage(1);
  };

  function inctty(tot) {
    tty += tot;
  }

  const onDDReport = async e => {
    e.preventDefault();     
    
    await getDDs(user.tfccode);    
    setCurrentPage(1);
  };

  const onAppList = async e => {
    e.preventDefault();     
    
    //console.log("Appln number : "+ formData.gappid);         
    
    await getApplnsTot(user.tfccode);    
    setCurrentPage(1);
  };

  /*const onDownRep = async e => {
    e.preventDefault();

    //console.log("redirecting to download report");
    history.push('/repdown');
    
  };*/


  const cvpending = async e => {
    e.preventDefault();     
    
    //console.log("Appln number : "+ formData.gappid);         
    
    await getApplnsPend(user.tfccode);        
    setCurrentPage(1);
  };

  const cvcomplete = async e => {
    e.preventDefault();     
    
    //console.log("Appln number : "+ formData.gappid);         
    
    await getApplnsCompl(user.tfccode);    
    setCurrentPage(1);
  };

  const adminCandidates = async e => {
    e.preventDefault();     
    
    //console.log("Appln number : "+ formData.gappid);         
    
    await getadminCompl(user.tfccode);    
    setCurrentPage(1);
  };


  const freezCandidates = async e => {
    e.preventDefault();     
    
    //console.log("Appln number : "+ formData.gappid);         
    
    await getfreezCompl(user.tfccode);    
    setCurrentPage(1);
  };

  const DPICandidates = async e => {
    e.preventDefault();     
    
    //console.log("Appln number : "+ formData.gappid);         
    
    await getdpiCompl(user.tfccode);    
    setCurrentPage(1);
  };

  const handleDownRow = async (e, i) => {
    e.preventDefault();
    //console.log("downloading "+ i);
    var temp = i.toString();
    //console.log(temp.split('/').pop());
    const tobesent = {};
    tobesent.tfccode = user.tfccode;
    tobesent.gfile = temp;
    await getdownfile(tobesent);
  };


  const cvprogress = async e => {
    e.preventDefault();     
    
    //console.log("Appln number : "+ formData.gappid);         
    
    await getApplnsPro(user.tfccode);    
    setCurrentPage(1);
  };

  return (    
    <section className='container'>
    <Fragment>
       {user === null ? (
      <Spinner /> ) : ( 
      <>
            
      <div className="container badge form text-dark" style={{textAlign: 'center', fontWeight: 'bold', color: 'brown'}}>
        
        <h3 style={{fontWeight: "bold"}}> REPORTS </h3>
      </div>     
                                      
                <div className="">
                
                {/* <button type='submit' className='btn btn-primary' onClick={ e => getallLogins(e) } value="">Retrieve Logins </button>
                <button type="submit" className="btn btn-dark" onClick={ e => getallLogs(e) } value="" > Daily report   </button>                    */}
               
                <input type='submit' className='btn btn-success' onClick={ e => onAppList(e) } value="Insitution wise Summary"/>
               
                {/* <input type='submit' className='btn btn-success' onClick={ e => cvprogress(e) } value="CV Progress"/>
                <input type='submit' className='btn btn-primary' style={{backgroundColor: "darkgoldenrod", color: "white"}} onClick={ e => cvcomplete(e) } value="CV Completed"/>                */}
                </div>
      </> ) }
    
  {loading === true && getdb === true ? (
        <Spinner /> ) : (
  <>                           
    <div className="grid-1">   

    {candprofiles !== null  ? (

    <Fragment>   

      {candprofiles.flist && candprofiles.flist.length > 0 ?
      <>
      <h1 className="badge"> List of Files. Click the Filename to get download link. </h1>
      <div style={{paddingTop: "70px"}}></div>
            <div className="grid-5">
            {candprofiles.flist.map((cert, i) => (

            cert.Size > 0 &&
            <div className="" key={i}>            
            <span> {i}. </span>    

            <span>              
            <button className="btn btn-dark" style={{color: "black", backgroundColor: "lightblue", fontSize: "10pt"}} onClick={(e) => handleDownRow(e, cert.Key)} > {cert.Key.split('/').pop()} </button> 
            
            </span>
              </div>

            ))}
            </div>

            <div style={{paddingTop: "30px"}}></div>

            <div className="grid" style={{textAlign: "center", fontWeight: "bold"}}>
            <a target="_blank" rel="noopener noreferrer"  
                      href = {dlink !== null && dlink} > {dlink !== null && 'Click here to Download the File'} </a>
            </div>
            


      </> : ''
      }


      {candprofiles.allapplns && candprofiles.allapplns.length > 0 ? 
      <>        
        <div className="table-responsive">    
          <div className='my-2 profile2'>
          <h4> Total Applications Received: <span style={{color: "navy", fontWeight: "bold"}}> {candprofiles.tpcnt && candprofiles.tpcnt.total}</span> </h4>
          </div>    
        <table className="table table-striped" bordered variant="dark" style={{fontSize: "10pt"}} >
            <thead >
              
              <tr className="" style={{fontWeight: "bolder", color: "red"}}>                
                <th scope="col">Sl.No</th>
                <th scope="col">College Code</th>
                <th scope="col">College Name</th>
                <th scope="col">No. of Faculty Applied</th>                
              </tr>
            </thead>
            <tbody>

          {currentList.map((profile, ind) => (
          
              <tr key={profile._id} style={{color: "black", fontWeight: "bold"}}>                
                <td> {ind+1} </td>
                <td> {profile._id} </td>
                <td> 
                {TRANS_COLLEGES_LIST.filter(e => e.key === profile._id).map(e => e.label)}
                </td>
                <td style={{color: "blue"}}> {profile.count} </td>
              </tr>
        )) }
          
          </tbody>
          </table>
            
          {candprofiles && candprofiles.allapplns &&
            <>
            <div className="p-2" />
            <div className="" style={{color: "teal", textAlign: "center", fontSize: "9pt"}}>Pages</div>
            <div className="p-1" />
            <Pagination postsPerpage={postsPerPage} totalPosts={Math.ceil(candprofiles.allapplns.length / postsPerPage)}  paginate={paginate} />
            </>
          }

          </div>

        </>

       : candprofiles && candprofiles.allapplns && candprofiles.allapplns.length === 0 && <div className="p-2 text-center">  No Applications Present </div>  }

{candprofiles.allapps && candprofiles.allapps.length > 0 ? 
      <>        
        <div className="container" >        
        <h5 className="" style={{color: "darkgreen", textAlign: "center"}}> Complete List of Candidates alloted to this TFC </h5> 
        <table className="table" bordered variant="dark" style={{fontSize: "11pt"}} >
            <thead>
              
              <tr className="text-primary" style={{fontWeight: "bolder"}}>                
                <th scope="col">Sl.No</th>
                <th scope="col">Appln. Number</th>
                <th scope="col">Verification Login</th>
                <th scope="col">Name of the Candidate</th>                
             
                <th scope="col">Mobile Number</th>                                
                <th scope="col"> Upload Status </th>
                <th scope="col"> CV Date </th>
                <th scope="col"> CV Slot </th>
                
              </tr>
            </thead>
            <tbody>

          {currentList.map((profile, i) => (
          
              <tr key={profile._aid} style={{color: "black", fontWeight: "bold"}}>                
                <td> {i+1} </td>
                <td> {profile._aid} </td>
                <td> {profile.cv && profile.cv.vo} </td>
                <td> {profile._p._n} </td>                
             
                <td> {profile.m} </td>                
                <td style={{color: "crimson"}}> {profile._docs && profile._docs.length > 0 ? <span style={{color: "blue"}} > Yes </span> : <span style={{color: "crimson"}} > No </span>} </td>
                <td> {profile.cv && profile.cv.d && profile.cv.d !== null && profile.cv.d !== undefined ? profile.cv.d : ''} </td> 
                <td> {profile.cv && profile.cv.s && profile.cv.s !== null && profile.cv.s !== undefined ? CV_SLOTS.filter(e => e.key === profile.cv.s).map(e => e.label): ''} </td> 

              </tr>
        )) }
          
          </tbody>
          </table>

          {candprofiles && candprofiles.allapps &&
            <>
            <div className="p-2" />
            <div className="" style={{color: "teal", textAlign: "center", fontSize: "9pt"}}>Pages</div>
            
            <Pagination postsPerpage={postsPerPage} totalPosts={Math.ceil(candprofiles.allapps.length / postsPerPage)}  paginate={paginate} />
            </>
          }


         
          </div>

        </>

       : candprofiles && candprofiles.allapps && candprofiles.allapps.length === 0 &&  <div className="p-2 text-center">  No Candidates Present </div>  }  

    {candprofiles.cvcompl && candprofiles.cvcompl.length > 0 ? 
      <>        
        <div className="container" >        
        <h5 className="text-danger" style={{textAlign: "center"}}> CV Completed </h5> 
        <Table className="table" bordered variant="dark" style={{fontSize: "10pt"}} >
            <thead>
              
              <tr className="text-primary" style={{fontWeight: "bolder"}}>                
                <th scope="col">Sl.No</th>
                <th scope="col">Appln. Number</th>
                <th scope="col">Name of the Candidate</th>                
                <th scope="col">Community</th>
                {/* <th scope="col">Sports</th>
                <th scope="col">ExServiceman</th>
                <th scope="col">Differently abled</th> */}
                <th scope="col">First Graduate</th>
                <th scope="col">Actual CV Date</th>
                <th scope="col">Last Updated on </th>
                <th scope="col"> CV Status </th>
              </tr>
            </thead>
            <tbody>

          {currentList.map((profile, i) => (
          
              <tr key={profile._aid} style={{color: "black", fontWeight: "bold"}}>                
                <td> {i+1} </td>
                <td> {profile._aid} </td>
                <td> {profile._p._n} </td>                
                <td> {profile._p.co} </td>
                {/* <td> {profile._sr.esp === 'Y' ? 'Yes': '-'} </td>
                <td> {profile._sr.ex === 'Y' ? 'Yes': '-'} </td>
                <td> {profile._sr.dap === 'Y' ? 'Yes': '-'} </td> */}
                <td> {profile._sp  && profile._sp.fgtfc !== undefined ? YES_NO_ARRAY.filter(e => e.key.toString() === profile._sp.fgtfc.toString()).map(e => e.label) : ""} </td>
                <td> {profile.cv.d} </td>
                <td> {profile.cv && profile.cv.lgs && moment(profile.cv.lgs[profile.cv.lgs.length-1].ad).format('DD-MM-YYYY')} </td>
                <td style={{color: "black"}}> 
                {profile.cv && profile.cv.st === 'ucomplete' ? "COMPLETE" : null}
                {profile.cv && profile.cv.st === 'vcomplete' ? "COMPLETE" : null} 
                {profile.cv && profile.cv.st === 'ccomplete' ? "COMPLETE" : null}
                {profile.cv && profile.cv.st === 'toupdate' ? "Sent for Updation" : null}
                {profile.cv && profile.cv.st === 'present' ? "Attendance Marked" : null}
                {profile.cv && profile.cv.st === 'incomplete' ? <span style={{color: "red"}}> Incomplete </span> : null}
                </td>
              </tr>
        )) }
          
          </tbody>
          </Table>

          {candprofiles && candprofiles.cvcompl &&
            <>
            <div className="p-2" />
            <div className="" style={{color: "teal", textAlign: "center", fontSize: "9pt"}}>Pages</div>
            
            <Pagination postsPerpage={postsPerPage} totalPosts={Math.ceil(candprofiles.cvcompl.length / postsPerPage)}  paginate={paginate} />
            </>
          }
            
          </div>

        </>

       : candprofiles && candprofiles.cvcompl && candprofiles.cvcompl.length === 0 && <div className="p-2 text-center">  No Candidates Present </div>}   


{candprofiles.freez && candprofiles.freez.length > 0 ? 
      <>
      <h5 className="p-3 text-danger" style={{textAlign: "center"}}> Applications opened for Edit by Candidates (Incomplete Status) </h5>         
        <div className="table-responsive" style={{fontWeight: "bold", fontSize: "6pt", fontFamily: "verdana"}}>  
        
        <Table className="table">  
            <thead className="text-primary">
              
              <tr className="text-success" style={{fontWeight: "bolder"}}>                
                <th scope="col">Sl.No</th>
                <th scope="col">Appln. Number</th>
                <th scope="col">Name of the Candidate</th>
                <th scope="col">TFC Centre</th>
                <th scope="col"> Actual CV Date </th>
                <th scope="col"> CV Status </th>
                <th scope="col"> Status of Incomplete List of Certificates </th>
                <th scope="col"> Upload Opened? </th>
                <th scope="col"> Candidate Freezed Upload? </th>     
                <th scope="col"> Candidate Opened Edit? </th>     
                <th scope="col"> Candidate Completed Edit? </th>     
                <th scope="col"> Mobile </th>     
                <th scope="col"> Incomplete List of Certificates </th>                

              </tr>
            </thead>
            <tbody style={{fontSize: "6pt"}}>

          {currentList.map((profile, i) => (
          
              <tr key={profile._aid} style={{color: "black", fontWeight: "bold"}}>                
                <td style={{fontSize: '8pt'}}> {i+1} </td>
                <td style={{fontSize: '8pt'}}> {profile._aid} </td>
                <td style={{fontSize: '8pt'}}> {profile._p._n} </td>
                <td style={{fontSize: '8pt'}}> {profile._sr.tfc} </td>                
                <td style={{fontSize: '8pt'}}> {profile.cv.d} </td>
                <td style={{color: "black", fontSize: "8pt"}}> 
                {profile.cv && profile.cv.st === 'ucomplete' ? "COMPLETE" : null}
                {profile.cv && profile.cv.st === 'vcomplete' ? "COMPLETE" : null} 
                {profile.cv && profile.cv.st === 'ccomplete' ? "COMPLETE" : null}
                {profile.cv && profile.cv.st === 'toupdate' ? "Sent for Updation" : null}
                {profile.cv && profile.cv.st === 'present' ? "Attendance Marked" : null}
                {profile.cv && profile.cv.st === 'incomplete' ? <span style={{color: "blue"}}> Incomplete </span> : null}                
                {profile.cv && profile.cv.st === 'ineligible' ? <span style={{color: "red"}}> INELIGIBLE </span> : null}                
                </td>
                <td style={{color: "crimson", fontSize: "8pt"}}>
                  {profile.cv && ((profile.cv.st === 'incomplete') || (profile.cv.st === "ineligible")) && profile.cv.icl && profile.cv.icl.length > 0 ? <span style={{color: "black"}}> Available </span> : "** Missing. Kindly Update **"}
                </td>
                <td style={{fontSize: '8pt'}}> {profile._alup ? "YES" : "NO"} </td>
                <td style={{fontSize: '8pt'}}> {profile._fzup ? "YES" : "NO"} </td>
                <td style={{fontSize: '8pt'}}> {profile._alEd ? "YES" : "NO"} </td>
                <td style={{fontSize: '8pt'}}> {profile._coEd ? "YES" : "NO"} </td>
                <td style={{fontSize: '8pt'}}> {profile.m} </td>
                <td style={{fontSize: '8pt'}}>
            {profile.cv.icl !== null && profile.cv.icl !== undefined && profile.cv.icl[profile.cv.icl.length-1] ? <div>
                 {profile.cv.icl[profile.cv.icl.length-1].bna !== false ? <div> *Bonafide Certificate (Nativity others - Studied VIII, IX and X in Tamilnadu)* </div> : null}
                {profile.cv.icl[profile.cv.icl.length-1].tenna !== false ? <div>  *Xth marksheet not available*  </div> : null} 
                {profile.cv.icl[profile.cv.icl.length-1].elef !== false ? <div> *XIth: Fail in a Subject*  </div> : null}  
                 {profile.cv.icl[profile.cv.icl.length-1].elena !== false ? <div>  *XIth marksheet not available* </div> : null} 
                {profile.cv.icl[profile.cv.icl.length-1].twena !== false ? <div>   *XIIth marksheet not available* </div> : null} 
                 {profile.cv.icl[profile.cv.icl.length-1].twef !== false ? <div>  *XIIth: Fail in a Subject*  </div> : null} 
                {profile.cv.icl[profile.cv.icl.length-1].twiv ? <div>  *XIIth: Invalid*  </div> : null} 
                {profile.cv.icl[profile.cv.icl.length-1].pcm !== false ? <div>  *Physics, Chemistry, Mathematics not studied* </div> : null} 
                {profile.cv.icl[profile.cv.icl.length-1].tcna !== false ? <div>  *Transfer Certificate / Bonafide Certificate not available* </div> : null} 
                 {profile.cv.icl[profile.cv.icl.length-1].tcf !== false ? <div> *Transfer Certificate / Bonafide Certificate not in acceptable format* </div> : null} 
                 {profile.cv.icl[profile.cv.icl.length-1].indn !== false ? <div>  *Nationality not indian*  </div> : null} 
                 {profile.cv.icl[profile.cv.icl.length-1].natina !== false ? <div>  *TN Nativity Certificate not available* </div> : null} 
                {profile.cv.icl[profile.cv.icl.length-1].natif !== false ? <div>  *TN Nativity Certificate not in format* </div> : null}
                 {profile.cv.icl[profile.cv.icl.length-1].pona !== false ? <div> *Parent Occupation Certificate not available*  </div> : null} 
                {profile.cv.icl[profile.cv.icl.length-1].ccna !== false ? <div> *TN Community Certificate not available*  </div> : null} 
                {profile.cv.icl[profile.cv.icl.length-1].ccf !== false ? <div>  *TN Community Certificate not in acceptable format* </div> : null} 
                {profile.cv.icl[profile.cv.icl.length-1].dpna !== false ? <div> *Disability Certificate not available*  </div> : null} 
                {profile.cv.icl[profile.cv.icl.length-1].dpf !== false ? <div>  *Disability Certificate not in acceptable format* </div> : null} 
                {profile.cv.icl[profile.cv.icl.length-1].exsna !== false ? <div> *Ex-Serviceman Certificate not available*  </div> : null}  
                 {profile.cv.icl[profile.cv.icl.length-1].exsf !== false ? <div>  *Ex-Serviceman Certificate not in acceptable format* </div> : null} 

                 </div> : null
              }
            </td>
              </tr>
        )) }
          
          </tbody>
          </Table>

          {candprofiles && candprofiles.freez &&
            <>
            <div className="p-2" />
            <div className="" style={{color: "teal", textAlign: "center", fontSize: "9pt"}}>Pages</div>
            
            <Pagination postsPerpage={postsPerPage} totalPosts={Math.ceil(candprofiles.freez.length / postsPerPage)}  paginate={paginate} />
            </>
          }
            
          </div>

        </>

       : candprofiles && candprofiles.freez && candprofiles.freez.length === 0 && <div className="p-2 text-center">  No Candidates Present </div>}   



{candprofiles.admins && candprofiles.admins.length > 0 ? 
      <>
      <h5 className="p-3 text-danger" style={{textAlign: "center"}}> Applications opened for Edit by Updation Officer </h5>         
        <div className="table-responsive" style={{fontWeight: "bold", fontSize: "6pt", fontFamily: "verdana"}}>  
        
        <Table className="table">  
            <thead className="text-primary">
              
              <tr className="text-success" style={{fontWeight: "bolder"}}>                
                <th scope="col">Sl.No</th>
                <th scope="col">Appln. Number</th>
                <th scope="col">Name of the Candidate</th>
                <th scope="col">TFC Centre</th>
                <th scope="col"> Actual CV Date </th>
                <th scope="col"> CV Status </th>
                <th scope="col"> Upload Opened? </th>
                <th scope="col"> Candidate Freezed Upload? </th>     
                <th scope="col"> Candidate Opened Edit? </th>     
                <th scope="col"> Candidate Completed Edit? </th>     
                <th scope="col"> Mobile </th>     

              </tr>
            </thead>
            <tbody style={{fontSize: "6pt"}}>

          {currentList.map((profile, i) => (
          
              <tr key={profile._aid} style={{color: "black", fontWeight: "bold"}}>                
                <td style={{fontSize: '8pt'}}> {i+1} </td>
                <td style={{fontSize: '8pt'}}> {profile._aid} </td>
                <td style={{fontSize: '8pt'}}> {profile._p._n} </td>
                <td style={{fontSize: '8pt'}}> {profile._sr.tfc} </td>                
                <td style={{fontSize: '8pt'}}> {profile.cv.d} </td>
                <td style={{color: "black", fontSize: "8pt"}}> 
                {profile.cv && profile.cv.st === 'ucomplete' ? "COMPLETE" : null}
                {profile.cv && profile.cv.st === 'vcomplete' ? "COMPLETE" : null} 
                {profile.cv && profile.cv.st === 'ccomplete' ? "COMPLETE" : null}
                {profile.cv && profile.cv.st === 'toupdate' ? "Sent for Updation" : null}
                {profile.cv && profile.cv.st === 'present' ? "Attendance Marked" : null}
                {profile.cv && profile.cv.st === 'incomplete' ? <span style={{color: "blue"}}> Incomplete </span> : null}                
                {profile.cv && profile.cv.st === 'ineligible' ? <span style={{color: "red"}}> INELIGIBLE </span> : null}                
                </td>
                <td style={{fontSize: '8pt'}}> {profile._alup ? "YES" : "NO"} </td>
                <td style={{fontSize: '8pt'}}> {profile._fzup ? "YES" : "NO"} </td>
                <td style={{fontSize: '8pt'}}> {profile._alEd ? "YES" : "NO"} </td>
                <td style={{fontSize: '8pt'}}> {profile._coEd ? "YES" : "NO"} </td>
                <td style={{fontSize: '8pt'}}> {profile.m} </td>

              </tr>
        )) }
          
          </tbody>
          </Table>

          {candprofiles && candprofiles.admins &&
            <>
            <div className="p-2" />
            <div className="" style={{color: "teal", textAlign: "center", fontSize: "9pt"}}>Pages</div>
            
            <Pagination postsPerpage={postsPerPage} totalPosts={Math.ceil(candprofiles.admins.length / postsPerPage)}  paginate={paginate} />
            </>
          }
            
          </div>

        </>

       : candprofiles && candprofiles.admins && candprofiles.admins.length === 0 && <div className="p-2 text-center">  No Candidates Present </div>}   




{candprofiles.dpierr && candprofiles.dpierr.length > 0 ? 
      <div>        
       
        <h5 className="p-3 text-danger" style={{textAlign: "center"}}> DPI Not matched Applications </h5> 
        <div className="table-responsive" style={{fontWeight: "bold", fontSize: "6pt", fontFamily: "verdana"}}>   
        <table className="table table-striped table-bordered table-hover table-condensed"> 
            <thead className="text-primary">
              
              <tr className="text-success" style={{fontWeight: "bolder"}}>                
                <th scope="col">Sl.No</th>
                <th scope="col">Appln. Number</th>
                <th scope="col">Updation Off</th>
                <th scope="col">Name of the Candidate</th>                
                <th scope="col">Roll Number</th>                
                <th scope="col">Date of Birth</th>                
                <th scope="col">Community</th>
                
                <th scope="col">Actual CV Date</th>                
                <th scope="col"> CV Status </th>

                <th scope="col"> DPI Error </th>
                <th scope="col"> Board </th>

              </tr>
            </thead>
            <tbody style={{fontSize: "6pt"}}>

          {currentList.map((profile, i) => (

profile.cv.dpierr !== "" &&
          
              <tr key={profile._aid} style={{color: "black", fontWeight: "bold"}}>                
                <td> {i+1} </td>
                <td> {profile._aid} </td>
                <td> {profile.cv && profile.cv.atfc} </td>
                <td> {profile._p._n} </td>
                <td> {profile._ac.hron} </td>
                <td> {profile._p.dob} </td>
                <td> {profile._p.co} </td>
                
                <td> {profile.cv.d} </td>

                <td style={{color: "blue"}}> 
                {profile.cv && profile.cv.st === 'ucomplete' ? "COMPLETE" : null}
                {profile.cv && profile.cv.st === 'vcomplete' ? "COMPLETE" : null} 
                {profile.cv && profile.cv.st === 'ccomplete' ? "COMPLETE" : null}
                {profile.cv && profile.cv.st === 'toupdate' ? "Sent for Updation" : null}
                {profile.cv && profile.cv.st === 'present' ? "Attendance Marked" : null}
                {profile.cv && profile.cv.st === 'incomplete' ? <span style={{color: "red"}}> Incomplete </span> : null}                
                {profile.cv && profile.cv.st === 'ineligible' ? <span style={{color: "red"}}> INELIGIBLE </span> : null}                
                </td>

                <td> <span style={{color: "darkred"}}> {profile.cv.dpierr !== "" ? (profile.cv.dpierr.includes("name not") ? "Name not matched" : profile.cv.dpierr) : "" } </span> </td>
                <td> 
                {profile._ac.nbe === "1" ? "HSC" : null} 
                {profile._ac.nbe === "2" ? "CBSE" : null} 
                {profile._ac.nbe === "3" ? "ICSE" : null} 
                </td>
              </tr>
        )) }
          
          </tbody>
          </table>

          {candprofiles && candprofiles.dpierr &&
            <>
            <div className="p-2" />
            <div className="" style={{color: "teal", textAlign: "center", fontSize: "9pt"}}>Pages</div>
            
            <Pagination postsPerpage={postsPerPage} totalPosts={Math.ceil(candprofiles.dpierr.length / postsPerPage)}  paginate={paginate} />
            </>
          }
            
          </div>

        </div>

       : candprofiles && candprofiles.dpierr && candprofiles.dpierr.length === 0 && <div className="p-2 text-center">  No Candidates Present </div>}   



      {candprofiles.cvall && candprofiles.cvall.length > 0 ? 
      <>        
        <div className="container">        
        <h5 className="p-3 text-danger" style={{textAlign: "center"}}> CV In Processing </h5> 
        <table className="table" bordered variant="dark" style={{fontSize: "11pt"}} >
            <thead>
              
              <tr className="text-primary" style={{fontWeight: "bolder"}}>                
                <th scope="col">Sl.No</th>
                <th scope="col">Appln. Number</th>
                
                <th scope="col">Name of the Candidate</th>                
                <th scope="col">Community</th>
                {/* <th scope="col">Sports</th>
                <th scope="col">ExServiceman</th>
                <th scope="col">Differently abled</th> */}
                <th scope="col">Actual CV Date</th>
                <th scope="col">Last Updated on</th>
                <th scope="col"> CV Status </th>
              </tr>
            </thead>
            <tbody>

          {currentList.map((profile, i) => (
          
              <tr key={profile._aid} style={{color: "black", fontWeight: "bold"}}>                
                <td> {i+1} </td>
                <td> {profile._aid} </td>
                
                <td> {profile._p._n} </td>                
                <td> {profile._p.co} </td>
                {/* <td> {profile._sr.esp === 'Y' ? 'Yes': '-'} </td>
                <td> {profile._sr.ex === 'Y' ? 'Yes': '-'} </td>
                <td> {profile._sr.dap === 'Y' ? 'Yes': '-'} </td> */}
                <td> {profile.cv.d} </td>
                <td> {profile.cv && profile.cv.lgs && moment(profile.cv.lgs[0].ad).format('DD-MM-YYYY')} </td>
                <td style={{color: "black"}}> 
                {profile.cv && profile.cv.st === 'ucomplete' ? "COMPLETE" : null}
                {profile.cv && profile.cv.st === 'vcomplete' ? "COMPLETE" : null} 
                {profile.cv && profile.cv.st === 'ccomplete' ? "COMPLETE" : null}
                {profile.cv && profile.cv.st === 'toupdate' ? "Sent for Updation" : null}
                {profile.cv && profile.cv.st === 'present' ? "Attendance Marked" : null}
                {profile.cv && profile.cv.st === 'incomplete' ? <span style={{color: "red"}}> Incomplete </span> : null}
                </td>
              </tr>
        )) }
          
          </tbody>
          </table>

          {candprofiles && candprofiles.cvall &&
            <>
            <div className="p-2" />
            <div className="" style={{color: "teal", textAlign: "center", fontSize: "9pt"}}>Pages</div>
            
            <Pagination postsPerpage={postsPerPage} totalPosts={Math.ceil(candprofiles.cvall.length / postsPerPage)}  paginate={paginate} />
            </>
          }
            
          </div>

        </>

       : candprofiles && candprofiles.cvall && candprofiles.cvall.length === 0 && <div className="p-2 text-center">  No Candidates Present </div>}   

      {candprofiles.logins && candprofiles.logins.length > 0 ? 
                  <>
              
                  <h5 className="p-3 text-danger text-center" > Login Details </h5> 
                  <table className="table striped table-bordered">
                  <thead>              
                    
                    <tr className="text-primary">                 
                      <th>Login Type</th>
                      <th>Username</th>
                      <th> Password </th>
                    </tr>
                  </thead>
                  <tbody>
      
          {candprofiles.logins.map((profile) => (
             <tr key={profile.username} style={{fontWeight: "bold"}}>                
                <td> 
                  {profile.ltype === 'A' ? " Attendance system" : null} 
                  {profile.ltype === 'C' ? " Coordinator" : null} 
                  {profile.ltype === 'V' || profile.ltype === "F"  ? " Verification Officer " : null}                   
                  {profile.ltype === 'U' ? " Updation Officer " : null}                   
                </td>
                <td> {profile.username} </td>
                <td> {profile.password}  </td>
              </tr>
          )) }
              </tbody>
              </table>
              
              </>
        
       : candprofiles && candprofiles.logins && candprofiles.logins.length === 0 && <div className="p-2 text-center">  NIL </div>}     

        {candprofiles.dds && candprofiles.dds.length > 0 ? 
            <>
            <h5 className="p-4 text-success" style={{textAlign: "center"}}> DD Details </h5> 
            <Table className="table" variant="table striped table-bordered dark" borderless style={{fontSize: "11pt"}} >
            <thead>              
              
              <tr className="text-primary">                
                <th>Sl.No</th>
                <th>Application Number</th>
                <th>Name of the Candidate</th>
                <th>ExServiceman? </th>
                <th>Differently abled? </th>
                <th>Community Change? </th>
                <th>DD Number </th>
                <th>Bank Name </th>
                <th>Branch </th>
                <th>DD Date </th>
                <th>Amount </th>
              </tr>
            </thead>
            <tbody>

          {candprofiles.dds.map((profile, i) => (

             <tr style={{color: "brown", fontWeight: "bold"}}>                
                <td> {i+1} </td>
                <td> {profile._aid} </td>
                <td> {profile._p && profile._p._n} </td>
                {profile.cv && profile.cv.dd && profile.cv.dd.length > 0 && 
                <td> {profile.cv.dd[profile.cv.dd.length-1].splexchange ? "Yes" : "No change"}  </td> }

              {profile.cv && profile.cv.dd && profile.cv.dd.length > 0 && 
                <td> {profile.cv.dd[profile.cv.dd.length-1].spldiffchange ? "Yes" : "No change"} </td> }

              {profile.cv && profile.cv.dd && profile.cv.dd.length > 0 && 
                <td> {profile.cv.dd[profile.cv.dd.length-1].splcomchange ? "Yes" : "No change"} </td> }

              {profile.cv && profile.cv.dd && profile.cv.dd.length > 0 && 
                <td> {profile.cv.dd[profile.cv.dd.length-1].ddno} </td> }

              {profile.cv && profile.cv.dd && profile.cv.dd.length > 0 && 
                <td> {profile.cv.dd[profile.cv.dd.length-1].bn} </td> }

              {profile.cv && profile.cv.dd && profile.cv.dd.length > 0 && 
                <td> {profile.cv.dd[profile.cv.dd.length-1].br} </td> }

              {profile.cv && profile.cv.dd && profile.cv.dd.length > 0 && 
                <td> {profile.cv.dd[profile.cv.dd.length-1].ddd} </td> }

              {profile.cv && profile.cv.dd && profile.cv.dd.length > 0 && 
                <td> {profile.cv.dd[profile.cv.dd.length-1].amt} </td> }
                
              </tr>
          )) }
          </tbody>
          </Table>
            </>

       : candprofiles && candprofiles.dds && candprofiles.dds.length === 0 && <div className="p-2 text-center">  No Candidates Present </div>}   
          
            
      
      {candprofiles.upcomp &&
      <>
            <h4 className="p-3" style={{color: "black", textAlign: "center"}}> DAILY REPORT SUMMARY - {moment().format('DD-MM-YYYY')} </h4>       

      <div className="my-1 p-1" style = {{color: "#5E0505", fontWeight: "bolder", backgroundColor: "white", border: "1px solid black" }}> 
      <div>
      {candprofiles.upcomp && candprofiles.upcomp.length > 0 ? (        
        candprofiles.upcomp.map((profile) => (                                           
                 
          <div style={{fontWeight: "bold"}}>  
          {inctty(profile.total)}   
              {profile._id.status === "present" && 
                  <div style={{color: "crimson"}}> 
                      Verification pending: {profile.total}
                  </div>
              }
              
              {profile._id.status === "toupdate" && 
                  <div style={{color: "crimson"}}> 
                      Updation pending: {profile.total}
                  </div>
              }

              {profile._id.status === "vcomplete" && 
                  <div style={{color: "green"}}> 
                      Number of Candidates Verified and Completed Successfully : 
                        {profile.total}
                  </div>
              }
              {profile._id.status === "ucomplete" && 
                  <div style={{color: "green"}}> 
                      Number of Candidates Updated and Completed Successfully: 
                        {profile.total}
                  </div>
              }
              {profile._id.status === "incomplete" && 
                  <div style={{color: "crimson"}}> 
                      Number of Incomplete Applications: {profile.total}
                  </div>
              }
              {profile._id.status === "ccomplete" && 
                  <div style={{color: "blue"}}> 
                      Incomplete to Complete Applications: {profile.total}
                  </div>
              }
                                       
              </div>
        ))
      ) : null}
      </div>
      <div className="p-1" style={{color: "darkred", textAlign: "center"}}> Total: {tty} </div>    
      </div>
      </>
      }
    
    {candprofiles.upcomp && candprofiles.alis && candprofiles.alis.length > 0 ? 
      <>        
        <div className="" style={{paddingLeft: "0px"}}>        
        
        <Table className="table" bordered variant="dark" style={{fontSize: "11pt"}} >
            <thead>
              
              <tr className="" style={{fontWeight: "bolder"}}>                
                <th scope="col">Sl.No</th>
                <th scope="col">Appln. Number</th>
                <th scope="col">Name of the Candidate</th>                
                <th scope="col">Verification Officer</th>
                <th scope="col">Updation Officer</th>
                
                <th scope="col"> CV Status </th>
              </tr>
            </thead>
            <tbody>

          {currentList.map((profile, i) => (
          
              <tr key={profile._aid} style={{color: "black", fontWeight: "bold"}}>                
                <td> {i+1} </td>
                <td> {profile._aid} </td>
                <td> {profile._p._n} </td>                
                <td> {profile.cv && profile.cv.ver && profile.cv.ver !== undefined && profile.cv.ver[profile.cv.ver.length-1].vn}</td>
                <td> {profile.cv && profile.cv.upd !== null && profile.cv.upd !== undefined && profile.cv.upd.length > 0 ? profile.cv.upd[profile.cv.upd.length-1].un : "---"}</td>
                <td style={{color: "green"}}> 
                {profile.cv && profile.cv.st === 'ucomplete' ? "COMPLETE" : null}
                {profile.cv && profile.cv.st === 'vcomplete' ? "COMPLETE" : null} 
                {profile.cv && profile.cv.st === 'ccomplete' ? "COMPLETE" : null}
                {profile.cv && profile.cv.st === 'toupdate' ? <span style={{color: "red"}}> Sent for Updation </span> : null}
                {profile.cv && profile.cv.st === 'present' ? <span style={{color: "red"}}> Verification Pending </span> : null}
                {profile.cv && profile.cv.st === 'incomplete' ? <span style={{color: "crimson"}}> Incomplete </span> : null}
                </td>
              </tr>
        )) }
          
          </tbody>
          </Table>

          {candprofiles && candprofiles.alis &&
            <>
            <div className="p-2" />
            <div className="" style={{color: "teal", textAlign: "center", fontSize: "9pt"}}>Pages</div>
            
            <Pagination postsPerpage={postsPerPage} totalPosts={Math.ceil(candprofiles.alis.length / postsPerPage)}  paginate={paginate} />
            </>
          }

          </div>

        </>

       : candprofiles && candprofiles.alis && candprofiles.alis.length === 0 && <div className="p-2 text-center">  No Candidates Present </div>}         
    
    </Fragment> 

    
    )        
    : null
    }
    
    </div> 

</> ) }

</Fragment>    
</section>
  );
}

ReportDash.propTypes = {
  getLogs: PropTypes.func.isRequired,  
  getDDs: PropTypes.func.isRequired,
  getLogins: PropTypes.func.isRequired,
  getApplnsTot: PropTypes.func.isRequired,
  getApplnsPend: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  rank: PropTypes.object.isRequired,
  getApplnsPro: PropTypes.func.isRequired,
  getApplnsCompl: PropTypes.func.isRequired,
  getsharedfiles: PropTypes.func.isRequired,
  getdownfile: PropTypes.func.isRequired,
  getfreezCompl: PropTypes.func.isRequired,
  getdpiCompl: PropTypes.func.isRequired,
  getadminCompl: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  profile: state.profile,
  rank: state.rank
});

export default connect(
  mapStateToProps,
  { getLogs, getDDs, getLogins, getApplnsPro, getApplnsPend, getApplnsTot, getApplnsCompl, getsharedfiles, getdownfile, getfreezCompl, getdpiCompl, getadminCompl}
)(ReportDash);