import React , {useEffect} from 'react';

import { PDFDownloadLink} from '@react-pdf/renderer';
import { Link, Navigate, useNavigate } from 'react-router-dom';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getProfilePdf, clearApplication } from '../../actions/vermaster';

//import {getIncomplete} from '../../actions/report';
import {
	Page,
	Text,
	View,
	Document,
	StyleSheet,
	Font, Image
} from "@react-pdf/renderer";

import pic from "../../utils/tnimage.png";

import moment from 'moment';



const {CURR_TRANSFER_REASON, PREV_TRANSFER_REASON, YES_NO_ARRAY, TRANS_COLLEGES_LIST,
  COMPANY_TYPES, NDEPT_LIST} = require("../../Constant2");

  const styles = StyleSheet.create({
    page: {
      flexDirection: "column",
      backgroundColor: "white",
      width: "100vw"
    },
    headermain: {
      fontFamily: "Times-Bold",
      fontSize: "12px",
      marginTop: "30px"
    },
    header1: {
      width: "100vw",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center"
    },
    header2: {
      marginTop: "20px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      marginHorizontal: "50px"
    },
    header3: {
      marginTop: "20px",
      width: "100vw",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center"
    },
    textmargin: {
      margin: "2px"
    },
    table: {
      width: "100vw",
      display: "flex",
      flexDirection: "column",
      marginTop: "10px",
      padding: "20px"
    },
    tableheader: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      fontFamily: "Times-Bold",
      fontSize: "10px",
      border: "1px solid black",
      height: "30px"
    },
    tableheader1: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "50%",
      justifyContent: "space-between",
      borderRight: "1px solid black",
      height: "100%"
    },
    tableheader2: {
      width: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRight: "1px solid black",
      height: "100%"
    },
    tableheader3: {
      width: "15%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    tableinnerheader: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      fontFamily: "Times-Bold",
      fontSize: "8px",
      border: "1px solid black",
      borderTop: "none",
      height: "30px"
    },
    tableinnerheadersec1: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-evenly",
      width: "70%",
      height: "100%"
    },
    tableinnerheaderele: {
      justifyContent: "center",
      borderRight: "1px solid black",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "100%",
      height: "100%",
      textAlign: "center"
    },
    tableinnerheadernameele: {
      borderRight: "1px solid black",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "2000px",
      height: "100%",
      textAlign: "center",
      paddingLeft: "5px"
    },
    tableinnerheaderboardele: {
      borderRight: "1px solid black",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "550px",
      height: "100%",
      textAlign: "center",
      paddingLeft: "5px"
    },
    tableinnerheadersec2: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-evenly",
      width: "30%",
      height: "100%"
    },
    tabledata: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      fontFamily: "Times-Roman",
      fontSize: "8px",
      border: "1px solid black",
      borderTop: "none",
      height: "30px"
    },
    tabledatasec1: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-evenly",
      width: "70%",
      height: "100%"
    },
    tabledataele: {
      justifyContent: "center",
      borderRight: "1px solid black",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      height: "100%",
      textAlign: "center"
    },
    tabledataappliele: {
      justifyContent: "center",
      borderRight: "1px solid black",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "450px",
      height: "100%",
      textAlign: "center"
    },
    tabledatanameele: {
      borderRight: "1px solid black",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "2000px",
      height: "100%",
      textAlign: "center",
      paddingLeft: "5px"
    },
    tabledataboardele: {
      borderRight: "1px solid black",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "550px",
      height: "100%",
      textAlign: "center",
      paddingLeft: "5px"
    },
    tabledatamarksele1: {
      height: "50%",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderBottom: "1px solid black"
    },
    tabledatamarksele2: {
      height: "50%",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    tabledatasec2: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-evenly",
      width: "30%",
      height: "100%"
    },
  
    centerentrydata: {
      width: "100vw",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
  
    entrydata: {
      // margin: "40px",
      // padding: "30px",
      height: "120px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-evenly",
      width: "90%",
      fontFamily: "Times-Roman",
      fontSize: "12px",
      border: "1px solid black",
      marginTop: "100px"
    },
  
    entrydatacolumns: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-evenly",
      height: "100%"
    },
    entrydatalastline: {
      width: "70%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginTop: "140px",
      fontFamily: "Times-Roman",
      fontSize: "12px",
      flexDirection: "row"
    },

    hcontainer: {            
      height: 50,
      width: "100%",    
      display:"flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row"
    },

    header4: {
      fontSize: 10,
      marginTop: 30,
      marginRight: 20,
      marginBottom: 0,
      textAlign: 'right',
      color: 'darkblue',
      fontFamily: 'Helvetica-Bold'
    },

    header40: {
      fontSize: 10,
      marginTop: 10,
      marginLeft: 20,
      marginRight: 10,
      marginBottom: 0,
      alignItems: "left",
      textAlign: 'left',
      color: 'darkblue',
      fontFamily: 'Helvetica-Bold',
      width: "100%",
      justifyContent: "right"
    },

    header8: {
      fontSize: 10,
      marginTop: 5,
      marginRight: 20,
      marginBottom: 0,
      textAlign: 'right',
      color: 'darkblue',
      fontFamily: 'Helvetica-Bold'
    },

    header12: {
      fontSize: 10,
      marginTop: 5,
      marginRight: 20,
      marginBottom: 0,
      textAlign: 'center',
      color: 'darkred',
      width: "50%",
      fontFamily: 'Helvetica-Bold'
    },

  });


const Pview2   = ({  
  clearApplication,
  auth: { user },
  profile: { profile, applnid, pphoto},
  
}) => {

 /* useEffect(() => {
    //console.log(applnid);
    getIncomplete(applnid);
    console.log("completed retrieving pdf incomp list");
  }, [getIncomplete, applnid]);  */

 const navigate = useNavigate();

      useEffect(() => {
        window.scrollTo(0,0);
      }, []);

    const clearProf = async e => {
      e.preventDefault();      
      await clearApplication(navigate);
      if(user && user.ltype.toString() === "V")
        navigate('/tfcdash');
      else if(user && user.ltype.toString() === "C")
        navigate("/coord2");
    };
    
        

    return(      
      <div id="my-3 row align-center" className = "profile" style={{height: "100vh", width: "100hw", display: "flex"}}>       
  
      {profile !== null && profile._cv !== null ? (  
       <div className="p-3" style={{fontSize: "12pt", fontWeight: "bolder"}}>
          <PDFDownloadLink
          document={<Mydoc profile={profile} applnid={applnid} pphoto={pphoto}/>}
          fileName={applnid} >
          {({ blob, url, loading, error }) => (
              loading ? 'Loading Report...' : 'Download Application Report !'
            )}
          </PDFDownloadLink>
        </div>
        ) : null }
        
        <div className="p-2">          
          <input type="submit"  value="GO BACK" className="btn btn-primary" style={{alignItems: "center", textAlign: "center", color: "white"}} onClick= {e => clearProf(e)} />                     
        </div>

  

        </div>
    
    )
}

const Mydoc = ({
  profile, applnid, pphoto
}) => (
  <Document style={styles.viewbox} >
    
    <Page size="A4" style={styles.page}>
    
			<View style={styles.hcontainer}>
      <Image  src={pic} alt="images" />
      </View>
			<View style={styles.headermain} fixed>
      
				<View style={styles.header1}>
					<Text style={styles.textmargin}>
						DIRECTORATE OF TECHNICAL EDUCATION :: CHENNAI 600 025
					</Text>
					<Text style={styles.textmargin}>
						TEACHING STAFF TRANSFER COUNSELLING 2024
					</Text>
          <Text style={styles.textmargin}>
						{profile.college} - {TRANS_COLLEGES_LIST.filter(e => e.key === profile.college).map(e => e.label)}
					</Text>
				</View>
				
				<View style={styles.header3}>
					<Text style={styles.textmargin}>FACULTY TRANSFER APPLICATION </Text>
          
					<Text style={styles.textmargin}>
						Application Number: {profile._aid}
					</Text>		
          <Text> Page Number ----- 1 </Text>					
				</View>
			
			<View style={styles.table}>
				<View style={styles.tableheader} fixed>
					
					
					<View style={styles.tableheader1}>						
						<Text>
							1. Name
						</Text>
					</View>
					<View style={styles.tableheader2}>
						<Text> {profile._n} </Text>
					</View>
					
				</View>
        
        <View style={styles.tableheader}>
					
					
					<View style={styles.tableheader1}>						
						<Text>
							2. Designation
						</Text>
					</View>
					<View style={styles.tableheader2}>
						<Text> {profile._p && profile._p._desig} </Text>
					</View>					
				</View>

        <View style={styles.tableheader}>
					
					
					<View style={styles.tableheader1}>						
						<Text>
							3. Department
						</Text>
					</View>
					<View style={styles.tableheader2}>
						<Text> {profile._p && profile._p._bran ? NDEPT_LIST.filter(e => e.key === profile._p._bran).map(e => e.label) : "NA"}  </Text>
					</View>					
				</View>

        <View style={styles.tableheader}>
					
					
					<View style={styles.tableheader1}>						
						<Text>
							4. Current Working Station
						</Text>
					</View>
					<View style={styles.tableheader2}>
						<Text> {profile._p && profile._p._csta} </Text>
					</View>					
				</View>

        <View style={styles.tableheader}>
					
					
					<View style={styles.tableheader1}>						
						<Text>
							5. Date from which applicant is working in the present Institution
						</Text>
					</View>
					<View style={styles.tableheader2}>
						<Text> {profile._p && profile._p._startDate} </Text>
					</View>					
				</View>

        <View style={styles.tableheader}>
					
					<View style={styles.tableheader1}>						
						<Text>
							6. On what grounds applicant was transferred from previous college to current college?
						</Text>
					</View>
					<View style={styles.tableheader2}>
						<Text> {profile._p && profile._p._pTransReason ? PREV_TRANSFER_REASON.filter(e => e.key === profile._p._pTransReason).map(e => e.label) : "NA"} </Text>
					</View>					
				</View>

       

        <View style={styles.tableheader}>
					
					
					<View style={styles.tableheader1}>						
						<Text>
							7. Previously Working Institution
						</Text>
					</View>
					<View style={styles.tableheader2}>
						<Text> {profile._p && profile._p._pSta ? TRANS_COLLEGES_LIST.filter(e => e.key === profile._p._pSta).map(e => e.label) : "NA"} </Text>
					</View>					
				</View>

        <View style={styles.tableheader}>
					
					<View style={styles.tableheader1}>						
						<Text>
							8. Joining date and Relieving date in Previous college
						</Text>
					</View>
					<View style={styles.tableheader2}>
						<Text> {profile._p && profile._p._pStartDate ? profile._p._pStartDate : "NA"} - {profile._p && profile._p._pEndDate ? profile._p._pEndDate : "NA"} </Text>
					</View>					
				</View>

        <View style={styles.tableheader}>
									
					<View style={styles.tableheader1}>						
						<Text>
							9. Name of the college to which transfer is sought - Option 1
						</Text>
					</View>
					<View style={styles.tableheader2}>
						<Text> {profile._p && profile._p._transCollege ? TRANS_COLLEGES_LIST.filter(e => e.key === profile._p._transCollege).map(e => e.label) : "NA"} </Text>
					</View>					
				</View>

        <View style={styles.tableheader}>
									
					<View style={[styles.tableheader1, {justifyContent: "center"}]}>						
						<Text>
							 Option 2
						</Text>
					</View>
					<View style={styles.tableheader2}>
						<Text> {profile._p && profile._p._transCollege2 ? TRANS_COLLEGES_LIST.filter(e => e.key === profile._p._transCollege2).map(e => e.label) : "NA"} </Text>
					</View>					
				</View>

        <View style={styles.tableheader}>
									
        <View style={[styles.tableheader1, {justifyContent: "center"}]}>						
						<Text>
							 Option 3
						</Text>
					</View>
					<View style={styles.tableheader2}>
						<Text> {profile._p && profile._p._transCollege3 ? TRANS_COLLEGES_LIST.filter(e => e.key === profile._p._transCollege3).map(e => e.label) : "NA"} </Text>
					</View>					
				</View>

        <View style={styles.tableheader}>
									
        <View style={[styles.tableheader1, {justifyContent: "center"}]}>						
						<Text>
							 Option 4
						</Text>
					</View>
					<View style={styles.tableheader2}>
						<Text> {profile._p && profile._p._transCollege4 ? TRANS_COLLEGES_LIST.filter(e => e.key === profile._p._transCollege4).map(e => e.label) : "NA"} </Text>
					</View>					
				</View>

        <View style={styles.tableheader}>
									
        <View style={[styles.tableheader1, {justifyContent: "center"}]}>						
						<Text>
							 Option 5
						</Text>
					</View>
					<View style={styles.tableheader2}>
						<Text> {profile._p && profile._p._transCollege5 ? TRANS_COLLEGES_LIST.filter(e => e.key === profile._p._transCollege5).map(e => e.label) : "NA"} </Text>
					</View>					
				</View>

        <View style={styles.tableheader}>
					
					
					<View style={styles.tableheader1}>						
						<Text>
							10. Reason for Transfer Request
						</Text>
					</View>
					<View style={styles.tableheader2}>
						<Text> {profile._p && profile._p._transReason ? CURR_TRANSFER_REASON.filter(e => e.key === profile._p._transReason).map(e => e.label) : "NA"} </Text>
					</View>					
				</View>

        <View style={styles.tableheader}>
					
				
					<View style={styles.tableheader1}>						
						<Text>
							11. Is the transfer requested to the city where the husband/wife is working ?
						</Text>
					</View>
					<View style={styles.tableheader2}>
						<Text> {profile._p && profile._p._spLoc ? YES_NO_ARRAY.filter(e => e.key === profile._p._spLoc).map(e => e.label) : "NA"} </Text>
					</View>					
				</View>

        <View style={styles.tableheader}>
					
					
					<View style={[styles.tableheader1, {height: "100px"}]}>						
						<Text>
							11.(a) Name and Address of office where Spouse is working
						</Text>
					</View>
					<View style={styles.tableheader2}>
						<Text> {profile._p && profile._p._spWorkAdd ? profile._p._spWorkAdd.replace(/(?:\r\n|\r|\n)/g, ' ') : "NA"} </Text>
					</View>					
				</View>

        <View style={styles.tableheader}>
				
					
					<View style={styles.tableheader1}>						
						<Text>
							11.(b) Company Type
						</Text>
					</View>
					<View style={styles.tableheader2}>
						<Text> {profile._p && profile._p._spWorkCompType ? COMPANY_TYPES.filter(e => e.key === profile._p._spWorkCompType).map(e => e.label) : "NA"} </Text>
					</View>					
				</View>

        <View style={styles.tableheader}>
					
					
					<View style={styles.tableheader1}>						
						<Text>
							11.(c) Spouse Designation
						</Text>
					</View>
					<View style={styles.tableheader2}>
						<Text> {profile._p && profile._p._spDesig ? profile._p._spDesig : "----"} </Text>
					</View>					
				</View>

        <View style={styles.tableheader}>
					
					
					<View style={styles.tableheader1}>						
						<Text>
							11.(d) Distance between the office where the applicant seeks transfer and the office where spouse works (in KM)
						</Text>
					</View>
					<View style={styles.tableheader2}>
						<Text> {profile._p && profile._p._spWorkDist ? profile._p._spWorkDist : "----"} </Text>
					</View>					
				</View>

        <View style={styles.tableheader}>
				
					<View style={styles.tableheader1}>						
						<Text>
							11.(e) Has the applicant has been transferred on request to the place of employment of his/her spouse before?
						</Text>
					</View>
					<View style={styles.tableheader2}>
						<Text> {profile._p && profile._p._pSpLocTrans ? YES_NO_ARRAY.filter(e => e.key === profile._p._pSpLocTrans).map(e => e.label) : "NA"} </Text>
					</View>					
				</View>

        <View style={styles.tableheader}>
					
					
					<View style={styles.tableheader1}>						
						<Text>
							11.(f) When has the applicant been transferred to the place of employment of his/her spouse before?
						</Text>
					</View>
					<View style={styles.tableheader2}>
						<Text> {profile._p && profile._p._pSpLocTransDate ? profile._p._pSpLocTransDate : "NA"} </Text>
					</View>					
				</View>

        

        <View style={styles.tableheader}>
					
					
					<View style={styles.tableheader1}>						
						<Text>
							12. Have you got Transfer through Counselling previously
						</Text>
					</View>
					<View style={styles.tableheader2}>
						<Text> {profile._p && profile._p._pTransCouns ? YES_NO_ARRAY.filter(e => e.key === profile._p._pTransCouns).map(e => e.label) : "NA"} </Text>
					</View>					
				</View>

        <View style={styles.tableheader}>
				
					
					<View style={styles.tableheader1}>						
						<Text>
							12.(a) Previous transfer through Counselling :- Order Number & Order Date
						</Text>
					</View>
					<View style={styles.tableheader2}>
						<Text> {profile._p && profile._p._pTransOrderNumber} -- {profile._p && profile._p._pTransOrderDate} </Text>
					</View>					
				</View>        

      </View>
        
    </View>
    </Page>

    <Page size="A4" style={styles.page}>
    
			
			<View style={styles.headermain} fixed>
				<View style={styles.header1}>
					<Text style={styles.textmargin}>
						DIRECTORATE OF TECHNICAL EDUCATION :: CHENNAI 600 025
					</Text>
					<Text style={styles.textmargin}>
						TEACHING STAFF TRANSFER COUNSELLING 2024
					</Text>
          <Text style={styles.textmargin}>
						{profile.college} - {TRANS_COLLEGES_LIST.filter(e => e.key === profile.college).map(e => e.label)}
					</Text>
				</View>
				
				<View style={styles.header3}>
					<Text style={styles.textmargin}>FACULTY TRANSFER APPLICATION </Text>
         
					<Text style={styles.textmargin}>
						Application Number: {profile._aid}
					</Text>          					
          <Text> Page Number ----- 2 </Text>
				</View>
			
			<View style={styles.table}>
      <View style={styles.tableheader}>
				
					
					<View style={styles.tableheader1}>						
						<Text>
							13. In case of newly joined service, the date of joining service
						</Text>
					</View>
					<View style={styles.tableheader2}>
						<Text> {profile._p && profile._p._newJoinDate ? profile._p._newJoinDate : "NA"} </Text>
					</View>					
				</View>

        <View style={styles.tableheader}>
				
					<View style={styles.tableheader1}>						
						<Text>
							14. Is there any disciplinary action pending against the applicant requesting transfer? 
						</Text>
					</View>
					<View style={styles.tableheader2}>
						<Text> {profile._p && profile._p._activeDiscip ? YES_NO_ARRAY.filter(e => e.key === profile._p._activeDiscip).map(e => e.label) : "NA"} </Text>
					</View>					
				</View>

        <View style={styles.tableheader}>
				
					
					<View style={styles.tableheader1}>						
						<Text>
							14 (a). Disciplinary Action Pending Details
						</Text>
					</View>
					<View style={styles.tableheader2}>
						<Text> {profile._p && profile._p._activeDiscipDetail ? profile._p._activeDiscipDetail.replace(/(?:\r\n|\r|\n)/g, ' ') : "NA"} </Text>
					</View>					
				</View>

        <View style={styles.tableheader}>
					
					
					<View style={styles.tableheader1}>						
						<Text>
							15. Whether the applicant was transferred before based on a complaint ?
						</Text>
					</View>
					<View style={styles.tableheader2}>
						<Text> {profile._p && profile._p._pTranComplaint ? YES_NO_ARRAY.filter(e => e.key === profile._p._pTranComplaint).map(e => e.label) : "NA"} </Text>
					</View>					
				</View>

        <View style={styles.tableheader}>
				
					
					<View style={styles.tableheader1}>						
						<Text>
							15 (a). Complaint Details ?
						</Text>
					</View>
					<View style={styles.tableheader2}>
						<Text> {profile._p && profile._p._pTranComplaintDetail ? profile._p._pTranComplaintDetail.replace(/(?:\r\n|\r|\n)/g, ' ') : "NA"} </Text>
					</View>					
				</View>

        <View style={styles.tableheader}>
					
					
					<View style={styles.tableheader1}>						
						<Text>
							16. Whether the college you are working is Co-educational ?
						</Text>
					</View>
					<View style={styles.tableheader2}>
						<Text> {profile._p && profile._p._coEd ? YES_NO_ARRAY.filter(e => e.key === profile._p._coEd).map(e => e.label) : "NA"} </Text>
					</View>					
				</View>

        <View style={styles.tableheader}>
				
					<View style={styles.tableheader1}>						
						<Text>
							17. Are you the only female faculty in Your department?
						</Text>
					</View>
					<View style={styles.tableheader2}>
						<Text> {profile._p && profile._p._aloneFemale ? YES_NO_ARRAY.filter(e => e.key === profile._p._aloneFemale).map(e => e.label) : "NA" } </Text>
					</View>					
				</View>

      </View>
    </View>

    
    
    <Text style={[styles.header40, {border: "1px solid"}] }>
              Principal Remarks : {profile.premarks}              
     </Text>

     <Text style={[styles.header40, {border: "1px solid"},  {marginTop: "100px"}] }>
              Forwarded by Principal : {profile.pname} , 
     </Text>

     <Text style={[styles.header40, {border: "1px solid"},  {marginTop: "10px"}] }>
                {TRANS_COLLEGES_LIST.filter(e => e.key === profile.college).map(e => e.label)}
     </Text>
    
    

    <Text style={[styles.header4, {marginTop: "100px"} ]}>
              Directorate of Technical Education
     </Text>  

    
     <Text style={styles.header8 }> Chennai </Text>
     <Text style={styles.header8 }> Date: {moment(new Date()).format('DD/MM/YYYY')} </Text>

    </Page>

  </Document>
);

Pview2.propTypes = {
    getProfilePdf: PropTypes.func.isRequired,  
    //getIncomplete: PropTypes.func.isRequired,
    clearApplication: PropTypes.func.isRequired,  
    auth: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    logprofile: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    profile: state.profile,
    logprofile: state.profile
  });

  export default connect(
    mapStateToProps,
    { getProfilePdf, clearApplication }
  )(Pview2);