import api from '../utils/api';
import { setAlert } from './alert';
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL, SET_IP,
  LOGOUT,
  CLEAR_PROFILE
} from './types';


export const getClientIp = () => async dispatch => {
  try {

    console.log("in client ip");
  
    const res = await api.get(`/auth/getip`);

    dispatch({
      type: SET_IP,
      payload: res.data
    });
    
  } 
  
  catch (err) {

      console.log("Catch");
      
      dispatch(setAlert(err.response.data.msg, 'danger'));

  }
};

// Load User
export const loadUser = () => async (dispatch) => {
  try {
    const res = await api.get('/auth');

    dispatch({
      type: USER_LOADED,
      payload: res.data.user
    });

    dispatch({
      type: SET_IP,
      payload: res.data.ip
    });

  } catch (err) {
    dispatch({
      type: AUTH_ERROR
    });
  }
};


// Login User
export const login = (username, password) => async (dispatch) => {
  const body = { username, password };

  try {
    const res = await api.post('/auth', body);

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data
    });

    dispatch(loadUser());
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: LOGIN_FAIL
    });
  }
};

export const changepass = (formData, navigate) => async dispatch => {
  const config = {
      headers: {
          'Content-type': 'application/json'            
      }
  }

  //const body = JSON.stringify(formData);
 
  try {
      console.log("dispatched");
      console.log(formData);

      if(formData.password !== formData.password2)
        dispatch(setAlert("Passwords dont match", 'danger'));
      else {
      const res = await api.post('/auth/changepass', formData, config);
      //console.log("Returned from db");
      console.log(res.data);      

      navigate("/tfcdash");
      dispatch(setAlert("Password changed Successfully", 'success'));
      
      }



  } catch (err) {

      const errors = err.response.data.errors;

      if(errors) {
          errors.forEach(error => {
              dispatch(setAlert(error.msg, 'danger'));
          });
      }
  }
};


// Logout
//export const logout = () => ({ type: LOGOUT, type: CLEAR_PROFILE });

export const logout = () => async (dispatch) => {

  try {    

    dispatch({
      type: CLEAR_PROFILE,      
    });

    dispatch({
      type: LOGOUT,      
    });

  }
 catch (err) {
  const errors = err.response.data.errors;

  if (errors) {
    errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
  }
}
}
