import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import { Link, Navigate, useNavigate } from 'react-router-dom';

import {Card, ListGroup, CardSubtitle, CardTitle, CardText, ListGroupItem, CardBody,
            Row, Col} from 'reactstrap';

import { clearApplication, getProfileCor, changeAppStatus, getProfilePdf, movetoIneligible, checkfreezecand, checkunfreezecand, getProfileVer, getProfileUpd, getProfileCorSear} from '../../actions/vermaster';

import {listAll, openCert, gettfc} from '../../actions/dload';

import {getIncomplete} from '../../actions/report';
import moment from 'moment';
import Dloadc from '../dashboard/dloadc';

const {GENDER, CASTE, NATIONALITY, NATIVITY, YES_NO_ARRAY, INELIGIBLE_LIST, DIST_LIST, STATES, DIFF_ABLED_TYPES} = require("../../Constant");

const CVerSearch = ({
  getProfileCor, getProfileVer, getProfileUpd,
  getIncomplete, movetoIneligible, checkunfreezecand, checkfreezecand, listAll, getProfileCorSear,
  auth: { user },
  profile: { profile, loading, logprofile, getdb, applnid },
  history,
  clearApplication,
  changeAppStatus
}) => {

  useEffect(() => {
    window.scrollTo(0,0);
  }, []); 

  const [formData, setFormData] = useState({
    gappid: '',
    vflag : "notyet",
    cremarks: '',
    status: '',
    tfcname: '',
    username: '',
    ltype: '', elist: '', ineremarks: ''
  });

  const { gappid, cremarks, elist, ineremarks } = formData;

  const navigate = useNavigate();

  const onChange = e => {
    setFormData({ ...formData, [e.target.name] : e.target.value });  
    //name: e.target.value to change name alone
    //console.log(e.target.value);
  }

  const gofreezecand = async e => {
    e.preventDefault();     
    
    //console.log("Appln number : "+ formData.gappid);         
    await checkfreezecand(formData.gappid, navigate);    
  //console.log("tfc in form " + formData.tfc);
    clearApplication(navigate);
    
    //getIncomplete(formData.gappid);
  };

  const gounfreezecand = async e => {
    e.preventDefault();     
    
    //console.log("Appln number : "+ formData.gappid);         
    
    await checkunfreezecand(formData.gappid, navigate);     
  //console.log("tfc in form " + formData.tfc);
    clearApplication(navigate);   
    //getIncomplete(formData.gappid);
  };


 /* const onDownload = e => {
    e.preventDefault();
    clearProf(e);
    navigate('/download');
  }*/

  const onSubmit = async e => {
    e.preventDefault();     
    
    //console.log("Appln number : "+ formData.gappid);         
    
    getProfileCorSear(formData.gappid + "$" + user.tfccode);    
    //getIncomplete(formData.gappid);
  };

  const OnInelig = async e => {
    e.preventDefault();     

    const tobesent = {};
    tobesent.appId = formData.gappid;
    if(formData.elist.toString() === "")
      tobesent.elist = INELIGIBLE_LIST[0].label;
    else
      tobesent.elist = formData.elist;
    tobesent.ineremarks = formData.ineremarks;
    tobesent.oldstatus = profile.cv.st;
    tobesent.status = "ineligible";    

    tobesent.tfccode = user.tfccode;
    tobesent.username = user.username;
    tobesent.ltype = user.ltype;
    
    console.log(tobesent);

    if(tobesent.ineremarks === '')
        alert("pls enter comments");
    else {
        await movetoIneligible(tobesent, navigate);    
    }

  };


  
  const pendingupd = async e => {
    e.preventDefault();     
    
    //console.log("Appln number : "+ formData.gappid);         
    
    //getProfileCor(formData.gappid);    
    //getIncomplete(formData.gappid);
    navigate('/inlist');
  };


  const changeVerStatus = async e => {
    e.preventDefault();
    const tobesent = {};
    tobesent.oldstatus = profile.cv.st;
    //formData.status = 'present';
    //console.log("Appln no status changed as present " + formData.gappid);
    //console.log("tfcname " + user.tfcname + "username " + user.username + "ltype " + user.ltype);
    tobesent.status = "open";    

    tobesent.tfccode = user.tfccode;
    tobesent.username = user.username;
    tobesent.ltype = user.ltype;
    tobesent.gappid = profile._aid;
    tobesent.cremarks = cremarks;
    
    console.log(tobesent);

    if(tobesent.cremarks === '')
      alert("pls enter comments");
    else {
      changeAppStatus(tobesent, navigate);
    //clearProf(e);
    }
  }

  const changeUpdStatus = async e => {
    e.preventDefault();
    const tobesent = {};
    tobesent.oldstatus = profile.cv.st;
    //formData.status = 'toupdate';
    //console.log("Appln no status changed as toupdate " + formData.gappid);
    //console.log("tfcname " + user.tfcname + "username " + user.username + "ltype " + user.ltype);
    tobesent.status = "toupdate";    
    tobesent.tfccode = user.tfccode;
    tobesent.username = user.username;
    tobesent.ltype = user.ltype;
    tobesent.gappid = profile._aid;
    tobesent.cremarks = cremarks;

    console.log(tobesent);

    if(tobesent.cremarks === '')
      alert("pls enter comments");
    else {
      changeAppStatus(tobesent, navigate);
    //clearProf(e);
    }

  }

  const changeIncStatus = async e => {
    e.preventDefault();
    const tobesent = {};
    tobesent.oldstatus = profile.cv.st;
    //formData.status = 'ccomplete';

    //console.log("Appln no status changed as complete " + formData.gappid);
    //console.log("tfcname " + user.tfcname + "username " + user.username + "ltype " + user.ltype);
    tobesent.status = "ccomplete";    
    tobesent.tfccode = user.tfccode;
    tobesent.username = user.username;
    tobesent.ltype = user.ltype;
    tobesent.gappid = profile._aid;
    tobesent.cremarks = cremarks;

    if(tobesent.cremarks === '')
      alert("pls enter comments");
    else {
      changeAppStatus(tobesent, navigate);
    //clearProf(e);
    }

  }

  const seepdf = async e => {
    e.preventDefault();
    await getProfilePdf(applnid);
    navigate('/download'); 
  };

  const goback = async e => {
    e.preventDefault();    
    navigate('/searchmore'); 
  };

  const clearProf = async e => {    
    clearApplication(navigate);
    formData.gappid = '';
  };

  
  const handleVerifyRow = async (aid) => {    
    console.log(aid);
    formData.appln = aid;    
    await getProfileVer(formData);
    await listAll(aid);
    navigate("/verify");
  }

  const handleEditRow = async (aid) => {    
    console.log(aid);    
    await getProfileUpd(aid);       
    await listAll(aid);
    navigate("/adash");
  }


  const [displayne, toggledisplayne] = useState(false);

function changeNE () {
    toggledisplayne(!displayne);
}

  return (    
    <section className='container'>
    <Fragment>
       {user === null ? (
      <Spinner /> ) : ( 
      <div className='' >
      <h5 style={{textAlign: 'center', color: "blue", fontSize: "11pt", fontWeight: "bolder"}}> TFC Centre: {user && user.tfcname} </h5>
      <div className="container badge form" style={{textAlign: 'center', color: 'brown'}}>
        <h5 style={{fontWeight: "bold"}}> Verification - Application Status - {user && user.username} </h5>        
      </div>      
      
        <form className="my-2 row form" onSubmit = {e => onSubmit(e)} style={{alignItems: "center", textAlign: "center"}}>
                <h3 style={{fontWeight: "bold", fontSize: "12pt", textAlign: "center", alignContent: "center", alignItems: "center"}}>Enter application number </h3> 
                <input type="text" name="gappid" value={gappid} onChange = {e => onChange(e)}/>   
                <div className='my-2' style={{padding: "20px"}}></div>          
                <button className="btn btn-success" value="">  Get Details     </button>
                {/* <button className="btn btn-primary" onClick= {e => clearProf(e)}> Clear </button>                                                         */}
        </form>        
        
      
      </div> ) }
      
      {loading === true && getdb === true ? (
        <Spinner /> ) : (
<>  
      {profile && profile.cv === undefined && profile._docs && profile._docs.length > 0 ? 
          <Col>
            <div style={{ color: 'crimson', textAlign: "center", fontWeight: "bold"}}> CANDIDATE UPLOADED CERTIFICATES</div>
            <div style={{ color: 'darkblue', textAlign: "center"}}> CERTIFICATE VERIFICATION IS PENDING</div>
          </Col>  : null
        }

        {profile && profile.cv === undefined && profile._docs && profile._docs.length < 1 ? 
          <Col>
            <div style={{ color: 'darkblue' , textAlign: "center", fontWeight: "bold"}}> NO CERTIFICATES UPLOADED</div>            
          </Col>  : null
        }

        {profile && profile._coEd !== undefined && profile._coEd && profile.cv !== undefined && profile.cv.st !== undefined && profile.cv.st === "incomplete" ? 
          <Col>
            <div style={{ color: 'darkred' , textAlign: "center", fontWeight: "bold"}}> Candidate has Completed Editing </div>            
          </Col>  : null
        }



      {profile !== null && profile._n !== undefined && user && ((user.ltype === 'V') || (user.ltype === 'F') || (user.ltype === "C")) ? (
        <Fragment>       
          {(profile.cv === undefined) || (profile.cv && profile.cv.tfc.toString() !== user.tfccode.toString()) ? (<h2 style={{textAlign: "center"}}> Candidate belongs to Some other TFC </h2>) : 
        (<div className="p-3">  
        <div className="container badge" style={{fontWeight: 'bold', background: ''}}>
        <h5 style={{fontWeight: "bold", color: "black"}}>                  
            Application Number: {profile._aid}      
        </h5>
        </div>         

          <div className="grid-3">
            <Row>
            <Col>
            <Card style={{ backgroundColor: "#F8F9F9", fontFamily: "Verdana",  color: 'darkgreen', fontSize: "10pt"}}> 
              <CardBody className="text-left">
                <CardTitle style={{ textAlign: "center", color: "green", fontWeight: "bolder"}}>  CANDIDATE DETAILS </CardTitle>
                <CardSubtitle> </CardSubtitle>  
                <CardText style={{color: 'black'}}>
                  <ListGroup>
                    <ListGroupItem> Name: <span style={{fontWeight: 'bold'}}>{profile._n} </span> </ListGroupItem>
                    <ListGroupItem> Mobile number: <span style={{fontWeight: 'bold'}}>{profile.m} </span> </ListGroupItem>
                    <ListGroupItem> Email: <span style={{fontWeight: 'bold'}}>{profile.e} </span> </ListGroupItem>
                    <ListGroupItem> Date of Birth: <span style={{fontWeight: 'bold'}}> {profile.dob} </span> </ListGroupItem>
                    <ListGroupItem> First Graduate: <span style={{fontWeight: 'bold'}}> {profile._sp && profile._sp.fgtfc.toString() === "Y" ? "Yes" : "No"} </span> </ListGroupItem>
                
                 </ListGroup>
                  </CardText>
                  </CardBody>   
                  </Card>    
          </Col>       
        </Row>

        {profile && profile.cvslot !== undefined && profile.cvdate !== undefined ?

        <Row>
            <Col>
            <Card style={{ backgroundColor: "bisque", fontFamily: "Verdana",  color: 'darkred'}}> 
              <CardBody className="text-center">
                <CardTitle style={{ textAlign: "center", color: "red", fontWeight: "bolder"}}>  CV Schedule </CardTitle>
                <CardSubtitle> </CardSubtitle>  
                <CardText style={{color: '#DC143C'}}>                  
                  <ListGroup>
                    <ListGroupItem> CV Date: <span style={{fontWeight: 'bold'}}>{profile && moment(profile.cvdate).format("DD-MM-YYYY")} </span> </ListGroupItem>
                    <ListGroupItem> Slot: <span style={{fontWeight: 'bold'}}>{profile && profile.cvslot} </span> </ListGroupItem>
                   </ListGroup>
                </CardText>
              </CardBody>
            </Card>
            </Col>
        </Row>

        : null }

        {profile && profile.cv && profile.cv.st !== undefined ? 

        <Row>
            <Col>
            <Card style={{ backgroundColor: "#FEF5E7", fontFamily: "Verdana",  color: 'darkred'}}> 
              <CardBody className="text-left" style={{fontSize: "10pt"}}>
                <CardTitle style={{ textAlign: "center", color: "green", fontWeight: "bolder"}}>  CERTIFICATE VERIFICATION SUMMARY </CardTitle>
                <CardSubtitle> </CardSubtitle>  
                <CardText style={{color: '#DC143C'}}>
                  <ListGroup>
                  <ListGroupItem> Application Status :  <span style={{fontWeight: 'bold'}}> 
                      {profile.cv.st === "toupdate" ? 'Sent for Updation' : null} 
                      {profile.cv.st === "vcomplete" ? 'Verified & Complete' : null}
                      {profile.cv.st === "ucomplete" ? "Details Updated & Complete" : null}
                      {profile.cv.st === "incomplete" ? "INCOMPLETE" : null}   
                      {profile.cv.st === "complete" ? "COMPLETE" : null}   
                      {profile.cv.st === "ccomplete" ? "Coordinator Marked Complete" : null}   
                      {profile.cv.st === "open" ? 'CV not started' : null}    
                      {profile.cv.st === "ineligible" ? 'INELIGIBLE' : null}    
                      </span></ListGroupItem>

                      {profile.cv.ver && profile.cv.ver[profile.cv.ver.length-1] !== undefined &&
                      <ListGroupItem> Verification Remarks:  <span style={{fontWeight: 'bold'}}>
                      {profile.cv.ver[profile.cv.ver.length-1].vr}
                      </span></ListGroupItem> }

                      {profile.cv.ver  && profile.cv.ver[profile.cv.ver.length-1] !== undefined && 
                      <ListGroupItem> Verification Officer:  <span style={{fontWeight: 'bold'}}>
                      {profile.cv.ver[profile.cv.ver.length-1].vn}
                      </span></ListGroupItem> }

                      {profile.cv.upd  && profile.cv.upd[profile.cv.upd.length-1] !== undefined &&
                      <ListGroupItem> Updation Remarks:  <span style={{fontWeight: 'bold'}}>
                      {profile.cv.upd[profile.cv.upd.length-1].ur}
                      </span> </ListGroupItem> }

                      {profile.cv.upd  && profile.cv.upd[profile.cv.upd.length-1] !== undefined &&
                      <ListGroupItem> Updation Officer:  <span style={{fontWeight: 'bold'}}>
                      {profile.cv.upd[profile.cv.upd.length-1].un}
                      </span></ListGroupItem> }
                      
                      <ListGroupItem> Upload is open:  <span style={{fontWeight: 'bold'}}>
                      {profile._alup ? 'YES': 'NO'}                      
                      </span></ListGroupItem>

                      <ListGroupItem> Freezed Upload:  <span style={{fontWeight: 'bold'}}>
                      {profile._fzup ? 'YES' : 'NO'}                      
                      </span></ListGroupItem>

                      {/* <ListGroupItem> Opened for Edit by Candidate:  <span style={{fontWeight: 'bold'}}>
                              
                      {profile._alEd ? 'YES': "NO"}              
                      </span></ListGroupItem>

                      <ListGroupItem> Candidate Completed Edit:  <span style={{fontWeight: 'bold'}}>
                      {profile._coEd ? 'YES':"NO"}              
                      </span></ListGroupItem> */}

                  </ListGroup>
                  </CardText>
                  </CardBody>   
                  </Card>    
          </Col>       
        </Row>

        : null }


{profile.cv && ((profile.cv.st === "incomplete") || (profile.cv.st === "ineligible")) && (profile.cv.icl === undefined || profile.cv.icl.length === 0) && (
        <Row>
            <Col>
            <Card style={{ backgroundColor: "bisque", fontFamily: "Verdana",  color: 'darkred', fontSize: "10pt"}}> 
              <CardBody className="text-center">
                <CardTitle style={{ textAlign: "center", color: "red", fontWeight: "bolder"}}>  INCOMPLETE LIST </CardTitle>
                <CardSubtitle> </CardSubtitle>  
                <CardText style={{color: 'navy'}}>
                  <ListGroup>
                      INCOMPLETE LIST OF CERTIFICATES IS MISSING.
                      <br /> KINDLY UPDATE THE LIST OF CERTIFICATES. 
                      
                  </ListGroup>
                </CardText>
              </CardBody>
             </Card>
            </Col>
        </Row>
)}
             
        
        {profile.cv && ((profile.cv.st === "incomplete") || (profile.cv.st === "ineligible")) && profile.cv.icl !== undefined && profile.cv.icl.length > 0 ? (
        <Row>
            <Col>
            <Card style={{ backgroundColor: "bisque", fontFamily: "Verdana",  color: 'darkred'}}> 
              <CardBody className="text-center">
                <CardTitle style={{ textAlign: "center", color: "red", fontWeight: "bolder"}}>  INCOMPLETE LIST </CardTitle>
                <CardSubtitle> </CardSubtitle>  
                <CardText style={{color: 'navy'}}>
                  <ListGroup>
                  {profile.cv.icl[profile.cv.icl.length-1].bna ? <ListGroupItem>  *Bonafide Certificate (Nativity others - Studied VIII, IX and X in Tamilnadu)* </ListGroupItem> : null }
                  {profile.cv.icl[profile.cv.icl.length-1].tenna ? <ListGroupItem> Xth marksheet not available </ListGroupItem> : null } 
                  
                   {profile.cv.icl[profile.cv.icl.length-1].elef ? <ListGroupItem> XIth: Fail in a Subject </ListGroupItem> : null }
                   {profile.cv.icl[profile.cv.icl.length-1].elena ? <ListGroupItem> XIth marksheet not available </ListGroupItem> : null }
                   {profile.cv.icl[profile.cv.icl.length-1].twena ? <ListGroupItem> XIIth marksheet not available </ListGroupItem> : null }
                   {profile.cv.icl[profile.cv.icl.length-1].twef ? <ListGroupItem> XIIth: Fail in a Subject </ListGroupItem> : null }
                   {profile.cv.icl[profile.cv.icl.length-1].twiv ? <ListGroupItem> XIIth: Invalid </ListGroupItem> : null }

                   {profile.cv.icl[profile.cv.icl.length-1].pcm ? <ListGroupItem> Physics, Chemistry, Mathematics not studied </ListGroupItem> : null }
                   {profile.cv.icl[profile.cv.icl.length-1].tcna ? <ListGroupItem>  Transfer Certificate/ Bonafide Certificate not available </ListGroupItem> : null }
                   {profile.cv.icl[profile.cv.icl.length-1].tcf ? <ListGroupItem> Transfer / Bonafide Certificate not in acceptable format </ListGroupItem> : null }
                   {profile.cv.icl[profile.cv.icl.length-1].indn ? <ListGroupItem> Nationality not indian </ListGroupItem> : null }
                   {profile.cv.icl[profile.cv.icl.length-1].natina ? <ListGroupItem> TN Nativity Certificate not available </ListGroupItem> : null }
                   {profile.cv.icl[profile.cv.icl.length-1].natif ? <ListGroupItem> TN Nativity Certificate not in format </ListGroupItem> : null }
                   {profile.cv.icl[profile.cv.icl.length-1].pona ? <ListGroupItem> Parent Occupation Certificate not available </ListGroupItem> : null }
                   {profile.cv.icl[profile.cv.icl.length-1].ccna ? <ListGroupItem> TN Community Certificate not available </ListGroupItem> : null }
                   {profile.cv.icl[profile.cv.icl.length-1].ccf ? <ListGroupItem> TN Community Certificate not in acceptable format </ListGroupItem> : null }
                   
                   
                   {profile.cv.icl[profile.cv.icl.length-1].dpna ? <ListGroupItem> Differently abled Certificate not available </ListGroupItem> : null }
                   {profile.cv.icl[profile.cv.icl.length-1].dpf ? <ListGroupItem> Differently abled Certificate not in acceptable format </ListGroupItem> : null }
                   {profile.cv.icl[profile.cv.icl.length-1].exsna ? <ListGroupItem> Ex-Serviceman certificate not available </ListGroupItem> : null }
                   {profile.cv.icl[profile.cv.icl.length-1].exsf ? <ListGroupItem> Ex-Serviceman certificate not in format </ListGroupItem> : null }

                   {profile.cv.icl[profile.cv.icl.length-1].govsna ? <ListGroupItem> Govt.School certificate (VI to XII) not available </ListGroupItem> : null }
                   {profile.cv.icl[profile.cv.icl.length-1].govsin ? <ListGroupItem> Govt.School certificate (VI to XII) not in format </ListGroupItem> : null }

                  </ListGroup>
                  </CardText>
                  </CardBody>   
                  </Card>    
          </Col>       
          </Row>
         ): null }

</div>

{profile.cv && profile.cv.st === "ineligible" && profile.cv.inel !== undefined && profile.cv.inel !== null && profile.cv.inel.length > 0 ? (
            <Card style={{ backgroundColor: "#FE6F5E", fontFamily: "Verdana",  color: 'darkred'}}> 
              <CardBody className="text-center">
                <CardTitle style={{ textAlign: "center", color: "white", fontWeight: "bolder"}}>  REASON FOR INELIGIBILITY </CardTitle>
                <CardSubtitle> </CardSubtitle>  
                <CardText style={{color: 'bistre', fontWeight: "bolder"}}>
                  {profile.cv.inel.map((prof) => (
                  <ListGroup>
                    
                  {prof.ineremarks !== null ? <ListGroupItem>  {prof.el} </ListGroupItem> : null }

                  </ListGroup>
                  ))}

                  </CardText>
                  </CardBody>
            </Card>
      
        ) : null }
         

        

        <div className="p-3">
               
            {profile.dpi !== undefined && profile.dpi !== null && profile.dpi.flag !== undefined && profile.dpi.flag.toString() === "success" &&
                <Card style={{ backgroundColor: "lightgrey", fontFamily: "Verdana",  color: 'darkgreen', fontSize: "11pt"}}> 
                <CardBody className="text-center">
                <CardTitle style={{ textAlign: "center", color: "black", fontWeight: "bolder"}}>  DPI Data </CardTitle>
                <CardSubtitle>  </CardSubtitle>  
                <CardText className="" style={{ color: 'darkgreen'}}>
                <ListGroup>
                <ListGroupItem> Status: <span style={{fontWeight: 'bold', textAlign: "right"}}> {profile.dpi && profile.dpi.flag} </span> </ListGroupItem>
                
                <ListGroupItem> Community: <span style={{fontWeight: 'bold', textAlign: "right"}}> {profile.dpi && profile.dpi.COM} </span> </ListGroupItem>

                <ListGroupItem> Name: <span style={{fontWeight: 'bold', textAlign: "right"}}> {profile.dpi && profile.dpi.NAME} </span> </ListGroupItem>

                <ListGroupItem> Group code: <span style={{fontWeight: 'bold', textAlign: "right"}}> {profile.dpi && profile.dpi.GRP} </span> </ListGroupItem>
                </ListGroup>
                </CardText>
                </CardBody>
                </Card>
}

{profile._ac && profile._ac.qy.toString() === "2023" && profile._ac.nbe.toString() === "1"  && ((profile.dpi === undefined) || (profile.dpi === null)) &&
                <Card style={{ backgroundColor: "lightgrey", fontFamily: "Verdana",  color: 'darkgreen', fontSize: "11pt"}}> 
                <CardBody className="text-center">
                <CardTitle style={{ textAlign: "center", color: "white", fontWeight: "bolder"}}>  DPI Not Matched </CardTitle>
                <CardSubtitle>  </CardSubtitle>  
                <CardText className="" style={{ color: 'crimson'}}>
                <ListGroup>
                <ListGroupItem> Please Verify Roll Number / Register Number, Name, Date of Birth, Qualifying Exam, Qualifying Year, and Board  </ListGroupItem>
                </ListGroup>
                </CardText>
                </CardBody>
                </Card>
}

{profile.dpi !== undefined && profile.dpi !== null && profile.dpi.flag !== undefined && (profile.dpi.flag.toString() === "duplicate")  &&
                <Card style={{ backgroundColor: "lightgrey", fontFamily: "Verdana",  color: 'darkgreen', fontSize: "11pt"}}> 
                <CardBody className="text-center">
                <CardTitle style={{ textAlign: "center", color: "white", fontWeight: "bolder"}}>  DPI Data - Candidate has made multiple applications </CardTitle>
                <CardSubtitle>  </CardSubtitle>  
                <CardText className="" style={{ color: 'crimson'}}>
                <ListGroup>
                <ListGroupItem> Status: <span style={{fontWeight: 'bold', textAlign: "right"}}> {profile.dpi && profile.dpi.flag} </span> </ListGroupItem>
                <ListGroupItem> Community: <span style={{fontWeight: 'bold', textAlign: "right"}}> {profile.dpi && profile.dpi.COM} </span> </ListGroupItem>

                <ListGroupItem> Name: <span style={{fontWeight: 'bold', textAlign: "right"}}> {profile.dpi && profile.dpi.NAME} </span> </ListGroupItem>
                <ListGroupItem> Group code: <span style={{fontWeight: 'bold', textAlign: "right"}}> {profile.dpi && profile.dpi.GRP} </span> </ListGroupItem>
                <ListGroupItem> Result: <span style={{fontWeight: 'bold', textAlign: "right"}}> {profile.dpi && profile.dpi.R_SULTC} </span> </ListGroupItem>

                </ListGroup>
                </CardText>
                </CardBody>
                </Card>
}

      
        </div>   

        <div className="but-center"> 
                      
                      {/* <input type='submit' style={{color: "white"}} className='btn btn-warning' onClick={ e => onDown(e) } value="Download certificates"/> */}

                      <Dloadc/>
        </div>


        <Row className="my-2 but-center">
        <input type="submit"  value="View more details" className="btn btn-primary" onClick= {e => goback(e)} />
        
          {(profile._coEd) || (profile._fzup) ? 
          <input type="submit"  value="Open Upload for Candidate" className="btn btn-danger" onClick= {e => gounfreezecand(e)} />
          :
         <input type="submit"  value="Freeze Upload for Candidate" className="btn btn-danger" onClick= {e => gofreezecand(e)} />
          }
        

        {/* <Row className="container2 but-center">
        {profile.cv && ((profile.cv.st === "vcomplete") || (profile.cv.st === "ucomplete") || (profile.cv.st === "incomplete")) ?
        <div className="container badge" style={{fontWeight: 'bold', background: 'lightblue'}}>
        <h5 style={{fontWeight: "bold", color: "black"}}>                  
              Release Application
        </h5>
        <form>
        
        <div className="form form-text text-center" style={{padding: "10px", fontWeight: 'bold', color: "red", textAlign: "center"}}> Enter Coordinator Comments </div>
          <textarea cols="80" rows="2" className="textarea2" name="cremarks" value={formData.cremarks}  onChange = {e => onChange(e)}/>
          </form>
        <input type="submit"  value="Request Release to Verification" className="btn btn-danger" onClick= {e => changeVerStatus(e)} /> 
        <input type="submit"  value="Request Release to Updation" className="btn btn-success" onClick= {e => changeUpdStatus(e)} /> </div>
        : null}        

<div style={{paddingTop: "100px"}}> </div>
        
        {profile.cv && ((profile.cv.st === 'incomplete')) ?
        (<div>
          {profile.cv.st === "incomplete" &&
          <input type="submit"  value="Incomplete to Complete" className="btn btn-success" onClick= {e => changeIncStatus(e)} /> }
          </div>
          )                    
        : null } */}

        {profile.cv && ((profile.cv.st === "incomplete") || (profile.cv.st === "ccomplete") || (profile.cv.st === "ucomplete") || (profile.cv.st === "vcomplete") || (profile.cv.st === "ineligible")) &&
        <input type="submit"  value="CV Report" className="btn btn-primary" onClick= {e => seepdf(e)} /> }

      {profile.cv && ((profile.cv.st === "incomplete")) &&
        <input type="submit" style={{"color": "white"}} value="Update Incomplete List of Certificates" className="btn btn-dark" onClick= {e => pendingupd(e)} /> }
        
        <input type="submit"  value="Cancel" className="btn btn-light" onClick= {e => clearProf(e)} /> 


        

        {/* {profile.cv && (profile.cv.st.toString() === "open") &&

        <input type="submit"  value="Verify" className="btn btn-success" onClick= {e => handleVerifyRow(profile._aid)} /> 
        
        }

        {profile.cv && (profile.cv.st.toString() === "toupdate") &&

        <input type="submit"  value="Update Details" className="btn btn-primary" onClick= {e => handleEditRow(profile._aid)} /> 

        } */}
        
        </Row>


        {profile.cv && ((profile.cv.st === "vcomplete") || (profile.cv.st === "ucomplete") || (profile.cv.st === "incomplete")) ?
        <div className="container badge" style={{fontWeight: 'bold'}}>
        <h5 style={{fontWeight: "bold", color: "black"}}>                  
              Release Application
        </h5>
        <form>
        
        <div className="form text-center" style={{padding: "10px", fontWeight: 'bold', color: "navy", textAlign: "center"}}> Enter Coordinator Comments </div>
          <textarea cols="80" rows="5" className="textarea2" name="cremarks" style={{textAlign: "center", fontSize: "12pt", color: "black"}} value={formData.cremarks}  onChange = {e => onChange(e)}/>
          </form>
          <div style={{paddingTop: "50px"}} />
        <input type="submit"  value="Request Release to Verification" className="btn btn-dark" onClick= {e => changeVerStatus(e)} /> 
        <input type="submit"  value="Request Release to Updation" className="btn btn-primary" onClick= {e => changeUpdStatus(e)} /> 
        {profile.cv.st === "incomplete" &&
        <button value="" className="btn btn-success" onClick= {e => changeIncStatus(e)}> Incomplete to Complete </button>    }

        </div>

        : null}        

        

        <div style={{paddingTop: "100px"}}> </div>
        
        
        {/* {profile.cv && ((profile.cv.st === "incomplete")) &&

        <form className="form my-2">            
            <div className='but-center'>
                <button
                onClick={() => changeNE()}
                type='button'
                className='btn' style={{color: "white", backgroundColor: "maroon"}}
                >
                Move this Application from Incomplete to InEligible
                </button>  
                <div className="p-2" />                
                
            </div>
                  
            {displayne && (            
            
            <div  style={{textAlign: "left", backgroundColor: "white"}} className="">              
              
              <div className="" style={{border: ""}}>

                <div className="form-group">
                <small className="form-text">         
                <label style={{color: "navy"}}> Please Select Ineligible Category </label> </small>
                <select name="elist" style={{color: "red"}} value={elist} onChange = {e => onChange(e)}>                           
                    {INELIGIBLE_LIST.map(e => <option key={e.key}> {e.label} </option>) }
                </select>
                </div>

                 <div className="form-group">   

                  <small className="form-text">         
                  <label style={{color: "navy"}}> Enter Reasons for Rejection </label>             
                  </small>
                  <textarea cols="80" rows="2" style={{color: "crimson"}} className="textarea2" name="ineremarks" value={formData.ineremarks}  onChange = {e => onChange(e)}/>
                </div>                

              </div>
              <input type="submit"  value="CONFIRM" className="btn btn-danger" onClick= {e => OnInelig(e)} /> 

            </div>

            )}

            </form> } */}

        

        {/* {profile.cv && ((profile.cv.st === "ineligible")) &&

        <form className="form my-2">            
            <div className='but-center'>
                <button
                onClick={() => changeNE()}
                type='button'
                className='btn' style={{color: "white", backgroundColor: "orange"}}
                >
                Additions to InEligible List
                </button>  
                <div className="p-2" />                
                
            </div>
                  
            {displayne && (            
            
            <div  style={{textAlign: "left", backgroundColor: "white"}} className="">              
              
              <div className="" style={{border: ""}}>

                <div className="form-group">
                <small className="form-text">         
                <label style={{color: "navy"}}> Please Select Ineligible Category </label> </small>
                <select name="elist" style={{color: "red"}} value={elist} onChange = {e => onChange(e)}>                           
                    {INELIGIBLE_LIST.map(e => <option key={e.key}> {e.label} </option>) }
                </select>
                </div>

                 <div className="form-group">   

                  <small className="form-text">         
                  <label style={{color: "navy"}}> Enter Reasons for Rejection </label>             
                  </small>
                  <textarea cols="80" rows="2" style={{color: "crimson"}} className="textarea2" name="ineremarks" value={formData.ineremarks}  onChange = {e => onChange(e)}/>
                </div>                

              </div>
              <input type="submit"  value="CONFIRM" className="btn btn-danger" onClick= {e => OnInelig(e)} /> 

            </div>

            )}

            </form> } */}
        
        </div>)}
        </Fragment>
      ) : null
      }
    
    </> ) }
    </Fragment>
    </section>
  );
}

CVerSearch.propTypes = {
  getProfileCor: PropTypes.func.isRequired,
  getIncomplete: PropTypes.func.isRequired,  
  clearApplication: PropTypes.func.isRequired,
  changeAppStatus: PropTypes.func.isRequired,
  getProfilePdf: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  movetoIneligible: PropTypes.func.isRequired,
  checkunfreezecand: PropTypes.func.isRequired,
  checkfreezecand: PropTypes.func.isRequired,

  getProfileVer: PropTypes.func.isRequired,
  getProfileUpd: PropTypes.func.isRequired,
  listAll: PropTypes.func.isRequired,
  getProfileCorSear: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  profile: state.profile
});

export default connect(
  mapStateToProps,
  { getProfileCor, clearApplication, changeAppStatus, getIncomplete, getProfilePdf, movetoIneligible, checkfreezecand, checkunfreezecand, getProfileVer, getProfileUpd, listAll, getProfileCorSear}
)(CVerSearch);