import React, { useEffect } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DashboardActions from './DashboardActions';
import { getCurrentProfile, clearProfile, cleargid } from '../../actions/profile';

const Dashboard = ({
  getCurrentProfile,
  clearProfile,
  auth: { user },
  profile: { profile }
}) => {

  const navigate = useNavigate();

  useEffect(() => {
    if(user !== null && user !== undefined && ((user.ltype.toString() === "V") || (user.ltype.toString() === "F")))
      {
         navigate("/tfcdash");
      }    
    else if(user !== null && user !== undefined && user.ltype.toString() === "C")
    {
       navigate("/coord2");
    }  
    
  }, [getCurrentProfile, profile, user]);


  const clearNext = async() => {
    //clearProfile(history, "3");   
    await clearProfile();
    //navigate("/search-profile");
  }

  return (
    <section className="container">
      {/* <h1 className="large text-primary">{user.tfcname}</h1> */}

      <h2> Principal System </h2>
      <p className="lead">
        <i className="fas fa-user" /> Welcome <br/> {user && user.tfccode} -- {user && user.tfcname}
      </p>
      {/* <div style={{textAlign: "right"}}>
      <button
          onClick={() => cclearProfile()}
          className="btn btn-light" style={{alignItems: "right"}}
        >
          Clear Search
      </button>
      </div> */}

        <div className="form-group" style={{textAlign: "right"}}>          
          <input type="submit" className='btn btn-light'  value="Clear / New Search" onClick= {e => clearNext(e)} />    
        </div>

      {profile !== null ? (
        <>
        <h2 className="my-2">Candidate Details</h2>
        <table className="table">
        <thead>
          <tr>
            <th>Candidate Name</th>
            <th className="hide-sm">Email</th>
            <th className="hide-sm">Application number</th>
            <th className="hide-sm">Mobile number</th>
            <th className="hide-sm">Photo</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr>
            <td> {profile._n} </td>
            <td> {profile.e} </td>
            <td> {profile._aid} </td>
            <td> {profile.m} </td>
            <td>
            <img className="" style={{height: '250px', width: 'auto'}} src= {profile._docs.filter((e) => e.id.toString() === "passport_photo").map((e) => e.durl)} alt="Photo-Not-Uploaded" />
            </td>
          </tr>
        </tbody>
        </table>
        <br/>
          <DashboardActions />
          
        </>
      ) : (
        <>
          <p>Search Candidate by application number / mobile number / email / hsc register number </p>
          <Link to="/search-profile" className="btn btn-primary my-1">
            Search
          </Link>
        </>
      )}
    </section>
  );
};

Dashboard.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  clearProfile: PropTypes.func.isRequired,
  cleargid: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile
});

export default connect(mapStateToProps, { getCurrentProfile, clearProfile, cleargid })(
  Dashboard
);
