import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import {S3_UPLOAD_CERTIFICATES_LIST, YES_NO_ARRAY, NATIVITY, ELIGIBILITY_TYPE, DIFF_ABLED_TYPES} from '../../Constant';
import {Card, ListGroup, CardSubtitle, CardTitle, CardText, ListGroupItem, CardBody, Table, Row} from 'reactstrap';

import { clearApplication2} from '../../actions/vermaster';
import {listAll, openCert, gettfc} from '../../actions/dload';


//const {GENDER, RELIGION, NATIONALITY, NATIVITY, YES_NO_ARRAY} = require("../../Constant");

const dloadc = ({
  getProfileCor,
  getIncomplete,
  listAll, openCert, gettfc,
  auth: { user },
  profile: {logloading, profile, fnames, profile2, applnid, loading, getdb },
  history, 
  clearApplication2,
  changeAppStatus
}) => {


  var certnames = [];
  certnames.push({key: 1, value: "x"});
  certnames.push({key: 2, value: "xi"});
  certnames.push({key: 2, value: "xii"});
  certnames.push({key: 3, value: "tc"});
  certnames.push({key: 4, value: "passport_photo"});
  certnames.push({key: 5, value: "sign"});


  const onBack = async e => {    
    history.push('/dashboard');    
  };

  function loadcerts() {
    //("invoking init " + logloading);
    if(logloading === true)
    {      
      if(profile && profile._p && profile._p.co !== "OC")
        certnames.push({key: 6, value: "community"});
      
      if(profile && profile._sr && profile._sr.dap === "Y" && profile._sr.adt  === "1")
        certnames.push({key: 7, value: "diff_abled_blind"});

      if(profile && profile._sr && profile._sr.dap === "Y" && profile._sr.adt === "2")
        certnames.push({key: 7, value: "diff_abled_deaf"});
      
      if(profile && profile._sr && profile._sr.dap === "Y" && profile._sr.adt === "3")
        certnames.push({key: 7, value: "diff_abled_loco"});
      
      if(profile && profile._sr && profile._sr.dap === "Y" && profile._sr.adt === "4")
        certnames.push({key: 7, value: "diff_abled_autism"});
      
      if(profile && profile._sr && profile._sr.dap === "Y" && profile._sr.adt === "5")
        certnames.push({key: 7, value: "diff_abled_multiple"});
      
      if(profile && profile._sr && profile._sr.ex === "Y")
          certnames.push({key: 8, value: "exservice"});

      if(profile && profile._p && profile._p.nt === "TN" && profile._sc.sit === "N")
          {
            certnames.push({key: 9, value: "nativity"});
          }
      if(profile && profile._p && profile._sc.sit === "Y" && profile._p.nt === "OTH")
          {
            certnames.push({key: 10, value: "eligibility"});
          }

      if(profile && profile._p && profile._p.ny === "SLR")
          certnames.push({key: 11, value: "srilankanRefuge"});

          if(profile && profile._sc && profile._sc.sigsc && profile._sc.sigsc === "Y")
          certnames.push({key: 12, value: "GovtSc"});

    }
  }


  return (    
    <section className=''>
    
    <Fragment>      

      {logloading ? loadcerts() : null }
      
        { (profile && profile.cv === undefined) || (profile && profile.cv && profile.cv.tfc && profile.cv.tfc.toString() !== user.tfccode.toString()) ?
          <h2 style={{textAlign: "center"}}> Candidate belongs to Some other TFC </h2> 
      
      :

            

      profile && profile._docs && profile._docs.length > 0 && user && (user.ltype === 'V' || user.ltype === 'U' || user.ltype === "F") || (user.ltype === "C") ?
      

      <div className="">     

 
            <Card className='' style={{fontFamily: "Verdana",  fontSize: "9pt"}}> 
            <CardBody className="text-left">
              <CardTitle style={{ textAlign: "center", color: "black", fontWeight: "bolder"}}>  List of Mandatory Certificates to be verified </CardTitle>
            <CardSubtitle> </CardSubtitle>  
            <CardText>
              <div className="grid-6">
                
                {certnames.map((pp, i) => {
                    return (<ListGroupItem style={{fontSize: "9pt", fontWeight: "bold"}}> {S3_UPLOAD_CERTIFICATES_LIST.filter(e => e.key.toString() === pp.value).map(e => e.label)} </ListGroupItem>)
                })}
                
                </div>
            </CardText>
            </CardBody>
            </Card>
 
           <div className='container2'>
            <table className="table table-bordered" style={{fontSize: "11pt", borderStyle: "2px solid black"}} >
            <thead className='thead-light'>
              
              <tr>   
                <th scope="col"> Sl.No </th>             
                
                <th scope="col"> Certificate Name </th>                
                                             

                <th scope = "col" > Download File </th> 

                <th scope = "col" > Saved File </th> 
            
              </tr>
            </thead>
            <tbody>

          
          {profile && profile._docs.map((cert, i) => (
            <tr key={i} >            
            <td style={{textAlign: "left", fontSize: "10pt"}}> {i+1} </td>    
            
            <td >                         
            {              
            S3_UPLOAD_CERTIFICATES_LIST.filter(e => e.key.toString() === cert.id.toString()).map(e => e.label) !== "" ?
            S3_UPLOAD_CERTIFICATES_LIST.filter(e => e.key.toString() === cert.id.toString()).map(e => e.label) :
            "OTHER CERTIFICATE"
            }

            </td>

            <td> 
            
                <a  target="_blank" rel="noopener noreferrer"  style={{textDecoration: "underline", color: "magenta"}} href={cert.durl}> Download </a> 
            </td>

            <td> 
              {cert.fileName} --- 
              <span style={{color: "green"}}>
              Size: {cert.PROPS && Math.round(cert.PROPS.size/1024)} KB  
              </span>
            </td>

          </tr>   ) ) }

          </tbody>
          </table>
          </div>
 
 

          </div> : <h5 className="my-2 profile-top" style={{color: "crimson"}}> Certificates Not Uploaded </h5>
        }        

    </Fragment>
    </section>
  );
}

dloadc.propTypes = {
  clearApplication2: PropTypes.func.isRequired,
  openCert: PropTypes.func.isRequired,
  gettfc: PropTypes.func.isRequired,
  listAll: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  profile: state.profile
});

export default connect(
  mapStateToProps,
  { clearApplication2, listAll, openCert, gettfc}
)(dloadc);