import api from '../utils/api';
import { setAlert } from './alert';

import {
    GET_PROFILE,
    PROFILE_ERROR,
    CLEAR_PROFILEAPP, GET_UPDLIST, CLEAR_UPDLIST,  CLEAR_PROFILE,
    EDIT_PROFILE, SET_DBCALL, RESET_DBCALL
} from './types';

//Create or Update Profile

import moment from 'moment';

export const movetoIneligible = (formData, history) => async dispatch => {
  try {

      dispatch({
          type: SET_DBCALL
        });

      //dispatch(setAlert("Processing...", 'danger'));

      console.log("Moving to ineligible list " + formData.appId);

      const config = {
          headers: {
              'Content-type': 'application/json'
          }
      }
      const res = await api.post('/vermaster/ineligible', formData, config);

      dispatch({
          type: RESET_DBCALL
        });

      dispatch({
          type: CLEAR_PROFILE,
          //payload: res.data
      });

      //dispatch(setAlert(res.data.msg, 'primary'));


  } catch (err) {

      console.log("Catch");

      dispatch({
          type: RESET_DBCALL
        });

      const errors = err.response.data.errors;

      if(errors) {
          errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
      }

      dispatch({
          type: PROFILE_ERROR,
          payload: { msg: err.response.statusText, status: err.response.status }
      });

  }
};


export const updateCV = (formData, history) => async dispatch => {
    try {

        dispatch({
            type: SET_DBCALL
          });

        //dispatch(setAlert("Processing...", 'danger'));

        const config = {
            headers: {
                'Content-type': 'application/json'
            }
        }
        //console.log(formData);
        //console.log("Received from Client" + formData.vflag);
        const res = await api.post('/vermaster/update', formData, config);
        //console.log("dispatched updatecv for " + formData._aid);
        //console.log(formData);

        dispatch({
            type: RESET_DBCALL
          });

        

        dispatch({
            type: CLEAR_PROFILEAPP,
            payload: res.data
        });

        dispatch(setAlert(res.data.msg, 'primary'));

        if(formData.vflag.toString() === "incomplete"){
          dispatch(setAlert("Proceed to fill incomplete list of certificates", 'danger'));
        }


    } catch (err) {

        console.log("Catch");

        dispatch({
            type: RESET_DBCALL
          });

        const errors = err.response.data.errors;

        if(errors) {
            errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }

        dispatch({
            type: PROFILE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });

    }
};


export const checkfreezecand = (appId, history) => async dispatch => {
  try {    
    
    dispatch({
      type: SET_DBCALL
    });


          
      console.log("Invoking freeze " + appId);
      const res = await api.get(`/vermaster/freezecandcor/${appId}`);

      dispatch(setAlert(res.data.msg, 'primary'));

        dispatch({
             type: CLEAR_PROFILE,
             payload: res.data
        });        

        dispatch({
          type: RESET_DBCALL
          });
  } 
  
  catch (err) {

    console.log(err);
    dispatch({
      type: RESET_DBCALL
    });
  
    console.log(err.response);

    if(err.response.status === 400) {
      dispatch(setAlert("Freeze failed", 'danger'));
      dispatch({
        type: PROFILE_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status }
    });  
  
  }          
  
  if(err.response.status === 403 || err.response.status === 401) {
      console.log("Catch invalid token");
  
       
  //      history.push('/expire');
        if(err.response.status === 401)
        {
          dispatch(setAlert("SESSION EXPIRED. LOGIN AGAIN", 'danger'));
          //const res = await api.get(`/api/auth/logout`);
        }
      }
  
  }
};

export const checkunfreezecand = (appId, history) => async dispatch => {
  try {    
    
    dispatch({
      type: SET_DBCALL
    });

          
      console.log("Invoking freeze " + appId);
      const res = await api.get(`/vermaster/unfreezecandcor/${appId}`);

      dispatch(setAlert(res.data.msg, 'primary'));

        dispatch({
             type: CLEAR_PROFILE,
             payload: res.data
        });        

        dispatch({
          type: RESET_DBCALL
          });
  } 
  
  catch (err) {

    console.log(err);
    dispatch({
      type: RESET_DBCALL
    });
  
    console.log(err.response);

    if(err.response.status === 400) {
      dispatch(setAlert("Freeze failed", 'danger'));
      dispatch({
        type: PROFILE_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status }
    });  
  
  }          
  
  if(err.response.status === 403 || err.response.status === 401) {
      console.log("Catch invalid token");
  
       
//        history.push('/expire');
        if(err.response.status === 401)
        {
          dispatch(setAlert("SESSION EXPIRED. LOGIN AGAIN", 'danger'));
          //const res = await api.get(`/api/auth/logout`);
        }
      }
  
  }
};


export const getProfileCor = appId => async dispatch => {
  try {

      dispatch({
          type: SET_DBCALL
        });
  
        dispatch({
          type: CLEAR_PROFILE,
          payload: null
      });


    console.log("Invoking get profile by ID");
    const res = await api.get(`/vermaster/cor/${appId}`);

    dispatch({
      type: GET_PROFILE,
      payload: res.data
    });      
    
    //console.log(res.data);
    //console.log("Invoking get profile by ID");
    //const res2 = await api.get(`/api/master/cor/log/${appId}`);

    //dispatch({
    //  type: LOG_PROFILE,
    //  payload: res2.data
    //});      

    dispatch({
      type: RESET_DBCALL
    });

    
  } 
  
  catch (err) {

      console.log("Catch");
      dispatch({
          type: RESET_DBCALL
        });
      
      dispatch(setAlert(err.response.data.msg, 'danger'));
      
      /*console.log(err.response.data);
      const errors = err.response.data;

      if(errors) {
          errors.forEach(error => dispatch(setAlert(errors, 'danger')));
      }*/

      dispatch({
          type: PROFILE_ERROR,
          payload: { msg: err.response.statusText, status: err.response.status }
      });

  }
};


export const getProfileCorSear = appId => async dispatch => {
  try {

      dispatch({
          type: SET_DBCALL
        });
  
        dispatch({
          type: CLEAR_PROFILE,
          payload: null
      });


    console.log("Invoking get profile by ID");
    const res = await api.get(`/vermaster/scor/${appId}`);

    dispatch({
      type: GET_PROFILE,
      payload: res.data
    });      
    
    //console.log(res.data);
    //console.log("Invoking get profile by ID");
    //const res2 = await api.get(`/api/master/cor/log/${appId}`);

    //dispatch({
    //  type: LOG_PROFILE,
    //  payload: res2.data
    //});      

    dispatch({
      type: RESET_DBCALL
    });

    
  } 
  
  catch (err) {

      console.log("Catch");
      dispatch({
          type: RESET_DBCALL
        });
      
      dispatch(setAlert(err.response.data.msg, 'danger'));
      
      /*console.log(err.response.data);
      const errors = err.response.data;

      if(errors) {
          errors.forEach(error => dispatch(setAlert(errors, 'danger')));
      }*/

      dispatch({
          type: PROFILE_ERROR,
          payload: { msg: err.response.statusText, status: err.response.status }
      });

  }
};




export const changeAppStatus = (formData, history) => async dispatch => {
    
    try {

        dispatch({
            type: SET_DBCALL
          });

        const config = {
            headers: {
                'Content-type': 'application/json'
            }
        }
        
        const res = await api.post('/vermaster/changestatus', formData, config);
        console.log("dispatched change status");
        
        dispatch(setAlert(res.data.msg, 'primary'));

        dispatch({
            type: CLEAR_PROFILE,
            payload: res.data
        });

        history('/tfcdash');        

        dispatch({
            type: RESET_DBCALL
          });

    } catch (err) {

        console.log("Catch Status db error");
        dispatch({
            type: RESET_DBCALL
          });
        
        const errors = err.response.data.errors;

        if(errors) {
            errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }

        dispatch({
            type: PROFILE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });

    }
};

export const getUpdpendingforme = uname => async dispatch => {
  try {

      dispatch({
          type: SET_DBCALL
        });

        dispatch({
          type: CLEAR_UPDLIST,
      });

  console.log("Invoking get profile Upd");
  const res = await api.get(`/vermaster/updme/${uname}`);

    dispatch({
      type: GET_UPDLIST,
      payload: res.data
    });      
    
    console.log("after db retrieve");
    //console.log(res.data);

    dispatch({
      type: RESET_DBCALL
    });

  } 
  
  catch (err) {

      console.log("Catch");

      dispatch({
          type: RESET_DBCALL
        });
          
      dispatch(setAlert(err.response.data.msg, 'danger'));
      
      /*console.log(err.response.data);
      const errors = err.response.data;

      if(errors) {
          errors.forEach(error => dispatch(setAlert(errors, 'danger')));
      }*/

      dispatch({
          type: PROFILE_ERROR,
          payload: { msg: err.response.statusText, status: err.response.status }
      });

  }
};


export const getProfileUpd = appId => async dispatch => {
    try {

        dispatch({
            type: SET_DBCALL
          });

          dispatch({
            type: CLEAR_PROFILEAPP,
            payload: null
        });

    console.log("Invoking get profile Upd");
    const res = await api.get(`/vermaster/upd/${appId}`);
  
      dispatch({
        type: GET_PROFILE,
        payload: res.data
      });      
      
      console.log("after db retrieve");
      //console.log(res.data);

      dispatch({
        type: RESET_DBCALL
      });

    } 
    
    catch (err) {

        console.log("Catch");

        dispatch({
            type: RESET_DBCALL
          });
            
        dispatch(setAlert(err.response.data.msg, 'danger'));
        
        /*console.log(err.response.data);
        const errors = err.response.data;

        if(errors) {
            errors.forEach(error => dispatch(setAlert(errors, 'danger')));
        }*/

        dispatch({
            type: PROFILE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });

    }
};

export const getProfilePdf = appId => async dispatch => {
    try {

        dispatch({
            type: SET_DBCALL
          });

          dispatch({
            type: CLEAR_PROFILEAPP
          });

      console.log("Invoking get profile PDF");
      if(appId !== null && appId !== undefined) {
        const res = await api.get(`/vermaster/pdf/${appId}`);
      
  
      dispatch({
        type: GET_PROFILE,
        payload: res.data
      });          
      }
      
      console.log("after db retrieve");
      //console.log(res.data);

      dispatch({
        type: RESET_DBCALL
      });



    } 
    
    catch (err) {

        console.log("Catch");

        dispatch({
            type: RESET_DBCALL
          });

        
        dispatch(setAlert(err.response.data.msg, 'danger'));
        
        /*console.log(err.response.data);
        const errors = err.response.data;

        if(errors) {
            errors.forEach(error => dispatch(setAlert(errors, 'danger')));
        }*/

        dispatch({
            type: PROFILE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });

    }
};

export const getProfileVer = appId => async dispatch => {
    try {

        dispatch({
            type: SET_DBCALL
          });
    
          dispatch({
            type: CLEAR_PROFILEAPP,
            payload: null
        });


      console.log("Invoking get profile by ID");
      const res = await api.get(`/vermaster/ver/${appId.appln}`);
  
      dispatch({
        type: GET_PROFILE,
        payload: res.data
      });      
      
      //console.log(res.data);

      dispatch({
        type: RESET_DBCALL
      });

    } 
    
    catch (err) {

        console.log("Catch");
        
        dispatch(setAlert(err.response.data.msg, 'danger'));
        
        /*console.log(err.response.data);
        const errors = err.response.data;

        if(errors) {
            errors.forEach(error => dispatch(setAlert(errors, 'danger')));
        }*/

        dispatch({
            type: PROFILE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });

    }
};



// Get profile by ID
export const getProfileById = (appId) => async dispatch => {
    try {

        dispatch({
            type: SET_DBCALL
          });

          //dispatch(setAlert("Processsing ", 'danger'));

    console.log("Invoking get profile by ID " + appId);
      const res = await api.get(`/vermaster/user/${appId}`);
  
      dispatch({
        type: GET_PROFILE,
        payload: res.data
      });      

      dispatch({
        type: RESET_DBCALL
      });
  
      
      //console.log(res.data);
      console.log("output for get profile by id");
    } 
    
    catch (err) {

        console.log(err);
        dispatch({
          type: RESET_DBCALL
        });

        console.log("Catch");
        
        dispatch(setAlert(err.response.data.msg, 'danger'));
        
        /*console.log(err.response.data);
        const errors = err.response.data;

        if(errors) {
            errors.forEach(error => dispatch(setAlert(errors, 'danger')));
        }*/

        dispatch({
            type: PROFILE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });

    }
};

export const getProfileAdmin = (appId) => async dispatch => {
    try {
    
    console.log("Invoking get profile for Admin " + appId);
      const res = await api.get(`/vermaster/admin/${appId}`);
  
      dispatch({
        type: GET_PROFILE,
        payload: res.data
      });      
      
      //console.log(res.data);
      console.log("output for get profile by id");
    } 
    
    catch (err) {

        console.log("Catch");
        
        dispatch(setAlert(err.response.data.msg, 'danger'));
        
        /*console.log(err.response.data);
        const errors = err.response.data;

        if(errors) {
            errors.forEach(error => dispatch(setAlert(errors, 'danger')));
        }*/

        dispatch({
            type: PROFILE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });

    }
};

  export const clearApplication = (history) => async dispatch => {
    //console.log("clearing the profile");
    dispatch({
        type: SET_DBCALL
      });

    dispatch({
        type: CLEAR_PROFILE,
        payload: null
    });    

    dispatch({
        type: RESET_DBCALL
      });
  };

  export const clearApplication2 = (history) => async dispatch => {
    //console.log("clearing the profile");
    dispatch({
        type: CLEAR_PROFILE,
        payload: null
    });

    history.push('/dload');
  };

  export const createCV = (formData, history) => async dispatch => {
    try {


        dispatch({
            type: SET_DBCALL
          });

        const config = {
            headers: {
                'Content-type': 'application/json'
            }
        }

        //console.log(formData);

        if(formData._cv !== undefined ) {

          //console.log("got you");
  
          var d1 = moment(formData._cv.d, 'DD-MM-YYYY');
          var r1 = new Date(d1);
  
          //var td = moment().format('DD-MM-YYYY');
          //console.log("td " + td);
          var r2 = new Date();
  
          //console.log(r1);
          //console.log(r2);

          //console.log("diff " + (r1-r2));

          /*const diffTime = Math.abs(r1 - r2);
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
          console.log(diffTime + " milliseconds");
          console.log(diffDays + " days"); */

          const _MS_PER_DAY = 1000 * 60 * 60 * 24;

            // a and b are javascript Date objects
            function dateDiffInDays(a, b) {
              // Discard the time and time-zone information.
              const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
              const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

              return Math.floor((utc2 - utc1) / _MS_PER_DAY);
            }

          //console.log(dateDiffInDays(r2,r1));

          if(parseInt(dateDiffInDays(r2,r1),10) <= 0) {
              console.log("Allowed CV");

              //dispatch(setAlert("Processing...", 'danger'));
              const res = await api.post('/vermaster/create', formData, config);
              console.log("dispatched addcv for " + formData._aid);        
              
      
              dispatch(setAlert(res.data.msg, 'primary'));
                      
    
          }
          else
          {
              console.log("CV Not allowed");
              dispatch(setAlert("Kindly Check Schedule", 'danger'));
          }
      }

      dispatch({
        type: RESET_DBCALL
      });       



      dispatch({
        type: CLEAR_PROFILE,
      });
        //console.log(formData);
        //console.log("Received from Client" + formData.vflag);

      
    } catch (err) {        

        console.log("Catch");

        dispatch({
            type: RESET_DBCALL
          });

        const errors = err.response.data.errors;

        if(errors) {
            errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }

        dispatch({
            type: PROFILE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });

    }
};

export const adminchange = (formData, history) => async dispatch => {
    try {

        const config = {
            headers: {
                'Content-type': 'application/json'
            }
        }
        //console.log(formData);
        //console.log("Received from Client" + formData.vflag);
        const res = await api.post('/vermaster/achange', formData, config);
        //console.log("dispatched admin changes for " + formData._aid);
        
        dispatch(setAlert(res.data.msg, 'primary'));

        //dispatch( setAlert(formData.vflag === 'vcomplete' ? 'Completed Successfully' : 
          //      (formData.vflag === 'toupdate' ? 'Sent for Updation' : 
           //     (formData.vflag === 'present' ? 'Marked Present' : null)), 'primary'));
        
        dispatch({
             type: CLEAR_PROFILE,
             payload: res.data
        });
                
        //console.log("Got after db");
        //console.log(res.data);
        //if(!edit) {
        history.push('/dashboard');
        //}

    } catch (err) {

        console.log("Catch");

        const errors = err.response.data.errors;

        if(errors) {
            errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }

        dispatch({
            type: PROFILE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });

    }
};


export const createChangeCV = (formData, history, edit = false) => async dispatch => {
    try {


          const config = {
            headers: {
                'Content-type': 'application/json'
            }
        }

        dispatch({
            type: CLEAR_PROFILE,
            payload: null
       });

       dispatch({
        type: SET_DBCALL
      });

        //console.log("Received from Client" + formData.vflag);
        const res = await api.post('/vermaster/edit', formData, config);
        //console.log("dispatched addcv");
       

        dispatch( setAlert(formData.vflag === 'complete' ? 'Completed Successfully' : 
                (formData.vflag === 'toupdate' ? 'Send to Update' : 
                (formData.vflag === 'present' ? 'Marked Present' : (edit === true) ? 'Changes Saved' : "ERROR")), 'primary'));


        console.log("Got after db");
        //console.log(res.data);

        dispatch({
            type: EDIT_PROFILE,
            payload: res.data
        });


        history.push('/dashboard');

        dispatch({
            type: RESET_DBCALL
          });

    } catch (err) {

        console.log("Catch " + err.response.data.msg);
        dispatch({
            type: RESET_DBCALL
          });

        const errors = err.response.data.errors;                

        dispatch(setAlert(err.response.data.msg, 'danger', 8000));
        

        if(errors) {
            errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }

        dispatch({
            type: PROFILE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });

    }
};

export const saveIncompleteList = (formData) => async dispatch => {
    try {

        dispatch({
            type: SET_DBCALL
          });

        const config = {
            headers: {
                'Content-type': 'application/json'
            }
        }        
        //console.log("received");
        //console.log(formData);
        
        const res = await api.post('/vermaster/incomp', formData, config);
        console.log("dispatched incomp with data below");
        //console.log(formData);


        dispatch(setAlert("Incomplete List Saved Successfully", 'primary'));
        
        dispatch({
            type: CLEAR_PROFILEAPP                
                });
                
        console.log("Got after db");
        //console.log(res.data);

        dispatch({
            type: RESET_DBCALL
          });


    } catch (err) {

        console.log("Catch");
        dispatch({
            type: RESET_DBCALL
          });

        const errors = err.response.data.errors;

        if(errors) {
            errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }

        dispatch({
            type: PROFILE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });

    }
};