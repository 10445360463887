import React from 'react';

const Pagination = ({ postsPerPage, totalPosts, paginate }) => {
  const pageNumbers = [];

  var i= 1;

  for (i = 1; i <= totalPosts; i++) {
    pageNumbers.push(i);
  }

  return (
    <nav style={{font: "verdana", fontSize: "10pt"}}>
      <div className="">
      <ul className='pagination pagination-sm justify-content-center flex-wrap'>
      {pageNumbers.map(numb => (
          <li key={numb} className='page-item' >
            <a onClick={() => paginate(numb)} href='#res' className='page-link' style={{color: "darkred",  fontSize: "10pt"}}>
              {numb}
            </a>
          </li>
        ))}
      </ul>
      </div>
    </nav>
  );
};

export default Pagination;