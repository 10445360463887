import {
  GET_PROFILE,
  PROFILE_ERROR,
  CLEAR_PROFILE,
  UPDATE_PROFILE, GET_PROFILE3, CLEAR_PROFILEAPP, GET_UPDLIST, CLEAR_UPDLIST,
  GET_PROFILES,
  GET_REPOS, UPDATE_PROFILE22, GET_PROFILE22, EDIT_PROFILE22,
  NO_REPOS, GET_APPLNS, GET_PROFILE2, EDIT_PROFILE2, SET_GT, RESET_GT, SET_GID,
  CLEAR_GID, SET_DBCALL, RESET_DBCALL, GET_DDREPORT, SET_LOADING, RESET_LOADING, GET_PROFILE4
} from '../actions/types';

const initialState = {
  profile: null,
  profiles: [],
  repos: [],
  loading: true,
  getdb: false,
  error: {},
  profile2: null,
  applnid: null,
  allapplns: [],
  loadingall: false,
  fnames: [],    
  gid: null,
  alldata: [],
  gt: null, 
  profile3: null, profile4: null,
  updlist: []
};

function profileReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_PROFILE:
    case UPDATE_PROFILE:
      return {      
        ...state,
                profile: payload,
                loading: false,
                applnid: payload._aid,
                pphoto: payload._docs.filter((e) => e.id.toString() === "passport_photo").map((e) => e.durl)[0],
      };

      case GET_DDREPORT:
                return {
                    ...state,                    
                    loading: false,
                    alldata: payload
                };

      case CLEAR_GID:
      return {
        ...state,
        gid: null,
        loading: false
      };

      case SET_GID:
      return {
        ...state,
        gid: payload,
        loading: false
      };

    case GET_PROFILES:
      return {
        ...state,
        profiles: payload,
        loading: false
      };
    case PROFILE_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
        profile: null
      };
    case CLEAR_PROFILE:
      return {       
        ...state,
        profile: null,
        profiles: [],
        repos: [],
        loading: true,
        error: {},
        profile2: null,
        applnid: null,
        pphoto: null,
        allapplns: [],
        loadingall: false,
        fnames: [],  
        gid: null,  getdb: false, gt: null,
        alldata: [],
        profile3: null, profile4: null
       
      };

      case CLEAR_PROFILEAPP:
        return {
            ...state,
            profile: null,   
            profile2: null,             
            loading: true,
            getdb: false,
            error: null,
            allapplns: null,            
            
            //applnid: null              
        };  

        case GET_UPDLIST:
          return {
              ...state,
              updlist: payload, loading: false
          };

      case CLEAR_UPDLIST:
          return {
              ...state,
              updlist: []
          };
    
    case GET_REPOS:
      return {
        ...state,
        repos: payload,
        loading: false
      };
    case NO_REPOS:
      return {
        ...state,
        repos: []
      };

      case UPDATE_PROFILE22:
      return {
        ...state,
        profile: payload.profile,
        gid: payload.gid,
        loading: false
      };

      case GET_PROFILE3:
                return {
                ...state,
                profile3: payload,
                loading: false,
                // applnid: payload._aid
            };

      
            case GET_PROFILE4:
              return {
              ...state,
              profile4: payload,
              loading: false,
              // applnid: payload._aid
          };

      case EDIT_PROFILE2:
      case GET_PROFILE2:
                return {
                ...state,
                profile2: payload,
                loading: false,
                // applnid: payload._aid
            };

            case EDIT_PROFILE22:
              case GET_PROFILE22:
                        return {
                        ...state,
                        profile3: payload,
                        loading: false,
                        // applnid: payload._aid
                    };
            
            case GET_APPLNS:
                return {
                    ...state,
                    allapplns: payload,
                    loading: false,
                    loadingall: true
                };
    case SET_DBCALL:
                return {
                    ...state, getdb: true, loading: true
                };

                case RESET_DBCALL:
                    return {
                        ...state, getdb: false
                    };
    case SET_LOADING:
                        return {
                            ...state, loading: true
                        };

                case RESET_LOADING:
                    return {
                        ...state, loading: false
                    };

                    case SET_GT:
                        return {
                            ...state, gt: payload
                        };

                        case RESET_GT:
                          return {
                              ...state, gt: null
                          };
  
    default:
      return state;
  }
}

export default profileReducer;
