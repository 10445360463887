import React, { Fragment, useEffect, useState } from 'react';
//import { Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import { Link, Navigate, useNavigate } from 'react-router-dom';

import {Card, ListGroup, CardSubtitle, CardTitle, CardText, ListGroupItem, CardBody,
            Row, Col, Table} from 'reactstrap';

import {updateCV, clearApplication, getProfileUpd, getProfilePdf, getUpdpendingforme} from '../../actions/vermaster';
const {SCHOOL_BY_BLOCK, SCHOOL_BY_DISTRICT, ALL_SCHOOL, BLOCKS_BY_DISTRICT, BLOCKS} = require("../../Schools24");

const {STATES, GENDER, DIST_LIST, CASTE, PARENT_OCCUPATION, ANNUAL_INCOME, YES_NO_ARRAY, 
  RELIGION, NATIVITY, NATIONALITY, QUALIFYING_EXAM, EXAM_BOARDS, HSC_GROUPS, YEAR_OF_PASSING,
MEDIUM_OF_INSTRUCTION, HSC_ACADEMIC_GROUP_CODES, HSC_VOCATIONAL_GROUP_CODES, OTHER_GROUP_CODES,
DIFF_ABLED_TYPES, ELIGIBILITY_TYPE, CATEGORY_OF_SCHOOL, GOVT_SCHOOL_TYPE} = require("../../Constant");

const VerSearchMore = ({
  getProfileUpd, getProfilePdf, getUpdpendingforme,
  updateCV,
  clearApplication,
  auth: { user },
  profile: { profile, loading, getdb, applnid, updlist },
  history
}) => {

  const [formData, setFormData] = useState({
    gappid: '',
    vflag : "notyet",
    uremarks: '',    
    tfcname: '',
    username: '',
    ltype: '',
    uoname: '', x: false, xi: false, xii: false, tc: false, com: false, nat: false, elt: false, ex: false, dap: false, govs: false
  });

  const { gappid, uoname, uremarks, x, xi, xii, tc, com, nat, elt, ex, dap, govs } = formData;

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0,0);
  }, []);

  const onChange = e => {
    setFormData({ ...formData, [e.target.name] : e.target.value });  
    //name: e.target.value to change name alone
    //console.log(e.target.value);
  }

  const onSubmit = async e => {
    e.preventDefault();      
    //console.log("Appln number : "+ formData.gappid);         
    getProfileUpd(formData.gappid);    
  };

  const onUpdforme = async e => {
    e.preventDefault();      
    //console.log("Username : "+ user.username);         
    getUpdpendingforme(user.username);    
  };


  const onComplete= async e => {
    e.preventDefault();      

    const tobesent = {};    
    
    tobesent.uremarks = formData.uremarks;
    tobesent.uoname = formData.uoname;
    tobesent._aid = profile._aid;
    tobesent.tfccode = user.tfccode;
    tobesent.username = user.username;
    tobesent.ltype = user.ltype;    
    tobesent.vflag = "ucomplete";
    tobesent.x = x; tobesent.xi = xi; tobesent.xii = xii; tobesent.tc = tc; tobesent.com = com;
    tobesent.nat = nat; tobesent.elt = elt; tobesent.ex = ex; tobesent.dap = dap;
    tobesent.govs = govs;
  
    if(tobesent.uremarks === '' || tobesent.uoname === '')
        alert("enter updation remarks and officer name");
    else {

      if(x === false && xi === false && xii === false && tc === false && com === false && nat === false && elt === false && ex === false && dap === false && govs === false)
          alert("Please Select List of Certificates verified");
      else {
        await updateCV(tobesent, history);    
        clearProf(e);
        //console.log("redirecting to complete ");
        await getProfilePdf(applnid);
        //clearProf(e);
        history.push('/download');
      }
    }
  };

 
  const movetohome = async e => {    
    if(user && user.ltype.toString() === "V")
      navigate('/search');    
    else if(user && user.ltype.toString() === "C")
      navigate("/csearch");
  };

  const onIncomplete = async e => {
    e.preventDefault();      

    const tobesent = {};    
    
    tobesent.uremarks = formData.uremarks;
    tobesent.uoname = formData.uoname;
    tobesent._aid = profile._aid;
    tobesent.tfccode = user.tfccode;
    tobesent.username = user.username;
    tobesent.ltype = user.ltype;    
    tobesent.vflag = "incomplete";
    tobesent.x = x; tobesent.xi = xi; tobesent.xii = xii; tobesent.tc = tc; tobesent.com = com;
    tobesent.nat = nat; tobesent.elt = elt; tobesent.ex = ex; tobesent.dap = dap;
    tobesent.govs = govs;
    //console.log(tobesent);


    if(tobesent.uremarks === '' || tobesent.uoname === '')
      alert("enter updation remarks and officer name");
    else {
      if(x === false && xi === false && xii === false && tc === false && com === false && nat === false && elt === false && ex === false && dap === false && govs === false)
          alert("Please Select List of Certificates verified");
      else {
          await updateCV(tobesent, history);    
          //clearProf();
          //console.log("redirecting to incomplete ");
          clearProf(e);
          //console.log(formData.applnid);
          await getProfilePdf(applnid);
          //getIncomplete(applnid);
          history.push('/pending');
      }
    }
  };

  const clearProf = async e => {    
    clearApplication(history);
    formData.gappid = '';
  };

  const changeDetails = async e => {
    //console.log("Invoked edit");
    history.push('/edit-details');    
  }


  return (    

    <section className='container'>
      <Fragment>   
        
      <div>
          <h5 className='badge profile-top' style={{fontWeight: "bold", color: "darkblue"}}>                  
            Application Number: {profile._aid}      
        </h5>

        <div className="grid-3">
        <Row>
        <Col>
        <Card style={{ backgroundColor: "#FEF5E7", fontFamily: "Verdana",  color: 'darkgreen', fontSize: "9pt"}}> 
            <CardBody className="text-left">
              <CardTitle style={{ textAlign: "center", color: "black", fontWeight: "bolder"}}>  BASIC DETAILS </CardTitle>
            <CardSubtitle> </CardSubtitle>  
            <CardText style={{color: 'navy'}}>
              <ListGroup>
                <ListGroupItem> Name: <span style={{fontWeight: 'bold'}}>{profile._n} </span> </ListGroupItem>
               
                <ListGroupItem> Date of Birth: <span style={{fontWeight: 'bold'}}> {profile.dob} </span> </ListGroupItem>

                <ListGroupItem> Mobile number: <span style={{fontWeight: 'bold'}}> {profile.m} </span> </ListGroupItem>
               
                <ListGroupItem>Gender: <span style={{fontWeight: 'bold'}}>
                  {GENDER.filter(e => e.key === profile._p.g).map(e => e.label)} </span>
                </ListGroupItem>
                <ListGroupItem> Nationality: <span style={{fontWeight: 'bold'}}> {NATIONALITY.filter(e => e.key.toString() === profile._p.ny.toString()).map(e => e.label)} </span> </ListGroupItem>
                <ListGroupItem> Nativity: <span style={{fontWeight: 'bold'}}> {NATIVITY.filter(e => e.key.toString() === profile._p.nt.toString()).map(e => e.label)} </span> </ListGroupItem>                  


                {profile._p && profile._p.ny.toString() !== "SLR" && profile._p.nt.toString() === "OTH" && profile._p.elt !== null && profile._p.elt !== undefined &&
                  <ListGroupItem> Eligibility Type: <span style={{fontWeight: 'bold'}}> {ELIGIBILITY_TYPE.filter(e => e.key.toString() === profile._p.elt.toString()).map(e => e.label)} </span> </ListGroupItem>
                  }

                  {profile._p && profile._p.ny.toString() !== "SLR" &&  profile._p.nt.toString() === "OTH" && profile._p.elt === undefined &&
                  <ListGroupItem> Eligibility Type: <span style={{fontWeight: 'bold', color: "red"}}> *NOT DEFINED*.
                  KINDLY UPDATE THIS FIELD.</span> </ListGroupItem>
                  }

                <ListGroupItem> Studied in Tamilnadu: <span style={{fontWeight: 'bold'}}> { profile._sc && profile._sc.sit && YES_NO_ARRAY.filter(e => e.key.toString() === profile._sc.sit.toString()).map(e => e.label)} </span> </ListGroupItem>
              </ListGroup>
              </CardText>
              </CardBody>   
              </Card>    
      </Col>
   
    </Row>

    <Row>
        <Col>
        <Card style={{ backgroundColor: "#F5EEF8", fontFamily: "Verdana",  color: 'darkgreen', fontSize: "9pt"}}> 
            <CardBody className="text-left">
              <CardTitle style={{ textAlign: "center", color: "black", fontWeight: "bolder"}}> PERSONAL DETAILS </CardTitle>
            <CardSubtitle> </CardSubtitle>  
            <CardText style={{color: '#145A32'}}>
              <ListGroup>
              <ListGroupItem> Religion: <span style={{fontWeight: 'bold'}}>  {RELIGION.filter(e => e.key.toString() === profile._p.r.toString()).map(e => e.label)} </span> </ListGroupItem> 
                <ListGroupItem> Native District: <span style={{fontWeight: 'bold'}}> 
                  {DIST_LIST.filter(e => e.key.toString() === profile._p.nd.toString()).map(e => e.label)} </span> 
                </ListGroupItem>

                <ListGroupItem> Community: <span style={{fontWeight: 'bold'}}> {profile._p.co} </span> </ListGroupItem>  
                <ListGroupItem style={{whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", display: "inline-block", width: "400px"}}> Caste: <span style={{fontWeight: 'bold'}}> { CASTE[profile._p.co].filter(e => e.key.toString() === profile._p.cas.toString()).map(e => e.label) } </span> </ListGroupItem>
                {profile._p.co.toString() !== "OC" && profile._ac.commcnum !== undefined &&
                    <ListGroupItem> Community Certificate Number: <span style={{fontWeight: 'bold'}}> {profile._ac.commcnum} </span> </ListGroupItem>  
                  }
                  {profile._p.co.toString() !== "OC" && profile._ac.commcnum === undefined &&
                    <ListGroupItem> Community Certificate Number: <span style={{fontWeight: 'bold', color: "red"}}> missing </span> </ListGroupItem>  
                  }
                  {profile._sc.sit.toString() === "N" && profile._ac.natcnum !== undefined &&
                    <ListGroupItem> Nativity Certificate Number: <span style={{fontWeight: 'bold'}}> {profile._ac.natcnum} </span> </ListGroupItem>  
                  }
                  {profile._sc.sit.toString() === "N" && profile._ac.natcnum === undefined &&
                    <ListGroupItem> Nativity Certificate Number: <span style={{fontWeight: 'bold', color: "red"}}> missing </span> </ListGroupItem>  
                  }
                <ListGroupItem> State: <span style={{fontWeight: 'bold'}}> 
                {STATES.filter(e => e.key === profile._p.s).map(e => e.label)} </span> 
                </ListGroupItem>  
                <ListGroupItem> Parent name: <span style={{fontWeight: 'bold'}}> {profile._p.pn} </span> </ListGroupItem>        
              </ListGroup>
              </CardText>
              </CardBody>   
              </Card>    
      </Col>
   
    </Row>

    <Row>
        <Col>
        {/* <Card style={{backgroundColor: "#F8F9F9", fontFamily: "Verdana",  color: 'darkgreen', fontSize: "9pt"}}> 
            <CardBody className="text-left">
              <CardTitle style={{ textAlign: "center", color: "black", fontWeight: "bolder"}}>
                 SPECIAL RESERVATION DETAILS </CardTitle>
            <CardSubtitle> </CardSubtitle>  
            <CardText className="" style={{color: 'red'}}>
              <ListGroup>
                <ListGroupItem> Sports Category: <span style={{fontWeight: 'bold'}}>{YES_NO_ARRAY.filter(e => e.key.toString() === profile._sr.vsc.toString()).map(e => e.label)} </span></ListGroupItem>
                <ListGroupItem> Ex-Serviceman: <span style={{fontWeight: 'bold'}}> {YES_NO_ARRAY.filter(e => e.key.toString() === profile._sr.ex.toString()).map(e => e.label)} </span> </ListGroupItem>        
                <ListGroupItem> Differently abled: 
                <span style={{fontWeight: 'bold'}}>{YES_NO_ARRAY.filter(e => e.key.toString() === profile._sr.dap.toString()).map(e => e.label)} </span>
                </ListGroupItem>          
                {profile._sr.dap === "Y" && 
                                    <ListGroupItem> Disability Type: 
                                    <span style={{fontWeight: 'bold'}}>{DIFF_ABLED_TYPES.filter(e => e.key.toString() === profile._sr.adt.toString()).map(e => e.label)} </span>
                                    </ListGroupItem>                        
                  }           
       
              </ListGroup>
              </CardText>
              </CardBody>   
              </Card>     */}
      </Col>
   
    </Row>      
    <Row>
        <Col>
        <Card style={{ backgroundColor: "#FEF5E7", fontFamily: "Verdana",  color: 'darkgreen', fontSize: "9pt"}}> 
            <CardBody className="text-left">
              <CardTitle style={{ textAlign: "center", color: "black", fontWeight: "bolder"}}>   SCHOLARSHIP DETAILS </CardTitle>
            <CardSubtitle> </CardSubtitle>  
            <CardText className="" style={{color: 'navy'}}>
              <ListGroup>
              <ListGroup>
                <ListGroupItem> Parent Occupation: <span style={{fontWeight: 'bold'}}> {PARENT_OCCUPATION.filter(e => e.key.toString() === profile._sp.po.toString()).map(e => e.label)} </span> </ListGroupItem>
                <ListGroupItem> Annual Income: <span style={{fontWeight: 'bold'}}> {profile._sp.ai} </span> </ListGroupItem> 
                <ListGroupItem> First graduate Scholarship: <span style={{fontWeight: 'bold'}}> {YES_NO_ARRAY.filter(e => e.key.toString() === profile._sp.fgtfc.toString()).map(e => e.label)} </span> </ListGroupItem> 
                 
                <ListGroupItem> Post Matric Scholarship: <span style={{fontWeight: 'bold'}}> {YES_NO_ARRAY.filter(e => e.key.toString() === profile._sp.pms.toString()).map(e => e.label)} </span> </ListGroupItem> 
                </ListGroup>
              </ListGroup>
              </CardText>
              </CardBody>   
              </Card>    
      </Col>
   
    </Row>   

    <Row>
        <Col>
        <Card style={{ backgroundColor: "#F5EEF8", fontFamily: "Verdana",  color: 'darkgreen', fontSize: "9pt"}}> 
            <CardBody className="text-left">
              <CardTitle style={{ textAlign: "center", color: "black", fontWeight: "bolder"}}>   ACADEMIC INFORMATION </CardTitle>
            <CardSubtitle> </CardSubtitle>  
            <CardText className="" style={{ color: '#145A32'}}>
              <ListGroup>
              <ListGroupItem> Medium of Instruction: <span style={{fontWeight: 'bold'}}> {profile._ac && profile._ac.mi && MEDIUM_OF_INSTRUCTION.filter(e => e.key.toString() === profile._ac.mi.toString()).map(e => e.label)} </span> </ListGroupItem>
              
              <ListGroupItem> Qualifying Examination: <span style={{fontWeight: 'bold'}}> {profile._ac && profile._ac.qe && QUALIFYING_EXAM.filter(e => e.key.toString() === profile._ac.qe.toString()).map(e => e.label)} </span> </ListGroupItem> 
              <ListGroupItem> Board of Examination: <span style={{fontWeight: 'bold'}}> {profile._ac && profile._ac.nbe && EXAM_BOARDS.filter(e => e.key.toString() === profile._ac.nbe.toString()).map(e => e.label)} </span> </ListGroupItem> 

              {profile._ac.qe === "1" && profile._ac.hg.toString() === "1" && profile._ac.gc !== null &&
                  <ListGroupItem> Group code: <span style={{fontWeight: 'bold'}}> {profile._ac && HSC_ACADEMIC_GROUP_CODES.filter(e => e.key.toString() === profile._ac.gc.toString()).map(e => e.label)} </span> </ListGroupItem>            
                }       

                {profile._ac.qe === "1" && profile._ac.hg.toString() === "2" && profile._ac.gc !== null &&
                  <ListGroupItem> Group code: <span style={{fontWeight: 'bold'}}> {profile._ac && HSC_VOCATIONAL_GROUP_CODES.filter(e => e.key.toString() === profile._ac.gc.toString()).map(e => e.label)} </span> </ListGroupItem>            
                }       

                {profile._ac.qe !== "1" && profile._ac.gc !== null && 
                  <ListGroupItem> Group code: <span style={{fontWeight: 'bold'}}> {profile._ac &&   OTHER_GROUP_CODES.filter(e => e.key.toString() === profile._ac.gc.toString()).map(e => e.label)} </span> </ListGroupItem>            
                }       

                 {(profile._ac.gc === null || profile._ac.gc === undefined ) && 
                    <ListGroupItem>
                      <span style={{color: "red", fontWeight: "bold"}}>**Wrongly Updated Group Code. Pls Contact TNEA Team. **</span>
                    </ListGroupItem>
                  } 

              <ListGroupItem> Year of Passing: <span style={{fontWeight: 'bold'}}> {profile._ac &&   profile._ac.qy && YEAR_OF_PASSING.filter(e => e.key.toString() === profile._ac.qy.toString()).map(e => e.label)} </span> </ListGroupItem> 

              {profile.dpi !== undefined && profile.dpi !== null && profile.dpi.flag !== undefined && profile.dpi.flag.toString() === "success" &&
<ListGroup>
 <ListGroupItem> HSC TOTAL MARKS in DPI: <span style={{color: "teal", fontWeight: 'bolder'}}> {profile.dpi && profile.dpi.BTOTAL_MK } </span> </ListGroupItem>
 {/* <ListGroupItem> SSLC TOTAL MARKS in DPI: <span style={{color: "navy", fontWeight: 'bolder'}}> {profile.dpi && profile.dpi.SSLC_TOTAL } </span> </ListGroupItem>  */}
 </ListGroup>
              }

              </ListGroup>
              </CardText>
              </CardBody>   
              </Card>    
      </Col>
   
    </Row>   

    <Row>
        <Col>
        <Card style={{ backgroundColor: "#F8F9F9", fontFamily: "Verdana",  color: 'darkgreen', fontSize: "9pt"}}> 
            <CardBody className="text-left">
              <CardTitle style={{ textAlign: "center", color: "black", fontWeight: "bolder"}}>   MARKS </CardTitle>
            <CardSubtitle> </CardSubtitle>  
            <CardText className="" style={{  color: 'black'}}>
              <ListGroup>
              <ListGroupItem> HSC Register Number: <span style={{fontWeight: 'bold'}}> {profile._ac && profile._ac.hrn} </span> </ListGroupItem>

              {(profile._ac.hg !== undefined && profile._ac.hg !== null) &&
              <ListGroupItem> HSC Group: <span style={{fontWeight: 'bold'}}> {profile._ac && profile._ac.hg && HSC_GROUPS.filter(e => e.key.toString() === profile._ac.hg.toString()).map(e => e.label)} </span> </ListGroupItem> 
              }

              {profile._ac && profile._ac.hg && profile._ac.hg.toString() === "1" ? 
              <div>
              <ListGroupItem> Mathematics: <span style={{fontWeight: 'bold'}}> {profile._ac.mmo && profile._ac.mmo} / {profile._ac.mmt && profile._ac.mmt} </span> </ListGroupItem> 
              <ListGroupItem> Physics: <span style={{fontWeight: 'bold'}}> {profile._ac.pmo && profile._ac.pmo} / {profile._ac.pmt && profile._ac.pmt} </span> </ListGroupItem> 
              <ListGroupItem> Chemistry: <span style={{fontWeight: 'bold'}}> {profile._ac.cmo && profile._ac.cmo} / {profile._ac.cmt && profile._ac.cmt} </span> </ListGroupItem> 
              <ListGroupItem> Optional: <span style={{fontWeight: 'bold'}}> {profile._ac.omo && profile._ac.omo} / {profile._ac.omt && profile._ac.omt} </span> </ListGroupItem>              

              <ListGroupItem> HSC TOTAL Marks: <span style={{fontWeight: 'bold'}}> {profile._ac && profile._ac.hscobm} / {profile._ac.hsctm} </span> </ListGroupItem>

                <ListGroupItem> X TOTAL Marks: <span style={{fontWeight: 'bold'}}> {profile._ac && profile._ac.xobm} / {profile._ac.xtm} </span> </ListGroupItem>     
              </div>
              :null}

                {profile._ac && profile._ac.qe !== "1" ?                
                <div> 
                <ListGroupItem> Mathematics: <span style={{fontWeight: 'bold'}}> {profile._ac.mmo && profile._ac.mmo} / {profile._ac.mmt && profile._ac.mmt} </span> </ListGroupItem> 
                <ListGroupItem> Physics: <span style={{fontWeight: 'bold'}}> {profile._ac.pmo && profile._ac.pmo} / {profile._ac.pmt && profile._ac.pmt} </span> </ListGroupItem> 
                <ListGroupItem> Chemistry: <span style={{fontWeight: 'bold'}}> {profile._ac.cmo && profile._ac.cmo} / {profile._ac.cmt && profile._ac.cmt} </span> </ListGroupItem> 


                {profile._ac.omo !== '' ? 
                <ListGroupItem> Optional: <span style={{fontWeight: 'bold'}}> {profile._ac.omo && profile._ac.omo} / {profile._ac.omt && profile._ac.omt} </span> </ListGroupItem>                   
                    : 
                <ListGroupItem> Optional: <span style={{fontWeight: 'bold'}}> Nil  </span> </ListGroupItem>       }

<ListGroupItem> HSC TOTAL Marks: <span style={{fontWeight: 'bold'}}> {profile._ac && profile._ac.hscobm} / {profile._ac.hsctm} </span> </ListGroupItem>

<ListGroupItem> X TOTAL Marks: <span style={{fontWeight: 'bold'}}> {profile._ac && profile._ac.xobm} / {profile._ac.xtm} </span> </ListGroupItem>

                </div>
                :null}


              {profile._ac && profile._ac.hg && profile._ac.hg.toString() === "2" ? 
              <div>
              <ListGroupItem> M/P/C: <span style={{fontWeight: 'bold'}}> {profile._ac.mpcmo && profile._ac.mpcmo} / {profile._ac.mpcmt && profile._ac.mpcmt} </span> </ListGroupItem> 
              <ListGroupItem> Theory: <span style={{fontWeight: 'bold'}}> {profile._ac.tmo && profile._ac.tmo} / {profile._ac.tmt && profile._ac.tmt} </span> </ListGroupItem> 
              <ListGroupItem> Practical I: <span style={{fontWeight: 'bold'}}> {profile._ac.p1mo && profile._ac.p1mo} / {profile._ac.p1mt && profile._ac.p1mt} </span> </ListGroupItem> 
              <ListGroupItem> Practical II: <span style={{fontWeight: 'bold'}}> {profile._ac.p2mo && profile._ac.p2mo} / {profile._ac.p2mt && profile._ac.p2mt} </span> </ListGroupItem>            
              <ListGroupItem> HSC TOTAL Marks: <span style={{fontWeight: 'bold'}}> {profile._ac && profile._ac.hscobm} / {profile._ac.hsctm} </span> </ListGroupItem>

                <ListGroupItem> X TOTAL Marks: <span style={{fontWeight: 'bold'}}> {profile._ac && profile._ac.xobm} / {profile._ac.xtm} </span> </ListGroupItem>       
              </div>
              :null}

              </ListGroup>

              
              </CardText>
              </CardBody>   
              </Card>    
      </Col>
   
    </Row>   
    </div>
<div style={{alignItems: "center", textAlign: "center"}} className="grid-3">
<Card>
<CardTitle style={{ textAlign: "center", color: "black", fontWeight: "bolder"}}> Photo </CardTitle>
<CardBody>
                <img className="rounded mx-auto d-block" style={{width: "150px", height: "150px"}} src= {profile._docs.filter((e) => e.id.toString() === "passport_photo").map((e) => e.durl)} alt="Photo-Not-Uploaded" />
</CardBody> 
</Card>  

<Card>
<CardTitle style={{ textAlign: "center", color: "black", fontWeight: "bolder"}}> Signature </CardTitle>
<CardBody>
                <img className="rounded mx-auto d-block" style={{width: "150px", height: "150px"}} src= {profile._docs.filter((e) => e.id.toString() === "sign").map((e) => e.durl)} alt="Sign-Not-Uploaded" />
</CardBody> 
</Card>  

    {profile.dpi !== undefined && profile.dpi !== null && profile.dpi.flag !== undefined && profile.dpi.flag.toString() === "success" &&
                <Card style={{ backgroundColor: "lightgreen", fontFamily: "Verdana",  color: 'darkgreen', fontSize: "9pt"}}> 
                <CardBody className="text-center">
                <CardTitle style={{ textAlign: "center", color: "black", fontWeight: "bolder"}}>  DPI Data Matched </CardTitle>
                <CardSubtitle>  </CardSubtitle>  
                <CardText className="" style={{ color: 'black'}}>
                <ListGroup>
                <ListGroupItem> Status: <span style={{fontWeight: 'bold', textAlign: "right"}}> {profile.dpi && profile.dpi.flag} </span> </ListGroupItem>
                <ListGroupItem> Community: <span style={{fontWeight: 'bold', textAlign: "right"}}> {profile.dpi && profile.dpi.COM} </span> </ListGroupItem>
                
                <ListGroupItem> Name: <span style={{fontWeight: 'bold', textAlign: "right"}}> {profile.dpi && profile.dpi.NAME} </span> </ListGroupItem>
                <ListGroupItem> Group code: <span style={{fontWeight: 'bold', textAlign: "right"}}> {profile.dpi && profile.dpi.GRP} </span> </ListGroupItem>
                <ListGroupItem> Type of School: <span style={{fontWeight: 'bold', textAlign: "right"}}> {profile.dpi && profile.dpi.MANAGEMENT} </span> </ListGroupItem>

                </ListGroup>
                </CardText>
                </CardBody>
                </Card>
}

{profile._ac && profile._ac.qy.toString() === "2023" && profile._ac.nbe.toString() === "1"  && ((profile.dpi === undefined) || (profile.dpi === null)) &&
                <Card style={{ backgroundColor: "red", fontFamily: "Verdana",  color: 'blue', fontSize: "9pt"}}> 
                <CardBody className="text-center">
                <CardTitle style={{ textAlign: "center", color: "white", fontWeight: "bolder"}}>  DPI Not Matched </CardTitle>
                <CardSubtitle>  </CardSubtitle>  
                <CardText className="" style={{ color: 'crimson'}}>
                <ListGroup>
                <ListGroupItem> Kindly Check Roll number / Register number, Name, Date of Birth, Qualifying Exam, Qualifying Year, Board and Academic Marks </ListGroupItem>
                </ListGroup>
                </CardText>
                </CardBody>
                </Card>
}

{profile.dpi !== undefined && profile.dpi !== null && profile.dpi.flag !== undefined && (profile.dpi.flag.toString() === "duplicate")  &&
                <Card style={{ backgroundColor: "red", fontFamily: "Verdana",  color: 'blue', fontSize: "9pt"}}> 
                <CardBody className="text-center">
                <CardTitle style={{ textAlign: "center", color: "white", fontWeight: "bolder"}}> DPI Data - Candidate has made multiple applications </CardTitle>
                <CardSubtitle>  </CardSubtitle>  
                <CardText className="" style={{ color: 'crimson'}}>
                <ListGroup>
                <ListGroupItem> Status: <span style={{fontWeight: 'bold', textAlign: "right"}}> {profile.dpi && profile.dpi.flag} </span> </ListGroupItem>
                <ListGroupItem> Community: <span style={{fontWeight: 'bold', textAlign: "right"}}> {profile.dpi && profile.dpi.COM} </span> </ListGroupItem>
                <ListGroupItem> Group code: <span style={{fontWeight: 'bold', textAlign: "right"}}> {profile.dpi && profile.dpi.GRP} </span> </ListGroupItem>
                <ListGroupItem> Name: <span style={{fontWeight: 'bold', textAlign: "right"}}> {profile.dpi && profile.dpi.NAME} </span> </ListGroupItem>
                <ListGroupItem> Result: <span style={{fontWeight: 'bold', textAlign: "right"}}> {profile.dpi && profile.dpi.R_SULTC} </span> </ListGroupItem>
                <ListGroupItem> Type of School: <span style={{fontWeight: 'bold', textAlign: "right"}}> {profile.dpi && profile.dpi.MANAGEMENT} </span> </ListGroupItem>

                </ListGroup>
                </CardText>
                </CardBody>
                </Card>
}

</div>

<div className="badge2 but-center">
          <h5 style={{color: "black", fontWeight: "bold"}}> School Details </h5>
          </div>

          <div className="" style={{color: "teal", fontWeight: ""}}>          
            <ListGroup className="grid-3">
                <ListGroupItem> Studied in Government School: <span style={{fontWeight: 'bold', color: "darkorange"}}>{YES_NO_ARRAY.filter(e => e.key.toString() === profile._sc.sigsc.toString()).map(e => e.label)} </span>
                   </ListGroupItem>
                <ListGroupItem> Category of School: <span style={{fontWeight: 'bold', color: "darkorange"}}>{CATEGORY_OF_SCHOOL.filter(e => e.key.toString() === profile._sc.caofsc.toString()).map(e => e.label)} </span>
                </ListGroupItem>
            </ListGroup>
          </div>

            {/* <div className="profile-grid but-center" style={{fontWeight: "bold", paddingBottom: "0px"}}>        
            
            
            <Table bordered variant="dark" style={{color: "navy", fontSize: "9pt"}} >
            <thead>
              
              <tr style={{color: "black", fontWeight: ""}}>   
                <th scope="col"> Class </th>             
                <th scope="col"> Year of Passing </th> 
                <th scope="col"> Name of School </th>                
                             
                <th scope = "col" > District </th> 
                <th scope="col"> State </th>  
                {profile._sc.sigsc.toString() === "Y" &&
                  <th scope="col"> School Type </th>
                }
              </tr>
            </thead>
            <tbody>

            <tr key="12">                       
              <td>  VI </td>
              <td> {profile._sc.cVIy} </td>
              <td> {profile._sc.cVIsn} </td>
              <td> {DIST_LIST.filter(e => e.key.toString() === profile._sc.cVId.toString()).map(e => e.label)[0]}  </td>
              <td> {STATES.filter(e => e.key === profile._sc.cVIs).map(e => e.label)[0]} </td>
              
              {profile._sc.sigsc.toString() === "Y" &&
                <td> {GOVT_SCHOOL_TYPE.filter(e => e.key === profile._sc.cVIst).map(e => e.label)[0]} </td>
              }
            </tr>


            <tr key="11">                       
              <td>  VII </td>
              <td> {profile._sc.cVIIy} </td>
              <td> {profile._sc.cVIIsn} </td>
              <td> {DIST_LIST.filter(e => e.key.toString() === profile._sc.cVIId.toString()).map(e => e.label)[0]}  </td>
              <td> {STATES.filter(e => e.key === profile._sc.cVIIs).map(e => e.label)[0]} </td>
              {profile._sc.sigsc.toString() === "Y" &&
                <td> {GOVT_SCHOOL_TYPE.filter(e => e.key === profile._sc.cVIIst).map(e => e.label)[0]} </td>
              }
            </tr>

            <tr key="1">                       
              <td>  VIII </td>
              <td> {profile._sc.cVIIIy} </td>
              <td> {profile._sc.cVIIIsn} </td>
              <td> {DIST_LIST.filter(e => e.key.toString() === profile._sc.cVIIId.toString()).map(e => e.label)[0]}  </td>
              <td> {STATES.filter(e => e.key === profile._sc.cVIIIs).map(e => e.label)[0]} </td>
              {profile._sc.sigsc.toString() === "Y" &&
                <td> {GOVT_SCHOOL_TYPE.filter(e => e.key === profile._sc.cVIIIst).map(e => e.label)[0]} </td>
              }
            </tr>
            <tr key="2">                       
              <td>  IX </td>
              <td> {profile._sc.cIXy} </td>
              <td> {profile._sc.cIXsn} </td>
              <td> {DIST_LIST.filter(e => e.key.toString() === profile._sc.cIXd.toString()).map(e => e.label)[0]}  </td>
              <td> {STATES.filter(e => e.key === profile._sc.cIXs).map(e => e.label)[0]} </td>
              {profile._sc.sigsc.toString() === "Y" &&
                <td> {GOVT_SCHOOL_TYPE.filter(e => e.key === profile._sc.cIXst).map(e => e.label)[0]} </td>
              }
            </tr>
            <tr key="3">                       
              <td>  X </td>
              <td> {profile._sc.cXy} </td>
              <td> {profile._sc.cXsn} </td>
              <td> {DIST_LIST.filter(e => e.key.toString() === profile._sc.cXd.toString()).map(e => e.label)[0]}  </td>
              <td> {STATES.filter(e => e.key === profile._sc.cXs).map(e => e.label)[0]} </td>
              {profile._sc.sigsc.toString() === "Y" &&
                <td> {GOVT_SCHOOL_TYPE.filter(e => e.key === profile._sc.cXst).map(e => e.label)[0]} </td>
              }
            </tr>
            <tr key="4">   
              <td>  XI </td>
              <td> {profile._sc.cXIy} </td>
              <td> {profile._sc.cXIsn} </td>
              <td> {DIST_LIST.filter(e => e.key.toString() === profile._sc.cXId.toString()).map(e => e.label)[0]}  </td>
              <td> {STATES.filter(e => e.key === profile._sc.cXIs).map(e => e.label)[0]} </td>
              {profile._sc.sigsc.toString() === "Y" &&
                <td> {GOVT_SCHOOL_TYPE.filter(e => e.key === profile._sc.cXIst).map(e => e.label)[0]} </td>
              }
            </tr>
            <tr key="5">                       
              <td>  XII </td>
              <td> {profile._sc.cXIIy} </td>
              <td> {profile._sc.cXIIsn} </td>
              <td> {DIST_LIST.filter(e => e.key.toString() === profile._sc.cXIId.toString()).map(e => e.label)[0]}  </td>
              <td> {STATES.filter(e => e.key === profile._sc.cXIIs).map(e => e.label)[0]} </td>
              {profile._sc.sigsc.toString() === "Y" &&
                <td> {GOVT_SCHOOL_TYPE.filter(e => e.key === profile._sc.cXIIst).map(e => e.label)[0]} </td>
              }
            </tr>
</tbody> </Table> </div>
 */}


<h5 className='badge' style={{fontFamily: "Verdana" }} ></h5>
          
          <h4 className='' style={{fontFamily: "Verdana", fontSize: "11pt", color: "black", fontWeight: "bold"}}> SCHOOL DETAILS </h4>
          

          <div className="grid-2" style={{fontSize: "11pt", fontWeight: "bold"}}>          
           
                <div> Studied VI to XII in Government School: <span style={{fontWeight: 'bold'}}>{YES_NO_ARRAY.filter(e => e.key.toString() === profile._sc.sigsc.toString()).map(e => e.label)} </span> </div>
                  
                <div> Category of School: <span style={{fontWeight: 'bold'}}>{CATEGORY_OF_SCHOOL.filter(e => e.key.toString() === profile._sc.caofsc.toString()).map(e => e.label)} </span>
                </div>
                
           
          </div>
           
            
            <table className='table but-center' bordered variant="dark" style={{fontFamily: "Verdana", textAlign: "center", color: "navy", fontSize: "11pt"}} >
            <thead>
              
              <tr style={{color: "black", fontWeight: ""}}>   
                <th scope="col"> Class </th>             
                <th scope="col"> Year of Passing </th> 
                <th scope="col"> Name of School </th>                
                <th scope="col"> Block </th>                
                <th scope = "col" > District </th> 
                <th scope="col"> State </th>  
                {profile._sc.sigsc.toString() === "Y" &&
                  <th scope="col"> School Type </th>
                }
              </tr>
            </thead>
            <tbody>

            <tr key="12">                        
              <td>  VI </td>
              <td> {profile._sc.cVIy} </td>
              {SCHOOL_BY_BLOCK[profile._sc.cVIb] !== undefined ?
                <td> {SCHOOL_BY_BLOCK[profile._sc.cVIb].filter(e => e.key === profile._sc.cVIsn).map(e => e.label)[0]} </td> :
                <td> {profile._sc.cVIsn} </td>
              }
              {BLOCKS_BY_DISTRICT[profile._sc.cVId] !== undefined ?
                <td> {BLOCKS_BY_DISTRICT[profile._sc.cVId].filter(e => e.key === profile._sc.cVIb).map(e => e.label)[0]} </td> :
                <td> {profile._sc.cVIb} </td>
              }
              <td> {DIST_LIST.filter(e => e.key.toString() === profile._sc.cVId.toString()).map(e => e.label)[0]}  </td>
              <td> {STATES.filter(e => e.key === profile._sc.cVIs).map(e => e.label)[0]} </td>
              {profile._sc.sigsc.toString() === "Y" &&
                <td> {GOVT_SCHOOL_TYPE.filter(e => e.key === profile._sc.cVIst).map(e => e.label)[0]} </td>
              }            </tr>


            <tr key="11">                       
              <td>  VII </td>
              <td> {profile._sc.cVIIy} </td>
              {SCHOOL_BY_BLOCK[profile._sc.cVIIb] !== undefined ?
                <td> {SCHOOL_BY_BLOCK[profile._sc.cVIIb].filter(e => e.key === profile._sc.cVIIsn).map(e => e.label)[0]} </td> :
                <td> {profile._sc.cVIIsn} </td>
              }
              {BLOCKS_BY_DISTRICT[profile._sc.cVIId] !== undefined ?
                <td> {BLOCKS_BY_DISTRICT[profile._sc.cVIId].filter(e => e.key === profile._sc.cVIIb).map(e => e.label)[0]} </td> :
                <td> {profile._sc.cVIIb} </td>
              }
              <td> {DIST_LIST.filter(e => e.key.toString() === profile._sc.cVIId.toString()).map(e => e.label)[0]}  </td>
              <td> {STATES.filter(e => e.key === profile._sc.cVIIs).map(e => e.label)[0]} </td>
              {profile._sc.sigsc.toString() === "Y" &&
                <td> {GOVT_SCHOOL_TYPE.filter(e => e.key === profile._sc.cVIIst).map(e => e.label)[0]} </td>
              }
            </tr>

          
            <tr key="1">                       
              <td>  VIII </td>
              <td> {profile._sc.cVIIIy} </td>
              {SCHOOL_BY_BLOCK[profile._sc.cVIIIb] !== undefined ?
                <td> {SCHOOL_BY_BLOCK[profile._sc.cVIIIb].filter(e => e.key === profile._sc.cVIIIsn).map(e => e.label)[0]} </td> :
                <td> {profile._sc.cVIIIsn} </td>
              }
              {BLOCKS_BY_DISTRICT[profile._sc.cVIIId] !== undefined ? 
                <td> {BLOCKS_BY_DISTRICT[profile._sc.cVIIId].filter(e => e.key === profile._sc.cVIIIb).map(e => e.label)[0]} </td> :
                <td> {profile._sc.cVIIIb} </td>
              }
              <td> {DIST_LIST.filter(e => e.key.toString() === profile._sc.cVIIId.toString()).map(e => e.label)[0]}  </td>
              <td> {STATES.filter(e => e.key === profile._sc.cVIIIs).map(e => e.label)[0]} </td>
              {profile._sc.sigsc.toString() === "Y" &&
                <td> {GOVT_SCHOOL_TYPE.filter(e => e.key === profile._sc.cVIIIst).map(e => e.label)[0]} </td>
              }
            </tr>
            <tr key="2">                       
              <td>  IX </td>
              <td> {profile._sc.cIXy} </td>
              {SCHOOL_BY_BLOCK[profile._sc.cIXb] !== undefined ?
                <td> {SCHOOL_BY_BLOCK[profile._sc.cIXb].filter(e => e.key === profile._sc.cIXsn).map(e => e.label)[0]} </td> :
                <td> {profile._sc.cIXsn} </td>
              }
              {BLOCKS_BY_DISTRICT[profile._sc.cIXd] !== undefined ?
                <td> {BLOCKS_BY_DISTRICT[profile._sc.cIXd].filter(e => e.key === profile._sc.cIXb).map(e => e.label)[0]} </td> :
                <td> {profile._sc.cIXb} </td>
              }

              <td> {DIST_LIST.filter(e => e.key.toString() === profile._sc.cIXd.toString()).map(e => e.label)[0]}  </td>
              <td> {STATES.filter(e => e.key === profile._sc.cIXs).map(e => e.label)[0]} </td>
              {profile._sc.sigsc.toString() === "Y" && 
                <td> {GOVT_SCHOOL_TYPE.filter(e => e.key === profile._sc.cIXst).map(e => e.label)[0]} </td>
              }
            </tr>
            <tr key="3">                       
              <td>  X </td>
              <td> {profile._sc.cXy} </td>
              {SCHOOL_BY_BLOCK[profile._sc.cXb] !== undefined ?
                <td> {SCHOOL_BY_BLOCK[profile._sc.cXb].filter(e => e.key === profile._sc.cXsn).map(e => e.label)[0]} </td> :
                <td> {profile._sc.cXsn} </td>
              }
              {BLOCKS_BY_DISTRICT[profile._sc.cXd] !== undefined ?
                <td> {BLOCKS_BY_DISTRICT[profile._sc.cXd].filter(e => e.key === profile._sc.cXb).map(e => e.label)[0]} </td> :
                <td> {profile._sc.cXb} </td>  
              }

              <td> {DIST_LIST.filter(e => e.key.toString() === profile._sc.cXd.toString()).map(e => e.label)[0]}  </td>
              <td> {STATES.filter(e => e.key === profile._sc.cXs).map(e => e.label)[0]} </td>
              {profile._sc.sigsc.toString() === "Y" &&
                <td> {GOVT_SCHOOL_TYPE.filter(e => e.key === profile._sc.cXst).map(e => e.label)[0]} </td>
              }
            </tr>
            <tr key="4">   
              <td>  XI </td>
              <td> {profile._sc.cXIy} </td>
              {SCHOOL_BY_BLOCK[profile._sc.cXIb] !== undefined ?
                <td> {SCHOOL_BY_BLOCK[profile._sc.cXIb].filter(e => e.key === profile._sc.cXIsn).map(e => e.label)[0]} </td> :
                <td> {profile._sc.cXIsn} </td>
              }
              {BLOCKS_BY_DISTRICT[profile._sc.cXId] !== undefined ?
                <td> {BLOCKS_BY_DISTRICT[profile._sc.cXId].filter(e => e.key === profile._sc.cXIb).map(e => e.label)[0]} </td> :
                <td> {profile._sc.cXIb} </td>
              }  
              <td> {DIST_LIST.filter(e => e.key.toString() === profile._sc.cXId.toString()).map(e => e.label)[0]}  </td>
              <td> {STATES.filter(e => e.key === profile._sc.cXIs).map(e => e.label)[0]} </td>
              {profile._sc.sigsc.toString() === "Y" &&
                <td> {GOVT_SCHOOL_TYPE.filter(e => e.key === profile._sc.cXIst).map(e => e.label)[0]} </td>
              }
            </tr>
            <tr key="5">                       
              <td>  XII </td>
              <td> {profile._sc.cXIIy} </td>
              {SCHOOL_BY_BLOCK[profile._sc.cXIIb] !== undefined ?
                <td> {SCHOOL_BY_BLOCK[profile._sc.cXIIb].filter(e => e.key === profile._sc.cXIIsn).map(e => e.label)[0]} </td> :
                <td> {profile._sc.cXIIsn} </td>
              }
              {BLOCKS_BY_DISTRICT[profile._sc.cXIId] !== undefined ? 
                <td> {BLOCKS_BY_DISTRICT[profile._sc.cXIId].filter(e => e.key === profile._sc.cXIIb).map(e => e.label)[0]} </td> :
                <td> {profile._sc.cXIIb} </td>  
              }

              <td> {DIST_LIST.filter(e => e.key.toString() === profile._sc.cXIId.toString()).map(e => e.label)[0]}  </td>
              <td> {STATES.filter(e => e.key === profile._sc.cXIIs).map(e => e.label)[0]} </td>
              {profile._sc.sigsc.toString() === "Y" &&
                <td> {GOVT_SCHOOL_TYPE.filter(e => e.key === profile._sc.cXIIst).map(e => e.label)[0]} </td>
              }
            </tr>
</tbody> </table>             

    

        <div className="p-1"> </div>

        
        {profile && profile._cv && profile._cv.dd && profile._cv.dd.length > 0 &&
        <div className="profile-grid">
          <div style={{textAlign: "center", color: "red", fontWeight: "bold"}}> 
          {profile._cv.dd[profile._cv.dd.length-1].splexchange === true && <div> Ex-Serviceman Changed to Yes</div> }
          {profile._cv.dd[profile._cv.dd.length-1].splcomchange === true && <div> Community Changed from SC/ST/SCA </div> }
          {profile._cv.dd[profile._cv.dd.length-1].spldiffchange === true && <div> Disabled Changed to Yes </div> }          
          </div>
          <div style={{textAlign: "center", color: "red", fontWeight: "bold"}}>
                DD received for {profile._cv.dd[profile._cv.dd.length-1].amt} 
          </div>
        </div>
        }

        
        <Row className="my-2 but-center">
        <input type="submit"  value="Go Back" className="btn btn-success" onClick= {e => movetohome(e)} />                     
        </Row>
        
        </div>
        </Fragment>
        </section>    
    
  );
}

VerSearchMore.propTypes = {
  getProfileUpd: PropTypes.func.isRequired,  
  getProfilePdf: PropTypes.func.isRequired,
  updateCV: PropTypes.func.isRequired,
  clearApplication: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  getUpdpendingforme: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  profile: state.profile
});

export default connect(
  mapStateToProps,
  { getProfileUpd, updateCV, clearApplication, getProfilePdf, getUpdpendingforme}
)(VerSearchMore);