import api from '../utils/api';
import { setAlert } from './alert';

import {    
    PROFILE_ERROR,
    GET_APPLNS,
    CLEAR_PROFILE, LOGOUT, CLEAR_ALLCANDS, 
    LOG_PROFILE, SET_DBCALL, RESET_DBCALL, GET_ALLCANDS, ALLCANDS_ERROR, GET_DLINK
} from './types';

//Create or Update Profile

export const getsharedfiles = (appId) => async dispatch => {
  try {

      console.log("dispatching call");

        dispatch({
          type: SET_DBCALL
        });

       
      const res = await api.get(`/upload/tfclist/${appId}`);   

      console.log("result of list of docs");
      //console.log(res.data.flist);

      if(res.data.flist.length > 0) {

        dispatch({
          type: GET_ALLCANDS,
          payload: res.data
          });
      
    }     

      dispatch({
          type: RESET_DBCALL
        }); 

      
  } catch (err) {

      console.log("Catch");
      dispatch({
          type: RESET_DBCALL
        });

      if(err.response) {
      const errors = err.response.data.errors;

      if(errors) {
          errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
      }

      dispatch({
        type: ALLCANDS_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status }
        });
      }

  }
};



export const getdownfile = (formData) => async dispatch => {
  try {

      console.log("dispatching call");
      

        dispatch({
          type: SET_DBCALL
        });

      //const res = await api.get(`/api/upload/openlist/${appId}`);   

      const config = {
        headers: {
            'Content-type': 'application/json'
        }
    }
    //console.log(formData);
    //console.log("Received from Client" + formData.vflag);
    const res = await api.post('api/upload/openlist', formData, config);


      console.log("result of list of docs");
      //console.log(res.data);

      dispatch({
          type: GET_DLINK,
          payload: res.data
      });   
    

      dispatch({
          type: RESET_DBCALL
        }); 

      

  } catch (err) {

      console.log("Catch");
      dispatch({
          type: RESET_DBCALL
        });

      if(err.response) {
      const errors = err.response.data.errors;

      if(errors) {
          errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
      }

      dispatch({
        type: ALLCANDS_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status }
        });
      }

  }
};

export const listAll = (appId) => async dispatch => {
    try {

        console.log("dispatching call");

/*        dispatch({
            type: SET_DBCALL
          });

        const res = await api.get(`/api/upload/list/${appId}`);   

        console.log("result of list of docs");
        console.log(res.data.Contents);

        if(res.data.Contents.length > 0) {

        dispatch({
            type: GET_PROFILE2,
            payload: res.data.Contents
        });        

        dispatch({
            type: RESET_DBCALL
          }); */

          dispatch({
            type: SET_DBCALL
          });


        const res2 = await api.get(`/upload/open/${appId}`);      

        console.log("list from db ");
        //console.log(res2.data);

        dispatch({
            type: GET_APPLNS,
            payload: res2.data
        });        

        
        if(res2.data.length === 0) {
            //console.log("no certs uploaded");
            dispatch(setAlert("No Certificates Uploaded", 'danger'));
        }

        dispatch({
            type: RESET_DBCALL
          });

    } catch (err) {

        console.log("Catch");
        dispatch({
            type: RESET_DBCALL
          });

        if(err.response) {
        const errors = err.response.data.errors;

        if(errors) {
            errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }

        dispatch({
            type: PROFILE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });
        }

    }
};

export const gettfc = (appId) => async dispatch => {
    try {

        dispatch({
            type: SET_DBCALL
          });

       if(appId !== null && appId !== "") {
        const res = await api.get(`/upload/gettfc/${appId}`);           

        await dispatch({
            type: LOG_PROFILE,
            payload: res.data
        });        
        
        //console.log(res.data._sr.tfc);
        //console.log("loaded log profile");
        }

        dispatch({
            type: RESET_DBCALL
          });

    } catch (err) {

        console.log("Catch");

        dispatch({
            type: RESET_DBCALL
          });

          if(err.response.status === 403 || err.response.status === 401) {   //401 invalid token/ no token
            console.log("Catch invalid token");
  
                  dispatch({ type: LOGOUT });
                  dispatch({ type: CLEAR_PROFILE });
                  dispatch({ type: CLEAR_ALLCANDS });  
            
              //history.push('/expire');
              if(err.response.status === 401)
              {
                dispatch(setAlert("SESSION EXPIRED. LOGIN AGAIN", 'danger'));
              }
            }

        if(err.response) {
        const errors = err.response.data.errors;

        if(errors) {
            errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }

        dispatch({
            type: CLEAR_PROFILE,
            payload: { msg: err.response.statusText, status: err.response.status }
        });
    }

    }
};


export const openCert = (appId) => async dispatch => {
    try {

        dispatch({
            type: SET_DBCALL
          });

        if(appId !== undefined) {
        //console.log("Passing  " + appId);

        /*const config = {
            headers: {
              'Content-Type': 'application/json'
            }
          };*/

        //const res = await api.get(`/api/upload/open`, appId, config);   

        const res = await api.get(`/upload/open/${appId}`);      

        //console.log("list from db " + res.data );

        dispatch({
            type: GET_APPLNS,
            payload: res.data
        });        

    }

    dispatch({
        type: RESET_DBCALL
      });

    } catch (err) {

        console.log("Catch");
        dispatch({
            type: RESET_DBCALL
          });

        if(err.response) {
        const errors = err.response.data.errors;

        if(errors) {
            errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }

        dispatch({
            type: PROFILE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });
        }
    }
};
