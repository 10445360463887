import api from '../utils/api';
import { setAlert } from './alert';

import {
  GET_PROFILE,   GET_PROFILE3,
  GET_PROFILES,
  PROFILE_ERROR,
  UPDATE_PROFILE,
  UPDATE_PROFILE22,
  CLEAR_PROFILE,
  ACCOUNT_DELETED,
  GET_REPOS,
  NO_REPOS,
  CLEAR_GID, GET_PROFILE2, SET_GID, SET_DBCALL, RESET_DBCALL, GET_PROFILE4
} from './types';

/*
  NOTE: we don't need a config object for axios as the
 default headers in axios are already Content-Type: application/json
 also axios stringifies and parses JSON for you, so no need for 
 JSON.stringify or JSON.parse
*/

export const cleargid = () => async (dispatch) => {
  try {

    // const res = await api.get('/profile/me');
    //const res = await api.get(`/profile/me/${prof}`);
    console.log("clearing gid");
    dispatch({ type: CLEAR_GID });

  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


export const clearProfile = () => async (dispatch) => {
  try {

    // const res = await api.get('/profile/me');
    //const res = await api.get(`/profile/me/${prof}`);
    console.log("clearing");
    dispatch({ type: CLEAR_PROFILE });

  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get current users profile
export const getCurrentProfile = (prof) => async (dispatch) => {
  try {    

    // const res = await api.get('/profile/me');
    const res = await api.get(`/profile/me/${prof}`);    

    dispatch({
      type: GET_PROFILE,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all profiles
export const getProfiles = () => async (dispatch) => {
  dispatch({ type: CLEAR_PROFILE });

  try {
    const res = await api.get('/profile');

    dispatch({
      type: GET_PROFILES,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


// Get profile by ID
export const getTFCcandlist = (userId) => async (dispatch) => {
  try {

    dispatch({
      type: SET_DBCALL
    });

    // dispatch({
    //   type: CLEAR_PROFILE
    // });


    const res = await api.get(`/profile/tfccandlist/${userId}`);

    dispatch({
      type: GET_PROFILE3,
      payload: res.data
    });

    dispatch({
      type: RESET_DBCALL
    });

  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};



export const getTFCcandinlist = (userId) => async (dispatch) => {
  try {

    dispatch({
      type: SET_DBCALL
    });

    // dispatch({
    //   type: CLEAR_PROFILE
    // });

    const res = await api.get(`/profile/tfccandinlist/${userId}`);

    dispatch({
      type: GET_PROFILE4,
      payload: res.data
    });

    dispatch({
      type: RESET_DBCALL
    });

  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};




// Get profile by ID
export const corgetTFCcandlist = (userId) => async (dispatch) => {
  try {

    dispatch({
      type: SET_DBCALL
    });

    const res = await api.get(`/profile/cortfccandlist/${userId}`);

    dispatch({
      type: GET_PROFILE3,
      payload: res.data
    });

    dispatch({
      type: RESET_DBCALL
    });

  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};



// Get profile by ID
export const corgetTFCincandlist = (userId) => async (dispatch) => {
  try {

    dispatch({
      type: SET_DBCALL
    });

    const res = await api.get(`/profile/cortfcincandlist/${userId}`);

    dispatch({
      type: GET_PROFILE4,
      payload: res.data
    });

    dispatch({
      type: RESET_DBCALL
    });

  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


// Get profile by ID
export const getProfileById = (userId, gt) => async (dispatch) => {
  try {
    console.log(userId, gt);

    dispatch({
      type: SET_GID,
      payload: gt
    });
   

    const res = await api.get(`/profile/user/${userId}`);

    dispatch({
      type: GET_PROFILE,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get Github repos
export const getGithubRepos = (username) => async (dispatch) => {
  try {
    const res = await api.get(`/profile/github/${username}`);

    dispatch({
      type: GET_REPOS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: NO_REPOS
    });
  }
};

// Create or update profile
export const searchprofile =
  (search, navigate) =>
  async (dispatch) => {
    try {
      //const res = await api.post('/profile', formData);
      console.log("clearing");
      dispatch({ type: CLEAR_PROFILE });

      const res = await api.get(`/profile/${search}`);

      dispatch({
        type: GET_PROFILE,
        payload: res.data
      });

      // dispatch(
      //   setAlert(edit ? 'Profile Updated' : 'Profile Created', 'success')
      // );

      // if (!edit) {
        navigate('/dashboard');
      // }
    } catch (err) {
      console.log('catched you');
      console.log(err.response);      

      if (err.response && err.response.status.toString() === "400") {
        console.log(err.response.data.msg);
        dispatch(setAlert(err.response.data.msg, 'danger'));
      }
      navigate('/dashboard');
      // dispatch({
      //   type: PROFILE_ERROR,
      //   payload: { msg: err.response.statusText, status: err.response.status }
      // });
    }
  };

// Add Experience
export const addnewgrievance = (formData, navigate) => async (dispatch) => {
  try {
    const res = await api.put('/profile/addgrievance', formData);

    dispatch({
      type: UPDATE_PROFILE22,
      payload: res.data
    });

    dispatch(setAlert('Grievance Added. Kindly Upload Request Letter', 'success'));

    // navigate('/dashboard');
    
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Add Education
export const savecomments = (formData, navigate) => async (dispatch) => {
  try {
    const res = await api.put('/profile/addcomments', formData);

    // dispatch({
    //   type: UPDATE_PROFILE,
    //   payload: res.data
    // });

    dispatch(setAlert('Comments Added', 'success'));

    navigate('/select-tfc');
    
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Delete experience
export const deleteExperience = (id) => async (dispatch) => {
  try {
    const res = await api.delete(`/profile/experience/${id}`);

    dispatch({
      type: UPDATE_PROFILE,
      payload: res.data
    });

    dispatch(setAlert('Experience Removed', 'success'));
  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Delete education
export const deleteEducation = (id) => async (dispatch) => {
  try {
    const res = await api.delete(`/profile/education/${id}`);

    dispatch({
      type: UPDATE_PROFILE,
      payload: res.data
    });

    dispatch(setAlert('Education Removed', 'success'));
  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Delete account & profile
export const deleteAccount = () => async (dispatch) => {
  if (window.confirm('Are you sure? This can NOT be undone!')) {
    try {
      await api.delete('/profile');

      dispatch({ type: CLEAR_PROFILE });
      dispatch({ type: ACCOUNT_DELETED });

      dispatch(setAlert('Your account has been permanently deleted'));
    } catch (err) {
      dispatch({
        type: PROFILE_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status }
      });
    }
  }
};

export const deletegrievance = (formData) => async dispatch => {
  const config = {
      headers: {
          'Content-type': 'application/json'            
      }
  }    
 // const body = JSON.stringify(formData);
 
  try {

        //console.log("dispatched delete");
        const res2 = await api.post('/upload/delgriev', formData, config);
        //console.log("Returned from db");
        //console.log(res2.data);
  
        dispatch(setAlert("Updated", 'success'));
  
        dispatch({
              type: GET_PROFILE,
              payload: res2.data
          });
  

  } catch (err) {

      console.log("Catch");
      
      dispatch(setAlert(err.response.data.msg, 'danger'));
      
      /*console.log(err.response.data);
      const errors = err.response.data;
      if(errors) {
          errors.forEach(error => dispatch(setAlert(errors, 'danger')));
      }*/

      dispatch({
          type: PROFILE_ERROR,
          payload: { msg: err.response.statusText, status: err.response.status }
      });

  }
};
