import React, { Fragment, useState, useEffect } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';

import {Card, ListGroup, CardSubtitle, CardTitle, CardText, ListGroupItem, CardBody,
            Row, Table} from 'reactstrap';

import { getProfileVer, clearApplication, updateCV, getProfilePdf, clearApplication2 } from '../../actions/vermaster';
import Dload2 from './Dload2';
import {listAll, openCert, gettfc} from '../../actions/dload';


const {CURR_TRANSFER_REASON, PREV_TRANSFER_REASON, YES_NO_ARRAY, TRANS_COLLEGES_LIST,
  COMPANY_TYPES, NDEPT_LIST
} = require("../../Constant2");

const VDash = ({
  getProfileVer, getProfilePdf,
  updateCV, listAll, openCert, gettfc,
  clearApplication,
  auth: { user },
  profile: { profile, loading, getdb, applnid },
  history
}) => {

    // useEffect(() => {
    //   if(applnid !== null && applnid !== undefined) {
    //     gettfc(applnid);       
    //      //console.log(logprofile);
    //      //console.log("invoking s3 list");
         
    //     listAll(applnid);
    //   }
    //      //console.log("completed retrieving the list");
    //    }, [applnid, listAll, gettfc]); 

  const navigate = useNavigate();


  const [formData, setFormData] = useState({
    gappid: '',
    vflag : "notyet",
    vremarks: profile && profile.premarks,
    tfcname: '',
    username: '', profimage: '',
    ltype: '',
    voname: profile && profile.pname, 
    x: false, xi: false, xii: false, tc: false, com: false, nat: false, elt: false, ex: false, dap: false, govs: false,
    slrc: false, fgc: false, inc: false, noctup: false, ux: false, uxz: false
  });

  const { gappid, voname, vremarks, x, xi, xii, tc, com, nat, elt, ex, dap, govs, slrc, fgc, inc, noctup, ux, uxz } = formData;

  const onChange = e => {
    setFormData({ ...formData, [e.target.name] : e.target.value });  
    //name: e.target.value to change name alone
    //console.log(e.target.value);
  }

  const onSubmit = async e => {
    e.preventDefault();      
    
    //console.log("Appln number : "+ formData.gappid);         
    await getProfileVer(formData.gappid);    
  };

  
  const onVerify = async e => {
    e.preventDefault();    
    
    //console.log(formData);
    
    const tobesent = {};    
    tobesent.vflag = "vcomplete";    
    tobesent.vremarks = formData.vremarks;
    tobesent.voname = formData.voname;
    tobesent._aid = profile._aid;
    tobesent.tfccode = user.tfccode;
    tobesent.username = user.username;
    tobesent.ltype = user.ltype;     
    tobesent.ux = ux; 
    console.log(tobesent);
    
    if(tobesent.vremarks === '' || tobesent.voname === '')
        alert("enter principal remarks and name");
    else {

      if(ux === false)
        alert("Kindly check Declaration");
      else {
       
        await updateCV(tobesent, navigate);    
          //history.push('/download');
          //clearProf(e);
          //console.log("redirecting to download");
      //await getProfilePdf(applnid);
      navigate('/tfcdash'); 
      }
    }
  };

  const onUpd = async e => {
    e.preventDefault();      
    //console.log("Appln number : " + formData.gappid + "send to update" );  

    const tobesent = {};    
    tobesent.vflag = "toupdate";    
    tobesent.vremarks = formData.vremarks;
    tobesent.voname = formData.voname;
    tobesent._aid = profile._aid;
    tobesent.tfccode = user.tfccode;
    tobesent.username = user.username;
    tobesent.ltype = user.ltype;    
    tobesent.ux = x; tobesent.uxi = xi; tobesent.uxii = xii; 
    //tobesent.utc = tc; 
    tobesent.ucom = com;
    tobesent.unat = nat; tobesent.uelt = elt; tobesent.uex = ex; tobesent.udap = dap;
    //tobesent.ugovs = govs;
    tobesent.uslrc = slrc; tobesent.ufgc = fgc; tobesent.uinc = inc; tobesent.noctup = noctup;

    //console.log(tobesent);

    if(formData.vremarks === '' || formData.voname === '')
        alert("enter verification remarks and officer name");
    else {

      if(x === false && xi === false && xii === false &&  com === false && nat === false && elt === false && ex === false && dap === false && slrc === false && fgc === false && inc === false && noctup === false)
            alert("Please Select List of Certificates verified");
      else {

        await updateCV(tobesent, navigate);    
        navigate('/tfcdash'); 
      }
    }
  };

  // const onIncomplete = async e => {
  //   e.preventDefault();      

  //   const tobesent = {};    
    
  //   tobesent.vremarks = formData.vremarks;
  //   tobesent.voname = formData.voname;
  //   tobesent._aid = profile._aid;
  //   tobesent.tfccode = user.tfccode;
  //   tobesent.username = user.username;
  //   tobesent.ltype = user.ltype;    
  //   tobesent.vflag = "incomplete";
  //   tobesent.ux = x; tobesent.uxi = xi; tobesent.uxii = xii; tobesent.utc = tc; tobesent.ucom = com;
  //   tobesent.unat = nat; tobesent.uelt = elt; tobesent.uex = ex; tobesent.udap = dap;
  //   tobesent.ugovs = govs;

  //   console.log(tobesent);


  //   if(tobesent.vremarks === '' || tobesent.voname === '')
  //     alert("enter updation remarks and officer name");
  //   else {
  //     if(x === false && xi === false && xii === false && tc === false && com === false && nat === false && elt === false && ex === false && dap === false && govs === false)
  //         alert("Please Select List of Certificates verified");
  //     else {
  //         await updateCV(tobesent, navigate);    
  //         console.log("Returned after updating the record as incomplete");
  //         //clearProf();
  //         //console.log("redirecting to incomplete ");
  //         clearProf(e);
  //         //console.log(formData.applnid);
  //        await getProfilePdf(applnid);
  //        console.log("profile loaded");
  //         //getIncomplete(applnid);
  //        //history.push('/pending');
  //        //console.log("redirected to pending");
  //     }
  //   }
  // };


  const onCan = async e => {

    clearApplication(navigate);
    formData.gappid='';
    navigate("/")

  };

  const clearProf = async e => {    
    clearApplication(navigate);
    formData.gappid='';
  };

  const onDown = async e => {    
    navigate('/vdload');    
  };

 
  return (    
    <section className='container'>
    <Fragment>
     {user === null ? (
      <Spinner /> ) : ( 
      <>
 
     <h5 style={{textAlign: 'center', color: "teal", fontSize: "11pt", fontWeight: "bolder"}}> {user && user.tfcname} </h5>

      <div className="profile-top" style={{textAlign: 'center', fontWeight: 'bolder', color: 'brown'}}>
        <h5 className="my-1"  style={{fontWeight: "bold"}}> Transfer Application </h5>
      </div>             
      
    </> 
    ) }     

      {loading === true && getdb === true ? (
        <Spinner /> ) : (
      
          profile !== null &&  user && ((user.ltype === 'V') || (user.ltype === 'C')) ? (
      <Fragment>   
        
        <div>
        <div className="profile-top" style={{fontWeight: 'bold'}}>
        <h5 style={{fontWeight: "bold", color: "darkblue"}}>                  
            Application Number: {profile._aid}      
        </h5>
        
        </div>        

          <div className="">
          <div className="grid-3">

          <Card style={{ fontFamily: "Verdana", fontSize: "11pt"}}> 
        <CardBody className="text-left">
          <CardTitle style={{ textAlign: "center", color: "black", fontWeight: "bolder"}}> Previous Institution Details </CardTitle>
        <CardSubtitle> </CardSubtitle>  
        <CardText>
          <ListGroup>
                      
            <ListGroupItem> Previously Working Institution: <span style={{color: "green", fontWeight: "bold"}}> {profile._p && profile._p._pSta ? TRANS_COLLEGES_LIST.filter(e => e.key === profile._p._pSta).map(e => e.label) : "NA"} </span> </ListGroupItem>
            <ListGroupItem> Joining Date: <span style={{color: "brown", fontWeight: "bold"}}> {profile._p && profile._p._pStartDate ? profile._p._pStartDate : "NA"} </span> </ListGroupItem>          
            <ListGroupItem> Relieving Date: <span style={{color: "brown", fontWeight: "bold"}}> {profile._p && profile._p._pEndDate ? profile._p._pEndDate : "NA" } </span> </ListGroupItem>          

          </ListGroup>
          </CardText>
          </CardBody>   
          </Card>    

        <Card style={{ fontFamily: "Verdana", fontSize: "11pt"}}> 
        <CardBody className="text-left">
          <CardTitle style={{ textAlign: "center", color: "black", fontWeight: "bolder"}}> Faculty Details </CardTitle>
        <CardSubtitle> </CardSubtitle>  
        <CardText>
          <ListGroup>
          
            <ListGroupItem> Name: <span style={{color: "brown", fontWeight: "bold"}}> {profile._n} </span> </ListGroupItem>          
            <ListGroupItem> Designation: <span style={{color: "brown", fontWeight: "bold"}}> {profile._p && profile._p._desig} </span> </ListGroupItem>          
            <ListGroupItem> Department: <span style={{color: "brown", fontWeight: "bold"}}> {profile._p && profile._p._bran ? NDEPT_LIST.filter(e => e.key === profile._p._bran).map(e => e.label) : "NA"} </span> </ListGroupItem>          
            <ListGroupItem> Currently Working Station: <span style={{color: "brown", fontWeight: "bold"}}> {profile._p && profile._p._csta} </span> </ListGroupItem>  
            <ListGroupItem> Joining Date in this College: <span style={{color: "blue", fontWeight: "bold"}}> {profile._p && profile._p._startDate} </span> </ListGroupItem>
            <ListGroupItem> On what grounds the applicant was transferred to this college: <span style={{color: "blue", fontWeight: "bold"}}>
            {profile._p && profile._p._pTransReason ? PREV_TRANSFER_REASON.filter(e => e.key === profile._p._pTransReason).map(e => e.label) : "NA"}
              </span> </ListGroupItem>          

          </ListGroup>
          </CardText>
          </CardBody>   
          </Card>                 

        <Card style={{ fontFamily: "Verdana", fontSize: "11pt"}}> 
        <CardBody className="text-left">
          <CardTitle style={{ textAlign: "center", color: "black", fontWeight: "bolder"}}> Transfer Request </CardTitle>
        <CardSubtitle> College to which transfer is sought </CardSubtitle>  
        <CardText>
          <ListGroup>
                      
            <ListGroupItem> Option 1: <span style={{color: "red", fontWeight: "bold"}}> {profile._p && profile._p._transCollege ? TRANS_COLLEGES_LIST.filter(e => e.key === profile._p._transCollege).map(e => e.label) : "NA"} </span> </ListGroupItem>
            <ListGroupItem> Option 2: <span style={{color: "red", fontWeight: "bold"}}> {profile._p && profile._p._transCollege2 ? TRANS_COLLEGES_LIST.filter(e => e.key === profile._p._transCollege2).map(e => e.label) : "NA"} </span> </ListGroupItem>
            <ListGroupItem> Option 3: <span style={{color: "red", fontWeight: "bold"}}> {profile._p && profile._p._transCollege3 ? TRANS_COLLEGES_LIST.filter(e => e.key === profile._p._transCollege3).map(e => e.label) : "NA"} </span> </ListGroupItem>
            <ListGroupItem> Option 4: <span style={{color: "red", fontWeight: "bold"}}> {profile._p && profile._p._transCollege4 ? TRANS_COLLEGES_LIST.filter(e => e.key === profile._p._transCollege4).map(e => e.label) : "NA"} </span> </ListGroupItem>
            <ListGroupItem> Option 5: <span style={{color: "red", fontWeight: "bold"}}> {profile._p && profile._p._transCollege5 ? TRANS_COLLEGES_LIST.filter(e => e.key === profile._p._transCollege5).map(e => e.label) : "NA"} </span> </ListGroupItem>
            <ListGroupItem> Reason for Transfer: <span style={{color: "blue", fontWeight: "bold", textDecoration: "underline"}}> {profile._p && profile._p._transReason ? CURR_TRANSFER_REASON.filter(e => e.key === profile._p._transReason).map(e => e.label) : "NA"} </span> </ListGroupItem>          
            <ListGroupItem> Is the transfer requested to the city where the husband/wife is working: <span style={{color: "brown", fontWeight: "bold"}}> {profile._p && profile._p._spLoc ? YES_NO_ARRAY.filter(e => e.key === profile._p._spLoc).map(e => e.label) : "NA" } </span> </ListGroupItem>   
            <ListGroupItem> Have you got Transfer through Counselling previously: <span style={{color: "brown", fontWeight: "bold"}}> {profile._p && profile._p._pTransCouns ? YES_NO_ARRAY.filter(e => e.key === profile._p._pTransCouns).map(e => e.label) : "NA" } </span> </ListGroupItem>          
            
          </ListGroup>
          </CardText>
          </CardBody>   
          </Card>      
          

        

        <Card style={{ fontFamily: "Verdana", fontSize: "11pt"}}> 
        <CardBody className="text-left">
          <CardTitle style={{ textAlign: "center", color: "black", fontWeight: "bolder"}}> Spouse Working Details </CardTitle>
        <CardSubtitle> </CardSubtitle>  
        <CardText>
          <ListGroup>
                      
                     
            {
              profile._p && profile._p._spLoc && profile._p._spLoc.toString() === "Y" ?
              <>
              <ListGroupItem> Name and Address of office where Spouse is working: <span style={{color: "brown", fontWeight: "bold"}}> {profile._p && profile._p._spWorkAdd} </span> </ListGroupItem>  
              <ListGroupItem> Company Type: <span style={{color: "brown", fontWeight: "bold"}}> {profile._p && profile._p._spWorkCompType ? COMPANY_TYPES.filter(e => e.key === profile._p._spWorkCompType).map(e => e.label) : "NA"} </span> </ListGroupItem>          
              <ListGroupItem> Spouse Designation: <span style={{color: "brown", fontWeight: "bold"}}> {profile._p && profile._p._spDesig} </span> </ListGroupItem>          
              <ListGroupItem> Distance between the office where the applicant seeks transfer and the office where spouse works (in KM): <span style={{color: "brown", fontWeight: "bold"}}> {profile._p && profile._p._spWorkDist} </span> </ListGroupItem> 
              <ListGroupItem> Has the applicant has been transferred on request to the place of employment of his/her spouse before? : <span style={{color: "brown", fontWeight: "bold"}}> {profile._p && profile._p._pSpLocTrans ? YES_NO_ARRAY.filter(e => e.key === profile._p._pSpLocTrans).map(e => e.label) : "NA"} </span> </ListGroupItem> 
              {profile._p._pSpLocTrans && profile._p._pSpLocTrans.toString() === "Y" &&
                <ListGroupItem> When has the applicant been transferred to the place of employment of his/her spouse before? : <span style={{color: "brown", fontWeight: "bold"}}> {profile._p && profile._p._pSpLocTransDate ? profile._p._pSpLocTransDate : "NA"} </span> </ListGroupItem>          
              }
              
              </> 
              : <ListGroupItem> Not Applicable </ListGroupItem>
            }
          </ListGroup>
          </CardText>
          </CardBody>   
          </Card>        
        

       

        <Card style={{ fontFamily: "Verdana", fontSize: "11pt"}}> 
        <CardBody className="text-left">
          <CardTitle style={{ textAlign: "center", color: "black", fontWeight: "bolder"}}> Previously Transferred through Counselling Details </CardTitle>
        <CardSubtitle> </CardSubtitle>  
        <CardText>
          <ListGroup>     
          {profile._p && profile._p._pTransCouns && profile._p._pTransCouns.toString() === "Y" ?              
          <>
              <ListGroupItem> Order Date: <span style={{color: "brown", fontWeight: "bold"}}> {profile._p && profile._p._pTransOrderDate} </span> </ListGroupItem>
              
              <ListGroupItem> Order Number: <span style={{color: "brown", fontWeight: "bold"}}> {profile._p && profile._p._pTransOrderNumber} </span> </ListGroupItem>
          </>                        
           : <ListGroupItem> Not Applicable </ListGroupItem>}
              </ListGroup>
          </CardText>
          </CardBody>
        </Card>
        

        <Card style={{ fontFamily: "Verdana", fontSize: "11pt"}}> 
        <CardBody className="text-left">
          <CardTitle style={{ textAlign: "center", color: "black", fontWeight: "bolder"}}> Other Details </CardTitle>
        <CardSubtitle> </CardSubtitle>  
        <CardText>
          <ListGroup>                                           
              <ListGroupItem> In case of newly joined service, the date of joining service: <span style={{color: "brown", fontWeight: "bold"}}> {profile._p && profile._p._newJoinDate ? profile._p._newJoinDate : "NA"} </span> </ListGroupItem>
              
              <ListGroupItem>  Is there any disciplinary action pending against the applicant requesting transfer ? <span style={{color: "brown", fontWeight: "bold"}}> {profile._p && profile._p._activeDiscip ? YES_NO_ARRAY.filter(e => e.key === profile._p._activeDiscip).map(e => e.label) : "NA"} </span> </ListGroupItem>

              {profile._p && profile._p._activeDiscip && profile._p._activeDiscip.toString() === "Y" &&
              <ListGroupItem>  Disciplinary Action Pending Details ? <span style={{color: "brown", fontWeight: "bold"}}> {profile._p && profile._p._activeDiscipDetail} </span> </ListGroupItem>
              }

            <ListGroupItem>  Whether the applicant was transferred before based on a complaint ? <span style={{color: "brown", fontWeight: "bold"}}> {profile._p && profile._p._pTranComplaint ? YES_NO_ARRAY.filter(e => e.key === profile._p._pTranComplaint).map(e => e.label) : "NA"} </span> </ListGroupItem>

            {profile._p && profile._p._pTranComplaint && profile._p._pTranComplaint.toString() === "Y" &&
              <ListGroupItem>  Complaint Details : <span style={{color: "brown", fontWeight: "bold"}}> {profile._p && profile._p._pTranComplaintDetail} </span> </ListGroupItem>
            }

            <ListGroupItem> Whether the college you are working is Co-educational ? <span style={{color: "brown", fontWeight: "bold"}}> {profile._p && profile._p._coEd ? YES_NO_ARRAY.filter(e => e.key === profile._p._coEd).map(e => e.label) : "NA"} </span> </ListGroupItem>

            {profile._p && profile._p._coEd && profile._p._coEd.toString() === "Y" &&
              <ListGroupItem>  Are you the only female faculty in Your department? <span style={{color: "brown", fontWeight: "bold"}}> {profile._p && profile._p._aloneFemale ? YES_NO_ARRAY.filter(e => e.key === profile._p._aloneFemale).map(e => e.label) : "NA" } </span> </ListGroupItem>
            }

              </ListGroup>
          </CardText>
          </CardBody>
        </Card>

      </div>

      <div className="but-center"> 
                      
                      {/* <input type='submit' style={{color: "white"}} className='btn btn-warning' onClick={ e => onDown(e) } value="Download certificates"/> */}

                      <Dload2/>
        </div>

        
         
        <div className="profile-top my-3" style={{fontWeight: 'bold', background: 'lightblue', textAlign: "center"}}>
        <h5 style={{fontWeight: "bold", color: "black"}}>                  
              Forwarded by Principal
        </h5>
        </div>
        
      

        </div>

        
        <form className='form'>
          
          <div className="form-text text-center" style={{padding: "10px", fontWeight: 'bold', color: "red", textAlign: "center"}}> Enter Remarks </div> 
          <textarea cols="65" placeholder="" rows="3" className="textarea2" name="vremarks" value={vremarks} onChange = {e => onChange(e)} required ></textarea>
         
          <div className="form form-text text-center" style={{padding: "10px", fontWeight: 'bold', color: "red", textAlign: "center"}}> Name of the Principal </div>           
          <textarea placeholder="" cols="30" rows="1" className="textarea2" name="voname" value={voname} onChange = {e => onChange(e)} required ></textarea>

          <div class="my-3 form-check form-check-inline text-center" >
            <input class="form-check-input" type="checkbox" name="ux" checked={ux} value="ux" onChange={() => {setFormData({ ...formData, ux: !ux }); }}   />
            <label class="form-check-label" htmlFor="inlineCheckbox1">
              This is to Certify that Information given above is verified with Service Record of the Applicant and found correct.
            </label>
        </div>

        {/* <div class="my-3 form-check form-check-inline text-center" >
            <input class="form-check-input" type="checkbox" name="uxz" checked={uxz} value="uxz" onChange={() => {setFormData({ ...formData, uxz: !uxz }); }}   />
            <label class="form-check-label" htmlFor="inlineCheckbox1">
              Data given by the Faculty is verified and corrections if any are mentioned above
            </label>
        </div> */}
         
        </form>
        
        <div className="my-3 text-center">
        
        <button type='submit' className='btn btn-success' onClick={ e => onVerify(e) } > Save Remarks </button>
        
        <button type='submit' className='btn btn-primary' onClick={ e => onCan(e) } > Cancel </button>        
        
        {/* <input type='submit' className='btn btn-danger'  onClick={ e => onIncomplete(e) } value="Enable Upload for Candidate"/> */}
        
        </div>

        </div>
        </Fragment>
      ) : null
      
      )
      }
    
    </Fragment>
    </section>
  );
}

VDash.propTypes = {
  getProfileVer: PropTypes.func.isRequired,  
  getProfilePdf: PropTypes.func.isRequired,
  updateCV: PropTypes.func.isRequired,
  clearApplication: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  clearApplication2: PropTypes.func.isRequired,
  openCert: PropTypes.func.isRequired,
  gettfc: PropTypes.func.isRequired,
  listAll: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  profile: state.profile
});

export default connect(
  mapStateToProps,
  { getProfileVer, updateCV, clearApplication, getProfilePdf, clearApplication2, openCert, listAll, gettfc}
)(VDash);